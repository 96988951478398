import React from 'react';
import './style.css'


export default function VericalSpacer(props) {
  let { height } = props;

  return (
    <div style={{
      width: "auto",
      height: `${height}`
    }}></div>
  );
}
