import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import "react-datepicker/dist/react-datepicker.css";
import './FilterFlights.css'
import { getFirstAndLastFlightTimeAndDate, getGCSDrones, getGCSFlightPilots, getGCSFlightsCSVData } from "../../../api";
import DateTimePicker from "./DateTimePicker";
import { useFirstRender } from "../../../ReusableComponents/firstRender";
import { Popup } from "../../../ReusableComponents/reusableFunctions";
import ButtonWithIcon from "../../../ReusableComponents/ButtonWithIcon";
import downloadBurron from "../../../icons/DownloadVector.png"
import { ActivityPopup } from "../../../ReusableComponents/ActivityPopup";
const FlightsFilter = ({
    selectedTab,
    selectedDrone,
    onFilterDataChange,
    isPilotEnable,
    selectedPilot,
    defaultFromDateTime,
    defaultToDateTime,
    stats,
    filterState,
    flightCount
}) => {
    const [firstTime, setFirstTime] = useState(false)
    const [pilotsOptions, setPilotsOptions] = useState([])
    const [dronesOptions, setDronesOptions] = useState([])
    const [activityPopup, setActivityPopup] = useState([])

    const [filters, setFilters] = useState({
        fromDateTime: null,
        toDateTime: null,
        selectedDrones: [],
        selectedPilots: [],
        selectedTab: null,
    });

    const [openDatepicker, setOpenDatepicker] = useState({
        fromdatePicker: false,
        todatePicker: false,
    });

    const buttonOptions = [
        { id: 1, placeholder: "All", },
        { id: 2, placeholder: "Today", },
        { id: 3, placeholder: "Week", },
        { id: 4, placeholder: "Month", },
        // { id: 5, placeholder: "Year", },
        { id: 6, placeholder: "Custom", },
    ];

    const initData = async () => {
        setFirstTime(true)
        try {
            if (isPilotEnable) {
                const pilots = await getGCSFlightPilots()
                setPilotsOptions(pilots)
                if (selectedPilot) {
                    const pilot = pilots.find(p => p.pilot_id === selectedPilot.value)
                    if (pilot) setFilters(prev => ({
                        ...prev,
                        selectedPilots: [{
                            value: pilot.pilot_id,
                            label: pilot.pilot_name
                        }]
                    }))
                }
            }
            const drones = (await getGCSDrones()).drones
            if (selectedDrone) {
                const drone = drones.find(drone => selectedDrone === drone.drone_id || selectedDrone.value === drone.drone_id)
                if (drone) setFilters(prev => ({
                    ...prev,
                    selectedDrones: [{
                        value: drone.drone_id,
                        label: drone.drone_name
                    }]
                }))
            }
            setDronesOptions(drones)
            if (selectedTab === "Custom") {
                setFilters({
                    ...filters,
                    fromDateTime: new Date(defaultFromDateTime),
                    toDateTime: new Date(defaultToDateTime),
                    selectedTab: selectedTab
                })
            } else handleFilterChange("selectedTab", selectedTab || "All")
        } catch (e) {
            console.error(e.message)
        }
    }

    useEffect(() => {
        initData()
    }, [])


    const handleFilterChange = async (updatedField, value) => {
        if (updatedField === "selectedTab") {
            let fromDateTime = filters.fromDateTime;
            let toDateTime = filters.toDateTime;
            // if (value === "Year") {
            //     let fDate = new Date(new Date().getFullYear(), 0, 1);
            //     fDate.setHours(0, 0, 0, 1);
            //     fromDateTime = fDate;

            //     let tDate = new Date(new Date().getFullYear(), 11, 31);
            //     tDate.setHours(23, 59, 59, 999);
            //     toDateTime = tDate;
            // } else 
            if (value === "Month") {
                let fDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1
                );
                fDate.setHours(0, 0, 0, 1);
                fromDateTime = fDate;
                let tDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                );
                tDate.setHours(23, 59, 59, 999);
                toDateTime = tDate;
            } else if (value === "Week") {
                const today = new Date();
                const dayOfWeek = today.getDay();
                let fDate = new Date(today);
                fDate.setDate(today.getDate() - dayOfWeek);
                fDate.setHours(0, 0, 0, 1);
                fromDateTime = fDate;
                let tDate = new Date(today);
                tDate.setDate(today.getDate() + (6 - dayOfWeek));
                tDate.setHours(23, 59, 59, 999);
                toDateTime = tDate;
            } else if (value == "Today") {
                let fDate = new Date();
                fDate.setHours(0, 0, 0, 1);
                fromDateTime = fDate;
                let tDate = new Date();
                tDate.setHours(23, 59, 59, 999);
                toDateTime = tDate;
            } else if (value == "Custom") {
                let fDate = new Date(filters.fromDateTime);
                fromDateTime = fDate;
                let tDate = new Date(filters.toDateTime);
                toDateTime = tDate;
            } else {
                try {
                    const dates = await getFirstAndLastFlightTimeAndDate()
                    fromDateTime = new Date(dates.first_end_time)
                    toDateTime = new Date(dates.last_end_time)
                } catch (e) {
                    handleFilterChange("selectedTab", "Weekly")
                }
            }

            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedTab: value,
                fromDateTime,
                toDateTime,
            }));
        } else {
            let fromDateTime = filters.fromDateTime;
            let toDateTime = filters.toDateTime;
            if (updatedField === "fromDateTime" || updatedField === "toDateTime") {
                if (filters.selectedTab === "All") {
                    if (updatedField === "fromDateTime") {
                        fromDateTime = new Date(value);
                    } else {
                        toDateTime = new Date(value);
                    }
                } else if (filters.selectedTab === "Daily") {
                    if (updatedField === "fromDateTime") {
                        fromDateTime = new Date(value);
                    } else {
                        toDateTime = new Date(value);
                    }
                } else if (filters.selectedTab === "Monthly") {
                    if (updatedField === "fromDateTime") {
                        fromDateTime = new Date(value);
                    }
                    else if (updatedField === "toDateTime") {
                        toDateTime = new Date(value);
                    }
                } else if (filters.selectedTab === "Custom") {
                    if (updatedField === "fromDateTime") {
                        fromDateTime = value;
                    } else if (updatedField === "toDateTime") {
                        toDateTime = new Date(value);
                    }
                } else if (filters.selectedTab === "Weekly") {
                    if (updatedField === "fromDateTime") {
                        fromDateTime = new Date(value);
                    }
                    else if (updatedField === "toDateTime") {
                        toDateTime = new Date(value);
                    }
                }

                setFilters((prevFilters) => ({
                    ...prevFilters,
                    selectedTab: "Custom",
                }))

                setOpenDatepicker((prevFilters) => ({
                    ...prevFilters,
                    fromdatePicker: false,
                    todatePicker: false,
                }))

            } else {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    [updatedField]: value,
                }));
                return;
            }

            setFilters((prevFilters) => ({
                ...prevFilters,
                fromDateTime,
                toDateTime,
            }));
        }
    };

    const firstRender = useFirstRender()
    useEffect(() => {
        if (!firstRender) {

            if (filters.fromDateTime && filters.toDateTime && filters.selectedTab) onFilterDataChange({
                ...filters,
                selectedPilot: filters.selectedPilots[0]?.label,
                selectedDrone: filters.selectedDrones[0]?.label,
                drones: filters.selectedDrones.map(d => d.value),
                pilots: filters.selectedPilots.map(p => p.value),
                fromDateTime: filters.fromDateTime.toISOString() || null,
                toDateTime: filters.toDateTime.toISOString() || null,
                firstTime
            });
            if (firstTime) setFirstTime(false)
        }
    }, [filters]);

    const downloadCSV = (data) => {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'export.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const getFlightsCSVData = async () => {
        try {
            setActivityPopup({
                open: true,
                item: "WAIT",
                icon: "WAIT",
                msg: 'Please wait while creating csv file.',
            })
            const flights = await getGCSFlightsCSVData(filterState)
            console.log('flights', flights);
            if (flights.length > 0) {
                downloadCSV(flights)
                setActivityPopup({
                    open: true,
                    item: "COMPLETE",
                    icon: "COMPLETE",
                    msg: 'File downloaded successfully.',
                })
            } else {
                setActivityPopup({
                    open: true,
                    item: "ERROR",
                    icon: "ERROR",
                    msg: 'Data not found.',
                })
            }
        }
        catch (e) {
            setActivityPopup({
                open: true,
                item: "ERROR",
                icon: "ERROR",
                msg: e.message || 'Error',
            })

        }
    }


    return <>
        <ActivityPopup
            item={activityPopup?.item}
            open={activityPopup?.open}
            icon={activityPopup?.icon}
            action={activityPopup?.action}
            msg={activityPopup?.msg}
            close={() => { setActivityPopup(undefined) }}
            onClickOk={() => { }}
        />
        <div className="filters__section">
            <div className="first_section">
                <div className="stats">
                    {stats && Object.keys(stats).map((statKey, index) => {
                        return <>
                            {index > 0 &&
                                <div className="separator"></div>
                            }
                            <div className="stat_card">
                                <span>{statKey}</span>
                                <p>{stats[statKey]}</p>
                            </div>
                        </>
                    })}
                </div>
                <div className="duration_selectors">
                    {buttonOptions.map((button, i) => (
                        <button
                            style={{
                                borderLeft:
                                    i === 0 &&
                                    filters.selectedTab != button.placeholder &&
                                    "1px solid #C9C9C9",
                                borderRight:
                                    filters.selectedTab != button.placeholder &&
                                    "1px solid #C9C9C9",
                            }}
                            className={
                                filters.selectedTab === button.placeholder ? "active" : ""
                            }
                            onClick={() => {
                                handleFilterChange("selectedTab", button.placeholder)
                            }
                            }
                        >
                            {button.placeholder}
                        </button>
                    ))}
                </div>
            </div>
            <div>

            </div>
            <div className="second__section">
                <div className="Drones__Pilots__select">
                    {isPilotEnable &&
                        <div className="pilots_wrapper">
                            <span className="select_dropdown_label">Select Pilot</span>

                            <div title={filters.selectedPilots[0]?.label.length > 32 && filters.selectedPilots[0]?.label}>
                                <Select
                                    style={{ width: "330px" }}
                                    placeholder="Search..."
                                    // searchable={filters.selectedPilots?.length === 0 ? true : false}
                                    options={pilotsOptions?.map(pilot => ({
                                        label: pilot.pilot_name || '',
                                        value: pilot.pilot_id || ''
                                    }))}
                                    values={filters.selectedPilots}
                                    className="custom_dropdown"
                                    onChange={(values) => {
                                        handleFilterChange("selectedPilots", values)
                                    }
                                    }
                                />

                            </div>

                        </div>
                    }
                    <div className="pilots_wrapper">
                        <span className="select_dropdown_label">Select Drone</span>

                        <div title={filters.selectedDrones[0]?.label?.length > 32 && filters.selectedDrones[0]?.label}>
                            <Select
                                placeholder="Search..."
                                style={{ width: "330px" }}
                                // searchable={filters.selectedDrones?.length === 0 ? true : false}
                                options={dronesOptions?.map(drone => ({
                                    label: `${drone.uin} (${drone.drone_name})`,
                                    value: drone.drone_id,
                                }))}
                                searchBy={"label"}
                                values={filters.selectedDrones}
                                className="custom_dropdown"
                                onChange={(values) => {
                                    handleFilterChange("selectedDrones", values)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="dates__section">
                        <span>Select Duration & Time</span>
                        <DateTimePicker
                            formdatePicker={openDatepicker.fromdatePicker}
                            todatePicker={openDatepicker.todatePicker}
                            fromDateTime={filters.fromDateTime}
                            toDateTime={filters.toDateTime}
                            setOpenDatepicker={setOpenDatepicker}
                            handleFilterChange={handleFilterChange}
                        />
                    </div>
                </div>
                <ButtonWithIcon
                    text={'Download'}
                    icon={downloadBurron}
                    isDisabled={stats && (stats.Flights || stats['No. of Flights']) ? false : true}
                    isIconLeft={true}
                    fontSize={'16px'}
                    iconWidth={'18px'}
                    isBtnActive={true}
                    onClick={() => { getFlightsCSVData() }}
                    borderRadius={'10px'}

                />
            </div>
        </div>
    </>
};
export default FlightsFilter;