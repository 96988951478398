import React, { Component } from 'react';
import * as API from '../api.js';
import '../style.css';
import AeroGCSInactive from '../sidebaricon/AeroGCSInactive.png';
import leaseActive from '../icons/leaseActive.png'
import SideBar from '../SideBar'
import dateFormat from 'dateformat';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import { jsPDF } from "jspdf";
import Loader from '../icons/loader.svg';
import shareLease from '../icons/ShareLease.png'
import DeleteIcon from '../icons/deleteLease.png'
import printIcon from '../icons/printLease.png'
import DownloadReportIcon from '../icons/DownloadReportIcon.png'

export default class leaseDroneInvoice extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        lease: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        discount: 10,
        gst: 18,
        totalValue: 0,
        withGst: false,
        paid_status: false,
        invoice: {},
        dailycharges: 0,
        generate_date: new Date(),
        invoiceData: {},
        showProcessPopupMark: false,
        showProcessPopup: false,
    }

    invoiceStatusChange = () => {
        console.log('invoice statusChange');
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "invoice",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            let paid_status = !this.state.paid_status
            API.updateInvoiceStaus(paid_status, this.state.lease.id).then((updateRes) => {
                if (updateRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "COMPLETE",
                        processAction: "UPDATE",
                        processMessage: `Invoice updated sucessfully`,
                        showProcessPopup: true,
                        deleteInvoiceId: '',
                        showProcessPopupMark: false
                    }), () => {
                        this.invoiceData()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: updateRes.message,
                        showProcessPopup: true,
                        showProcessPopupMark: false
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Invoice",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: 'Something went wrong while updating invoice.',
                    showProcessPopup: true,
                    showProcessPopupMark: false
                }))
            })
        })
    }

    invoiceData = () => {
        API.invoiceData(this.state.lease.id ? this.state.lease.id : this.state.invoice.lease_id, this.state.discount, this.state.withGst, this.state.totalValue, this.state.lease.drone_id, this.state.paid_status, this.state.lease.customer_id, this.state.gst, this.state.dailycharges).then((inData) => {
            console.log('inData', inData);
            if (inData.message == 'OK') {
                this.setState({ loading: false })
            } else if (inData.invoiceData) {
                let data = inData.invoiceData
                this.setState((state) => ({
                    ...state,
                    invoiceData: data,
                    lease: data.leaseData,
                    withGst: data.gstflag,
                    totalValue: data.total_amount,
                    gst: data.gst,
                    discount: data.discount,
                    dailycharges: data.dailycharges,
                    paid_status: data.paid_status,
                    generate_date: data.generate_date
                }), () => {
                    this.setState({ loading: false })
                })
            } else {
                // window.history.back()
            }
        }, (e) => {
            console.log(e)
        })
    }

    deleteLeaseInvoice = () => {
        console.log('invoice delete');
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "invoice",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteLeaseInvoice(this.state.invoiceData.id).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Invoice deleted sucessfully',
                        showProcessPopup: true,
                    }), () => {
                        window.history.back()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting lease.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            showProcessPopupInvoiceShare: false,
            processMessage: null,
        }))
    }

    downloadPdf = async () => {
        let doc = new jsPDF('p', 'pt', 'a4')
        let pdfjs = document.getElementById("invoice-page")
        await doc.html(pdfjs, {
            callback: function (doc) {
                doc.save('invoice.pdf')
            }, x: 0, y: 5
        });
    }

    getAsset = async () => {
        let doc = new jsPDF('p', 'pt', 'a4')
        let pdfjs = document.getElementById("invoice-page")
        let pdfFile = {}
        await doc.html(pdfjs, {
            callback: function (doc) {
                // doc.save('invoice.pdf')
                let blobfile = doc.output('datauri')
                pdfFile = blobfile
                console.log('blobfile', blobfile);
            }, x: 0, y: 5
        });
        return pdfFile
    }

    shareInvoicePdf = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "invoice",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: "Sharing invoice on register email please wait...",
            showProcessPopupInvoiceShare: true,
        }), async () => {
            let myAsset = await this.getAsset()
            API.shareInvoicePdf(this.state.invoiceData.id, myAsset, this.state.invoiceData.leaseData.email, this.state.invoiceData.leaseData.customer_name).then((shareRes) => {
                if (shareRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "COMPLETE",
                        processAction: "ADD",
                        processMessage: "Invoice  Shared to customer's email successfully",
                        showProcessPopupInvoiceShare: true,
                    }))
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Invoice",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: shareRes.message,
                        showProcessPopupInvoiceShare: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "invoice",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: 'Something went wrong while sharing invoice.',
                    showProcessPopupInvoiceShare: true,
                }))
            })
        })
    }


    componentWillMount() {
        document.title = `Lease-Invoice`;
        if (this.props.location.state) {
            let { user, type, lease, withGst, totalValue, gst, discount, dailycharges, paid_status, invoice } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                lease,
                withGst,
                totalValue,
                gst,
                discount,
                dailycharges,
                paid_status,
                invoice,
            }), () => {
                this.invoiceData()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.setState({ loading: false })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: AeroGCSInactive,
                    text: "AeroGCS",
                    path: "/"
                },
                // {
                //     icon: DronesInactive,
                //     text: "Drones",
                //     path: "/drone_management/drones",
                // },
                // {
                //     icon: RPAS,
                //     text: "Drone Control Center",
                //     path: "/drone_control_center/all_drones",
                // },
                // {
                //     icon: ProjectsInActive,
                //     text: "Projects",
                //     path: "/projects",
                // }
                {
                    icon: leaseActive,
                    text: "Drone Lease",
                    path: "/drone_management/lease_dashboard",
                    selected: true
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode, // or expanded
            aeroGCSHelp: true,
        };
        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar"><div className="top-bar-text">Invoice</div></div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div className="main-content">
                            <ActivityPopup
                                item={this.state.itemName}
                                open={this.state.showProcessPopup}
                                icon={this.state.processPopupIcon}
                                action={this.state.processAction}
                                msg={this.state.processMessage}
                                close={() => this.closeActivityPopup()}
                                onClickOk={() => this.state.showProcessPopupMark ? this.invoiceStatusChange() : this.deleteLeaseInvoice()}
                            />

                            <ActivityPopup
                                item={this.state.itemName}
                                open={this.state.showProcessPopupInvoiceShare}
                                icon={this.state.processPopupIcon}
                                action={this.state.processAction}
                                msg={this.state.processMessage}
                                close={() => this.closeActivityPopup()}
                                onClickOk={() => this.shareInvoicePdf()}
                            />

                            <div className="page" style={{ padding: "0px", height: "auto", minHeight: "800px", paddingBottom: "0px", width: "600px", minWidth: "600px", marginTop: '20px' }}>
                                <div id='leaseInvoiceIcons' style={{ display: 'flex', height: '25px', alignItems: 'center', justifyContent: "flex-end", position: 'sticky', marginBottom: '-40px', marginRight: '40px' }}>
                                    {this.state.lease.customer_name ? <div style={{ marginRight: '10px' }}><img src={shareLease} style={{ width: '18px', height: '18px', cursor: "pointer", }}
                                        onClick={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                itemName: "Lease",
                                                processPopupIcon: "WARNING",
                                                processAction: "ADD",
                                                processMessage: "Are you sure you want to share this invoice to customer's email ?",
                                                showProcessPopupInvoiceShare: true,
                                            }))
                                        }} />
                                    </div> : ''}
                                    <div style={{ marginRight: '10px' }}><img src={DownloadReportIcon} style={{ width: '18px', height: '18px', cursor: "pointer", }}
                                        onClick={() => {
                                            this.downloadPdf();
                                        }} />
                                    </div>
                                    <div style={{ marginRight: '10px' }}><img src={printIcon} style={{ width: '18px', height: '18px', cursor: "pointer", }}
                                        onClick={() => {
                                            window.print();
                                        }} />
                                    </div>
                                    <div style={{ marginRight: '10px' }}><img src={DeleteIcon} style={{ width: '18px', height: '18px', cursor: "pointer", }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            itemName: "Lease",
                                            processPopupIcon: "WARNING",
                                            processAction: "DELETE",
                                            processMessage: "Are you sure you want to delete this invoice ?",
                                            showProcessPopup: true,
                                        }))
                                    }} /></div>
                                    <div className="addcustomer-button-empty-dashboard" style={{ cursor: "pointer", padding: "6px 15px" }} onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            showProcessPopupMark: true,
                                            itemName: "Lease",
                                            processPopupIcon: "WARNING",
                                            processAction: "UPDATE",
                                            processMessage: `Are you sure you want to mark ${this.state.paid_status ? 'Unpaid' : 'Paid'} these invoice ?`,
                                            showProcessPopup: true,
                                        }))
                                    }}>{this.state.paid_status ? 'Mark Unpaid' : 'Mark Paid'}</div>
                                </div>
                                <div id="invoice-page" className='leaseInvoice'>
                                    <div id="invoice-contents1" style={{ padding: '0px' }}>
                                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#EAEAEA', padding: '15px 40px' }}>
                                            <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center' }}>
                                                {/* <div><img src={invoice1} style={{ width: '23px', height: '23px' }} /></div> */}
                                                <div style={{ fontSize: '20px', fontWeight: '500' }}>Invoice</div>
                                            </div>

                                        </div>

                                        <div style={{ width: '100%', marginTop: '25px', marginBottom: '25px', padding: '0px 40px' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontSize: '14px', textAlign: 'left' }}>
                                                    <div style={{ fontSize: '19px', fontWeight: '500' }}>{this.state.lease.customer_name ? this.state.lease.customer_name : 'Unknown'}</div>
                                                </div>
                                                <div style={{ fontSize: '14px', textAlign: 'right' }}>
                                                    <div>{`Date ${dateFormat(this.state.generate_date, 'dd/mm/yyyy')}`}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontSize: '14px', textAlign: 'left', }}>
                                                    <div style={{ fontSize: '11px', }}>{`Drone Id : ${this.state.lease.drone_id ? this.state.lease.drone_id : '-'}`}</div>
                                                </div>
                                                <div style={{ fontSize: '14px', textAlign: 'right' }}>
                                                    <div>{`Phone No. ${this.state.lease.contact_number ? this.state.lease.contact_number : '-'}`}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontSize: '14px', textAlign: 'left', }}>
                                                    <div>{this.state.lease.gst_no ? `GST No. ${this.state.lease.gst_no}` : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ height: '2px', backgroundColor: '#3C3C3C' }} />
                                        <div style={{ padding: '30px 40px 0px 40px', fontSize: '14px' }}>
                                            <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>Drone daily charges</div>
                                                <div>Rs. {this.state.dailycharges}</div>
                                            </div>
                                            <hr style={{ margin: '5px 0px' }} />
                                            <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>{this.state.lease.totalDays < 2 ? `Charge for ${this.state.lease.totalDays} day` : `Charges for ${this.state.lease.totalDays} days`}</div>
                                                <div>Rs. {Number(this.state.lease.totalDays) * Number(this.state.dailycharges)}</div>
                                            </div>
                                            <hr style={{ margin: '5px 0px' }} />
                                            {this.state.withGst ? <>
                                                <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>GST @18%</div>
                                                    <div>Rs. {Math.round((Number((this.state.gst / 100) * (Number(this.state.lease.totalDays) * Number(this.state.dailycharges)))))}</div>
                                                </div>
                                                <hr style={{ margin: '5px 0px' }} />
                                            </> : ''}
                                            <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div>Discount</div>
                                                <div>{this.state.discount}%</div>
                                            </div>
                                            <hr style={{ margin: '10px 0px' }} />
                                            <div style={{ marginTop: '350px' }}>
                                                <hr style={{ margin: '5px 0px', height: '1px', backgroundColor: '#3C3C3C' }} />
                                                <div style={{ width: "100%", display: 'flex', height: '25px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div style={{ fontWeight: '500', fontSize: '16px' }}>Total</div>
                                                    <div style={{ fontWeight: '500', fontSize: '16px' }}>Rs. {this.state.totalValue}</div>
                                                </div>
                                                <hr style={{ margin: '10px 0px', height: '1px', backgroundColor: '#3C3C3C' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ></div>

                        </div>}
                </div>
            </div > : <div />
        );
    }
}
