import React, { useState } from 'react'
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import ButtonWithIcon from './ReusableComponents/ButtonWithIcon'
import DropDownWithButton from './ReusableComponents/DropDownWithButton'
import Select from 'react-dropdown-select'
import { Popup } from './ReusableComponents/reusableFunctions'
import { registerDrone } from './api'

const RegisterDronePopup = ({ onRequestClose }) => {
    const [drone, setDrone] = useState({
        droneID: '',
        droneName: '',
        uin: '',
        model: '',
        serialNo: '',
        fcsNo: '',
        rcsNo: '',
        board_type: '',
        green_build_number: '',
        device_ram: '',
        android_version: '',
        device_model_name: '',
        firmware_version: '',
        license_type: '',
    })
    const [error, setError] = useState({})
    const isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9]+$/.test(value);
    }
    const isAlphaNumericWithUnderscore = (value) => {
        return /^[a-zA-Z0-9_]+$/.test(value);
    }
    const isAlphaNumericWithDecimal = (value) => {
        return /^[a-zA-Z0-9\.]+$/.test(value);
    }
    const isNumericWithDecimal = (value) => {
        return /^[0-9\.]+$/.test(value);
    }
    const isAlphaNumericWithSpace = (value) => {
        return /^[a-zA-Z0-9\s]+$/.test(value);
    }
    const isValueContainingOnlySpaces = (value) => {
        return value.trim() === ""
    }
    const is_Value_AplhaNumeric_UnderScore_Space = (value) => {
        return /^[a-zA-Z0-9_\s]+$/.test(value);
    }
    const handleRegisterDrone = async () => {
        Popup.alert("WAIT", "Registering drone, please wait...")
        try {
            drone.device_ram = `${drone.device_ram} GB`
            await registerDrone(drone)
            Popup.alert("COMPLETE", "Drone registered successfully", () => onRequestClose(true), () => onRequestClose(true))
        } catch (err) {
            console.log(err);
            Popup.alert("ERROR", err.message || "Something went wrong while registering drone")
        }
    }
    const isRegisterDisabled = () => {
        if (!drone.droneID ||
            !drone.droneName ||
            !drone.uin ||
            !drone.model ||
            !drone.serialNo ||
            !drone.fcsNo ||
            !drone.rcsNo ||
            !drone.board_type ||
            !drone.green_build_number ||
            !drone.device_ram ||
            !drone.android_version ||
            !drone.device_model_name ||
            !drone.firmware_version ||
            !drone.license_type ||
            error?.["droneID"] ||
            error?.["droneName"] ||
            error?.["uin"] ||
            error?.["model"] ||
            error?.["serialNo"] ||
            error?.["fcsNo"] ||
            error?.["rcsNo"] ||
            error?.["board_type"] ||
            error?.["green_build_number"] ||
            error?.["device_ram"] ||
            error?.["android_version"] ||
            error?.["device_model_name"] ||
            error?.["firmware_version"] ||
            error?.["license_type"]
        ) return true;
        else return false;
    }

    const validateParamters = (name, value) => {
        setError(prev => ({

            ...prev, [name]: undefined
        }))
        switch (name) {
            case 'droneID':

                if (!isAlphaNumeric(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Drone ID should only contain alphanumeric characters'
                        }
                    }))
                }
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Drone ID should be between 1 and 30 characters'
                        }
                    }))
                }
                return value.toUpperCase()
            case 'droneName':

                if (!is_Value_AplhaNumeric_UnderScore_Space(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Drone name should only contain alphanumeric characters with optional underscore or space'
                        }
                    }))
                }
                if (isValueContainingOnlySpaces(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Drone name should not contain any spaces'
                        }
                    }))
                }
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Drone name should be between 1 and 30 characters'
                        }
                    }))
                }
                return value;
            case 'uin':
                if (value.length < 6 || value.length > 16) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'UIN should be between 6 and 16 characters'
                        }
                    }))
                }
                if (!isAlphaNumeric(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'UIN should only contain alphanumeric characters'
                        }
                    }))

                }
                return value;
            case 'model':
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Model no. should be between 1 and 30 characters'
                        }
                    }))
                }
                if (!isAlphaNumeric(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Model no. should contain only alphanumeric characters'
                        }
                    }))
                }
                return value;
            case 'device_model_name':
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Device Model Name should be between 1 and 30 characters'
                        }
                    }))
                }
                if (isValueContainingOnlySpaces(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Device Model Name should not contain only spaces'
                        }
                    }))
                }
                return value;

            case 'device_ram':
                if (Number(value) < 1 || Number(value) > 100 || value == 'e') {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Device RAM should be between 1 and 100 GB'
                        }
                    }))
                }
                return (value);
            case 'firmware_version':
                if (value.length > 40 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Firmware Version should be between 1 and 40 characters'
                        }
                    }))

                }
                if (isValueContainingOnlySpaces(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Firmware Version should not contain only spaces'
                        }
                    }))
                }
                return value;
            case 'license_type':
                return value;
            case 'android_version':
                if (isValueContainingOnlySpaces(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Android version should not contain only spaces'
                        }
                    }))
                }
                if (!isAlphaNumericWithSpace(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Android version should only contain alphanumeric characters and spaces'
                        }
                    }))
                }
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Android version should be between 1 and 30 characters'
                        }
                    }))

                }
                return value;
            case 'green_build_number':
                if (!isNumericWithDecimal(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'AeroGCS GREEN Build Number should only contain numeric characters with optional decimal point'
                        }
                    }))
                }
                if (value.length > 25 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'AeroGCS GREEN Build Number should be between 1 and 25 characters'
                        }
                    }))
                }
                return value;
            case 'board_type':
                if (value.length > 30 || value.length <= 0) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Board type should be between 1 and 30 characters'
                        }
                    }))
                }
                if (isValueContainingOnlySpaces(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Board type should not contain only spaces'
                        }
                    }))
                }
                return value;
            case 'rcsNo':
                if (value.length < 6 || value.length > 16) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'RC Serial no. should be between 6 and 16 characters'
                        }
                    }))
                }
                if (!isAlphaNumericWithDecimal(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]:
                        {
                            name,
                            message: 'RC Serial no. should only contain alphanumeric characters with an optional decimal point'
                        }
                    }))
                }
                return value;
            case 'fcsNo':
                if (value.length < 6 || value.length > 16) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'FC Serial no. should be between 6 and 16 characters'
                        }
                    }))
                }
                if (!isAlphaNumericWithDecimal(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'FC Serial no. should only contain alphanumeric characters with an optional decimal point'
                        }
                    }))
                }
                return value;
            case 'serialNo':
                if (value.length < 6 || value.length > 16) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Serial no. should be between 6 and 16 characters'
                        }
                    }))
                }
                if (!isAlphaNumericWithDecimal(value)) {
                    setError(prev => ({
                        ...prev,
                        [name]: {
                            name,
                            message: 'Serial no. should only contain alphanumeric characters with an optional decimal point'
                        }
                    }))
                }
                return value;
        }


    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let filteredValue = validateParamters(name, value)
        setDrone({ ...drone, [name]: filteredValue });

    }
    return (
        <AnimatedModal
            isOpen={true}
            height="670px"
            width="700px"
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                <div style={{ textAlign: 'center', fontSize: '16px', color: '#3c3c3c', fontWeight: '500', borderBottom: '1px solid rgba(0,0,0,.1)', paddingBottom: '10px', marginBottom: '10px', padding: '12px 0px' }}>Register Drone</div>
                <form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', padding: '25px 5px 25px 25px' }}>
                    <div style={{ display: 'grid', columnGap: '45px', gridTemplateColumns: '1fr 1fr', justifyItems: 'center', rowGap: '10px', overflowY: 'auto', height: '530px', paddingRight: "20px" }}>

                        {/* Drone ID */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Drone ID</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="droneID"
                                    value={drone.droneID}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['droneID']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['droneID'].message}</div>
                            }
                        </div>

                        {/* Drone Name */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Drone Name</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="droneName"
                                    value={drone.droneName}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['droneName']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['droneName'].message}</div>
                            }
                        </div>
                        {/* UIN */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> UIN</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="uin"
                                    value={drone.uin}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['uin']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['uin'].message}</div>
                            }
                        </div>
                        {/* Model */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Model Number</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="model"
                                    value={drone.model}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['model']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['model'].message}</div>
                            }
                        </div>

                        {/* Serial No */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Serial Number</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="serialNo"
                                    value={drone.serialNo}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['serialNo']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['serialNo'].message}</div>
                            }
                        </div>

                        {/* FCS No */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> FC Serial Number</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="fcsNo"
                                    value={drone.fcsNo}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['fcsNo']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['fcsNo'].message}</div>
                            }
                        </div>

                        {/* RCS NO */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> RC Serial Number</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="rcsNo"
                                    value={drone.rcsNo}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['rcsNo']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['rcsNo'].message}</div>
                            }
                        </div>

                        {/* Board Type */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Board Type</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="board_type"
                                    value={drone.board_type}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['board_type']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['board_type'].message}</div>
                            }
                        </div>

                        {/* Green Build No. */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> AeroGCS GREEN Build Number</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="green_build_number"
                                    value={drone.green_build_number}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['green_build_number']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['green_build_number'].message}</div>
                            }
                        </div>
                        {/* Device RAM */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Device RAM</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    outline: 'none',
                                    border: '0.5px solid #C9C9C9',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '0px 5px'
                                }}>

                                    <input type={'number'}
                                        max={100}
                                        style={{ border: 'none', outline: 'none', height: '100%', width: '100%' }}
                                        name="device_ram"
                                        value={drone.device_ram}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    <span style={{ fontSize: '12px', color: '#555C67', }}>
                                        GB
                                    </span>
                                </div>
                            </div>
                            {error?.['device_ram']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['device_ram'].message}</div>
                            }
                        </div>

                        {/* Android Version */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Android Version </div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="android_version"
                                    value={drone.android_version}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['android_version']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['android_version'].message}</div>
                            }
                        </div>


                        {/* Device Model Name */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Device Model Name</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="device_model_name"
                                    value={drone.device_model_name}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['device_model_name']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['device_model_name'].message}</div>
                            }
                        </div>

                        {/* Firmware Version */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Firmware Version</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <input type={'text'} style={{
                                    outline: 'none',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '11px',
                                    height: '39px',
                                    padding: '2px 5px',
                                    width: '100%'
                                }} name="firmware_version"
                                    value={drone.firmware_version}
                                    onChange={(e) => { handleInputChange(e) }}
                                />
                            </div>
                            {error?.['firmware_version']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['firmware_version'].message}</div>
                            }
                        </div>

                        {/* Licence Type */}
                        <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontSize: '12px', color: '#555C67', textAlign: 'left' }}> Licence Type</div>
                            <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px", width: '100%' }}>
                                <Select
                                    searchable={false}
                                    dropdownPosition='top'
                                    multi={false}
                                    style={{ width: "297.5px", fontSize: '12px', borderRadius: '5px', textAlign: 'left', paddingRight: '10px' }}
                                    placeholder="Search..."
                                    options={[
                                        { label: "OEM", value: "OEM" },
                                        { label: "15 Days Trial", value: "15 Days Trial" },
                                    ]}
                                    values={[drone.license_type]}
                                    className="register-drone-popup"
                                    onChange={(values) => {
                                        if (values?.[0]) {
                                            handleInputChange({ target: { name: 'license_type', value: values[0].value } })
                                        }
                                    }
                                    }
                                />
                            </div>
                            {error?.['license_type']
                                && <div style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{error?.['license_type'].message}</div>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                        <ButtonWithIcon text={'Cancel'} onClick={onRequestClose} />
                        <ButtonWithIcon isDisabled={isRegisterDisabled()} isBtnActive text={'Register'} onClick={handleRegisterDrone} />
                    </div>
                </form>
            </div>

        </AnimatedModal>
    )
}

export default RegisterDronePopup