import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import { Link, Redirect } from 'react-router-dom';

export default class AdminAssignService extends Component {
    state = {
        subserv: [],
        services: [],
        user: {},
        serviceStatus: [],
        allservices: [],
        type: "",
        user_info: {
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            password: "",
            phone: "",
            userid: ""
        },
        successParams: {
            title: "Assign Services",
            message1: "Services Updated Successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "View All Users",
            buttonLink: "/admin/users",
        },
        redirect: false,
        config: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    }
    successRedirectComponent = () => {
        let { user_info } = this.state
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode,
                    config: this.state.config
                }
            }} />
        }
    }
    saveChangesClickHandler = () => {
        let { serviceStatus } = this.state;
        serviceStatus.forEach((service, i) => {
            if (service.assigned == true) {
                this.state.subserv.push(service)
            }
        })
        API.postServicesAssignment({ "services": this.state.subserv }, this.state.user_info.userid).then((result) => {
            if (result == "services updated successfully") {
                this.setState((state) => ({
                    ...state,
                    redirect: true
                }))
            } else {
                alert("Save failed! Please try again later");
            }
        }, (e) => {
            alert("Save failed! Please try again later");
        })
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }
    componentWillMount() {
        if (this.props.location.state) {
            document.title = " Assign Services - Admin";
            let { user, type, user_info, serviceStatus, } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info,
                serviceStatus,

            }), () => {
            })
        } else {
            window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/admin/dashboard");
            return;
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
                
            })
        }
    }
    servicesubscribeOnChangeHandler = (event) => {
        let t = event.target;
        let { serviceStatus } = this.state;
        serviceStatus.forEach((service, i) => {
            if (service.title == t.name.split("checked_")[1]) {
                service.assigned = t.checked;
            }
            if (i == serviceStatus.length - 1) {
                this.setState((state) => ({
                    ...state,
                    serviceStatus,
                }))
            }
        });
    }
    render() {
        let { user, type, user_info, } = this.state
        return (
            user.username ? <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new"><div className="top-bar-text-new">Assign Services</div></div>
                    <div className="main-content">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px" }}>
                            {this.successRedirectComponent()}
                            <div className="customer-details-top-section">
                                <img className="customer-details-logo" style={{ borderRadius: "50%" }} src={`/images/${user_info.first_name[0].toLowerCase()}.PNG`} />
                                <div className="services-content-title customer-details-company-name">{user_info.first_name + " " + user_info.last_name}</div>
                            </div>
                            <div className="customer-details-middle-section">
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">First Name:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.first_name}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Last Name:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.last_name}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Email:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.username}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Phone Number:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.phone}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Address:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-details-bottom-section">
                                <table className="customers-table">
                                    <tr className="customers-table-header-row">
                                        <th>Services</th>
                                        <th>Subscribed</th>
                                    </tr>                                       
                                        {this.state.serviceStatus.filter(service => (!(this.state.user_info.role_id == '8b28f377-c02f-4f3c-8bd7-f4794c066546') || (service.title)) && (!(this.state.user_info.organization == "31e5a4fd-150f-4600-a233-c1ed88bb59b3") || (service.title))).map((service) =>
                                        <tr className="customers-table-data-row">
                                            <td>{service.title}</td>
                                            <td><input className="assign-drones-checkbox" name={"checked_" + service.title} type="checkbox" defaultChecked={service.assigned} onChange={this.servicesubscribeOnChangeHandler} /></td>
                                        </tr>
                                     )}
                                </table>
                                <div style={{ marginLeft: "auto", width: "fit-content", marginTop: "30px" }}>
                                    <button onClick={this.saveChangesClickHandler} style={{ border: "none", outline: "none", background: "white", padding: "0" }}>
                                        <div className="addcustomer-button-empty-dashboard" >Save Changes</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div />
        );
    }
}
