
import React, { Component } from 'react';
import StepComponent from './StepComponent';
import ReactJoyride, { STATUS } from 'react-joyride';
import { skipTrainingTaskTour, skipDetectionTaskTour, skipAnnotationTaskTour } from '../reusableFunctions';
import AppConstants from '../../AppConstants';
import { ActivityPopup } from '../ActivityPopup';

class VerticalStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTour: false,
      showProcessPopup: false,
      processPopupIcon: null,
      processMessage: null,
      guideSteps: this.props.taskType === 1 ? [
        {
          disableBeacon: true,
          target: '#step1',
          content: (<div style={AppConstants.TEXTSTYLING}><p>Add task name and select project</p></div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 1 : Create Task</div>),
          placement: 'right',
          styles: {
            options: {
              width: 250,
            },
          },
        },
        {
          target: '#step2',
          content: (<div style={AppConstants.TEXTSTYLING}><p>Upload images or <br />select from existing imageset</p></div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 2 : Upload Images</div>),
          placement: 'right',
          styles: {
            options: {
              width: 250,
              height: 200
            },
          },
        },
        {
          target: '#step3',
          content: (<div style={AppConstants.TEXTSTYLING}>Annotate/label images for objects to be detected</div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 3 : Annotate Images</div>),
          placement: 'right',
          styles: {
            options: {
              width: 300,
              height: 200
            },
          },
        },
        {
          target: '#step4',
          content: (<div style={{ textAlign: 'left', fontSize: '12px', height: '30px', color: '#3c3c3c' }}>Select training parameters such as <br /> accuracy, purpose of training</div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 4 : Training Parameters</div>),
          placement: 'right',
          styles: {
            options: {
              width: 300,
              height: 200
            },
          },
        },
        {
          target: '#step5',
          content: (<div style={AppConstants.TEXTSTYLING}>View reports once task is completed </div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 5 : View Reports</div>),
          placement: 'top',
          styles: {
            options: {
              width: 250,
              height: 200
            },
          },
        },
      ] : [
        {
          disableBeacon: true,
          target: '#step1',
          content: (<div style={AppConstants.TEXTSTYLING}><p>Add task name and select project</p></div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 1 : Create Task</div>),
          placement: 'right',
          styles: {
            options: {
              width: 250,
            },
          },
        },
        {
          target: '#step2',
          content: (<div style={AppConstants.TEXTSTYLING}><p>Upload images or select  <br /> from existing imageset</p></div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 2 : Upload Images</div>),
          placement: 'right',
          styles: {
            options: {
              width: 250,
              height: 200
            },
          },
        },
        {
          target: '#step3',
          content: (<div style={AppConstants.TEXTSTYLING}>Select detection parameters such as <br />  {this.props.taskType == 2 ? 'detectors, purpose of detection' : 'class labels for annotation'}  </div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 3 : {this.props.taskType == 2 ? 'Detection' : 'Annotation'} Parameters</div>),
          placement: 'right',
          styles: {
            options: {
              width: 300,
              height: 200
            },
          },
        },
        {
          target: '#step4',
          content: (<div style={{ textAlign: 'left', fontSize: '12px', height: '30px', color: '#3c3c3c' }}>View reports once task is completed</div>),
          title: (<div style={AppConstants.TITLESTYLING}>Step 4 : View Reports</div>),
          placement: 'right',
          styles: {
            options: {
              width: 300,
              height: 200
            },
          },
        },
      ]
    }
  }

  updateTourState = (data) => {
    const { action, index, status, type } = data;
    if (this.props.taskType === 1) {
      if ([STATUS.SKIPPED].includes(status)) {
        skipTrainingTaskTour()
        setTimeout(() => {
          this.props.setTourComplete(0)
        }, AppConstants.TRIGGER_DELAY)

      } else if ([STATUS.FINISHED].includes(status)) {
        localStorage.setItem('trainingTaskTour', true)
        this.props.setTourComplete(1)
      }
    } else if (this.props.taskType === 2) {
      if ([STATUS.SKIPPED].includes(status)) {
        skipDetectionTaskTour()
        setTimeout(() => {
          this.props.setTourComplete(0)
        }, AppConstants.TRIGGER_DELAY)

      } else if ([STATUS.FINISHED].includes(status)) {
        localStorage.setItem('detectionTaskTour', true)
        this.props.setTourComplete(1)
      }
    } else if (this.props.taskType === 4) {
      if ([STATUS.SKIPPED].includes(status)) {
        skipAnnotationTaskTour()
        setTimeout(() => {
          this.props.setTourComplete(0)
        }, AppConstants.TRIGGER_DELAY)

      } else if ([STATUS.FINISHED].includes(status)) {
        localStorage.setItem('annotationTaskTour', true)
        this.props.setTourComplete(1)
      }
    }
  }



  render() {
    return (
      <div style={{
        marginTop: '20px'
      }}>
        {/* -------------- Activity Popup ----------------------- */}
        < ActivityPopup
          open={this.state.showProcessPopup}
          icon={this.state.processPopupIcon}
          msg={this.state.processMessage}
          close={() => this.closeActivityPopup()
          }
        />
        {/* --------------------------------------------------------------- */}
        {this.props.joyrideTrigger ?
          <ReactJoyride
            continuous={true}
            run={true}
            hideBackButton={true}
            hideCloseButton={true}
            showSkipButton={true}
            steps={this.state.guideSteps}
            disableOverlayClose={true}
            floaterProps={AppConstants.FLOATER_PROPS}
            locale={{ back: 'Back', close: 'Close', last: 'Next', next: 'Next', skip: 'Stop tour' }}
            callback={(e) => this.updateTourState(e)}
            styles={{
              buttonNext: AppConstants.NEXT_BUTTON,
              buttonSkip: AppConstants.SKIP_BUTTON,
              spotlight: { border: "2px solid #05426f", borderRadius: '5px', maxWidth: "210px !important", maxHeight: "45px !important", margin: "0px -43px" },
              options: AppConstants.MAIN_OPTIONS
            }}
          />
          : <></>}

        {this.props.steps.map((step, i) => {
          return <StepComponent
            expanded={this.props.expanded}
            previousStepCompleted={i == 0 ? true : this.props.steps[i - 1].completed ? true : false}
            step={step}
            taskType={this.props.taskType}
            stepNumber={i + 1}
            active={this.props.currentStep == i + 1}
            onclickActionButton={(step) => this.props.onClickActionButton(step)}
            onClickStep={(step) => this.props.onClickStep(step)}
            onClickExpandStep={(step) => this.props.onClickExpandStep(step)}
            isLastStep={i == this.props.steps.length - 1}
            stepHeight={this.props.stepHeight}
          />
        })}
      </div>
    )
  }
}

export default VerticalStepper

