import React, { Component } from 'react';
import * as API from '../api.js';
import dateFormat from 'dateformat';
import '../style.css';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal.js';
import { DeleteComponentTable } from '../ReusableComponents/Menu.js';
import LoaderComponent from '../ReusableComponents/LoaderComponent.js';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon.js';
import Plus from "../icons/addBlue.png";
import closeIcon from '../icons/blackClose.png'
import { getLimitedName } from '../ReusableComponents/reusableFunctions.js';
import CopyToClipboard from 'react-copy-to-clipboard';


export default class APIKeys extends Component {
    state = {
        user: {},
        type: "",
        userAPIKeys: [],
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        generateKey: false,
        apiName: '',
        loading: true,
        viewKey: false,
        showKey: undefined,
        apiKeyPopUp: false,
        copyKey: undefined,
        blueButtonStyle: {
            fontSize: '11px',
            fontWeight: '500',
            color: 'white',
            backgroundColor: '#2989cf',
            display: 'flex',
            width: '100px',
            height: '25px',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    componentWillMount() {
        document.title = "Settings - API Keys";
        if (this.props) {
            let { user, type } = this.props;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getAllAPIkeys()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    handleInputChange = (event, state) => {
        if (event.target.value.length <= 50) {
            this.setState({
                [state]: event.target.value
            })
        }
    }

    generateAPIKey = () => {
        this.setState({
            generateKey: false,
            itemName: "API key",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }, () => {
            API.generateAPIKey(this.state.apiName).then((apikey) => {
                console.log('apikey', apikey);
                if (apikey.message == 'OK') {
                    this.setState({
                        itemName: "API Key",
                        processPopupIcon: "COMPLETE",
                        processAction: "ADD",
                        processMessage: null,
                        showProcessPopup: true,
                        apiName: ''
                    }, () => { this.getAllAPIkeys() })
                } else {
                    this.setState({
                        itemName: "API Key",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: apikey?.message?.message || apikey?.message || 'Something wents wrong.',
                        showProcessPopup: true,
                        apiName: ''
                    })
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    itemName: "API Key",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                    apiName: ''
                })
            })
        })

    }

    getAllAPIkeys = () => {
        API.getAllAPIkeys().then((data) => {
            console.log('data', data);
            if (data.keysData) {
                this.setState({ userAPIKeys: data.keysData, loading: false })
            } else {
                this.setState({ userAPIKeys: [], loading: false })
            }
        }).catch(e => {
            console.log(e);
        })
    }

    deleteAPIkey = () => {
        this.setState((state) => ({
            ...state,
            itemName: "API Key",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteAPIKey(this.state.deleteSelect.id).then((res) => {
                console.log('res', res);
                this.getAllAPIkeys()
                this.setState({
                    itemName: "API Key",
                    processPopupIcon: "COMPLETE",
                    processAction: "DELETE",
                    processMessage: res.message,
                    showProcessPopup: true,
                })
                this.getAllAPIkeys()
            }).catch(e => {
                console.log(e);
                this.setState({
                    itemName: "API Key",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: null,
                    showProcessPopup: true,
                    apiName: ''
                })
            })
        })
    }

    render() {
        return (
            this.state.user?.username ? <div className="wrapper">
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteAPIkey()}
                />
                <AnimatedModal
                    isOpen={this.state.generateKey}
                    height={"200px"}
                    width={"500px"}
                >
                    <div style={{ textAlign: 'left', height: '100%', width: '100%', padding: '10px 20px' }}>
                        <div className="services-content-title" style={{ textAlign: "center", color: "#3C3C3C", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>Generate API Key</div>
                            <img src={closeIcon} alt='close'
                                style={{ height: '20px', cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({
                                        generateKey: false,
                                        apiName: ''
                                    })
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px', }}>
                            <div className='services-content-title' style={{ fontSize: '13px' }}>API Name</div>
                            <input style={{ width: '100%' }}
                                onChange={(event) => {
                                    let check = /^[A-Za-z]*$/;
                                    if (check.test(event.target.value)) this.handleInputChange(event, "apiName")
                                }}
                                value={this.state.apiName}
                                name="apiName"
                                className="add-user-form-text"
                                placeholder="Enter API name"
                            />
                        </div>
                        <div className="teamsInvite" style={{ display: "flex", gap: '20px', justifyContent: "right", position: "absolute", width: "calc(100% - 40px)", bottom: "15px", padding: '0px 20px' }}>
                            <div onClick={() => {
                                this.setState({
                                    generateKey: false,
                                    apiName: ''
                                })
                            }}
                                style={{ ...this.state.blueButtonStyle, color: '#555C67', backgroundColor: 'white', border: '1px solid #C9C9C9' }}
                            >
                                close
                            </div>
                            <div
                                style={{ ...this.state.blueButtonStyle, opacity: this.state.apiName == "" ? "0.5" : "1", cursor: this.state.apiName == "" ? "not-allowed" : "pointer" }}
                                onClick={() => { if (this.state.apiName != "") this.generateAPIKey() }}>
                                Generate
                            </div>
                        </div>
                    </div>
                </AnimatedModal>
                <AnimatedModal
                    isOpen={this.state.viewKey}
                    height={"150px"}
                    width={"675px"}
                >
                    <div style={{ textAlign: 'left', height: '100%', width: '100%', padding: '10px 20px' }}>
                        <div className="services-content-title" style={{ textAlign: "center", color: "#3C3C3C", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>API Key</div>
                            <img src={closeIcon} alt='close'
                                style={{ height: '20px', cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({
                                        showKey: undefined,
                                        viewKey: false,
                                        copyKey: undefined
                                    })
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px', display: 'flex', height: '35px', borderRadius: '7px', paddingLeft: '10px', justifyContent: 'center', alignItems: 'center', border: '1px solid #C9C9C9' }}>
                            <input
                                value={this.state.showKey}
                                name="apiName"
                                placeholder="Enter API name"
                                style={{ width: '100%', border: 'none', outline: 'none', fontSize: '13px' }}
                            />
                            <CopyToClipboard text={this.state.showKey}
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    backgroundColor: '#3988F9',
                                    width: '100px',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    borderTopRightRadius: '6px',
                                    borderBottomRightRadius: '6px',
                                    cursor: 'pointer'
                                }}
                                onCopy={(e) => {
                                    this.setState({ copyKey: { message: 'API key is copied!', isError: false } });
                                }}><div>Copy</div></CopyToClipboard>

                        </div>
                        {this.state.copyKey ?
                            <div style={{ color: this.state.copyKey.isError ? 'red' : 'green', fontSize: '11px', margin: '5px' }}>{this.state.copyKey.message}</div>
                            : <></>}

                    </div>
                </AnimatedModal >
                <div className='setting-containt' >
                    <div style={{ display: "flex", width: '100%', justifyContent: "space-between", alignItems: "center" }}>
                        <div></div>
                        <div style={this.state.blueButtonStyle}
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    generateKey: true,
                                }))
                            }}
                        >Add key</div>
                    </div>
                    <div className='scroll-container'
                        style={{ overflow: 'auto', marginTop: '20px', height: '90%', border: 'none', borderRadius: '8px' }}
                    >
                        {this.state.loading ?
                            <LoaderComponent />
                            : <table className="customers-table" style={{ marginTop: '0px' }}>
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '14%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '12%' }} />
                                <col style={{ width: '5%' }} />
                                {this.state.userAPIKeys.length > 0 ? <>
                                    <tr className="customers-table-header-row" style={{ background: "#E1EDFF" }}>
                                        <th>Name</th>
                                        <th>Key</th>
                                        <th style={{ textAlign: 'center' }}>Date Generated</th>
                                        <th style={{ textAlign: 'center' }}>Limit</th>
                                        <th style={{ textAlign: 'center' }}>Count</th>
                                        <th style={{ textAlign: 'center' }}>Status</th>
                                        <th style={{ textAlign: 'center' }}></th>
                                    </tr>
                                    {this.state.userAPIKeys.map((keyData =>
                                        <tr className="customers-table-data-row">
                                            <td>{getLimitedName(keyData.name, 15)}</td>
                                            <td style={{ wordBreak: 'break-word' }}>{keyData.key.substring(0, 15)}...<span style={{
                                                marginLeft: '5px',
                                                color: 'rgb(41, 137, 207)',
                                                padding: '2px 10px 2px 10px',
                                                fontSize: '10px',
                                                fontWeight: '500',
                                                outline: 'none',
                                                border: 'none',
                                                borderRadius: '3px',
                                                cursor: 'pointer',
                                            }}
                                                onClick={() => {
                                                    this.setState({ showKey: keyData.key, viewKey: true })
                                                }}
                                            >View</span></td>
                                            <td style={{ textAlign: 'center' }}>{dateFormat(keyData.date_created, 'dd/mm/yyyy')}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.total_limit}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.count}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.is_active == true ? 'Active' : 'Inactive'}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <DeleteComponentTable
                                                    style={{ margin: "auto" }}
                                                    onClickDelete={(e) => {
                                                        e.preventDefault();
                                                        this.setState((state) => ({
                                                            ...state,
                                                            deleteSelect: keyData,
                                                            itemName: "API Key",
                                                            processPopupIcon: "WARNING",
                                                            processAction: "DELETE",
                                                            processMessage: null,
                                                            showProcessPopup: true,
                                                        }))
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </>
                                    : <tr className="customers-table-data-row">
                                        <td colspan="7" style={{ textAlign: "center", }}>No API Keys Found</td>
                                    </tr>}
                            </table>}
                    </div>
                </div>

                {/* <div className="top-bar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="top-bar-text" style={{ top: '0', left: '20px' }}>API Keys</div>

                        <div style={{ marginLeft: "auto", width: "fit-content" }}>
                            <div style={{ marginRight: "10px" }}>
                                <ButtonWithIcon
                                    text="Key" width="84px"
                                    icon={Plus}
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            generateKey: true,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
                {/* <div className='scroll-container' style={{ width: "75vw", height: '72vh', overflow: 'auto', padding: '0px 20px 0px 20px', marginTop: '20px' }}>
                        {this.state.loading ? <LoaderComponent />
                            : <table className="customers-table" style={{ marginTop: '0px' }}>
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '10%' }} />
                                {this.state.userAPIKeys.length > 0 ? <>
                                    <tr className="customers-table-header-row">
                                        <th>Name</th>
                                        <th>Key</th>
                                        <th style={{ textAlign: 'center' }}>Date Generated</th>
                                        <th style={{ textAlign: 'center' }}>Limit</th>
                                        <th style={{ textAlign: 'center' }}>Count</th>
                                        <th style={{ textAlign: 'center' }}>Status</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                    {this.state.userAPIKeys.map((keyData =>
                                        <tr className="customers-table-data-row">
                                            <td>{keyData.name}</td>
                                            <td>{keyData.key}</td>
                                            <td style={{ textAlign: 'center' }}>{dateFormat(keyData.date_created, 'dd/mm/yyyy')}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.total_limit}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.count}</td>
                                            <td style={{ textAlign: 'center' }}>{keyData.is_active == true ? 'Active' : 'Inactive'}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <DeleteComponentTable
                                                    style={{ margin: "0 10px" }}
                                                    onClickDelete={(e) => {
                                                        e.preventDefault();
                                                        this.setState((state) => ({
                                                            ...state,
                                                            deleteSelect: keyData,
                                                            itemName: "API Key",
                                                            processPopupIcon: "WARNING",
                                                            processAction: "DELETE",
                                                            processMessage: null,
                                                            showProcessPopup: true,
                                                        }))
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </>
                                    : <tr className="customers-table-data-row">
                                        <td colspan="7" style={{ textAlign: "center", }}>No API Keys Found</td>
                                    </tr>}
                            </table>}
                    </div> */}
            </div >
                : <></>)
    }
}