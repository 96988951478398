import React from 'react';
import "./OverviewBox.css";
import threeDot from "../../icons/threeDot.svg"
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { DeleteComponentTable } from '../../ReusableComponents/Menu';

const OverviewBox = ({ linkData, boxImage, boxTitle, boxSubTitle, boxTime, activities }) => {
    return (
        <div className='overview_box'>
            <Link to={linkData}>
                <div className='overview_box_container'>
                    <div className='overview_box_data'>
                        <div className='overview_box_image'>
                            <img src={boxImage} alt='folder' />
                        </div>
                        <div className='overview_box_title_container'>
                            <div className='overview_box_data_container'>
                                <div className='overview_box_title'>{boxTitle.length >= 14 ? `${boxTitle.substring(0, 12)}...` : boxTitle}</div>
                                <div className='overview_box_subtitle'>{boxSubTitle}</div>
                            </div>
                            <div className='overview_box_time'>{dateFormat(boxTime, 'dd mmm yyyy hh:MM TT')}</div>
                        </div>
                    </div>
                    <div className='overviewBox_action_container'>
                        {activities && <td>
                            <DeleteComponentTable
                                style={{ margin: "5px 5px" }}
                                onClickDelete={activities.onClickDelete}
                                disableDelete={activities.disableDelete}
                                canEdit={activities.canEdit}
                                onClickEdit={activities.onClickEdit}
                                canManage={activities.canManage}
                                onClickManage={activities.onClickManage}
                                canRename={activities.canRename}
                                onClickRename={activities.onClickRename}
                                canExport={activities.canExport}
                                onClickExport={activities.onClickExport}
                                canDuplicate={activities.canDuplicate}
                                onClickDuplicate={activities.onclickDuplicate}
                                canDownload={activities.canDownload}
                                onClickDownload={activities.onClickDownload}
                            />
                        </td>}
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default OverviewBox