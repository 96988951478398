import React, { useState } from 'react'
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { Popup } from '../ReusableComponents/reusableFunctions'
import { addEmailToSettings } from '../api'
import closeBtn from "../icons/blackClose.png"

const AddNewEmailPopup = ({ addEmailCallback }) => {
    const [email, setEmail] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState("")
    const handleAddEmail = async (email) => {
        try {

            Popup.alert("WAIT", "Adding email ,please wait...")
            const resEmail = await addEmailToSettings(email)
            addEmailCallback(resEmail)
            Popup.alert("COMPLETE", "Email added successfully")
            onRequestClose()
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e.message || "Something went wrong while adding email")
        }
    }
    const onRequestClose = () => {
        setIsOpen(false)
        setEmail("")
    }
    const isAddEmailBtnDisabled = () => {
        return error || email.length == 0
    }
    function validateEmail(email) {
        setError("")
        if (email.length > 30) {
            setError('Number of characters should not be greater than 30');
        }

        // Regular expression to validate the email format
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address');
        }
    }
    return (<>
        <div style={{
            fontSize: '11px',
            fontWeight: '500',
            color: 'white',
            backgroundColor: '#2989cf',
            display: 'flex',
            width: '100px',
            height: '25px',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        }}
            onClick={() => setIsOpen(true)}
        >Add Email</div>
        <AnimatedModal
            isOpen={isOpen}
            width={"500px"}
            height={'230px'}
            onRequestClose={onRequestClose}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    if (!error) {
                        handleAddEmail(email)
                    }
                }}
                style={{ display: "flex", flexDirection: 'column', padding: "20px", justifyContent: "space-between", height: "100%", fontFamily: 'Lato' }}>

                <div style={{ fontSize: "20px", fontWeight: 600, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Add Email</span>
                    <img style={{ width: "22px", height: "22px", cursor: 'pointer' }} src={closeBtn} onClick={onRequestClose} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', textAlign: 'left' }}>
                    <div htmlFor="email" style={{ color: "#555C67", fontSize: '14px', fontWeight: 500 }}>
                        Email ID
                    </div>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                            validateEmail(e.target.value)
                            setEmail(e.target.value)
                        }}
                        placeholder="abc@gmail.com"
                        style={{
                            outline: 'none',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            fontSize: '13px',
                            height: '39px',
                            padding: '2px 5px',
                            width: '100%'
                        }}
                    />
                    {<div style={{ color: "red", fontSize: '12px', textAlign: 'left', height: "15px" }}>{error}</div>}
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: 'right',
                    gap: "20px"
                }}>
                    <button
                        type='button'
                        style={{
                            fontSize: '13px',
                            fontWeight: '500',
                            color: '#555C67',
                            backgroundColor: 'white',
                            display: 'flex',
                            width: '85px',
                            height: '30px',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            border: '1px solid #C9C9C9'
                        }} onClick={onRequestClose}>Cancel</button>


                    <button
                        disabled={isAddEmailBtnDisabled()}
                        style={{
                            fontSize: '13px',
                            fontWeight: '500',
                            color: 'white',
                            backgroundColor: '#2989cf',
                            opacity: isAddEmailBtnDisabled() ? 0.5 : 1,
                            display: 'flex',
                            width: '85px',
                            height: '30px',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: isAddEmailBtnDisabled() ? "default" : "pointer",
                        }}>Add</button>
                </div>
            </form>
        </AnimatedModal >
    </>
    )
}

export default AddNewEmailPopup