import React from "react"

import { Link } from "react-router-dom";
import RightArrow from "../../assets/icons/arrow-right.svg"

/**
To use this BreadCrump you have to pass data array of paths in the props.
data = [
    {
        displayName: "Projects",
        pathname: "/aerofarm/projects",
        state: {
            type,
            user,
            ...........
        },
        style:{style you want,if u want to use pass color otherwise it takes blue by default },
        className : u can pass also className to each breadCrumb
    },
        {
        displayName: "Projects",
        className : u can pass also className to each breadCrumb
        style:{style you want,u want to use pass color otherwise it takes blue by default }
        }  
        above object is the active page which you dont have to pass pathname or u can use it if u dont want to navigate
    }
]

also u can pass 2nd props which is dividerStyle

*/

export const BreadCrumbs2_0 = ({ data, dividerStyle }) => {

    return (
        <>
            <div className="bread-crumb-container">
                {data.map((breadCrumb, i) => {
                    const selected = i == data.length - 1
                    return <div >
                        {
                            breadCrumb.pathname ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Link
                                        to={{
                                            pathname: breadCrumb.pathname,
                                            state: breadCrumb.state,
                                        }}
                                    >
                                        <div className={selected ? 'selected-bread-crumb' : "bread-crumb"} >{breadCrumb?.displayName?.length > 16 ? `${breadCrumb.displayName.substring(0, 15)}...` : breadCrumb.displayName}</div>
                                    </Link>
                                    <span style={dividerStyle ? dividerStyle : { fontSize: "12px" }}>
                                        <img src={RightArrow} className="bread-crumbs-next-icon" />
                                    </span>
                                </div>
                                : <div onClick={() => {
                                    if (breadCrumb.onClick) {
                                        breadCrumb.onClick()
                                    }
                                }} className={selected ? 'selected-bread-crumb' : "bread-crumb"}>
                                    {breadCrumb?.displayName?.length > 16 ? `${breadCrumb.displayName.substring(0, 15)}...` : breadCrumb.displayName}
                                </div>
                        }
                    </div>
                })}
            </div>
        </>
    )
}
