import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from '../ReusableComponents/reusableFunctions.js';
import btnAdd from '../icons/btnAdd.svg';
import service from "../sidebaricon/Services.png";
import dateFormat from 'dateformat'
import SideBar from "../SideBar";
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import Loader from '../icons/loader.svg';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { connect } from "react-redux";
import { updateProfileDetails, updateSubscriptionData } from '../redux'
import AeroGCSInactive from '../sidebaricon/AeroGCSInactive.png';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import customerActive from '../icons/customerActive.png'
import leaseActive from '../icons/leaseActive.png'
import leaseStatActive from '../icons/leaseStatActive.png'
import leaseRevenueActive from '../icons/leaseRevenueActive.png'
import MenuDark from '../icons/MenuDark.png';
import { Pie, Line } from "react-chartjs-2";
import validator from 'validator'
import noLeaseGraph from '../icons/noLeaseGraph.png'
import noLeaseData from '../icons/noLeaseData.png'
import noCustomers from '../icons/noCustomers.png'
import noDrones from '../icons/noDrones.png'
import noInvoices from '../icons/noInvoices.png'
import AppConstants from "../AppConstants.js";
import SubscriptionExpireModal from "../subscription/Reusable/SubscriptionExpireModal.js";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={MenuDark} style={{ height: "14px" }} alt="I am alt" />
    </a>
));

class leaseDashboard extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        addCustomerPopUp: false,
        customerAddress: '',
        contactNumber: '',
        customerEmail: '',
        customerGovIdNo: '',
        customerName: '',
        customerPilotLicenseId: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        usersCustomers: [],
        deleteUserCust: '',
        customerId: '',
        lease: [],
        minDate: new Date(),
        pieLoading: true,
        pieData: '',
        totalCustomers: 0,
        droneLeaseOff: 0,
        droneLeaseOn: 0,
        totalDrone: 0,
        drones: [],
        invoices: [],
        addLeaseDronePopUp: false,
        selectedCustomer: '',
        selectedCustomerName: '',
        selectedDrone: '',
        selectedDroneName: '',
        leaseLoading: true,
        custLoading: true,
        droneLoading: true,
        invoiceLoading: true,
        leaseOnPopUp: false,
        leaseDetailsSelect: {},
        invoiceType: false,
        withGst: false,
        totalValue: 0,
        gst: 18,
        discount: 0,
        coveredRevenueAmount: 0,
        totalRevenueAmount: 0,
        dailycharges: 0,
        selectedLeaseDays: 0,
        selectedLeaseAmount: 0,
        leaseGstAmount: 0,
        noLeaseData: true,
        showSubscriptionExpirePopup: false
    };

    getAllInvoices = () => {
        console.log('inget invoices');
        API.getLeaseInvoices(null, null).then((leaseResult) => {
            console.log("invoice data", leaseResult);
            this.setState(state => ({
                ...state,
                invoices: leaseResult.invoiceData,
                loading: false,
            }), () => {
                this.setState({ invoiceLoading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }
    addUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addLeaseDronePopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.addUserCustomerDroneLease(this.state.selectedCustomer.id, this.state.selectedDrone.drone_id, this.state.fromDate, this.state.toDate, this.state.selectedDrone.drone_rent)
                .then((leaseRes) => {
                    console.log('leaseRes', leaseRes);
                    if (leaseRes.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Lease added sucessfully',
                            showProcessPopup: true,
                            fromDate: '',
                            toDate: '',
                            selectedDroneName: '',
                            selectedDrone: '',
                            selectedCustomer: '',
                            selectedCustomerName: '',
                        }), () => {
                            this.getAllUserDronesLease()
                            this.getLeaseStat()
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addLeaseDronePopUp: false,
                            itemName: "Lease",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: leaseRes.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addLeaseDronePopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding lease.',
                        showProcessPopup: true,
                    }))
                })
        })
    }

    onChangeListenerDrone = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value == -1) {
            this.setState((state) => ({
                ...state,
                selectedDrone: '',
                selectedDroneName: "",
            }))
        } else {
            this.setState((state) => ({
                ...state,
                selectedDrone: this.state.drones[key],
                selectedDroneName: key,
            }))
        }
    }

    onChangeListenerCust = (e) => {
        e.preventDefault();
        let key = e.target.value
        console.log(key);
        if (e.target.value == -1) {
            this.setState((state) => ({
                ...state,
                selectedCustomer: '',
                selectedCustomerName: "",
            }))
        } else {
            this.setState((state) => ({
                ...state,
                selectedCustomer: this.state.usersCustomers[key],
                selectedCustomerName: key
            }))
        }
    }

    componentWillUnmount() {
        updateOutTime(this.state.outId)
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getAllUserDronesLease = () => {
        API.getAllUserDronesLease(null, null).then((leaseData) => {
            console.log('leaseData', leaseData);
            this.setState(state => ({
                ...state,
                lease: leaseData.usersCustomersDroneLease,
            }), () => {
                this.setState({ leaseLoading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }

    getLeaseStat = () => {
        API.getLeaseStat().then((leaseSatat) => {
            console.log('leaseSatat', leaseSatat);
            let pieData = {
                labels: ['Active', 'Upcoming', 'Expired'],
                datasets: [
                    {
                        label: 'Lease analysis',
                        data: [leaseSatat.activeLease, leaseSatat.upcomingLease, leaseSatat.expiredLease],
                        backgroundColor: [
                            'rgba(96, 44, 175, 1)',
                            'rgba(0, 219 ,160, 1)',
                            'rgba(87, 254, 19 , 1)',
                        ],
                        borderColor: [
                            'rgba(255,255,255, 1)',
                            'rgba(255, 255 ,255, 1)',
                            'rgba(255, 255, 255 , 1)',
                        ],
                        borderWidth: 1,
                    }
                ]
            }

            this.setState(state => ({
                ...state,
                pieData: pieData,
                noLeaseData: leaseSatat.activeLease == 0 && leaseSatat.upcomingLease == 0 && leaseSatat.expiredLease == 0 ? true : false,
                droneLeaseOff: leaseSatat.droneLeaseOff,
                droneLeaseOn: leaseSatat.droneLeaseOn,
                totalCustomers: leaseSatat.totalCustomers,
                coveredRevenueAmount: leaseSatat.coveredRevenueAmount,
                totalRevenueAmount: leaseSatat.totalRevenueAmount,
                totalDrone: Number(leaseSatat.droneLeaseOff) + Number(leaseSatat.droneLeaseOn),
            }), () => {
                this.setState({ pieLoading: false })
            })

        }, (e) => {
            console.log(e)
        })
    }

    getAllUserCustomer = () => {
        API.getAllUserCustomer('lease', 0).then((data) => {
            console.log('data', data);
            if (data.usersCustomers && data.usersCustomers.length > 0) {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: data.usersCustomers
                }), () => {
                    this.setState({ custLoading: false })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: []
                }), () => {
                    this.setState({ custLoading: false })
                })
            }
        }, (e) => {
            console.log(e)
        })
    }

    onChangeHandler = (e) => {
        let t = e.target
        if (t.name == 'contactNumber') {
            console.log(t.value.length);
            if (t.value.length < 11) {
                this.setState((state) => ({
                    ...state,
                    [t.name]: t.value
                }))
            } else { console.log('contact number length is greater than 10'); }
        } else if (t.name == 'customerEmail') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toLowerCase()
            }))
        } else if (t.name == 'customerGovIdNo') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toUpperCase()
            }))
        } else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    addUserCustomer = () => {
        console.log('info added', this.state.customerName, this.state.contactNumber, this.state.customerEmail, this.state.customerAddress, this.state.customerGovIdNo, this.state.customerPilotLicenseId,);
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.addUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, 'lease').then((custAdd) => {
                    console.log("addUserCustomer", custAdd);
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Customer added sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.getLeaseStat()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding customer.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }

    getDronesData = () => {
        API.gcsDrones().then((result) => {
            this.setState(state => ({
                ...state,
                drones: result.drones,
                unregisteredAssignedDrones: result.unregisteredAssignedDrones,
                loading: false
            }), () => {
                this.setState({ droneLoading: false })
            })
        }, (e) => {
            console.log(e)
        })
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "User-Drone_lease";

        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
            if (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
        })

        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {
                this.getAllUserDronesLease()
                this.getLeaseStat()
                this.getAllUserCustomer()
                this.getDronesData()
                this.getAllInvoices()
                this.setState({ loading: false, })
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getAllUserDronesLease()
                    this.getLeaseStat()
                    this.getAllUserCustomer()
                    this.getDronesData()
                    this.getAllInvoices()
                    this.setState({ loading: false, })
                })
            }, (e) => {
                window.location.replace("/login")
            })
        }
    }


    deleteUserCustomerDroneLease = () => {
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Lease",
            processPopupIcon: "WAIT",
            processAction: "ADD",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomerDroneLease(this.state.deleteLeaseId).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Lease deleted sucessfully',
                        showProcessPopup: true,
                        deleteUserCust: ''
                    }), () => {
                        this.getAllUserDronesLease()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Lease",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting lease.',
                    showProcessPopup: true,
                }))
            })
        })
    }


    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    totalIvoiceAmout = () => {
        if (this.state.withGst) {
            let amountWithDiscount = (Number(this.state.discount) / 100) * Number(this.state.selectedLeaseAmount)
            let total = (Number(this.state.leaseGstAmount) + Number(this.state.selectedLeaseAmount)) - Number(amountWithDiscount)
            this.setState({ totalValue: total, })
        } else {
            let amountWithDiscount = (Number(this.state.discount) / 100) * Number(this.state.selectedLeaseAmount)
            let total = Number(this.state.selectedLeaseAmount) - Number(amountWithDiscount)
            this.setState({ totalValue: total })
        }
    }

    render() {
        let { user, type, } = this.state;
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: AeroGCSInactive,
                    text: "AeroGCS",
                    path: "/"
                },
                // {
                //     icon: DronesInactive,
                //     text: "Drones",
                //     path: "/drone_management/drones",
                // },
                // {
                //     icon: RPAS,
                //     text: "Drone Control Center",
                //     path: "/drone_control_center/all_drones",
                // },
                // {
                //     icon: ProjectsInActive,
                //     text: "Projects",
                //     path: "/projects",
                // }
                {
                    icon: leaseActive,
                    text: "Drone Lease",
                    path: "/drone_management/lease_dashboard",
                    selected: true
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode, // or expanded
            aeroGCSHelp: true,
        };

        // if (this.props.location.state) {
        return <div className="wrapper">
            <SideBar
                config={sidebarConfigurations}
                parentSidebarSwitch={this.parentSidebarSwitch}
            />
            <div
                className="right-content"
                onClick={() => {
                    this.setState((state) => ({
                        ...state,
                        sidebarMode: "shrunk",
                    }));
                }}
            >
                <div className="top-bar">
                    <div className="top-bar-text">Lease</div>
                </div>
                {
                    this.state.showSubscriptionExpirePopup ?
                        <SubscriptionExpireModal
                            data={this.state.showSubscriptionExpirePopup}
                        /> : <></>
                }

                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteUserCustomerDroneLease()}
                />

                <AnimatedModal
                    isOpen={this.state.leaseOnPopUp}
                    height="400px"
                    width="600px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '10px 40px' }}>
                        <div
                            style={{
                                marginTop: "10px",
                                textAlign: "left",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#3C3C3C",
                            }}
                        >
                            Lease Details
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />


                        {this.state.invoiceType ? <div className="content-text" style={{ fontSize: "14px", fontWeight: 400, margin: '10px 0px', textAlign: 'left', }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Daily Charges</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: '-27px', zIndex: '1' }}>Rs.</span>
                                    <input type='text' className='add-user-form-text2' style={{ width: '120px', paddingLeft: '35px' }}
                                        value={this.state.dailycharges} onChange={(e) => {
                                            let selectedLeaseAmount = Number(e.target.value) * Number(this.state.selectedLeaseDays)
                                            this.setState({
                                                selectedLeaseAmount: selectedLeaseAmount,
                                                dailycharges: Number(e.target.value),
                                                leaseGstAmount: Math.round((Number((this.state.gst / 100) * Number(selectedLeaseAmount))))
                                            }, () => {
                                                this.totalIvoiceAmout()
                                            })
                                        }}
                                    />

                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Charges for {this.state.selectedLeaseDays} days</div> <div>Rs. {this.state.selectedLeaseAmount}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <input type='checkbox' className='add-user-form-checkbox' style={{ marginRight: '15px', cursor: 'pointer' }}
                                        value={this.state.withGst} onChange={() => {
                                            this.setState({ withGst: !this.state.withGst }, () => { this.totalIvoiceAmout() })
                                        }}
                                    />Include GST @ 18%
                                </div>
                                <div>Rs. {this.state.leaseGstAmount}</div>
                            </div>
                            <     div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                                <div>Discount</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input type='text' className='add-user-form-text2' style={{ width: '80px', paddingRight: '25px' }} maxLength={2}
                                        value={this.state.discount} onChange={(e) => {
                                            if (e.target.value == '') {
                                                this.setState({ discount: 0 }, () => { this.totalIvoiceAmout() })
                                            } else {
                                                this.setState({ discount: e.target.value }, () => { this.totalIvoiceAmout() })
                                            }
                                        }}
                                    /> <span style={{ marginLeft: '-20px' }}>%</span>
                                </div>
                            </div>
                            <hr style={{ margin: '20px 0px 0px 0px', padding: '0px', width: '100%' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', fontWeight: '500' }}>
                                <div>Total Amount</div> <div style={{ color: '#2989CF' }}>Rs. {this.state.totalValue}</div>
                            </div>

                        </div> : <>
                            <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Drone Id</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.drone_id}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Mob. No.</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.contact_number ? this.state.leaseDetailsSelect.contact_number : '-'}</div>
                                </div>
                            </div>

                            <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Name</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.customer_name ? this.state.leaseDetailsSelect.customer_name : 'Unknown'}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Status</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.status}</div>
                                </div>
                            </div>

                            <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '60%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>GST No.</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.gst_no ? this.state.leaseDetailsSelect.gst_no : '-'}</div>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Date</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{dateFormat(this.state.leaseDetailsSelect.startdate, 'dd/mm/yyyy')}  To  {dateFormat(this.state.leaseDetailsSelect.enddate, 'dd/mm/yyyy')}</div>
                                </div>
                            </div>

                            <div style={{ margin: '15px 0px', display: 'flex', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div className="itemKey" style={{ textAlign: "left", fontSize: '12px', color: '#666666' }}>Address</div>
                                    <div className="services-content-title" style={{ textAlign: "left", fontSize: '14px' }}>{this.state.leaseDetailsSelect.address ? this.state.leaseDetailsSelect.address : '-'}</div>
                                </div>
                            </div>
                        </>}
                        <div style={{ display: 'flex', alignItems: 'center', bottom: '25px', width: '85%', position: 'absolute', justifyContent: 'end', }}>
                            <div className="addcustomer-button-empty-dashboard"
                                style={{ cursor: "pointer", padding: "8px 38px", background: "white", marginRight: '20px' }}
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        leaseOnPopUp: false,
                                        leaseDetailsSelect: {},
                                        invoiceType: false,
                                        withGst: false,
                                        totalValue: 0,
                                        gst: 18,
                                        discount: 0,
                                    }));
                                }}
                            >
                                Close
                            </div>

                            {this.state.invoiceType ?
                                <Link to={{
                                    pathname: '/drone_management/lease_drone_invoice',
                                    state: {
                                        user: this.state.user,
                                        type: this.state.type,
                                        lease: this.state.leaseDetailsSelect,
                                        withGst: this.state.withGst,
                                        totalValue: this.state.totalValue,
                                        gst: this.state.gst,
                                        discount: this.state.discount,
                                        dailycharges: this.state.dailycharges,
                                        paid_status: false,
                                    }
                                }}
                                >
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 25px", background: "#2989CF", color: 'white' }}>
                                        Generate
                                    </div>
                                </Link>

                                : <>{this.state.leaseDetailsSelect.invoiceGen ? <Link to={{
                                    pathname: '/drone_management/lease_drone_invoice',
                                    state: {
                                        user: this.state.user,
                                        type: this.state.type,
                                        lease: this.state.leaseDetailsSelect,
                                        withGst: this.state.withGst,
                                        totalValue: this.state.totalValue,
                                        gst: this.state.gst,
                                        discount: this.state.discount,
                                        dailycharges: this.state.leaseDetailsSelect.daily_charges,
                                        paid_status: false,
                                    }
                                }}
                                    style={{ color: '#3c3c3c' }}
                                >
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 32px", background: "#2989CF", color: 'white' }} >
                                        Invoice
                                    </div>
                                </Link>
                                    :
                                    <div className="addcustomer-button-empty-dashboard"
                                        style={{ cursor: "pointer", padding: "8px 32px", background: "#2989CF", color: 'white' }}
                                        onClick={() => {
                                            this.totalIvoiceAmout()
                                            this.setState((state) => ({
                                                ...state,
                                                invoiceType: true
                                            }));
                                        }}>
                                        Invoice
                                    </div>
                                }</>
                            }
                        </div>
                    </div>
                </AnimatedModal>


                <AnimatedModal
                    isOpen={this.state.addCustomerPopUp}

                    height="480px"
                    width="600px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '5px 20px' }}>
                        <div
                            style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#3C3C3C",
                            }}
                        >
                            Add Customer
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Name<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='30' name="customerName" value={this.state.customerName} className="add-user-form-text" placeholder="Name" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Contact Number<span style={{ color: 'red', }}>*</span></div>
                                <input type="number" name="contactNumber" value={this.state.contactNumber} className="add-user-form-text" placeholder="Contact Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                {this.state.contactNumberErromsg ? <div style={{ fontSize: '12px', color: 'red' }} >{this.state.contactNumberErromsg}</div> : ''}
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Address<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='30' name="customerAddress" value={this.state.customerAddress} className="add-user-form-text" placeholder="Address" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Government Id Number<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='20' name="customerGovIdNo" value={this.state.customerGovIdNo} className="add-user-form-text" placeholder="PAN or Addhar Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Email<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='25' name="customerEmail" value={this.state.customerEmail} className="add-user-form-text" placeholder="Email" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                {this.state.contactEmailErromsg ? <div style={{ fontSize: '12px', color: 'red' }} >{this.state.contactEmailErromsg}</div> : ''}
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Pilot License Id</div>
                                <input type="text" maxLength='20' name="customerPilotLicenseId" value={this.state.customerPilotLicenseId} className="add-user-form-text" placeholder="Pilot License Id Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                        </div>
                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >GST Number</div>
                                <input type="text" maxLength='25' name="gst_no" value={this.state.gst_no} className="add-user-form-text" placeholder="gst_no" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            {/* <div style={{ textAlign: "left", width: '45%', }}>
                                <div  className="content-text" >Pilot License Id</div>
                                <input type="text" maxLength='20' name="customerPilotLicenseId" value={this.state.customerPilotLicenseId} className="add-user-form-text" placeholder="Pilot License Id Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div> */}
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addCustomerPopUp: false,
                                        customerName: '',
                                        contactNumber: '',
                                        customerEmail: '',
                                        customerAddress: '',
                                        customerGovIdNo: '',
                                        customerPilotLicenseId: ''
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.customerName && this.state.contactNumber && this.state.customerAddress && this.state.customerGovIdNo && this.state.customerEmail ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.addUserCustomer()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                </AnimatedModal>

                <AnimatedModal
                    isOpen={this.state.addLeaseDronePopUp}
                    height="420px"
                    width="500px"
                >
                    <div style={{ width: "100%", height: "100%", padding: '5px 20px' }}>
                        <div className="services-content-title"
                            style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                color: "#3C3C3C",
                            }}
                        >
                            Add Drone On Lease
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className="content-text">Select Customer</div>
                                <select
                                    onChange={(event) => this.onChangeListenerCust(event)}
                                    className="add-user-form-text2"
                                    value={this.state.selectedCustomerName}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option key={-1} value={-1}>Select Customer For Lease</option>
                                    {this.state.usersCustomers.map((cust, i) => {
                                        return <option key={i} value={i}>{cust.customer_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '100%', }}>
                                <div className="content-text">Select Drone</div>
                                <select
                                    onChange={(event) => this.onChangeListenerDrone(event)}
                                    className="add-user-form-text2"
                                    value={this.state.selectedDroneName}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    disabled={this.state.edit}
                                >
                                    <option key={-1} value={-1}>Select Drone For Lease</option>
                                    {this.state.drones.map((drone, i) => {
                                        return <option key={i} value={i}>{drone.drone_id}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >Start Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        className="add-user-form-text"
                                        type="date"
                                        max='9000-01-01'
                                        min={dateFormat(this.state.minDate, 'yyyy-mm-dd')}
                                        defaultValue={this.state.fromDate}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                fromDate: value,
                                            }))
                                        }}
                                        value={this.state.fromDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text" >End Date</div>
                                <div>
                                    <input
                                        id="dateRequired"
                                        name="dateRequired"
                                        defaultValue={this.state.toDate}
                                        className="add-user-form-text"
                                        type="date"
                                        max='9000-01-01'
                                        min={dateFormat(this.state.fromDate ? this.state.fromDate : this.state.minDate, 'yyyy-mm-dd')}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.setState((state) => ({
                                                ...state,
                                                toDate: value,
                                            }))
                                        }}
                                        value={this.state.toDate}
                                        style={{
                                            height: "auto",
                                            borderRadius: '10px',
                                            paddingLeft: '5px',
                                            width: "auto",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addLeaseDronePopUp: false,
                                        selectedCustomer: '',
                                        selectedCustomerName: "",
                                        selectedDrone: '',
                                        selectedDroneName: "",
                                        fromDate: '',
                                        toDate: '',
                                        edit: false,
                                        editLease: '',
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.fromDate && this.state.toDate && this.state.selectedCustomerName && this.state.selectedDroneName ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.addUserCustomerDroneLease()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                </AnimatedModal>
                {/* --------------------------------------------------------------- */}
                <div className="main-content">
                    <div style={{ height: "100px", marginBottom: '10px' }}>
                        {this.state.loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "4%", height: "105px", width: "84vw" }}>
                                <img src={Loader} style={{ height: "80px" }} />
                            </div> :
                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '100%' }}>
                                <div style={{ marginLeft: '0px', cursor: 'auto' }} className='dashboard-box'>
                                    <div className='flex-center' style={{ width: "90%", justifyContent: "space-evenly" }}>
                                        {this.state.noLeaseData ?
                                            <div className="empty-data-message" style={{ fontSize: "14px", display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "space-evenly", alignItems: 'center', borderRadius: "5px", height: "100%", width: '100%' }}>
                                                <img src={noLeaseGraph} style={{ height: "50px", width: "50px", }} />
                                                <div>
                                                    {this.state.pieLoading ? <img src={Loader} style={{
                                                        height: "11vh",
                                                        width: "7vw",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        marginRight: "-62px",
                                                    }} /> : "No Data"} </div>
                                            </div>
                                            : <>
                                                {this.state.pieLoading ?
                                                    <div style={{ display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: 'center', borderRadius: "5px", height: "82%" }}>
                                                        <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '40%' }} />
                                                    </div>
                                                    : <div style={{
                                                        paddingBottom: "15px",
                                                        paddingRight: "10px",
                                                        paddingTop: '10px',
                                                    }}>
                                                        <Pie
                                                            height={100}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                                labels: {
                                                                    font: {
                                                                        family: "Poppins",
                                                                        size: "10",
                                                                        style: "normal",
                                                                        color: "#666666",
                                                                    },
                                                                },
                                                                legend: {
                                                                    display: true,
                                                                    position: "right",
                                                                    labels: {
                                                                        usePointStyle: true,
                                                                        pointStyle: "circle",
                                                                        style: 'normal',
                                                                        fontSize: 10,
                                                                        padding: 10,

                                                                    }
                                                                },
                                                            }}
                                                            data={this.state.pieData} />
                                                    </div>}
                                            </>}
                                    </div>
                                </div>
                                <div className='dashboard-box' style={{ cursor: 'auto' }}>
                                    <div className='flex-center' style={{ justifyContent: "space-evenly" }}>
                                        {this.state.pieLoading ?
                                            <div style={{ display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: 'center', borderRadius: "5px", height: "82%" }}>
                                                <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '40%' }} />
                                            </div>
                                            : <>
                                                <div><img src={customerActive} className='box-icon' /></div>
                                                <div style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                                    <div className='box-header'>{this.state.totalCustomers}</div>
                                                    <div className='box-data' >{this.state.totalCustomers < 2 ? 'Customer' : 'Customers'}</div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                                <div style={{ cursor: 'auto' }} className='dashboard-box'>
                                    <div className='flex-center' style={{ justifyContent: "space-evenly" }}>
                                        {this.state.pieLoading ?
                                            <div style={{ display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: 'center', borderRadius: "5px", height: "82%" }}>
                                                <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '40%' }} />
                                            </div>
                                            : <>
                                                <div><img src={leaseStatActive} className='box-icon' /></div>
                                                <div style={{ display: "flex", marginLeft: "5%", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                                    <div className='box-header'>{this.state.droneLeaseOn} / {this.state.totalDrone}</div>
                                                    <div className='box-data' >Drones On Lease</div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                                <div className='dashboard-box' style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", cursor: 'auto' }} >
                                    <div className='flex-center' style={{ justifyContent: "space-evenly" }}>

                                        {this.state.pieLoading ?
                                            <div style={{ display: 'flex', backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: 'center', borderRadius: "5px", height: "82%" }}>
                                                <img src={Loader} style={{ height: "15vh", width: "15vw", marginLeft: '40%' }} />
                                            </div>
                                            : <>
                                                <div><img src={leaseRevenueActive} className='box-icon' /></div>
                                                <div style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                                    <div className='box-header services-content-title'><span>&#8377;</span> {this.state.coveredRevenueAmount} <span style={{ fontSize: '10px', fontWeight: '400' }}>Paid</span></div>
                                                    <div className='box-header services-content-title'><span>&#8377;</span> {Number(this.state.totalRevenueAmount) - Number(this.state.coveredRevenueAmount)} <span style={{ fontSize: '10px', fontWeight: '400' }}>Unpaid</span></div>

                                                    {/* <div className='box-data' >Revenue</div> */}
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>}

                    </div>

                    <div style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '50%', width: '100%', overflow: 'hidden', padding: '10px 0px', }}>
                            {this.state.leaseLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginRight: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginRight: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Lease</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={btnAdd} style={{ marginRight: '20px', width: '25px', height: '25px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ addLeaseDronePopUp: true })
                                            }} />
                                            <Link to={{
                                                pathname: `/drone_management/user_drone_lease`,
                                                state: { user, type }
                                            }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ cursor: "pointer", padding: "6px 20px", background: "white" }}>View All</div>
                                            </Link>

                                        </div>
                                    </div>

                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 50px)', overflow: 'hidden' }}>
                                        {this.state.lease.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                            <col style={{ width: '35%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '15%' }} />
                                            <tr className="customers-table-header-row" style={{ zIndex: '0', }}>
                                                <th>Customer Name</th>
                                                <th style={{ textAlign: 'center' }}>Start Date</th>
                                                <th style={{ textAlign: 'center' }}>End Date</th>
                                                <th>Status</th>
                                            </tr>
                                            {this.state.lease.map((lease, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td>
                                                        <div className="leasedData" style={{ cursor: 'pointer', width: 'fit-content', }}
                                                            onClick={() => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    leaseDetailsSelect: lease,
                                                                    dailycharges: Number(lease.daily_charges),
                                                                    selectedLeaseDays: lease.totalDays,
                                                                    selectedLeaseAmount: lease.rent_amount,
                                                                    leaseGstAmount: Math.round((Number((this.state.gst / 100) * Number(lease.rent_amount))))
                                                                }), () => {
                                                                    this.setState({ leaseOnPopUp: true })
                                                                })
                                                            }}
                                                        >{lease.customer_name ? lease.customer_name.length > 20 ?
                                                            `${lease.customer_name.substring(0, 20)}..` :
                                                            `${lease.customer_name}` : 'Unknown'}</div>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(lease.startdate, "dd-mm-yyyy")}</td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(lease.enddate, "dd-mm-yyyy")}</td>
                                                    <td>{lease.status}</td>
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noLeaseData} style={{ height: "45px" }} />
                                                <div style={{ marginTop: '10px' }}>No Lease</div>
                                            </div>
                                        }
                                    </div>
                                </div>}

                            {this.state.custLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginLeft: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginLeft: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Customers</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={btnAdd} style={{ marginRight: '20px', width: '25px', height: '25px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ addCustomerPopUp: true })
                                            }} />
                                            <Link to={{
                                                pathname: `/drone_management/rent-drone_customers`,
                                                state: { user, type }
                                            }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ cursor: "pointer", padding: "6px 20px", background: "white" }}>View All</div>
                                            </Link>

                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 50px)', overflow: 'hidden' }}>
                                        {this.state.usersCustomers.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                            <col style={{ width: '40%' }} />
                                            <col style={{ width: '25%' }} />
                                            <col style={{ width: '35%' }} />
                                            {/* <col style={{ width: '15%' }} /> */}
                                            <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                                                <th>Customer Name</th>
                                                <th style={{ textAlign: 'center' }}>Phone Number</th>
                                                <th>Address</th>
                                                {/* <th>Status</th> */}
                                            </tr>
                                            {this.state.usersCustomers.map((cust, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td>
                                                        <Link style={{ color: '#3c3c3c' }} to={{
                                                            pathname: "/drone_management/rent-drone_customer",
                                                            state: { user: this.state.user, type: this.state.type, userCustomer: cust }
                                                        }}>
                                                            {cust.customer_name.length > 23 ?
                                                                `${cust.customer_name.substring(0, 23)}..` :
                                                                `${cust.customer_name}`}
                                                        </Link>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{cust.contact_number}</td>
                                                    <td>{cust.address.length > 20 ?
                                                        `${cust.address.substring(0, 20)}..` :
                                                        `${cust.address}`}</td>
                                                    {/* <td>{lease.status}</td> */}
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noCustomers} style={{ height: "45px" }} />
                                                <div style={{ marginTop: '10px' }}>No Customers</div>
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </div>

                        <div style={{ display: 'flex', height: '50%', width: '100%', overflow: 'hidden', padding: '10px 0px', }}>
                            {this.state.droneLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginRight: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginRight: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Drones</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Link to={{
                                                pathname: `/drone_management/lease_dronesMgmt`,
                                                state: { user, type }
                                            }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ cursor: "pointer", padding: "6px 20px", background: "white" }}>View All</div>
                                            </Link>
                                            {/* <img src={btnAdd} style={{ marginLeft: '20px', width: '25px', height: '25px' }} /> */}
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 45px)', overflow: 'hidden' }}>
                                        {this.state.drones.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                            <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                                                <th>Drone Id</th>
                                                <th>Drone Name</th>
                                                <th style={{ textAlign: 'center' }}>Registered Date </th>
                                            </tr>
                                            {this.state.drones.map((drone, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td>
                                                        <Link style={{ color: "#707683" }} to={{
                                                            pathname: `/drone_management/lease_drone_details`,
                                                            state: { user, type, drone, sidebarMode: this.state.sidebarMode }
                                                        }}>
                                                            {drone.drone_id}
                                                        </Link>
                                                    </td>
                                                    <td>{drone.drone_name}</td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(drone.registered_date, "dd-mm-yyyy")}</td>
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noDrones} style={{ height: "45px" }} />
                                                <div style={{ marginTop: '10px' }}>No Drones</div>
                                            </div>
                                        }
                                    </div>
                                </div>}

                            {this.state.invoiceLoading ?
                                <div style={{ display: 'flex', border: '1px solid #C2C2C2', backgroundColor: "#FAFAFA", alignItems: 'center', borderRadius: "5px", width: '50%', marginTop: '10px', marginLeft: '1%' }}>
                                    <img src={Loader} style={{ height: "50%", width: "100%", marginLeft: '5%' }} />
                                </div> :
                                <div style={{ width: '50%', marginLeft: '1%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="table-header">Invoices</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Link to={{
                                                pathname: `/drone_management/lease_drone_invoices`,
                                                state: { user, type }
                                            }}>
                                                <div className="addcustomer-button-empty-dashboard" style={{ cursor: "pointer", padding: "6px 20px", background: "white" }}>View All</div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #C2C2C2', background: '#FAFAFA', borderRadius: '5px', marginTop: '10px', height: 'calc(100% - 45px)', overflow: 'hidden' }}>
                                        {this.state.invoices.length ? <table className="customers-table" style={{ marginTop: '0px', borderRadius: '5px', }}>
                                            <tr className="customers-table-header-row" style={{ zIndex: '0' }}>
                                                <th>Invoice Id</th>
                                                <th style={{ textAlign: 'center' }}>Date</th>
                                                <th>Status</th>
                                            </tr>
                                            {this.state.invoices.map((invoice, key) => (
                                                <tr className="customers-table-data-row">
                                                    <td>
                                                        <Link style={{ color: '#3c3c3c' }} to={{
                                                            pathname: '/drone_management/lease_drone_invoice',
                                                            state: {
                                                                user: this.state.user,
                                                                type: this.state.type,
                                                                lease: invoice.leaseData,
                                                                withGst: invoice.gstflag,
                                                                totalValue: invoice.total_amount,
                                                                gst: invoice.gst,
                                                                discount: invoice.discount,
                                                                dailycharges: invoice.dailycharges,
                                                                paid_status: invoice.paid_status,
                                                                invoice: invoice
                                                            }
                                                        }}>
                                                            {invoice.invoiceid}
                                                        </Link>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>{dateFormat(invoice.generate_date, "dd-mm-yyyy")}</td>
                                                    <td>{invoice.paid_status == true ? 'Paid' : 'Unpaid'}</td>
                                                </tr>
                                            ))}
                                        </table> :
                                            <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={noInvoices} style={{ height: "45px" }} />
                                                <div style={{ marginTop: '10px' }}>No Invoices</div>
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(leaseDashboard)