import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import NoContent from "../../ReusableComponents/NoContent";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
const DronePerformance = ({ isDisabled, drones, redirectTo, loading }) => {
    const [maxHours, setMaxHours] = useState(100);
    useEffect(() => {
        setMaxHours(Math.max(...drones.map(item => item.hoursUsed)))
    }, [drones])
    return <div className='table-box-large' style={{ overflow: "hidden" }}>
        <div className="" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <div className="table-header" >Drone Performance</div>
            {!isDisabled && <Link
                to={redirectTo}
            >
                <span className="view-all">View All</span>
            </Link>}
        </div>

        {
            loading ? <LoaderComponent message={" "} /> :
                drones.length > 0 && !isDisabled ?
                    <div style={{ width: "100%", display: 'flex', flexDirection: "column", rowGap: "10px", marginTop: "10px" }}>
                        {drones.map(drone => <div style={{ display: "flex", flexDirection: 'column', width: "100%", textAlign: 'left', fontSize: '13px', color: '#555C67', fontWeight: '500' }}>
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%", gap: "40px" }}>
                                {/* progress bar */}
                                <div className="drone-performance-progress-bar" style={{ width: Number(drone.hoursUsed) === 0 ? '2px' : `calc(${(Number(drone.hoursUsed) / Number(maxHours)) * 100}% - 115px)`, }}></div>
                                {/* hours */}
                                <span className="drone-performance-hours">{parseFloat(drone.hoursUsed).toFixed(2)} Hours</span>
                            </div>
                            <div className="drone-performance-drone-name" >{drone.name || 'NA'}</div>
                        </div>)}
                    </div>
                    :
                    <NoContent />
        }

    </div >
}
export default DronePerformance