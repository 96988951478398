import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../../icons/loader.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./FlightCard.css"
const FlightCard = ({ className, leftBorderColor, icon, loading, statCount, statName, action, isDisabled, position, minWidth, maxWidth, width, minHeight, maxHeight, height, border, borderRadius, backgroundColor, padding, iconContainer }) => {
  const location = useLocation()
  return (
    action?.actionStatLink?.pathname ?
      <Link to={{
        pathname: action.actionStatLink.pathname,
        state: action.actionStatLink.state
      }} style={{ height: "100%", width: '100%' }}>
        <div
          className={isDisabled ? "flight-card flight-card-disabled" : `flight-card hover-flight-card ${className}`}
          style={{ background: backgroundColor || '#fcfcfc' }}
        >
          <div className="flight-card-left-border" style={{ background: leftBorderColor }} >

          </div>

          {loading ? (
            <div
              style={{
                width: "80px",
                // marginLeft: "30px",
                display: "flex",
                alignItems: "center ",
              }}
            >
              <img src={Loader} style={{ width: "100%" }} />
            </div>
          ) : (
            <div className="flight-card-info-container" >
              <div className="flight-card-title">
                {statName}
              </div>
              <div
                className="flight-card-count"
              >
                {statCount}
              </div>
            </div>
          )}
          <div style={{ height: "100%", display: 'flex', alignItems: "center" }}>
            {iconContainer}
          </div>
          {icon &&
            <div className="flight-card-icon" style={{ width: "40px", height: "40px", marginLeft: "30px" }}>
              <img style={{ width: "100%" }} src={icon} />
            </div>
          }
        </div>
      </Link> :
      <div
        className={isDisabled ? "flight-card flight-card-disabled" : `flight-card hover-flight-card ${className}`}
        style={{ background: backgroundColor || '#fcfcfc' }}
      >
        <div className="flight-card-left-border" style={{ background: leftBorderColor }} >

        </div>

        {loading ? (
          <div
            style={{
              width: "80px",
              // marginLeft: "30px",
              display: "flex",
              alignItems: "center ",
            }}
          >
            <img src={Loader} style={{ width: "100%" }} />
          </div>
        ) : (
          <div className="flight-card-info-container" >
            <div className="flight-card-title">
              {statName}
            </div>
            <div
              className="flight-card-count"
            >
              {statCount}
            </div>
          </div>
        )}
        <div style={{ height: "100%", display: 'flex', alignItems: "center" }}>
          {iconContainer}
        </div>
        {icon &&
          <div className="flight-card-icon" style={{ width: "40px", height: "40px", marginLeft: "30px" }}>
            <img style={{ width: "100%" }} src={icon} />
          </div>
        }
      </div>
  );
};

export default FlightCard;
