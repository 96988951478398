import React, { useEffect, useState } from "react"
import ERRORLOGSICON from "../icons/attention.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getDronesErrorCount } from "../api"

const DroneErrorLogsBtn = ({ linkState }) => {
    const [count, setCount] = useState()
    const [loading, setLoading] = useState(true)
    const getDroneErrorCountFunc = async () => {
        const drone_id = linkState?.drone?.drone_id
        const data = await getDronesErrorCount(drone_id)
        let totalCount = 0;
        if (drone_id) {
            totalCount = !isNaN(data?.drones?.[0]?.error_count) ? data?.drones?.[0]?.error_count : 0
        } else {
            totalCount = data.drones.reduce((acc, curr) => acc + Number(curr.error_count), 0)
        }
        setCount(totalCount)
        setLoading(false)
    }
    useEffect(() => {
        getDroneErrorCountFunc()
        const getErrosTimer = setInterval(getDroneErrorCountFunc, 1000 * 60 * 5)
        return () => clearInterval(getErrosTimer)

    }, [])
    const history = useHistory()
    return <div style={{ position: 'relative', cursor: 'pointer', }} onClick={() => {
        history.push({
            pathname: "/drone_management/drone/errorLogs",
            state: linkState
        })
    }}>
        {((count && count > 0) ? true : false) && <div style={{ border: '1px solid red', borderRadius: '50%', background: 'red', cursor: 'pointer', position: 'absolute', left: '21px', top: '-1px', color: 'white', fontWeight: 600, fontSize: '10px', width: '17px', height: '17px', boxShadow: '0px 0px 0px 2px white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
                loading ? <></>
                    :
                    <span>{count}</span>
            }
        </div>}
        <img src={ERRORLOGSICON} style={{ height: '32px', cursor: 'pointer' }} />
    </div>
}
export default DroneErrorLogsBtn