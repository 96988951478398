import { Link } from 'react-router-dom';
import React from 'react';
import "./style.css";
import { Component } from 'react';



export default class TileTray extends Component {
    state = {
        tiles: [],
    }
    componentDidMount() {
        let {user,type,sidebarMode,tiles} = this.props;
        this.setState((state)=>({
            ...state,
            user,
            type,
            sidebarMode,
            tiles
        }))

    }
    componentWillUnmount() {

    }
    render() {
       let { user,type,sidebarMode,tiles} = this.state;
       return <div className="cardstray" id="cardstray">
            {tiles.map((tile) => {
                return <Link to={{
                    pathname: tile.redirectURL || tile.redirecturl,
                    state: {
                        user,
                        type,
                        tile,
                        sidebarMode
                    }
                }} className="cardscon">
                    <div>
                        <img className="cardimg" src={tile.image} alt="imageIcon" />
                        <div className="service-title">{tile.title}</div><br /><span className="para">{tile.description.length <= 50 ? tile.description : tile.description.substr(0, 47) + "..."}</span>
                    </div>
                </Link>
            })
            }
        </div>
    }
}