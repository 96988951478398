import React, { useEffect, useState } from 'react'
import Pause from "../assets/Icons/Pause.svg"
import Play from "../assets/Icons/Play.svg"
import Backward from "../assets/Icons/Backward.svg"
import Forward from "../assets/Icons/Forward.svg"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
const VideoPlayBack = ({ onClickForward, onClickBackward, onTogglePlay, onSliderChange, onSpeedChange, maxValue, value, replayPip, title, subTitle, onHover, onChangeValue, startTime }) => {
    function formatTime(seconds) {
        // const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }
    const [play, setPlay] = useState(true)

    useEffect(() => {
        if (replayPip && onChangeValue) {
            onChangeValue({ currentTime: 0, startTime })
        }
    }, [value])

    return <>
        {replayPip ? <div style={{ width: "100%" }}>
            {onHover && <div style={{ color: 'white', marginLeft: '15px', marginBottom: '5px', fontSize:'14px' }}>{`${formatTime(value)} / ${formatTime(maxValue)}`}</div>}
            <Slider
                style={{ height: '0px', padding: '0px', zIndex: '99', }}
                handleStyle={{
                    background: '#F98A39',
                    border: '#F98A39'
                }}
                trackStyle={{
                    background: '#F98A39',
                }}
                railStyle={{
                    background: '#ffffff80'
                }}
                min={0}
                max={maxValue}
                value={value}
                onChange={onSliderChange}
                disabled={true}
            />
            <div className="replaypip-playback-container">
                {title && <div style={{ fontSize: '14px', fontWeight: '600', }}>{title}</div>}
                {subTitle && <div style={{ fontSize: '12px', fontWeight: '400' }}>{subTitle}</div>}
            </div>
        </div>
            : <div className="replay-playback-container">
                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                    <img src={play ? Play : Pause} onClick={(e) => {
                        setPlay(prev => !prev)
                        onTogglePlay(e)
                    }} className="replay-toggle" />
                    <div style={{ display: 'flex', gap: "5px", alignItems: "center", fontFamily: 'Lato', fontWeight: 400, }}>
                        <div>{formatTime(value)}</div>
                        <span>/</span>
                        <div>{formatTime(maxValue)}</div>
                    </div>
                    <img src={Backward} disabled={value < 0} onClick={onClickBackward} className={`replay-back ${value <= 0 ? "replay-btn-disabled" : ""}`} />
                    <img src={Forward} disabled={value > maxValue} onClick={onClickForward} className={`replay-forward ${value >= maxValue ? "replay-btn-disabled" : ""}`} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available', gap: "20px" }}>
                    <Slider
                        handleStyle={{
                            width: '20px',
                            height: '20px',
                            boxShadow: 'none',
                            border: 'none',
                            background: 'white',
                            opacity: 1,
                            marginTop: '-7px'
                        }}
                        trackStyle={{
                            background: "white",
                            height: '5px'
                        }}
                        railStyle={{
                            background: '#FFFFFF80',
                            height: '5px'
                        }}
                        min={0}
                        max={maxValue}
                        value={value}
                        onChange={onSliderChange}
                    />
                    <select
                        disabled={value == maxValue || value == 0}
                        className='replay-speed-control' defaultValue={1} onChange={onSpeedChange}>
                        <option value={0.5}>x0.5</option>
                        <option value={1}>x1</option>
                        <option value={1.5}>x1.5</option>
                        <option value={2}>x2</option>
                    </select>
                </div>
            </div>}
    </>
}

export default VideoPlayBack
