import React, { Component } from 'react';
import SideBar from '../SideBar';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import Plus from "../assets/Icons/plus.png"
import { Link } from 'react-router-dom';
import { getIncidentReports } from '../api';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import teams from "../sidebaricon/TeamsInactive.png";
import planner from "../assets/sideBarIcons/PlanInactive.png";
import setting from "../sidebaricon/Settings.png";
import help from "../sidebaricon/Help.png";
import incidentActive from '../icons/incidentActive.png';
import LoaderComponent from "../ReusableComponents/LoaderComponent";

class IncidentReport extends Component {
    state = {
        user: {},
        selectedDate: new Date(),
        selectedTime: new Date().getTime(),
        reportsList: [],

        item: undefined,
        showProcessPopup: false,
        itemName: undefined,
        processPopupIcon: undefined,
        processMessage: undefined,

        loader: true,
    }

    getIncidentReportsList = () => {
        this.setState({
            loader: true,
        }, () => {
            getIncidentReports().then(data => {
                this.setState({
                    reportsList: data.incidentReports,
                    st: data.st,
                    loader: false,
                })
            }).catch(e => {
                console.log(e)
                this.setState({
                    item: "Report",
                    itemName: "Report",
                    processPopupIcon: "ERROR",
                    processMessage: "Error Occured While Getting Incident Reports !",
                    showProcessPopup: true,
                    loader: false,
                });
            })
        })
    }

    closeActivityPopup = () => {
        this.setState({
            showProcessPopup: false,
        });
    }

    componentWillMount() {
        document.title = "Incident Report";
        this.setState({
            user: this.props?.location?.state?.user
        });
        this.getIncidentReportsList()
    }

    render() {

        let { user, type, inviteModal, validationMessage, validationColor, teamUsers } = this.state
        console.log("user :", user);
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: teams,
                    text: "Teams",
                    path: "/teams",
                },
                {
                    icon: planner,
                    text: "Planner",
                    path: "/planner",
                },
                {
                    icon: incidentActive,
                    text: "Incident Report",
                    path: "/incidentReport",
                    selected: true
                },
                {
                    icon: setting,
                    text: "Settings",
                    path: "/profile-settings",
                },
                {
                    icon: help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
        }

        return (
            <>
                {this.state.showProcessPopup && <ActivityPopup
                    item={this.state.item}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.setState({ showProcessPopup: false })}
                />}

                {user?.username ?
                    <div className="wrapper">
                        <SideBar
                            config={sidebarConfigurations}
                            showMainDashboardIcons={this.state.showBackIcon}
                            parentSidebarSwitch={this.parentSidebarSwitch}
                        />
                        <div className="right-content"
                            onClick={
                                () => {
                                    this.setState((state) => ({
                                        ...state, sidebarMode: 'shrunk'
                                    }))
                                }
                            }
                        >
                            <div className="top-bar" style={{ zIndex: '3' }} ><div className="top-bar-text">{"Report"}</div></div>
                            {this.state.loader ?
                                <LoaderComponent height="90%" width="100%" />
                                : <div className='main-content'>
                                    <div style={{ textAlign: "center" }}>

                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "22px" }}>
                                            <div>Incident Report</div>
                                            <div><ButtonWithIcon
                                                text="Report" icon={Plus}
                                                linkData={
                                                    {
                                                        pathname: "/newIncidentReport",
                                                        state: {
                                                            type,
                                                            user: this.props.location?.state?.user
                                                            // plan: this.props.location.state.plan,
                                                            // project: this.props.location.state.project,
                                                        }
                                                    }
                                                }

                                            /></div>
                                        </div>
                                        <div style={{ height: "80vh", overflow: "auto" }}>
                                            <table className="customers-table" style={{ marginTop: "0" }} >
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "40%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "15%" }} />
                                                <tr className="customers-table-header-row">
                                                    <th>Date</th>
                                                    <th>Incident Brief</th>
                                                    <th>Person Reported</th>
                                                    <th>Contact</th>
                                                    <th>Location</th>
                                                </tr>
                                                {this.state.reportsList.length === 0 ?
                                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                        <td colspan="6">No Report Available</td>
                                                    </tr> :
                                                    this.state.reportsList.map((report) =>
                                                        <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                            <td>
                                                                <Link
                                                                    style={{ color: "#707683" }}
                                                                    to={{
                                                                        pathname: "/incidentReportDetail",
                                                                        state: {
                                                                            user,
                                                                            type,
                                                                            sidebarMode: this.state.sidebarMode,
                                                                            report,
                                                                            st: this.state.st
                                                                        }
                                                                    }}
                                                                >
                                                                    {report.incident_time_date ? report.incident_time_date.substr(0, 10) : "-"}
                                                                </Link>
                                                            </td>
                                                            <td>{report.details ? report.details.length > 15 ? `${report.details.substring(0, 35)}...` : report.details : "-"}</td>
                                                            <td>{report.name ? report.name.length > 15 ? `${report.name.substring(0, 20)}...` : report.name : "-"}</td>
                                                            <td>{report.contact_number ? report.contact_number : "-"}</td>
                                                            <td>{report.location ? report.location.length > 15 ? `${report.location.substring(0, 20)}...` : report.location : "-"}</td>

                                                            {/* <td>
                                                                <DeleteComponentTable
                                                                    style={{ margin: "0 10px" }}
                                                                    onClickDelete={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            selectedTaskId: task.task_id,
                                                                            itemName: "Task",
                                                                            processPopupIcon: "WARNING",
                                                                            processAction: "DELETE",
                                                                            processMessage: null,
                                                                            showProcessPopup: true,
                                                                        }))
                                                                    }}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                    )
                                                }
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            }

                        </div>
                    </div > : <div />
                }
            </>
        );
    }
}

export default (IncidentReport);