import React, { useRef, useState } from "react";
import MakeDragable from "../../ReusableComponents/MakeDragable";
import DCCDroneFlyView from "../../DCCDroneFlyView.js";

const EnterprisePip = ({ Title, flightId, height, width, bottom, right, zIndex, dragWindow, onClickAction, onCloseAction, user }) => {
    const [pauseReplay, setPauseReplay] = useState(true)
    const [onHover, setOnHover] = useState(false)
    const [lastValue, setLastValue] = useState(0)

    const onClickExtend = () => {
        onClickAction(lastValue)
    }

    return <MakeDragable
        Title={Title}
        onClickAction={() => { onClickExtend() }}
        onCloseAction={onCloseAction}
        height={height || '160px'}
        width={width || '280px'}
        bottom={bottom || "30px"}
        right={right || "30px"}
        zIndex={zIndex || "99"}
        dragWindow={dragWindow}
        pauseReplay={pauseReplay}
        pausePlayOnClickAction={() => { onClickExtend() }}
        onHover={(e) => { setOnHover(e) }}
    >
        <DCCDroneFlyView
            location={{ hash: "", pathname: "/drone_control_center/fly_view", state: { flight: flightId, user: user } }}
            width={width || '280px'}
            height={height || '160px'}
            pip={true}
            pauseReplay={pauseReplay}
            onHover={onHover}
            onChangeValue={(e) => { setLastValue(e) }}
        />
    </MakeDragable>
}

export default EnterprisePip;