import React, { Component } from 'react';
import * as API from '../api.js';
import '../style.css';
import SettingsActive from '../sidebaricon/SettingsActive.png';
import { Redirect } from 'react-router-dom';
import eye from '../Icon/eye.svg'
import eyeOff from '../Icon/eye-off.svg'
import editActive from '../Icon/editActive.svg';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import shieldIcon from '../Icon/shield.svg';
import activeShieldIcon from '../Icon/activeShield.svg';

export default class MgmtSettings extends Component {

    state = {
        user: {},
        type: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        match: false,
        successParams: {
            title: "SETTINGS",
            message1: "Password has been changed successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "Back to Settings",
            buttonLink: "/profile-settings"
        },
        showOldP: false,
        showNewP: false,
        showConfirmP: false,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        twoStepAuthentication: false,
        isChangePassword: false,
        saveBtnStyle: {
            fontSize: '11px',
            fontWeight: '500',
            color: 'white',
            backgroundColor: '#2989cf',
            display: 'flex',
            width: '100px',
            height: '25px',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        inputFieldStyle: {
            outline: 'none',
            border: '0.5px solid #C9C9C9',
            borderRadius: '5px',
            fontSize: '11px',
            height: '28px',
            paddingLeft: '10px',
        },
        showCurrentPass: false,
        showNewPass: false,
        showConfirmPass: false
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    componentWillMount() {
        document.title = "Settings - Change password";
        if (this.props) {
            let { user, type } = this.props;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    submitChangePassword = (e) => {
        if (e) e.preventDefault()
        if (this.state.user.password === '' && this.state.user.aeromegh_login_dirty === false) {
            this.setState((state) => ({
                ...state,
                itemName: "Password",
                processPopupIcon: "WARNING",
                processAction: "UPDATE",
                processMessage: "Are you sure you want to set this password ?",
                showProcessPopup: true,

            }))
        } else {
            this.setState((state) => ({
                ...state,
                address: "-",
                email: state.username,
                itemName: "Password",
                processPopupIcon: "WARNING",
                processAction: "UPDATE",
                processMessage: "Are you sure you want to change the password ?",
                showProcessPopup: true,
            }))
        }
    }

    editPassword = () => {
        if (this.state.user.password === '' && this.state.user.aeromegh_login_dirty === false) {
            this.setState((state) => ({
                ...state,
                oldPassword: '',
            }))
        }
        if (this.state.newPassword.length >= 8 && this.state.confirmPassword.length >= 8) {
            if (this.state.oldPassword != this.state.newPassword) {
                if (this.state.newPassword === this.state.confirmPassword) {
                    API.editPassword(this.state.oldPassword, this.state.newPassword).then(() => {
                        this.setState((state) => ({
                            ...state,
                            itemName: "Password",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: '',
                            showProcessPopup: true,
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                            match: false,
                            isChangePassword: false
                        }))
                    }, (e) => {
                        this.setState((state) => ({
                            itemName: "Password",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: e,
                            showProcessPopup: true,
                        }))
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        itemName: "ERROR",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: "New password and confirm password not match!",
                        showProcessPopup: true,
                    }))
                }
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "ERROR",
                    processPopupIcon: "ERROR",
                    processAction: "UPDATE",
                    processMessage: "You cannot use your old password as the new password. Please enter a new password.",
                    showProcessPopup: true,
                }))
            }
        } else {
            this.setState((state) => ({
                ...state,
                itemName: "ERROR",
                processPopupIcon: "ERROR",
                processAction: "UPDATE",
                processMessage: "Password is too short! must have 8 or more characters.",
                showProcessPopup: true,
            }))
        }
    }

    handlePasswordChange = (e) => {
        let t = e.target;
        if (t.value.length <= 40) {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.replace(/\s/g, '')
            }))
        }
    }

    render() {
        let { user } = this.state
        return (
            user.username ?
                <div className="wrapper">

                    {/* --------------Activity Popup ----------------------- */}
                    <ActivityPopup
                        item={this.state.itemName}
                        open={this.state.showProcessPopup}
                        icon={this.state.processPopupIcon}
                        action={this.state.processAction}
                        msg={this.state.processMessage}
                        close={() => this.closeActivityPopup()}
                        onClickOk={() => this.editPassword()}
                    />
                    {/* --------------------------------------------------------------- */}

                    <div className='setting-containt' >
                        <div style={{
                            border: '1px solid #2989cf2e',
                            borderRadius: '5px',
                            marginBottom: "20px",
                            padding: "0 20px"
                        }}>
                            <div style={{
                                display: "flex", justifyContent: "space-between",
                                padding: '10px 0 5px 10px',
                            }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ fontWeight: "500" }}>Login</div>
                                    {this.state.isChangePassword ? <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
                                        <div style={{ ...this.state.saveBtnStyle, border: '1px solid #C9C9C9', backgroundColor: 'white', color: '#555C67' }}
                                            onClick={() => { this.setState({ isChangePassword: false }) }}
                                        >
                                            Cancel
                                        </div>
                                        <div style={this.state.saveBtnStyle} onClick={() => { this.submitChangePassword() }}>Change</div>
                                    </div> : <div></div>}
                                </div>

                                <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
                                    {!this.state.isChangePassword ? <img src={editActive} style={{ height: "30px", opacity: '', cursor: 'pointer' }}
                                        onClick={() => {
                                            this.setState({
                                                isChangePassword: true,
                                            })
                                        }}
                                    /> : <></>}
                                </div>
                            </div>
                            {!this.state.isChangePassword ?
                                <div>
                                    <div style={{ padding: '0 0 5px 10px' }}>
                                        <div style={{ fontWeight: "400", fontSize: "11px", }}>Password</div>
                                    </div>
                                    <div style={{ padding: '0 0 10px 10px' }}>
                                        <div style={{ fontWeight: "400", fontSize: "11px", opacity: "0.6", display: "none" }}>Password last updated : March 1</div>
                                    </div>
                                </div>
                                :
                                <div style={{ padding: '0 0 10px 10px' }}>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <div style={{ fontSize: '11px', color: '#555C67' }}> Current password</div>
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px" }}>
                                            <input type={this.state.showCurrentPass ? "text" : 'password'} style={this.state.inputFieldStyle} name="oldPassword"
                                                value={this.state.oldPassword}
                                                onChange={(e) => { this.handlePasswordChange(e) }}
                                            />
                                            <img src={this.state.showCurrentPass ? eye : eyeOff} style={{ height: "15px" }} onClick={() => this.setState({ showCurrentPass: !this.state.showCurrentPass })} />
                                        </div>

                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <div style={{ fontSize: '11px', color: '#555C67' }}> New password</div>
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px" }} > <input type={this.state.showNewPass ? "text" : 'password'}
                                            style={this.state.inputFieldStyle}
                                            name="newPassword"
                                            value={this.state.newPassword}
                                            onChange={(e) => {
                                                this.handlePasswordChange(e)
                                            }}
                                        />
                                            <img src={this.state.showNewPass ? eye : eyeOff} style={{ height: "15px" }} onClick={() => this.setState({ showNewPass: !this.state.showNewPass })} />
                                        </div>
                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <div style={{ fontSize: '11px', color: '#555C67' }}> Confirm password</div>
                                        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "10px" }}> <input type={this.state.showConfirmPass ? "text" : 'password'}
                                            style={this.state.inputFieldStyle}
                                            name="confirmPassword"
                                            value={this.state.confirmPassword}
                                            onChange={(e) => {
                                                this.handlePasswordChange(e)
                                            }}
                                        />
                                            <img src={this.state.showConfirmPass ? eye : eyeOff} style={{ height: "15px" }} onClick={() => this.setState({ showConfirmPass: !this.state.showConfirmPass })} />
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                        <div style={{
                            border: '1px solid #2989cf2e',
                            borderRadius: '5px',
                            marginBottom: "20px",
                            padding: "0 20px"
                        }}>
                            <div style={{
                                display: "flex", justifyContent: "space-between",
                                padding: '10px 0 10px 10px',
                            }}>
                                <div style={{ fontWeight: "500" }}>Security</div>
                            </div>
                            <div style={{ padding: '0 0 5px 10px' }}>
                                <div style={{ fontWeight: "400", fontSize: "11px", opacity: "0.6" }}>Multi Factor Authentication (MFA) </div>
                            </div>
                            <div style={{ padding: '0 0 5px 10px' }}>
                                <div style={{ fontWeight: "400", fontSize: "11px", }}>After entering your password, you'll be asked for a second verification step.</div>
                            </div>

                            <div style={{ padding: '10px', display: "flex", width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <img src={!this.props.user.otp_flag ? shieldIcon : activeShieldIcon} alt='shield'
                                        style={{ height: '30px' }}
                                    />
                                </div>
                                <label className='toggle-label' style={{ marginBottom: '0px' }}>
                                    <input className='toggle-input'
                                        value={this.props.user.otp_flag}
                                        type="checkbox"
                                        checked={this.props.user.otp_flag}
                                        onClick={() => this.props.toggleTwoStepAuth()}
                                    />
                                    <span className='toggle-span' />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                : <div />
        );
    }
}
