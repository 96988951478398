import React, { Component } from "react";
import "./style.css";
import emptyTransparent from './icons/emptyTransparent.png'
import Loader from './icons/loader.svg';
import downloadIcon from './icons/download.svg';
import viewIcon from './icons/view.svg';
import trashIcon from './icons/trash.svg';
import shareIcon from "./icons/share.png";
import videoPlayIcon from './icons/VideoPlay.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import closeButton from './close-button.png';
import waiting from './res/waiting.png';
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import Popup from "reactjs-popup";
import * as API from './api.js';
import VideoPlayer from "./VideoPlayer.js"
import { Modal } from "react-bootstrap";
import Novideo from './assets/Images/Novideo.png';
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import Dropdown from 'react-bootstrap/Dropdown';
import copyLink from './icons/copyLink.png'
import copyLinkActive from './icons/tick.png'
import { addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions";
export default class AeroStreamVideos extends Component {
    state = {

        loader: true,
        user: {},
        type: "",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        sortType: 'Last Modified',
        liveFlightsCount: 0,
        message: 'Getting Stream Link Please Wait...',
        icon: waiting,
        showLinkPopup: false,
        copied: false,
        outId: ""
    };
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getAeroStreamVideos = () => {
        this.setState((state) => ({
            ...state,
            loading: true
        }))
        API.getAeroStreamVideos(0).then((data) => {
            this.setState((state) => ({
                ...state,
                flights: data.flights,
                liveFlightsCount: data.liveFlightsCount,
                loading: false
            }), () => {
                if (this.state.sortType === 'A-Z') {
                    this.sortByName()
                } else if (this.state.sortType === 'Z-A') {
                    this.reverseByName()
                } else if (this.state.sortType === 'First Modified') {
                    this.sortByDate()
                } else if (this.state.sortType === 'Last Modified') {
                    this.reverseByDate()
                }
            })
        }, (e) => {
            console.log(e);
        })

    }



    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.flights.sort((a, b) => a.title.localeCompare(b.title))
        this.setState((state) => ({
            ...state,
            flights: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.flights.sort((a, b) => b.title.localeCompare(a.title))
        this.setState((state) => ({
            ...state,
            flights: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.flights.sort((a, b) => a.start_time.localeCompare(b.start_time))
        this.setState((state) => ({
            ...state,
            flights: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.flights.sort((a, b) => b.start_time.localeCompare(a.start_time))
        this.setState((state) => ({
            ...state,
            flights: sort,
        }));
    }


    getSharableLink = (project_name, plan_name, flight_id) => {
        this.setState((state) => ({
            ...state,
            message: 'Getting Stream Link Please Wait...',
            icon: waiting,
            editTaskPopup: true,
        }), () => {
            let baseURL = process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "");
            API.getSharableLink(project_name, plan_name, flight_id).then((data) => {
                let streamID = data.streamID;
                let URL = baseURL + "/live_camera_feed" + "?streamID=" + streamID;
                this.setState((state) => ({
                    ...state,
                    sharableLink: URL,
                    showLinkPopup: true
                }))
            }, (e) => {
                console.log(e);
            })
        })

    }

    deleteVideo = () => {
        API.deleteRADVideo(this.state.selectedFlghtID).then((data) => {
            if (data.message === "OK") {
                this.setState((state) => ({
                    ...state,
                    cteateIcon: success,
                    deletingRequestText: 'Video Deleted Successfully... ',
                }))
                this.getAeroStreamVideos()

            } else {
                this.setState((state) => ({
                    ...state,
                    cteateIcon: fail,
                    deletingRequestText: 'Video not deleted... ',
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }

    componentWillUnmount() {updateOutTime(this.state.outId) }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "AeroStream - My Videos";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getAeroStreamVideos()
                API.getUserSubbedServices().then(
                    (response) => {
                        let services = response[0];
                        if (services.length == 1 && services[0].title == 'AeroStream') {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: false
                            }))
                        } else {
                            this.setState((state) => ({
                                ...state,
                                showBackIcon: true
                            }))
                        }
                    }
                )
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getAeroStreamVideos()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }

    }
    render() {
        let videoPlayerHeight = 350
        let videoPlayerWidth = videoPlayerHeight * 1.777777777
        let { user, type, sidebarMode } = this.state;

        const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <div className='sort-type-style'>{this.state.sortType}</div>
            </a>
        ));

        return (
            <div className="wrapper">
                {/* <SideBar config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <Popup
                        style={{ width: "min-content" }}

                        trigger={<button id="play-video-popup-button" style={{
                            display: "none",
                        }}
                        ></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => { document.getElementById("play-video-popup-button").click(); }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <VideoPlayer playerHeight={videoPlayerHeight} playerWidth={videoPlayerWidth} playing={true} url={this.state.videoLink} streamKey={this.state.stream_key} />
                            </div>


                            <>
                            </>

                        </div>
                    </Popup>


                    <Popup
                        trigger={<button id="send-plan-popup-button" style={{
                            display: "none",
                            // width:"30% !important"
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("send-plan-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    cteateIcon: waiting,
                                    deletingRequestText: 'Deleting Request Please Wait...',
                                }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.cteateIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.deletingRequestText
                                }</div>

                            </>

                        </div>
                    </Popup>

                    <AnimatedModal
                        isOpen={this.state.editTaskPopup}
                        onRequestClose={() => {
                            this.setState((state) => ({
                                ...state,
                                editTaskPopup: false,
                            }))
                        }}
                        height={this.state.showLinkPopup ? "100px" : "200px"}
                        width={this.state.showLinkPopup ? "500px" : "400px"}
                    >
                        {this.state.showLinkPopup ? <>
                            <div style={{ margin: '0px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                                <div style={{ width: '90%' }}>
                                    <input className="add-user-form-text" value={this.state.sharableLink} style={{ width: '100%', color: '#2989CF' }} />
                                </div>
                                <div>
                                    <CopyToClipboard text={this.state.sharableLink}
                                        style={{ height: '40px', paddingTop: '9px', paddingBottom: '9px', cursor: 'pointer' }}
                                        onCopy={() => {
                                            this.setState({ copied: true })
                                            setTimeout(() => {
                                                this.setState({ copied: false, editTaskPopup: false })
                                            }, 1500);
                                        }}>
                                        <img src={this.state.copied ? copyLinkActive : copyLink} style={{}} />
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </> : <>
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.icon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>
                            <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{this.state.message}</div>
                        </>
                        }
                    </AnimatedModal>

                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showVideoDeletePopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showVideoDeletePopup: false,

                            }));
                        }}
                    >
                        <Modal.Body >
                            <p>Are you sure you want to delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showVideoDeletePopup: false,
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    document.getElementById("send-plan-popup-button").click();
                                    this.setState((state) => ({
                                        ...state,
                                        showVideoDeletePopup: false,
                                        cteateIcon: waiting,
                                        deletingRequestText: 'Deleting Video Please Wait...',
                                    }))
                                    this.deleteVideo()

                                }}
                            >
                                Ok
                            </button>

                        </Modal.Footer>
                    </Modal>
                    <Modal
                        style={{ marginTop: '85px' }}
                        show={this.state.showVideo}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showVideo: false,
                            }));
                        }}
                    >
                        <Modal.Body>
                            {/* <p>Are you sure to delete all mission waypoints.</p> */}
                            <VideoPlayer playerHeight={'220px'} playing={true} url={this.state.videoLink} streamKey={this.state.stream_key} />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showVideo: false,
                                    }));
                                }}
                            >
                                cancel
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <div className="top-bar-new">
                        <div className="top-bar-text-new">AEROSTREAM</div>
                    </div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> :
                        <div className="main-content-new" style={{ background: 'none' }}>
                            {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}

                            <div style={{ width: "100%" }}>
                                <div>
                                    {/* <div className="page" style={{ padding: '0px', width: '1200px', minWidth: '957px' }}> */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="services-content-title">
                                            {`My Videos`} ({this.state?.flights?.length})

                                        </div>
                                        <Dropdown style={{ marginRight: '10px' }}>
                                            <Dropdown.Toggle as={CustomSortToggle} id="dropdown-custom-components" >
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm" title=""
                                                className="custom-dropdown-settings-sort"
                                            >
                                                <Dropdown.Item eventKey="delete"
                                                    style={{ padding: '0px !important', fontSize: '12px' }}
                                                    className="dropdown-set-padding-sort"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.reverseByDate()
                                                    }}
                                                >
                                                    <div style={{ marginLeft: '5px' }}>Last Modified</div>
                                                </Dropdown.Item>

                                                <Dropdown.Item eventKey="delete"
                                                    style={{ padding: '0px !important', fontSize: '12px' }}
                                                    className="dropdown-set-padding-sort"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.sortByDate()
                                                    }}
                                                >
                                                    <div style={{ marginLeft: '5px' }}>First Modified</div>
                                                </Dropdown.Item>

                                                <Dropdown.Item eventKey="delete"
                                                    style={{ padding: '0px !important', fontSize: '12px' }}
                                                    className="dropdown-set-padding-sort"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.sortByName()
                                                    }}
                                                >
                                                    <div style={{ marginLeft: '5px' }}>A-Z</div>
                                                </Dropdown.Item>

                                                <Dropdown.Item eventKey="delete"
                                                    style={{ padding: '0px !important', fontSize: '12px' }}
                                                    className="dropdown-set-padding-sort"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.reverseByName()
                                                    }}
                                                >
                                                    <div style={{ marginLeft: '5px' }}>Z-A</div>
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {this.state.flights && this.state.flights.length > 0 ? <div className="flight-graph-container" style={{ paddingRight: '10px', paddingLeft: '0px', width: "100%", marginTop: '12px' }}>
                                        <div className="cardstray2" id="cardstray2" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 275px))' }}>
                                            {this.state.flights.filter(f => f.stream_uploaded == true || f.end_time == null).map((flight, f) => {
                                                return <div className="cardscon" style={{ height: '222px', width: '245px', cursor: 'pointer', borderRadius: '3px', padding: 'unset' }}

                                                ><div className="container" style={{ background: 'transparent' }}>
                                                        <div className="row" style={{ height: '32px', background: '#666666', padding: '5px 12px 5px 8px' }}>
                                                            <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                                                {flight.end_time == null ? '' :
                                                                    <div style={{ marginLeft: 'auto' }}>

                                                                        <img src={downloadIcon} className="share-icon" style={{ height: '20px', width: '20px', marginLeft: 'auto' }}
                                                                            onClick={() => {
                                                                                window.open(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/request_a_drone/download/progress?fid=" + flight.id);

                                                                            }}
                                                                        />

                                                                        <img src={trashIcon} className="share-icon" style={{ height: '20px', width: '20px', marginLeft: '12px' }} onClick={() => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                showVideoDeletePopup: true,
                                                                                selectedFlghtID: flight.id,
                                                                                deletingRequestText: 'Deletieng Video Please Wait...',
                                                                                // shareLinkText2: 'Please Wait...'
                                                                            }))
                                                                        }} />

                                                                    </div>}

                                                                <img src={shareIcon} className="share-icon" style={{ height: '15px', width: '15px', marginLeft: '12px', marginTop: '2px' }} onClick={() => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        shareLinkText1: 'Getting Stream Link Please Wait...',
                                                                        // shareLinkText2: 'Please Wait...'
                                                                    }))


                                                                    // document.getElementById("send-plan-popup-button1").click();
                                                                    this.getSharableLink(flight.project_name, flight.plan_name, flight.id)


                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <img
                                                                onError={(e) => {
                                                                    e.target.src = emptyTransparent
                                                                    document.getElementById(f).style.display = ''
                                                                }}
                                                                className="cardimg"
                                                                style={{ width: '243px', height: '136px', position: 'absolute', borderRadius: "0px" }}
                                                                src={flight.thumbURL} alt="Video preview unavailable"
                                                            />
                                                            <div id={f} style={{ marginTop: '90px', fontSize: '16px', width: '243px', height: '136px', position: 'absolute', display: 'none', fontFamily: 'Poppins', fontWeight: 'normal' }}>Video preview unavailable</div>
                                                        </div>
                                                        <img src={videoPlayIcon}
                                                            onClick={async () => {


                                                                if (flight.end_time == null) {

                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        // showVideo: true,
                                                                        videoLink: `${flight.streamLiveBase ? `${flight.streamLiveBase}` : process.env.REACT_APP_STREAMING_BASE}/streams/${flight.streamkey}.m3u8`,
                                                                        stream_key: flight.streamkey
                                                                    }), () => {
                                                                        document.getElementById("play-video-popup-button").click();
                                                                    })

                                                                } else {

                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        // showVideo: true,
                                                                        videoLink: flight.videoURL
                                                                    }), () => {
                                                                        document.getElementById("play-video-popup-button").click();
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            style={{ width: "30px", height: "30px", zIndex: '1', marginTop: '50px', position: 'relative' }} />
                                                        <div className="row" style={{ height: '14px', display: 'flex' }}>
                                                            <div className="para" style={{ marginTop: '52px', textAlign: 'left', padding: '5px', fontWeight: '500' }}>{`${flight.title && flight.title.length > 35 ? flight.title.substring(0, 34) + '...' : flight.title}`}</div>
                                                            {flight.end_time == null ? <div className="para" style={{ marginTop: '52px', textAlign: 'left', padding: '5px', marginLeft: 'auto', color: '#2989cf' }}>{`Live`}</div> : ''}
                                                        </div>
                                                        <div className="row" style={{ height: '14px', display: 'flex', marginTop: '10px' }}>
                                                            <div className="para" style={{ marginTop: '55px', marginRight: 'auto', textAlign: 'left', padding: '5px', fontSize: '11px' }}>
                                                                {new Date(flight.start_time).toLocaleString().split(',')[1]}
                                                            </div><br />
                                                            <div className="para" style={{ display: 'flex', marginLeft: 'auto', marginTop: '55px', textAlign: 'left', padding: '5px' }}>
                                                                <img className="para" src={viewIcon} style={{ height: '18px', width: '18px', marginRight: '8px' }}></img>
                                                                <div className="para" style={{ fontSize: '11px' }}>{flight.videoViewCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div> :
                                        <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
                                            <img style={{ height: '180px', width: '200px' }} src={Novideo} />
                                            <p style={{ marginTop: '10px' }}>No videos available</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }
}
