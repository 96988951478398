import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import CanvasJSReact from './canvasjs.react';
import { Link,  Redirect } from 'react-router-dom';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class AdminUsersStatistics extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        currentpage: 1,
        userperpage: 8,
        currentCount: 0,
        allUsersTimeline: [],
        newUsersTimeline: [],
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }


    componentWillMount() {
        document.title = "Users - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }



    componentDidMount() {
        API.getUsersStats().then((results) => {
            let { allUsersTimeline, newUsersTimeline, currentCount } = results
            this.setState((prevState) => ({
                ...prevState,
                allUsersTimeline,
                newUsersTimeline,
                currentCount
            }))
        }).catch(e => {
            console.log("Failed to get page data.")
            console.log(e)
        })
    }


    render() {
        const { user, type, sidebarMode } = this.state;
        return (
            <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new"><div className="top-bar-text-new">USER STATISTICS</div></div>
                    <div className="main-content">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px", display: "flex" }}>
                            <div style={{ display: "block", margin: "auto" }}>
                                <div style={{ display: "flex" }}>
                                    <Link to={{
                                        pathname: "/admin/users",
                                        state: {
                                            user,
                                            type,
                                            sidebarMode
                                        }
                                    }} style={{
                                        color: "",
                                        margin: "40px 0px 0px auto"
                                    }}>
                                        <div className="addcustomer-button-empty-dashboard">
                                            All Users
                                        </div>
                                    </Link>
                                </div>
                                <div style={{
                                    display: "flex",
                                    marginTop: "-80px"
                                }}>
                                    <div style={{ margin: "auto", fontSize: "160px", fontWeight: "bold", lineHeight: "200px" }}>{this.state.currentCount}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ margin: "auto", fontSize: "20px", fontWeight: "bold" }}>Total AeroMegh Users</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "80px" }}>
                                    <div style={{ margin: "auto", width: "700px" }}>
                                        <CanvasJSChart options={{
                                            theme: "light2",
                                            animationEnabled: true,
                                            zoomEnabled: true,
                                            title: {
                                                text: "Total Users"
                                            },
                                            data: [{
                                                type: "area",
                                                dataPoints: this.state.allUsersTimeline.map(dp => ({ x: new Date(dp.timestamp), y: dp.count }))
                                            }]
                                        }} style={{
                                            width: "400px"
                                        }}>

                                        </CanvasJSChart>
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginTop: "80px" }}>
                                    <div style={{ margin: "auto", width: "700px" }}>
                                        <CanvasJSChart options={{
                                            theme: "light2",
                                            animationEnabled: true,
                                            zoomEnabled: true,
                                            title: {
                                                text: "New Users"
                                            },
                                            data: [{
                                                type: "area",
                                                dataPoints: this.state.newUsersTimeline.map(dp => ({ x: new Date(dp.timestamp), y: dp.count }))
                                            }]
                                        }} style={{
                                            width: "400px"
                                        }}>

                                        </CanvasJSChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
