import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import * as API from './api.js';
import thumb from './assets/map_placeholder.png';
import { Redirect } from "react-router-dom";
import closeButton from './close-button.png';
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import videoPlayIcon from './icons/videoPlayIcon.png'
import VideoPlayer from "./VideoPlayer.js"
import './style.css';
import Popup from "reactjs-popup";
import Loader from './icons/loader.svg';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import Novideo from './assets/Images/Novideo.png';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import { DeleteComponentGrid } from './ReusableComponents/Menu.js';
import AppConstants, { SERVICES, appConstants } from './AppConstants'
import { SortComponent } from './ReusableComponents/SortComponent.js';
import { ActiveRole, getActiveTeam, GCS_FEATURES, getFeaturesPermissions } from './Teams/index.js';
export default class RMPMyVideos extends Component {
  state = {
    user: {},
    type: "",
    userperpage: 50000,
    currentpage: 0,
    counts: 0,
    progress: 0,
    files: {},
    _progressInfos: [
      { percentage: 0, fileName: '' },
      { percentage: 0, fileName: '' },
      { percentage: 0, fileName: '' },
      { percentage: 0, fileName: '' },
      { percentage: 0, fileName: '' },
      { percentage: 0, fileName: '' },
    ],
    toShowProgressOf: {},
    showAddImagePopup: false,
    showDownloadPopup: false,
    loader: true,
    services: [],
    projects: [],
    userdisplay: [],
    redirect: false,
    collection_name: '',
    createCollectionMsg: '',
    videos: [],
    showBackIcon: true,
    cancel: false,
    loading: true,
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    imageset: '',
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    flights: [],
    gridVideo: [],
    sortType: 'Last Modified',
    selectedFlghtID: '',
    selectedVideo: {},
    outId: ""
  }
  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null,
      selectedFlghtID: '',
      selectedVideo: {}
    }))
  }

  sortByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'A-Z'
    }));
    let sort = this.state.gridVideo.sort((a, b) => (a.file_name ? a.file_name : a.streamkey).localeCompare(b.file_name ? b.file_name : b.streamkey))
    this.setState((state) => ({
      ...state,
      gridVideo: sort,
    }));

  }

  reverseByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Z-A'
    }));
    let sort = this.state.gridVideo.sort((a, b) => (b.file_name ? b.file_name : b.streamkey).localeCompare(a.file_name ? a.file_name : a.streamkey))
    this.setState((state) => ({
      ...state,
      gridVideo: sort,
    }));
  }

  sortByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'First Modified'
    }));
    let sort = this.state.gridVideo.sort((a, b) => (a.created_on ? a.created_on : a.start_time).localeCompare(b.created_on ? b.created_on : b.start_time))
    this.setState((state) => ({
      ...state,
      gridVideo: sort,
    }));
  }

  reverseByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Last Modified'
    }));
    let sort = this.state.gridVideo.sort((a, b) => (b.created_on ? b.created_on : b.start_time).localeCompare(a.created_on ? a.created_on : a.start_time))
    this.setState((state) => ({
      ...state,
      gridVideo: sort,
    }));
  }


  getAeroStreamVideos = () => {
    this.setState((state) => ({
      ...state,
      loading: true
    }))
    API.getAeroStreamVideosInGCS(this.state.project.project_name, this.state.plan.plan_name, 0).then((data) => {
      let videos = this.state.videos.concat(data.flights)
      let sort = videos.sort((a, b) => (b.created_on ? b.created_on : b.start_time).localeCompare(a.created_on ? a.created_on : a.start_time))
      this.setState((state) => ({
        ...state,
        flights: data.flights,
        loading: false,
        gridVideo: sort
      }), () => {
        if (this.state.sortType === 'A-Z') {
          this.sortByName()
        } else if (this.state.sortType === 'Z-A') {
          this.reverseByName()
        } else if (this.state.sortType === 'First Modified') {
          this.sortByDate()
        } else if (this.state.sortType === 'Last Modified') {
          this.reverseByDate()
        }
      })
    }, (e) => {
      console.log(e);
    })

  }


  successRedirectComponent = () => {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: "/aerocapture/upload_images",
        state: {
          user: this.state.user,
          type: this.state.type,
          sidebarMode: this.state.sidebarMode,
          collection: this.state.newCollecton
        }
      }} />
    }
  }

  onChangeListenerVideoFile = (e) => {
    e.preventDefault();
    let fileArr = Array.from(e.target.files)
    this.setState((state) => ({
      ...state,
      video: fileArr,
      videoName: fileArr[0].name
    }))
  }

  deleteStreamVideo = () => {
    this.setState((state) => ({
      itemName: "Video",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }))
    if (this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteRADVideo(this.state.selectedFlghtID).then((data) => {
      this.setState((state) => ({
        selectedVideo: {},
        selectedFlghtID: '',
        itemName: "Video",
        processPopupIcon: "COMPLETE",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
      }), () => {
        this.getAeroStreamVideos()
      })
    }, (e) => {
      console.log(e);
      this.setState((state) => ({
        selectedVideo: {},
        selectedFlghtID: '',
        itemName: "Video",
        processPopupIcon: "ERROR",
        processAction: "DELETE",
        processMessage: e,
        showProcessPopup: true,
      }))
    })
  }

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam: activeTeam
      }, () => {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        getFeaturesPermissions([GCS_FEATURES.IMAGECOLLECTIONS, GCS_FEATURES.PLANS])
          .then(permissions => {
            this.setState(state => ({ ...state, permissions, }), () => {
              var style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = '.popup-content { width: 30% !important; }';
              document.getElementsByTagName('head')[0].appendChild(style);
              document.title = " My Videos";
              if (this.props.location.state) {
                let { user, type, task, project, plan } = this.props.location.state;
                this.setState((state) => ({
                  ...state,
                  user,
                  type,
                  task,
                  project,
                  plan
                }), () => {
                  this.getAeroStreamVideos()
                  API.getUserSubbedServices().then(
                    (response) => {
                      let services = response[0];
                      if (services.length == 1 && services[0].title == 'AeroStream') {
                        this.setState((state) => ({
                          ...state,
                          showBackIcon: false
                        }))
                      } else {
                        this.setState((state) => ({
                          ...state,
                          showBackIcon: true
                        }))
                      }
                    }
                  )
                })
              } else {
                API.getUserType().then(([blah, type, user]) => {
                  this.setState((state) => ({
                    ...state,
                    user,
                    type
                  }), () => {
                    this.getAeroStreamVideos();
                  })
                }, (e) => {
                  window.location.replace("/login");
                })
              }
            })
          })
      })
    })
  }

  search(key) {
    this.setState((state) => ({
      ...state,
      key,
      currentpage: 1
    }),
      () => this.getAeroStreamVideos()
    );
  }

  firstpage = () => {
    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: 1
      }), () => {
        this.getAeroStreamVideos();
      });
    }
  }

  prevpage = () => {
    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: this.state.currentpage - 1
      }), () => {
        this.getAeroStreamVideos();
      });
    }
  }

  nextpage = () => {
    if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
      this.setState((state) => ({
        ...state,
        currentpage: state.currentpage + 1
      }), () => {
        this.getAeroStreamVideos();
      });
    }
  }

  lastpage = () => {
    if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
      this.setState((state) => ({
        ...state,
        currentpage: Math.ceil(this.state.counts / this.state.userperpage)
      }), () => {
        this.getAeroStreamVideos();
      });
    }
  }

  changepage(num) {
    if (isNaN(num)) {
      return null;
    }
    else {
      this.setState((state) => ({
        ...state,
        currentpage: num,
      }), () => this.getAeroStreamVideos());
    }

  }
  handleChange = (event) => {
    this.setState({ collection_name: event.target.value });
  }

  render() {
    const handleVideoUploadClick = (event) => {
      document.getElementById("hiddenVideoFileInput").click();
    };

    let videoPlayerHeight = 350
    let videoPlayerWidth = videoPlayerHeight * 1.777777777
    let { user, type, videos, sidebarMode, showBackIcon, userperpage, currentpage } = this.state

    return (
      user.username ? <div className="wrapper">
        <div style={{ display: 'flex' }}>
        </div>
        <div className="right-content-new"
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <Modal show={this.state.videoProgressPopup} onHide={() => this.setState((state) => ({
            ...state,
            videoProgressPopup: false
          }))}
            className="modal-dialog-centered"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
              {
                <>{this.state._progressInfos.length && this.state._progressInfos[0] ?
                  <ul type="none" style={{ paddingLeft: 'unset' }}>
                    <li style={{ fontSize: '14px', marginBottom: '8px' }}><>{
                      this.state._progressInfos[0].percentage == 100 ? 'Uploaded' : 'Uploading'
                    }</> {
                        this.state._progressInfos[0].fileName.length > 35 ?
                          `${this.state._progressInfos[0].fileName.substring(0, 34)}...` :
                          `${this.state._progressInfos[0].fileName}`
                      }</li>
                    <li>
                      <div className="progress-bar-container">
                        <div
                          className="progress-bar-bar"
                          style={{
                            width: `${this.state._progressInfos[0].percentage}%`,
                            display: "flex",
                          }}
                        ></div>
                      </div>
                      <div className="progress-bar-text">{`${this.state._progressInfos[0].percentage}%`}</div>
                    </li>
                  </ul> : ''}
                </>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                className="add-user-form-submit-button ok-button btn-ok"
                style={{ width: "fit-content" }}
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    videoProgressPopup: false
                  }))
                }}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>
          {this.successRedirectComponent()}
          <Popup
            style={{ width: "min-content" }}

            trigger={<button id="play-video-popup-button" style={{
              display: "none",
            }}
            ></button>}
            modal
          >
            <img
              id="close-popup"
              src={closeButton} style={{
                width: "auto",
                height: "26px",
                position: "absolute",
                top: "-13px",
                right: "-13px",
                cursor: "pointer"

              }} onClick={() => {
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = '.popup-content { width: 30% !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);
                document.getElementById("play-video-popup-button").click();
              }}></img>
            <div >
              <div style={{ width: "100%", display: "flex" }}>

                <VideoPlayer playerHeight={videoPlayerHeight} playerWidth={videoPlayerWidth} playing={true} url={this.state.videoLink} streamKey={this.state.stream_key} />
              </div>


              <>
              </>

            </div>
          </Popup>

          <div className="top-bar-new">
            <div className="breadCrumbs">
              <BreadCrumbs data={[
                {
                  displayName: "projects",
                  pathname: "/projects",
                  state: {
                    type,
                    user,
                  }
                },
                {
                  displayName: this.props.location.state.project.project_name,
                  pathname: "/plans",
                  state: {
                    type,
                    user,
                    project: this.props.location.state.project
                  }
                },
                {
                  displayName: this.props.location.state.plan.plan_name,
                  pathname: "/remote_mission/assignDroneToPlan",
                  state: {
                    type,
                    user,
                    plan: this.props.location.state.plan,
                    project: this.props.location.state.project,
                  }
                },
                {
                  displayName: "My Videos"
                }
              ]} />
              <ActiveRole
                hideBrackets={true}
              />
            </div>
          </div>

          {/* -------------- Activity Popup ----------------------- */}
          <ActivityPopup
            item={this.state.itemName}
            open={this.state.showProcessPopup}
            icon={this.state.processPopupIcon}
            action={this.state.processAction}
            msg={this.state.processMessage}
            close={() => this.closeActivityPopup()}
            onClickOk={() => this.deleteStreamVideo()}
          />
          {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
          </div> : <div className="main-content-new" style={{ background: 'none' }} onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }>
            <Modal
              style={{ marginTop: '150px' }}
              show={this.state.showAddImagePopup}
              onHide={() => {
                this.setState((state) => ({
                  ...state,
                  showAddImagePopup: false,
                  collection_name: ''

                }));
              }}
            >
              <Modal.Body >
                <div style={{ marginBottom: "30px" }}>
                  <div className="services-content-title">{"Create an Imageset"}</div>
                  <label className="font-color-secondary" style={{ fontSize: "13px" }}>To upload images you need to create an imageset.</label>
                  <input type="text" value={this.state.collection_name} className="add-user-form-text" placeholder="eg. Imageset Name" maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT}
                    onChange={this.handleChange} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="addcustomer-button-empty-dashboard popup-btn-left"
                  onClick={() => {
                    this.setState((state) => ({
                      ...state,
                      showAddImagePopup: false,
                    }));
                  }}
                >
                  cancel
                </button>
                {this.state.collection_name ?
                  <button className="add-user-form-submit-button popup-btn-right btn-ok">
                    Ok
                  </button> :
                  <button
                    className="add-user-form-submit-button popup-btn-right btn-ok"
                    style={{ opacity: '0.5', cursor: 'default' }}
                  >
                    Ok
                  </button>}

              </Modal.Footer>
            </Modal>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "25px", width: "100%" }}>
              <div className="services-content-title">{`Total Videos`}
                <span className='data-count'>({this.state.gridVideo.length})</span>
              </div>
            </div>
            {this.state.gridVideo.length > 0 ? <>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
              </div>
              <div style={{ height: "30px" }}>
                <SortComponent
                  label={'Name'}
                  sortAscending={this.sortByName}
                  sortDecending={this.reverseByName}
                  sortASC={true}
                />
              </div>
            </> : <></>}
            {this.state.gridVideo.length ?
              <div className="aerocapture-cards-tray-projects scroll-container" style={{
                height: 'auto', width: '102%',
                gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px));", overflowY: 'hidden',
              }}>
                {this.state.gridVideo.map((video, key) => {
                  return (
                    <div style={{ marginBottom: "18px" }}>
                      {<div className="customer-cards-dashboard card"
                        style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)' }}>
                        <div
                          style={{
                            dispaly: "relative",
                          }}
                          className="card-header customer-cards-dashboard-logo3-video project-card-header"
                        >
                          {
                            video && video.thumb_file_name ?
                              <img id="ProjectImage" style={{ objectFit: 'cover', height: '120px' }}
                                src={`${AppConstants.BLOB_URL}/${this.state.permissions.container}/DetectionVideos/${this.state.project.id}/${this.state.plan.id}/${video.thumb_file_name}?${this.state.permissions.st}`}
                              /> :
                              video.thumbURL ? <img id="ProjectImage" style={{ objectFit: 'cover', height: '120px' }}
                                src={video.thumbURL}
                              /> :
                                < img id='ProjectImage' src={thumb} style={{ height: '120px' }} />
                          }
                          <div style={{ position: "absolute", top: "5px", right: "5px" }} onClick={(e) => {
                            e.preventDefault()
                          }}>
                            {video.end_time == null || video.end_time == '' ? '' :
                              <div>
                                <DeleteComponentGrid
                                  style={{ margin: "0 5px" }}
                                  disableDelete={!this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE}
                                  onClickDelete={(e) => {
                                    e.preventDefault()
                                    if (video.streamkey && video.drone_id && video.start_time) {
                                      this.setState((state) => ({
                                        ...state,
                                        selectedFlghtID: video.id,
                                        itemName: "Video",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: null,
                                        showProcessPopup: true,
                                      }))
                                    } else {
                                      let selectedVideo = {
                                        file_name: video.file_name,
                                        video_id: video.id,
                                        project_id: video.project_id,
                                        plan_id: video.plan_id
                                      }
                                      this.setState((state) => ({
                                        ...state,
                                        selectedVideo,
                                        itemName: "Video",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: null,
                                        showProcessPopup: true,
                                      }))
                                    }
                                  }}
                                />
                              </div>
                            }
                          </div>
                          <img src={videoPlayIcon} style={{ width: "55px", height: "55px", top: '-100px', position: 'relative', cursor: 'pointer' }} onClick={
                            () => {

                              this.setState((state) => ({
                                ...state,
                                videoLink: !(video.file_name) && video.videoURL ? video.videoURL : `${AppConstants.BLOB_URL}/${this.state.permissions.container}/DetectionVideos/${this.state.project.id}/${this.state.plan.id}/${video.file_name}?${this.state.permissions.st}`,
                              }), () => {
                                var style = document.createElement('style');
                                style.type = 'text/css';
                                style.innerHTML = '.popup-content { width: 45% !important; }';
                                document.getElementsByTagName('head')[0].appendChild(style);
                                document.getElementById("play-video-popup-button").click();
                              })
                            }
                          } />
                        </div>
                        <div className="card-body change-color customer-cards-dashboard-text project-card-body">
                          <div>
                            <span style={{ fontSize: '14px', fontWeight: '500' }}>{
                              video.file_name && !(video.streamkey) ?
                                video.file_name.length > 14 ?
                                  `${video.file_name.substring(0, 13)}...` :
                                  `${video.file_name}` : `${video.streamkey}.mp4`
                            }</span>
                            <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between", color: "white" }}>
                              <span>{
                                video?.size ?
                                  (() => {
                                    if (Number(video?.size) < 1000) {
                                      return (Number(video?.size)).toFixed(2) + ' B'
                                    }
                                    else if (Number(video?.size) < 1024000) {
                                      return (Number(video?.size) / 1000).toFixed(2) + ' KB'
                                    }
                                    else if (Number(video?.size) < 1.024e+9) {
                                      return (Number(video?.size) / 1000 / 1000).toFixed(2) + ' MB'
                                    }
                                    else if (Number(video?.size) < 1.024e+12) {
                                      return (Number(video?.size) / 1000 / 1000 / 1000).toFixed(2) + ' GB'
                                    }
                                  })()
                                  : 'NA'
                              }</span>
                              <span>{
                                video.created_on ?
                                  (() => {
                                    let date = (new Date(video.created_on)).toGMTString().split(' ').slice(1, 4).join(' ');
                                    return `${date}`
                                  })()
                                  :
                                  (() => {
                                    let date = (new Date(video.start_time)).toGMTString().split(' ').slice(1, 4).join(' ');
                                    return `${date}`
                                  })()
                              }</span>
                            </div>
                          </div>
                        </div>
                      </div>}
                    </div>
                  )
                })}
              </div> :
              <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
                <img style={{ height: '180px', width: '200px' }} src={Novideo} />
                <p style={{ marginTop: '10px' }}>No videos available</p>
              </div>
            }

            <footer className="footer" style={{ visibility: (this.state.counts > userperpage ? "visible" : "hidden"), position: 'fixed', width: '100%', bottom: '3%' }} >
              <div style={{ float: "left", marginTop: "0.5rem", display: "flex" }}>
                <div style={{ color: "#109cf1", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                  Page : {currentpage}
                </div>


              </div>
              <div style={{ float: "right", marginTop: "0.5rem", display: "flex", marginRight: '13.5rem' }}>
                {this.state.currentpage == 1 ?
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", cursor: "default", opacity: '0.5' }}>
                    <div className="add-user-form-submit-button" >
                      First
                    </div>
                  </button> :
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                    <div className="add-user-form-submit-button" >
                      First
                    </div>
                  </button>}
                {this.state.currentpage == 1 ?
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                    <div className="addcustomer-button-empty-dashboard" >
                      Prev
                    </div>
                  </button> :

                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                    <div className="addcustomer-button-empty-dashboard" >
                      Prev
                    </div>
                  </button>}
                <form>
                  <div style={{ display: "flex" }}>
                    <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                      Go to page :
                    </div>

                    <div className="add-user-form-text-wrapper">
                      <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                        onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                        onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                    </div>
                  </div>
                </form>
                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", cursor: "default", opacity: '0.5' }}>
                    <div className="addcustomer-button-empty-dashboard" >
                      Next
                    </div>
                  </button> :
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                    <div className="addcustomer-button-empty-dashboard" >
                      Next
                    </div>
                  </button>
                }
                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ?
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", cursor: 'default', opacity: '0.5' }}>
                    <div className="add-user-form-submit-button" >
                      Last
                    </div>
                  </button> :
                  <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                    <div className="add-user-form-submit-button" >
                      Last
                    </div>
                  </button>}
              </div>
            </footer>
          </div>}
        </div>
      </div> : <div />
    );
  }
}


