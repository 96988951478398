import React, { Component } from "react";

function arrayCompare(arr1, arr2) {
  return arr1.map(elem => arr2.includes(elem)).every(e => e)
}

function getWSConnectionString(topics) {
  return `${process.env.REACT_APP_KAFKA_MIDDLE_PROTOCOL + "://" + process.env.REACT_APP_KAFKA_MIDDLE_HOST + (process.env.REACT_APP_KAFKA_MIDDLE_PORT ? process.env.REACT_APP_KAFKA_MIDDLE_PORT : "") + "?server=temp?type=receiver?flightId=" + topics.toString() + "?token=ABCD"}`
}

export default class KafkaListener extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  ws = null

  componentDidUpdate(prevProps, prevState) {
    let { messageCallback, connectedCallback, disconnectedCallback, topics, from } = this.props;
    // console.log('from1', from);
    if (!arrayCompare(prevProps.topics, topics)) {
      try {
        this.ws.close()
        delete this.ws
      } catch (e) {
        console.log(e)
      }
      // console.log("Connecting on " + getWSConnectionString(topics))
      this.ws = new WebSocket(getWSConnectionString(topics))

      this.ws.onmessage = evt => {
        let message = JSON.parse(evt.data);
        if (messageCallback) messageCallback(message.topic, message);
      }

      this.ws.onclose = () => {
        if (disconnectedCallback) disconnectedCallback();
      }
    }
  }

  componentWillMount() {
    let { messageCallback, connectedCallback, disconnectedCallback, topics, from } = this.props;
    console.log('from', from, topics);
    topics.map((topic) => {
      this.ws = new WebSocket(getWSConnectionString(topic))
      this.ws.onmessage = evt => {
        let message = JSON.parse(evt.data);
        // console.log("MESSAGE FROM KAFKA", message)
        if (messageCallback) messageCallback(message.topic, message);
      }
      this.ws.onclose = () => {
        if (disconnectedCallback) disconnectedCallback();
      }
    })
  }

  componentWillUnmount() {
    try {
      this.ws.close()
      delete this.ws
    } catch (e) {
      console.log(e)
    }
  }


  render() {
    return <div style={{ display: "none" }} />
  }
}
