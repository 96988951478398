import React from 'react';
import "./style.css";
import * as API from "./api.js";
import ReactPlayer from 'react-player';
import Cookies from 'universal-cookie'
const cookie = new Cookies()


export default class VideoPlayer extends React.PureComponent {
	state = {
		last: 200,
		key: 1,
		seconds: 0
	}




	playLive = () => {
		// alert('inside on play')

		let stream_key = this.props.url.split('.m3u8')[0].split('streams/')[1];
		this.heartBeatInterval = setInterval(() => {
			let uri = `${this.props.url.split(`/streams/`)[0]}/play.html?name=${stream_key}`
			fetch(uri).then((r) => {
				if (r.status == 404) {
					// alert('video is not live')

					console.log("STREAM NOT LIVE");
					return 0
				} else if (r.status == 200) {
					// alert('video is live')
					let dt = {
						streams: [
							{
								streamKey: stream_key
							}]
					}
					API.viewerHeartbeat(dt).then((data) => {
						// alert('heartbeat called')
						console.log("HEARTBEAT CALLED");
						console.log(data);
					})
				}
			})


		}, 120000);

	}

	play = () => {
		console.log(this.p.getDuration());
		let videoDuration = Math.round(this.p.getDuration())
		this.setState((state) => ({
			...state,
			videoDuration: videoDuration
		}))
		// alert('playing stored video')
		this.heartBeatInterval1 = setInterval(() => {
			console.log('INTERVAL');
			let sec = this.state.seconds + 5
			this.setState((state) => ({
				...state,
				seconds: sec
			}))
			let stream_key = this.props.streamKey
			let dt = {
				streams: [
					{
						streamKey: stream_key
					}]
			}
			if (this.state.seconds >= 120) {
				API.viewerHeartbeat(dt).then((data) => {
					this.setState((state) => ({
						...state,
						seconds: 0
					}))
				})
			}
		}, 5000)
	}
	pause = () => {
		// alert('pause');
		clearInterval(this.heartBeatInterval1)
	}
	componentDidMount() {

		setTimeout(() => {
			try {
				document.getElementById("video_container").style["max-width"] = "none !important"
				document.getElementById("video_container").style["max-height"] = "none !important"

			} catch (e) { }
		}, 3000);
		if (this.props.url && this.props.url.split('.').includes('mp4')) {
			this.playLive()
		}
	}

	componentWillUnmount() {
		try {
			clearInterval(this.checkStream)
			clearInterval(this.heartBeatInterval)
			clearInterval(this.heartBeatInterval1)

		} catch (e) {

		}
	}
	onTrigger = (data) => {
		// console.log(data);
		if (this.props.videoCallback) {
			this.props.videoCallback({ playedSeconds: data.playedSeconds, flightId: this.props.flightId, videoDuration: this.state.videoDuration });
		}
		// event.preventDefault();
	}
	render() {
		let { url, paddingLeft, paddingRight, height, streamKey, playerHeight, playerWidth, playing } = this.props;
		let styleObject = {
			position:'relative'
		}
		if (paddingLeft) styleObject["paddingLeft"] = paddingLeft
		if (paddingRight) styleObject["paddingRight"] = paddingRight
		if (height) styleObject["height"] = height
		if (url && url.includes('m3u8')) {
			streamKey = url.split('.m3u8')[0].split('streams/')[1]
		}
		return <div className="video-player" style={styleObject}>
			{this.props?.droneName && <div style={{ position: 'absolute', right: '6px', top: 0, fontWeight: '500', fontSize: '12px', color: 'white', zIndex: 99, width: '298px', background: '#3c3c3cc9', textAlign: 'right', padding: '0px 5px' }} title={this.props.droneName}>{this.props.droneName.length > 20 ? `${this.props.droneName.substring(0, 20)}...` : this.props.droneName || ""}</div>}
			<div key={this.state.key + 2} style={{ display: 'flex' }}>
				{url && url.includes('m3u8') ?
					<iframe
						allow="fullscreen"
						height={playerHeight || '100%'}
						width={playerWidth || "100%"}
						// width="957"
						// height="560"
						style={styleObject}
						src={`${url.split(`/streams/`)[0]}/play.html?name=${streamKey}`} frameborder="0"></iframe>
					: <ReactPlayer
						config={{
							file: {
								attributes: {
									onContextMenu: e => e.preventDefault(),
									controlsList: 'nodownload'
								}
							}
						}}
						// style={{ borderRadius: "4px",margin:'auto',minWidth:'320px' }}
						style={{ margin: 'auto', background: 'black', minWidth: '190px' }} width={playerWidth || "100%"} height={playerHeight || "100%"}
						url={url}
						muted={true}
						playing={playing == false ? false : true}
						controls={true}
						autoPlay={false}
						onPlay={
							() => {
								this.play();
							}
						}

						onPause={() => {
							this.pause();
						}}
						onProgress={(data) => {
							// console.log(data);
							this.onTrigger(data)
							// this.setState((state)=>({
							// 	...state,
							// 	playedSeconds
							// }))
						}}
						ref={p => { this.p = p }}

					/>}


			</div>
		</div>
	}
}