import React, { Component } from 'react';
import * as API from '../../api.js';
import '../../style.css';
import Loader from '../../icons/loader.svg';
import dateFormat from 'dateformat';
import { Link, Redirect } from 'react-router-dom';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import { Line, Bar } from "react-chartjs-2";
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal.js';
import { SelectComponent } from '../../ReusableComponents/SelectComponent.js';
import Chart from "react-google-charts";
import AppConstants from '../../AppConstants.js';

var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substring(0, 10);
let dt = someDate.getDate()
let date2 = new Date();
date2.setDate(dt + 7)
let dd = date2.toISOString().substring(0, 10)
var today = new Date();
var todayDate = dateFormat(today, "yyyy-mm-dd")
var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
let aa = lastWeek.toISOString().substr(0, 10);
var firstDateOfYear = dateFormat(new Date(new Date().getFullYear(), 0, 1), "yyyy-mm-dd")

const mapOptions = {
    backgroundColor: "#EAEAEA",
    datalessRegionColor: "#FAF9F6",
    colorAxis: { colors: AppConstants.MAP_COLORS },
    legend: 'none'
}

export default class UserStatistics extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        currentpage: 1,
        userperpage: 20,
        key: " ",
        showDownloadCsvPopup: false,
        cancel: false,
        fromDate: aa,
        toDate: '',
        graphUnits: [{ value: "week", label: "This week" }, { value: "month", label: "This month" }, { value: "year", label: "This year" }, { value: 'custom', label: 'Custom' }],
        selectedGraphUnit: { value: "week", label: "This Week" },
        selectedGoStyle: {},
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        industry: " ",
        country: " ",
        role: " ",
        data: { datasets: [] },
        yTicks: {
            padding: 7,
            fill: "black",
            display: true,
            fontSize: 12,
            fontFamily: "Poppins",
            beginAtZero: true,
            fontStyle: "normal",
            fontColor: "#666666",
            lineHeight: "14px",
        },
        color1: "#85b4ff",
        color2: '#b5d2ff',
        color3: "#ebf3ff",
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        viewFlag: false,
        customFromDate: "-",
        customToDate: "-",
        firstDateOfYear: firstDateOfYear,
        industry: " ",
        country: " ",
        role: " ",
        showDownloadCsvPopup: false,
        xAxisLabel: 'Days',
        barGraphInput: '',
        countryData: [],
        lineLoading: true,
        barLoading: true,
        mapLoading: true,
        selectedIndustry: '',
        count: 0,
        redirectToStats: false,
        statType: '',
        statValue: ''
    }

    initDate = () => {
        let todaysDate = new Date()
        let currentDate = dateFormat(todaysDate, "yyyy-mm-dd")
        function getLastWeek() {
            var today = new Date();
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return lastWeek;
        }

        var lastWeek = getLastWeek();
        var lastWeekMonth = lastWeek.getMonth() + 1;
        var lastWeekDay = lastWeek.getDate();
        var lastWeekYear = lastWeek.getFullYear();
        var lastWeekDisplayPadded = ("0000" + lastWeekYear.toString()).slice(-4) + "-" + ("00" + lastWeekMonth.toString()).slice(-2) + "-" + ("00" + lastWeekDay.toString()).slice(-2);

        this.setState((state) => ({
            fromDate: lastWeekDisplayPadded,
            toDate: currentDate
        }), () => {
            this.onChangeGraphUnit([this.state.selectedGraphUnit])
        })
    }

    getSignupRecords() {
        const { industry, country, role, fromDate, toDate } = this.state
        API.downloadUserRecords(industry == "" ? " " : industry, country == "" ? " " : country, role == "" ? " " : role, fromDate, toDate)
            .then(data => {
                this.setState((state) => ({
                }))
            })
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    componentWillMount() {
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler

        );
        document.title = "User Statistics";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;

            let dateUnit = JSON.parse(localStorage.getItem('dateUnit'))

            if (dateUnit === null) {
                this.initDate()
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
                selectedGraphUnit: dateUnit != null ? dateUnit.unit : { value: "week", label: "This week" },
                fromDate: dateUnit != null ? dateUnit.fromDate : this.state.fromDate,
                toDate: dateUnit != null ? dateUnit.toDate : this.state.toDate
            }), () => {
                this.getUserStatistics()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.initDate()
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/success",
                state: {
                    ...this.state.successParams,
                    user: this.state.user,
                    type: this.state.type,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
        if (this.state.redirectToStats) {
            localStorage.setItem("dateUnit", JSON.stringify({ unit: this.state.selectedGraphUnit, fromDate: this.state.fromDate, toDate: this.state.toDate }))
            return <Redirect to={{
                pathname: "/analytictool/customStatistics",
                state: {
                    user: this.props.location.state.user,
                    type: this.props.location.state.type,
                    statType: this.state.statType,
                    statValue: this.state.statValue,
                    dateUnit: this.state.selectedGraphUnit,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate
                }
            }} />
        }
    }

    getUserStatistics = async () => {
        let { industry, country, role, fromDate, toDate, selectedGraphUnit } = this.state
        API.getUserSignupStatistics(industry, country, role, fromDate, toDate, selectedGraphUnit.value)
            .then(data => {

                let stepSize = data.users.length > 10 ? Math.floor(data.users.length / 10) : 1
                let yTicks = data.data.every((val, i, arr) => val === arr[i]) ? {
                    padding: 7,
                    fill: "black",
                    display: true,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    beginAtZero: true,
                    fontStyle: "normal",
                    fontColor: "#666666",
                    lineHeight: "14px",
                    stepSize: stepSize
                } : {
                    padding: 7,
                    fill: "black",
                    display: true,
                    fontSize: 12,
                    fontFamily: "Poppins",
                    beginAtZero: true,
                    fontStyle: "normal",
                    fontColor: "#666666",
                    lineHeight: "14px",
                }
                this.setState((state) => ({
                    ...state,
                    yTicks: yTicks,
                    loading: false,
                    userdisplay: data.users,
                    count: data.users.length,
                    lineLoading: false
                }), () => {
                    this.createBarGraphData()
                    this.constructGeoChartData()
                })
                data.data.forEach(element => {
                    if (element > 0) {
                        // let flightCount = this.state.flightCount
                        this.setState((state) =>
                        ({
                            ...state,
                            isFlights: true
                        }))
                    }

                })
                if (this.state.selectedGraphUnit.value == 'week') {
                    const data1 = (canvas) => {

                        return {
                            labels: data.labels,
                            datasets: [
                                {
                                    data: data.data,
                                    fill: true,
                                    backgroundColor: (context) => {
                                        if (!context.chart.chartArea) return;
                                        const chart = context.chart;
                                        const { ctx, data, chartArea: { top, bottom }, } = chart;
                                        const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                                        gradient.addColorStop(0, this.state.color1);
                                        gradient.addColorStop(0.5, this.state.color2);
                                        gradient.addColorStop(1, this.state.color3);
                                        return gradient
                                    },
                                    borderColor: "#2989cf",
                                    borderWidth: 0.5,
                                    tension: 0.5
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        lineLoading: false
                    }),
                        () => {
                            try {
                                document.getElementsByTagName('canvas')[0].className = "flight-graph";
                            } catch (e) {
                            }
                        }
                    );
                }
                else if (this.state.selectedGraphUnit.value == 'month') {
                    const data1 = (canvas) => {

                        return {
                            labels: data.labels,
                            datasets: [
                                {
                                    data: data.data,
                                    fill: true,
                                    backgroundColor: (context) => {
                                        if (!context.chart.chartArea) return;
                                        const chart = context.chart;
                                        const { ctx, data, chartArea: { top, bottom }, } = chart;
                                        const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                                        gradient.addColorStop(0, this.state.color1);
                                        gradient.addColorStop(0.5, this.state.color2);
                                        gradient.addColorStop(1, this.state.color3);
                                        return gradient
                                    },
                                    borderColor: "#2989cf",
                                    borderWidth: 0.5,
                                    tension: 0.5
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Days",
                        lineLoading: false

                    })
                        ,
                        () => {
                            try {
                                document.getElementsByTagName('canvas')[0].className = "flight-graph";
                            } catch (e) {
                            }
                        }
                    );
                }
                else if (this.state.selectedGraphUnit.value == 'year') {
                    const data1 = (canvas) => {

                        return {
                            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
                            datasets: [
                                {
                                    data: data.data,
                                    fill: true,
                                    backgroundColor: (context) => {
                                        if (!context.chart.chartArea) return;
                                        const chart = context.chart;
                                        const { ctx, data, chartArea: { top, bottom }, } = chart;
                                        const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                                        gradient.addColorStop(0, this.state.color1);
                                        gradient.addColorStop(0.5, this.state.color2);
                                        gradient.addColorStop(1, this.state.color3);
                                        return gradient
                                    },
                                    borderColor: "#2989cf",
                                    borderWidth: 0.5,
                                    tension: 0.5
                                },
                            ],
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: "Months",
                        lineLoading: false
                    })
                        ,
                        () => {
                            try {
                                document.getElementsByTagName('canvas')[0].className = "flight-graph";
                            } catch (e) {

                            }
                        }
                    );
                } else if (this.state.selectedGraphUnit.value == 'custom') {
                    let dateDiff = ''
                    if (this.state.viewFlag) {
                        dateDiff = this.checkDateDiff()
                    }
                    const gradientColor = (context) => {
                        if (!context.chart.chartArea) return;
                        const chart = context.chart;
                        const { ctx, data, chartArea: { top, bottom }, } = chart;
                        const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                        gradient.addColorStop(0, this.state.color1);
                        gradient.addColorStop(0.5, this.state.color2);
                        gradient.addColorStop(1, this.state.color3);
                        return gradient
                    }
                    const data1 = (canvas) => {

                        let temp = []
                        if (dateDiff < 60) {
                            return {
                                labels: data.labels,
                                datasets: [
                                    {
                                        data: data.data,
                                        fill: true,
                                        backgroundColor: gradientColor,
                                        borderColor: "#2989cf",
                                        borderWidth: 0.5,
                                        tension: 0.5
                                    },
                                ],
                            }
                        } else if (dateDiff > 60 && dateDiff < 365) {
                            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                            let mLabels = []
                            for (let i = 0; i < data.labels.length; i++) {
                                let t = data.labels[i]
                                mLabels.push(months[t - 1])
                            }
                            return {
                                labels: mLabels,
                                datasets: [
                                    {
                                        data: data.data,
                                        fill: true,
                                        backgroundColor: gradientColor,
                                        borderColor: "#2989cf",
                                        borderWidth: 0.5,
                                        tension: 0.5
                                    },
                                ],
                            }
                        } else if (dateDiff > 365) {
                            return {
                                labels: data.labels,
                                datasets: [
                                    {
                                        data: data.data,
                                        fill: true,
                                        backgroundColor: gradientColor,
                                        borderColor: "#2989cf",
                                        borderWidth: 0.5,
                                        tension: 0.5
                                    },
                                ],
                            }
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        data: data1(),
                        xAxisLabel: dateDiff < 60 ? "Days" : dateDiff > 60 && dateDiff < 365 ? 'Months' : 'Years',
                        lineLoading: false
                    })
                        ,
                        () => {
                            try {
                                document.getElementsByTagName('canvas')[0].className = "flight-graph";
                            } catch (e) {

                            }
                        }
                    );
                }

            })
    }

    constructGeoChartData = () => {
        API.getCountryData(this.state.fromDate, this.state.toDate).then(result => {
            this.setState((state) => ({
                ...state,
                mapLoading: false,
                countryData: result.data
            }))
        })
    }

    createBarGraphData = () => {
        API.getIndustryData(this.state.fromDate, this.state.toDate).then(data => {
            console.log("GOT DATA FOR INDUSTRY GRAPH")
            let sum = data.data.reduce((a, b) => a + b, 0)
            let stepSize = sum > 10 ? Math.floor(sum / 10) : 1
            let labels = ["Agriculture", "Construction", "Oil & Gas", "Power & Utility", "Survey & Mapping", "Other"]
            let barGraphTicks = {
                padding: 7,
                fill: "black",
                display: true,
                fontSize: 12,
                fontFamily: "Poppins",
                beginAtZero: true,
                fontStyle: "normal",
                fontColor: "#666666",
                lineHeight: "14px",
                stepSize: stepSize
            }
            const bar_data = (canvas) => {

                return {
                    labels: labels,
                    datasets: [
                        {
                            data: data.data,
                            fill: true,
                            backgroundColor: (context) => {
                                if (!context.chart.chartArea) return;
                                const chart = context.chart;
                                const { ctx, data, chartArea: { top, bottom }, } = chart;
                                const gradient = ctx.createLinearGradient(0, 0, top, bottom);
                                gradient.addColorStop(0, this.state.color1);
                                gradient.addColorStop(0.5, this.state.color2);
                                gradient.addColorStop(1, this.state.color3);
                                return gradient
                            },
                            borderColor: "#2989cf",
                            borderWidth: 0.5,
                        },
                    ],
                }
            }
            this.setState((state) => ({
                ...state,
                barGraphInput: bar_data(),
                barGraphTicks: barGraphTicks,
                barLoading: false
            }),
                () => {
                    try {
                        document.getElementsByTagName('canvas')[0].className = "flight-graph";
                    } catch (e) {
                    }
                }
            );
        })
    }

    checkDateDiff = () => {
        let { fromDate, toDate } = this.state

        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;

        // Calculating the time difference between two dates
        const diffInTime = date2.getTime() - date1.getTime();

        // Calculating the no. of days between two dates
        const diffInDays = Math.round(diffInTime / oneDay);
        return diffInDays
    }

    getUser = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts, industry, country, role, fromDate, toDate } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        API.getUserSingupData(industry == "" ? " " : industry, country == "" ? " " : country, role == "" ? " " : role, fromDate, toDate, userperpage, firstpage).then((result) => {
            let users = result.users;
            let count = result.count;
            this.setState(state => ({
                ...state,
                userdisplay: users,
                counts: count,
                loading: false
            }))

        })

    }
    onChangeGraphUnit = (unit) => {
        if (unit[0].value == 'week') {
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
            let aa = lastWeek.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                    toDate: todayDate,
                    viewFlag: false,
                    selectedGraphUnit: unit[0],
                    lineLoading: true,
                    mapLoading: true,
                    barLoading: true
                }), () => this.getUserStatistics()
            );
        } else if (unit[0].value == 'month') {
            var toDate = new Date()
            var t = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
            var fdate = dateFormat(t, "yyyy-mm-dd")
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: fdate,
                    toDate: todayDate,
                    viewFlag: false,
                    selectedGraphUnit: unit[0],
                    lineLoading: true,
                    mapLoading: true,
                    barLoading: true
                }), () => this.getUserStatistics()
            );
        } else if (unit[0].value == 'year') {
            this.setState((state) => ({
                ...this.state,
                fromDate: firstDateOfYear,
                toDate: todayDate,
                viewFlag: false,
                selectedGraphUnit: unit[0],
                lineLoading: true,
                mapLoading: true,
                barLoading: true
            }), () => this.getUserStatistics()
            );
        } else {
            this.setState((state) => ({
                ...this.state,
                fromDate: '-',
                toDate: '-',
                selectedGraphUnit: unit[0],
                viewFlag: false,
            }));
        }
    }

    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                // this.getUser();
            });
        } else {
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    //this.getUser();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                //this.getUser();
            });
        } else {
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                //this.getUser();
            });
        } else {
        }
    }

    changepage(num) {
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => {
                //this.getUser()
            });
        }
    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => {
                this.getUser()
            });
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.userdisplay.sort((a, b) => a.first_name.localeCompare(b.first_name))
        this.setState((state) => ({
            ...state,
            userdisplay: sort,
        }));

    }

    redirectToStatistics = (statType, statValue) => {
        this.setState((state) => ({
            ...state,
            statType: statType,
            statValue: statValue,
            redirectToStats: true
        }))
    }


    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.userdisplay.sort((a, b) => b.first_name.localeCompare(a.first_name))
        this.setState((state) => ({
            ...state,
            userdisplay: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.userdisplay.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            userdisplay: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.userdisplay.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            userdisplay: sort,
        }));
    }

    render() {
        const { user, type, userdisplay, currentpage, userperpage, counts, firstDateOfYear } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        return (

            this.state.user && this.state.user.first_name ? <div className="wrapper">

                {/* --------------Activity Popup ----------------------- */}

                <ActivityPopup item={this.state.itemName} open={this.state.showProcessPopup} icon={this.state.processPopupIcon} action={this.state.processAction} msg={this.state.processMessage} close={() => this.closeActivityPopup()} />

                {/* --------------------------------------------------------------- */}

                {/*----------------------- Download user survey data in CSV popup-----------------------  */}
                <AnimatedModal
                    isOpen={this.state.showDownloadCsvPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            showDownloadCsvPopup: false,
                        }))
                    }}
                    height="260px"
                    width="400px"
                >
                    <div style={{ textAlign: "center", margin: '15px 0px', height: '30px', fontSize: '20px', fontWeight: '500', color: "#3c3c3c" }}>Download user survey data</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <div style={{ margin: '10px 80px', }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>From</div>
                            <div>
                                <input
                                    id="dateRequired"
                                    name="dateRequired"
                                    className="add-user-form-text2"
                                    type="date"
                                    max='9000-01-01'
                                    defaultValue={this.state.fromDate}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        this.setState((state) => ({
                                            ...state,
                                            fromDate: value,
                                            selectedGoStyle: {}
                                        }))
                                    }}
                                    value={this.state.fromDate}
                                    required
                                    style={{
                                        marginLeft: "5px",
                                        height: "30px",
                                        borderRadius: '10px',
                                        paddingLeft: '5px',
                                        width: "auto",
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: "flex", marginTop: "8%", justifyContent: "space-between" }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex" }}>To</div>
                            <div>
                                <input
                                    id="dateRequired"
                                    name="dateRequired"
                                    defaultValue={this.state.toDate}
                                    className="add-user-form-text2"
                                    type="date"
                                    max='9000-01-01'
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        this.setState((state) => ({
                                            ...state,
                                            toDate: value,
                                            selectedGoStyle: {}
                                        }))
                                    }}
                                    value={this.state.toDate}
                                    required
                                    style={{
                                        marginLeft: "5px",
                                        height: "30px",
                                        borderRadius: '10px',
                                        paddingLeft: '5px',
                                        width: "auto",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '30px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                        <button
                            className="addcustomer-button-empty-dashboard popup-btn-left"
                            onClick={() => {
                                this.setState((state) => ({
                                    ...state,
                                    fromDate: '',
                                    toDate: '',
                                    showDownloadCsvPopup: false,
                                }), () => {
                                    this.initDate()
                                });
                            }}
                        >
                            Cancel
                        </button>
                        {this.state.fromDate && this.state.toDate ?
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.getSignupRecords()
                                }}
                            >
                                Download
                            </button>
                            : <button disabled style={{ opacity: '0.5' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                            >
                                Download
                            </button>}
                    </div>
                </AnimatedModal>
                {/* ------------------------------------------------------------ */}

                <div className="right-content-new" >
                    {this.successRedirectComponent()}
                    <div className="top-bar-new">
                        <div className="top-bar-text-new">USERS Analytics</div>
                    </div>

                    <div className="main-content-new" style={{ padding: "20px" }}>

                        <div style={{ margin: "1% 4%", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div className="services-content-title">{"User Signup Statistics"}</div>
                                {this.state.fromDate != '-' && this.state.toDate != '-' ?
                                    <span className='data-count' style={{ textAlign: "center" }}>
                                        : {dateFormat(this.state.fromDate, "dd-mm-yyyy") + "  to  " + dateFormat(this.state.toDate, "dd-mm-yyyy")}
                                    </span> : <></>}
                            </div>
                            <div>
                                <div className="addcustomer-button-empty-dashboard" style={{ padding: '8px 15px', cursor: "pointer", opacity: userdisplay.length > 0 ? 1 : 0.5 }} onClick={() => {
                                    if (userdisplay.length > 0) {
                                        this.getSignupRecords()
                                    }
                                }}>Download data</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", height: "24px", margin: '0% 4%' }}>
                            <div>
                                <div style={{ display: "flex" }}>
                                    <SelectComponent
                                        selected={[this.state.selectedGraphUnit]}
                                        itemsList={this.state.graphUnits}
                                        onchangeHandler={(value) => { this.onChangeGraphUnit(value) }}
                                        fontSize={"13px"}
                                        fontWeight={500}
                                        border={true}
                                        disabled={this.state.loading}
                                        width={"110px"}
                                    /><span className='data-count'>({this.state.count})</span>
                                </div>
                            </div>

                            {this.state.selectedGraphUnit.value === 'custom' ?
                                <div style={{ display: "flex" }}>
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex", paddingLeft: "30px", alignItems: 'center' }} >
                                            <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "14px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>From:</label>
                                            <input
                                                id="dateRequired"
                                                name="dateRequired"
                                                defaultValue={this.state.fromDate}
                                                className="add-user-form-text2"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState((state) => ({
                                                        ...state,
                                                        fromDate: value,
                                                        viewFlag: false,
                                                        selectedGoStyle: {}
                                                    }))
                                                }}
                                                value={this.state.fromDate}
                                                required
                                                style={{
                                                    marginLeft: "5px",
                                                    height: "30px",
                                                    borderRadius: '10px',
                                                    paddingLeft: '5px',
                                                    width: "auto",
                                                }}
                                            />
                                            <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "14px", paddingTop: "8px", marginLeft: "15px", fontWeight: '600' }}>To:</label>
                                            <input
                                                id="dateRequired"
                                                name="dateRequired"
                                                defaultValue={this.state.toDate}
                                                className="add-user-form-text2"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(e) => {

                                                    let value = e.target.value;
                                                    this.setState((state) => ({
                                                        ...state,
                                                        toDate: value,
                                                        viewFlag: false,
                                                        selectedGoStyle: {}
                                                    }),
                                                    )

                                                }}
                                                value={this.state.toDate}
                                                required
                                                style={{
                                                    marginLeft: "5px",
                                                    height: "30px",
                                                    borderRadius: '10px',
                                                    paddingLeft: '5px',
                                                    width: "auto",
                                                }}
                                            />
                                            <div className="add-user-form-submit-button"
                                                style={{ height: "30px", margin: "2px 0px 2px 20px", cursor: this.state.fromDate != '-' && this.state.toDate != '-' ? "pointer" : 'not-allowed', opacity: this.state.fromDate != '-' && this.state.toDate != '-' ? 1 : 0.5, textAlign: "center", padding: "10px 20px 10px 20px" }}
                                                onClick={() => {
                                                    if (this.state.fromDate != '-' && this.state.toDate != '-') {
                                                        this.setState(
                                                            (state) => ({
                                                                ...state,
                                                                viewFlag: true,
                                                                lineLoading: true,
                                                                barLoading: true,
                                                                mapLoading: true
                                                            }), () => this.getUserStatistics()
                                                        )
                                                    }
                                                }}
                                            >View</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>}
                        </div>
                        <div style={{ marginTop: '20px', background: "white" }} className="container">
                            <div style={{ height: "380px" }}>
                                <div style={{ height: "100%" }}>
                                    <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>
                                        {this.state.lineLoading ?
                                            <div style={{ height: "67vh", width: "100%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                                <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                            </div>
                                            :
                                            <div style={{ overflow: "hidden", width: '86vw', position: 'relative' }}>
                                                <Line
                                                    width={"70vw"}
                                                    height={"20vh"}
                                                    options={{
                                                        maintainAspectRatio: true,
                                                        responsive: true,
                                                        labels: {
                                                            font: {
                                                                family: "Poppins",
                                                                size: "10",
                                                                style: "normal",
                                                                color: "#666666",
                                                            },
                                                        },
                                                        plugins: {
                                                            legend: {
                                                                display: false,
                                                            },
                                                        },
                                                        elements: {
                                                            point: {
                                                                pointStyle: 'circle',
                                                                backgroundColor: "#2989CF",
                                                            }
                                                        },
                                                        scales: {
                                                            min: 1,
                                                            x:
                                                            {
                                                                barPercentage: 0.5,
                                                                gridLines: {
                                                                    color: "#2989cf",
                                                                    display: true,
                                                                    drawOnChartArea: false,
                                                                    drawTicks: true,
                                                                    lineWidth: 1
                                                                },
                                                                ticks: {
                                                                    padding: 7,
                                                                    fill: "black",
                                                                    display: true,
                                                                    fontSize: 10,
                                                                    fontFamily: "Poppins",
                                                                    fontStyle: "normal",
                                                                    fontColor: "#666666",
                                                                    lineHeight: "auto",
                                                                },
                                                                scaleLabel: {
                                                                    display: true,
                                                                    fontSize: 12,
                                                                    fontFamily: "Poppins",
                                                                    fontStyle: "normal",
                                                                    fontColor: "#666666",
                                                                    labelString: this.state.xAxisLabel,
                                                                    borderColor: "#666666",
                                                                },
                                                            },
                                                        },
                                                        y:
                                                        {
                                                            barPercentage: 1,
                                                            gridLines: {
                                                                color: "#2989cf",
                                                                value: "none",
                                                                display: true,
                                                                drawOnChartArea: false,
                                                                drawTicks: false,
                                                                lineWidth: 1
                                                            },
                                                            ticks: this.state.yTicks,
                                                            scaleLabel: {
                                                                fontSize: 12,
                                                                fontFamily: "Poppins",
                                                                fontStyle: "normal",
                                                                fontColor: "#666666",
                                                                display: true,
                                                                labelString: "Users",
                                                            },
                                                        },
                                                    }}
                                                    data={this.state.data}
                                                />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0px', marginTop: '15px' }} className="container">
                            <div style={{ width: '48%' }}>
                                <div className="services-content-title" style={{ width: '44%', marginBottom: '5px' }}>Industry wise users </div>
                                <div style={{ height: "380px", background: "white" }}>
                                    <div style={{ height: "100%" }}>

                                        <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>
                                            {this.state.barLoading ?
                                                <div style={{ height: "67vh", width: "100%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                                    <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                                </div>
                                                :
                                                <div style={{ overflow: "hidden", width: '40vw', position: 'relative' }}>
                                                    <Bar
                                                        width={"30vw"}
                                                        height={"20vh"}
                                                        onElementsClick={element => {
                                                            if (element.length > 0 && this.state.fromDate !== '-' && this.state.toDate !== '-') {
                                                                this.props.history.push({ pathname: '/analytictool/user_statistics', state: { user: this.props.location.state.user, type: this.props.location.state.type, dateUnit: this.state.selectedGraphUnit } })
                                                                this.redirectToStatistics("industry", element[0]._view.label)
                                                            }
                                                        }}
                                                        options={{
                                                            maintainAspectRatio: true,
                                                            responsive: true,
                                                            labels: {
                                                                font: {
                                                                    family: "Poppins",
                                                                    size: "10",
                                                                    style: "normal",
                                                                    color: "#666666",
                                                                },
                                                            },
                                                            plugins: {
                                                                legend: {
                                                                    display: false,
                                                                },
                                                            },
                                                            elements: {
                                                                point: {
                                                                    pointStyle: 'circle',
                                                                    backgroundColor: "#2989CF",
                                                                }
                                                            },
                                                            scales: {
                                                                min: 0,
                                                                x:
                                                                {
                                                                    barPercentage: 0.5,
                                                                    gridLines: {
                                                                        color: "#2989cf",
                                                                        display: true,
                                                                        drawOnChartArea: false,
                                                                        drawTicks: false,
                                                                        lineWidth: 1
                                                                    },
                                                                    ticks: {
                                                                        padding: 5,
                                                                        fill: "black",
                                                                        display: true,
                                                                        fontSize: 8,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        lineHeight: "auto",
                                                                        beginAtZero: false
                                                                    },
                                                                    scaleLabel: {
                                                                        display: true,
                                                                        fontSize: 12,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        labelString: "Industry",
                                                                        borderColor: "#666666",
                                                                    },
                                                                },
                                                                y:
                                                                {
                                                                    barPercentage: 1,
                                                                    gridLines: {
                                                                        color: "#2989cf",
                                                                        value: "none",
                                                                        display: true,
                                                                        drawOnChartArea: false,
                                                                        drawTicks: false,
                                                                        lineWidth: 1
                                                                    },
                                                                    ticks: this.state.barGraphTicks,
                                                                    scaleLabel: {
                                                                        fontSize: 12,
                                                                        fontFamily: "Poppins",
                                                                        fontStyle: "normal",
                                                                        fontColor: "#666666",
                                                                        display: true,
                                                                        labelString: "Users",
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        data={this.state.barGraphInput}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '48%' }} >
                                <div className="services-content-title" style={{ width: '44%', marginBottom: '5px' }}>Country wise users</div>
                                <div style={{ height: "380px", background: "white" }}>
                                    <div style={{ height: "100%" }}>

                                        <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>
                                            {this.state.mapLoading ?
                                                <div style={{ height: "67vh", width: "100%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                                    <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                                </div>
                                                :
                                                <div style={{ overflow: "hidden", width: '86vw', position: 'relative' }}>
                                                    <Chart
                                                        chartType="GeoChart"
                                                        width="100%"
                                                        height="400px"
                                                        chartEvents={[
                                                            {
                                                                eventName: "select",
                                                                callback: ({ chartWrapper }) => {
                                                                    const chart = chartWrapper.getChart();
                                                                    const selection = chart.getSelection();
                                                                    if (selection.length === 0) return;
                                                                    const region = this.state.countryData[selection[0].row + 1];
                                                                    if (region.length > 0 && this.state.fromDate !== '-' && this.state.toDate !== '-') {
                                                                        this.props.history.push({ pathname: '/analytictool/user_statistics', state: { user: this.props.location.state.user, type: this.props.location.state.type } })
                                                                        this.redirectToStatistics("country", region[0])
                                                                    }
                                                                },
                                                            },
                                                        ]}
                                                        data={this.state.countryData}
                                                        options={mapOptions} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: "3% 3%" }}>
                            <div className="services-content-title">Users <span className='data-count'>({this.state.count})</span></div>
                            {
                                this.state.lineLoading ? (
                                    <div style={{ height: "10vh", width: "93vw", display: "flex" }}>
                                        <img src={Loader} style={{
                                            height: "auto",
                                            width: "auto",
                                            margin: "auto"
                                        }} />
                                    </div>
                                ) : (
                                    <div style={{ width: "100%", height: '50vh', marginTop: '10px', overflowY: "scroll" }}>
                                        <table className="customers-table" style={{ marginTop: "0" }}>
                                            <tr className="customers-table-header-row" >
                                                <th>Name
                                                    <SortComponent
                                                        style={{ marginLeft: '5px' }}
                                                        sortAscending={this.sortByName}
                                                        sortDecending={this.reverseByName}
                                                        sortASC={false}
                                                    />
                                                </th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Industry</th>
                                                <th>Country</th>
                                                <th>Role</th>
                                                <th>Signup date
                                                    <SortComponent
                                                        style={{ marginLeft: '5px' }}
                                                        sortAscending={this.sortByDate}
                                                        sortDecending={this.reverseByDate}
                                                        sortASC={false}
                                                    />
                                                </th>
                                            </tr>
                                            {userdisplay.length === 0 ?
                                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                    <td colspan="7">No Users Available</td>
                                                </tr> :

                                                userdisplay.map((user_info) =>

                                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                        <td>
                                                            <Link style={{ color: "#707683" }} to={{
                                                                pathname: `/analytictool/userdetails`,
                                                                state: { user, type, user_info, sidebarMode: this.state.sidebarMode }
                                                            }}>
                                                                {(user_info.first_name + " " + user_info.last_name).length > 20 ? (user_info.first_name + " " + user_info.last_name).substring(0, 20) : user_info.first_name + " " + user_info.last_name}
                                                            </Link></td>
                                                        <td>{user_info.username.length > 30 ? `${user_info.username.substring(0, 30)}...` : user_info.username}</td>
                                                        <td>{user_info.phone ? user_info.phone : "-"}</td>
                                                        <td>{user_info.industry ? user_info.industry.length > 22 ? `${user_info.industry.substring(0, 22)}...` : user_info.industry : "-"}</td>
                                                        <td>{user_info.country ? user_info.country : "-"}</td>
                                                        <td>{user_info.user_role ? user_info.user_role : "-"}   </td>
                                                        <td>{user_info.created_on ? dateFormat(user_info.created_on, 'dd mmm yyyy hh:MM:ss TT') : "-"}</td>
                                                    </tr>
                                                )}

                                        </table>
                                    </div>
                                )
                            }
                        </div>
                        <div style={{ paddingBottom: "5%" }}>
                            <footer className="footer" style={{ visibility: (this.state.counts > 20 ? "visible" : "hidden") }}>
                                <div style={{ float: "left", marginTop: "2rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", marginTop: "11px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginTop: "2rem", display: "flex" }}>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                        <div className="add-user-form-submit-button" >
                                            First
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button>
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>
                                            {/* <input type="text" style={{height:"40px",width:"40px",textAlign:"center",color: "#109cf1",fontSize:"14px"}} value={currentpage} onChange={(event)=>this.changepage(parseInt(event.target.value))} name="currentpage"/> */}
                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text2" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>

                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                        <div className="add-user-form-submit-button" >
                                            Last
                                        </div>
                                    </button>

                                </div>


                            </footer>
                        </div>
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
