import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import './TableView.css'
import { SortComponent } from '../SortComponent';
import TableViewPagination from './TableViewPagination';
import { DeleteComponentTable } from '../Menu';
import NoContent from '../NoContent';
import LoaderComponent from '../LoaderComponent';
import { useFirstRender } from '../firstRender';

const TableView = ({ columns, data, tableHeading, isLoading, showSrNo, count, onStateChange, onClickRow, activities, hidePagination, pageNumber, onClickLink }) => {
    const firstRender = useFirstRender();
    const [onTableStateChange, setOnTableStateChange] = useState({
        pageSize: 15,
        pageNumber: Number(pageNumber) || 1,
        orderBy: undefined,
        orderType: undefined
    })
    useEffect(() => {
        if (!firstRender)
            setOnTableStateChange(prev => ({ ...prev, pageNumber: Number(pageNumber) || 1 }))
    }, [pageNumber])
    let srNo = (onTableStateChange.pageNumber - 1) * onTableStateChange.pageSize

    useEffect(() => {
        if (!firstRender) {
            onStateChange(onTableStateChange)
        }
    }, [onTableStateChange])

    const filteredData = data.map(row => {
        const formattedRow = {};
        Object.keys(row).forEach(key => {
            if (columns.some(column => column.accessor === key)) {
                formattedRow[key] = row[key];
            }
            else {
                formattedRow[key] = "-";
            }
        });
        return formattedRow;
    });

    return (
        <div className={'reusable-table-view-container'} >
            <div className={tableHeading?.length > 0 ? 'reusable-table-title-container' : 'reusable-table-no-title-container'}>
                {tableHeading && <div className='reusable-table-title'> {tableHeading} </div>}
                {<div className='reusable-table-title'> ({count})</div>}
            </div>
            <div className={`reusable-table-containar scroll-container`} >
                <div style={{
                    margin: "0% 0% 0% 2%",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: 'space-between',
                }}>

                </div>
                <table className={`reusable-table-view `}>
                    {showSrNo && <col style={{ width: '5%' }} />}
                    {columns.map((column, columnIndex) => {
                        return <col key={columnIndex} style={{ width: column.width || 'auto' }} />
                    })}
                    {activities && <col style={{ width: '5%' }} />}
                    {<thead>
                        <tr className="reusable-table-view-header-row">
                            {showSrNo && <th key={0}>No.</th>}
                            {columns.map((column, columnIndex) => {
                                return <th key={columnIndex} style={{ textAlign: column.isCenter ? "center" : "left" }} >{column.Header}
                                    {column.isSortable && <SortComponent
                                        style={{ marginLeft: '5px' }}
                                        sortAscending={() => { setOnTableStateChange({ ...onTableStateChange, orderBy: column.accessor, orderType: 'ASC' }) }}
                                        sortDecending={() => { setOnTableStateChange({ ...onTableStateChange, orderBy: column.accessor, orderType: 'DESC' }) }}
                                        sortASC={false}
                                    />}</th>
                            })}
                            {(activities) && <th></th>}
                        </tr>
                    </thead>}
                    {isLoading ?
                        <tr align="center" >
                            <td colSpan={showSrNo && activities ? columns.length + 2 : activities || showSrNo ? columns.length + 1 : columns.length} style={{ height: "228px", background: "#FCFCFC" }}>
                                <LoaderComponent message={" "} imgMargin={"0px 0px 0px 80px"} />
                            </td>
                        </tr> :
                        filteredData.length === 0 ?
                            <tr className="reusable-table-view-data-row" align="center" >
                                <td colSpan="100%" style={{ height: "228px" }}>
                                    <NoContent />
                                </td>
                                {/* {activities &&  <td></td>} */}
                            </tr> :
                            <>
                                {filteredData.map((row, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        onClick={() => {
                                            if (onClickRow) onClickRow(data[rowIndex])
                                        }}
                                        className={`reusable-table-view-data-row-link row-hover`}
                                        style={{ cursor: onClickRow ? "pointer" : "auto", opacity: data[rowIndex]?.opacity || '1' }}
                                    >
                                        {showSrNo && <td key={rowIndex + 1}>{++srNo}</td>}
                                        {columns.map((column, columnIndex) => {
                                            const accessor = column.accessor;
                                            return <td style={{
                                                // width: column.width || "auto",
                                                maxWidth: '0px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: column.isLink ? "unset" : 'ellipsis',
                                                cursor: onClickRow ? "pointer" : "default",
                                                color: data[rowIndex].rowColor ? data[rowIndex].rowColor : ''
                                            }} key={columnIndex} >
                                                {column.isDate && row[accessor] != null ? dateFormat(row[accessor], "dd/mm/yyyy hh:MM TT") :
                                                    column.isLink ?
                                                        <img src={row[accessor].img} style={{ height: "20px", position: "relative", zIndex: 1, top: column.isCenter ? "25%" : "unset", left: column.isCenter ? "50%" : "unset", transform: column.isCenter ? "translate(-50%,-50%)" : "unset" }}
                                                            onClick={(e) => { e.stopPropagation(); onClickLink(data[rowIndex]) }} />
                                                        : row[accessor] || '-'}
                                            </td>;
                                        })}
                                        {activities && <td>
                                            <DeleteComponentTable
                                                style={{ marginRight: '10px' }}
                                                onClickDelete={activities.onClickDelete ? () => activities.onClickDelete(data[rowIndex]) : undefined}
                                                disableDelete={activities.disableDelete}
                                                canEdit={activities.canEdit}
                                                onClickEdit={activities.onClickEdit ? () => activities.onClickEdit(data[rowIndex]) : undefined}
                                                canManage={activities.canManage}
                                                onClickManage={activities.onClickManage ? () => activities.onClickManage(data[rowIndex]) : undefined}
                                                canRename={activities.canRename}
                                                onClickRename={activities.onClickRename ? () => activities.onClickRename(data[rowIndex]) : undefined}
                                                canExport={activities.canExport}
                                                onClickExport={activities.onClickExport ? () => activities.onClickExport(data[rowIndex]) : undefined}
                                                canDuplicate={activities.canDuplicate}
                                                onClickDuplicate={activities.onclickDuplicate ? () => activities.onclickDuplicate(data[rowIndex]) : undefined}
                                                canDownload={activities.canDownload}
                                                onClickDownload={activities.onClickDownload ? () => activities.onClickDownload(data[rowIndex]) : undefined}
                                            /> </td>}
                                    </tr>
                                ))}
                                <tr class="extra-table-row">

                                </tr>
                            </>

                    }
                </table>
            </div >
            {
                hidePagination || isLoading ? <></> : <div>
                    <TableViewPagination
                        currentPage={Number(pageNumber) || onTableStateChange.pageNumber}
                        totalCount={count || 0}
                        pageSize={onTableStateChange.pageSize}
                        padding="10px 5px"
                        onPageChange={(page) => {
                            setOnTableStateChange({ ...onTableStateChange, pageNumber: page })
                        }}
                    />
                </div>
            }
        </div >
    );
};

export default TableView;