import React, { useState, useRef, useEffect, Component } from 'react'
import StepContract from '../../icons/caret-down.png'
import AppConstants from '../../AppConstants'
export default function StepComponent(props) {
  const [imgSrc, setImgSrc] = useState(
    props.imgSrc,
  )

  useEffect(() => {
  }, [])

  return (
    <div className='content-text' style={{ fontSize: '10px', display: 'flex', width: props.width ? props.width : "", }}>
      <div className='flex-center' style={{ flexDirection: "column", width: '26%' }}>
        {/* Step number and icon */}
        <div className={`flex-center data-count stepper-circle ${props.step.completed || props.previousStepCompleted ? 'stepper-circle-completed' : ''}`}
          onClick={() => {
            if (props.previousStepCompleted) {
              props.onClickStep(props.stepNumber)
            }
          }}
          style={{
            
            cursor: props.step.completed || props.previousStepCompleted ? 'pointer' : 'default', border: '2px solid',
            background: (props.previousStepCompleted || props.step.completed) && props.active ? '#2989cf' : 'none',
            color:(props.previousStepCompleted || props.step.completed)?props.active ?"white":"#2989cf":'#A0A0A0',
            borderColor: props.previousStepCompleted || props.step.completed || props.active ? '#2989cf' : '#A0A0A0'
          }}>
          {!props.step.stepIcon ?
            <img src={props.active ? props.step.stepIcon[0] : (props.step.completed ? props.step.stepIcon[1] : props.step.stepIcon[2])}
              style={{ width: '16px' }}
            />
            : props.stepNumber}
        </div>
        {!props.isLastStep ? <div className='slide' style={{ marginLeft: '5px', borderRight: props.step.completed ? '2px solid #2989cf' : '2px solid #A0A0A0', height: props.step.expand ? `${props.stepHeight ? props.stepHeight : '90px'}` : '30px' }} />
          : <>
            <div className='slide' style={{ marginLeft: '5px', borderRight: props.step.completed ? '2px solid #2989cf' : '2px solid #A0A0A0', height: props.step.expand ? props.step.actionButton ? props.stepHeight : `50px` : '30px' }} />
            <div className='stepper-circle' style={{ background: props.step.completed ? '#2989cf' : '#A0A0A0', width: '10px', height: '10px', marginLeft: '5px' }} />
          </>}
      </div>
      {props.expanded ? < div className="slide " style={{ color:AppConstants.COLORS.STEPPERTEXTCOLOR,height: props.step.expand ? `${props.stepHeight ? props.stepHeight : '110px'}` : '30px', marginTop: '7px',marginLeft:"", width: '70%', overflow: 'hidden' }}>
        {/* Step name */}
        <div id={`step${props.stepNumber}`} style={{ display: 'flex', alignItems: 'center',color:AppConstants.COLORS.STEPPERTEXTCOLOR }} >
          <div className={props.previousStepCompleted ? 'stepper-step' : 'stepper-step-incomplete'} style={{fontSize:"15px"}} onClick={() => {
            if (props.previousStepCompleted) {
              props.onClickStep(props.stepNumber)
            }
          }}>{props.step.stepName ? props.step.stepName : `Step ${props.stepNumber}`}</div>
          <img src={StepContract} style={{ height: '10px', marginLeft: '10px', cursor: 'pointer',transform:props.step.expand ?"rotate(0deg)":"rotate(180deg)",transition:"0.25s all" }} onClick={() => { props.onClickExpandStep(props.stepNumber) }} />
        </div>
        {props.step.expand ? <div>
          <div style={{ marginTop: '5px',color:AppConstants.COLORS.STEPPERTEXTCOLOR }}>
            {/* Step description */}
            <div className='stepper-description' style={{color:AppConstants.COLORS.STEPPERTEXTCOLOR}}>
              {props.step.description1}
            </div>
            <div className='stepper-description' style={{ height: '20px',color:AppConstants.COLORS.STEPPERTEXTCOLOR }}>
              {props.step.description2}
            </div>
            {/* Step action button */}
            {props.step.actionButton ? <div id={`button-step${props.stepNumber}`} className="addcustomer-button-empty-dashboard"
              style={{ width: "92px", padding: "5px 10px", marginTop: '10px', color: props.step.enable || props.step.completed ? 'white' : '#666666', background: props.step.enable || props.step.completed ? '#2989cf':"", fontWeight: 500, fontSize: '11px', cursor: props.step.enable && !props.step.completed && props.previousStepCompleted ? 'pointer' : 'default', opacity: props.step.completed || !props.step.enable ? 0.5 : 1, borderColor: props.step.completed || props.step.enable ? "#2989cf" : "#666666" }}
              onClick={() => {
                if (props.step.enable && props.active && !props.step.completed)
                  props.onclickActionButton(props.stepNumber)
              }} >
              {props.step.actionButton}
            </div> : <></>}
          </div>
        </div> : <></>}
      </div> : <></>
      }
    </div >
  )
}
