//survey new logic
import React, { Component, useEffect } from "react";
import { addProjectDetails } from "./redux";
import * as API from "./api.js";
import "./style.css";
import { connect } from "react-redux";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import * as htmlToImage from 'html-to-image'
import Loader from './icons/loader.svg';
import SingleMap from "./ReusableComponents/map"
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import "leaflet/dist/leaflet.css";
import L, { layerGroup, marker } from "leaflet";
import "leaflet-editable"
import * as turf from '@turf/turf'
import {
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import { addPlan } from "./redux";
import { onUpdateLayer } from './ReusableComponents/map/leafletMeasure.js';
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import getArrows from './ReusableComponents/ArrowOnPolyline'
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from './Teams';
import AppConstants, { MAP_URLS } from './AppConstants';
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import { addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");

const EARTH_RADIUS = 6371.0 / 0.001;

class RMSurveyFlightPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: {},
            map: {},
            cameras: [
                {
                    "name": "Sony A6000",
                    "sensor_height": "15.60",
                    "sensor_width": "23.50",
                    "focal_length": "16.0"
                },
                {
                    "name": "Sony Rx100 II",
                    "sensor_height": "13.2",
                    "sensor_width": "8.80",
                    "focal_length": "10.4"
                },
                {
                    "name": "GO Pro Hero 4",
                    "sensor_height": "6.17",
                    "sensor_width": "4.55",
                    "focal_length": "3"
                },
                {
                    "name": "GO Pro Hero 7",
                    "sensor_height": "44.9",
                    "sensor_width": "62.3",
                    "focal_length": "17"
                },
                {
                    "name": "Canon s100 power shot",
                    "sensor_height": "7.60",
                    "sensor_width": "5.70",
                    "focal_length": "5.2"
                },
                {
                    "name": "RedEdge",
                    "sensor_height": "4.8",
                    "sensor_width": "3.6",
                    "focal_length": "5.5"
                },
                {
                    "name": "Sentera double 4k sensor",
                    "sensor_height": "6.2",
                    "sensor_width": "4.65",
                    "focal_length": "5.4"
                },
                {
                    "name": "Micasense Altum",
                    "sensor_height": "7.16",
                    "sensor_width": "5.35",
                    "focal_length": "8"
                },
                {
                    "name": "RedEdge-MX",
                    "sensor_height": "4.8",
                    "sensor_width": "3.6",
                    "focal_length": "5.4"
                },
                {
                    "name": "RedEdge P",
                    "sensor_height": "4.8",
                    "sensor_width": "3.6",
                    "focal_length": "5.4"
                },
                {
                    "name": "RedEdge M",
                    "sensor_height": "4.8",
                    "sensor_width": "3.6",
                    "focal_length": "5.4"
                },
                {
                    "name": "Custom Camera",
                    "sensor_height": "2",
                    "sensor_width": "2",
                    "focal_length": "2"
                }

            ],
            selectedCamera: {
                "name": "Sony A6000",
                "sensor_height": "15.60",
                "sensor_width": "23.50",
                "focal_length": "16.0"
            },
            center: { lat: 20.008482, lng: 73.756834 },
            altitude: 1,
            speed: 0,
            drawPlan: '',
            speedd: "",
            type: "",
            flightType: "",
            sidebarMode: this.props.location.state
                ? this.props.location.state.sidebarMode
                : "shrunk",
            redirect: false,
            creatingPlan: false,
            focalLength: "16.0",
            sensorHeight: "15.60",
            sensorWidth: "23.50",
            imageCount: "0",
            frontOverlap: 0,
            sideOverlap: 0,
            turnAroundAngle: 0,
            turnAroundDist: 0,
            surveyArea: 0,
            surveyLength: 0,
            polygon: {},
            fencePolygon: {},
            imagenaryPolygon: {},
            fenceCircle: {},
            Height_Ftp: 0,
            Width_Ftp: 0,
            polygonWidth: 0,
            polygonHeight: 0,
            reversePointPlot: false,
            itemName: "",
            showProcessPopup: false,
            processPopupIcon: "",
            processAction: "",
            processMessage: "",
            deleteItem: "",
            latLongList: [],
            latLongList1: [],
            DGCAfenceList: [],
            toggle: false,
            cameraIndex: 0,
            planName: '',
            projectName: '',
            editPlan: false,
            editPolygon: [],
            surveyTime: '0',
            layer: {},
            bounds: [],
            idx: 0,
            mapLoading: true,
            RGBShow: true,
            band1: "1",
            band2: "2",
            band3: "3",
            processTileIndex: "NDVI",
            processTileMin: -0.283,
            processTileMax: 0.181,
            processTileType: "RGN",
            baseLayer: '',
            activeBaseLayer: 'satelite',
            selectedLayer: 'Orthomosaic',
            layerSelectionPopup: false,
            selectedLayerType: 'orthomosaic',
            intersectReversePointPlot: false,
            outId: ""
        };
    }

    surveyWayPoints = []
    outerPolgonPoint = []
    pointsOnIntersectline = []
    visited = []
    unvisited = []

    setBaseLayerToState = (baseLayer) => {
        this.setState({ baseLayer })
    }

    updateHistogram = async () => {
        return new Promise((resolve, reject) => {
            let taskId = this.state.linked_map
            let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
            let sas = encodeURIComponent(this.state.permissions?.st)
            let blobContainer = this.state.permissions?.container
            let algorithm = processTileIndex
            API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
                this.setState((state) => ({
                    ...state,
                    processTileMin: data.statistics[1].percentiles[0].toFixed(4),
                    processTileMax: data.statistics[1].percentiles[1].toFixed(4),
                    processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
                }), () => {
                    this.overlayTilesLayerOf("plantHealth")
                })
            })
        })
    }

    changeSelectedLayer = (layer) => {
        console.log("Layer : ", layer);
        if (layer == 'NDVI') {
            console.log("Here is NDVI");
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "NDVI",
                processTileType: "RGN",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'VARI') {
            console.log("Here is vari");
            this.setState((state) => ({
                selectedLayer: layer,
                layerSelectionPopup: false,
                processTileIndex: "VARI",
                processTileType: "RGB",
                selectedLayerType: 'plantHealth'
            }), () => {
                this.updateHistogram()
            })
        } else if (layer == 'Orthomosaic') {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'orthomosaic'
            })
            this.overlayTilesLayerOf("orthomosaic")
        } else {
            this.setState({
                selectedLayer: layer,
                layerSelectionPopup: false,
                selectedLayerType: 'base'
            })
            this.updatePlanStyle('base')
            this.removeLayer('orthoLayer')
        }
    }

    // To change base layer of map
    switchBaseLayer = () => {
        const { map1 } = this.state
        this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
            this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        })
    }

    // save plan function starts here
    convertToWaypoints = () => {
        var latLongList = {
            waypoints:
                this.surveyWayPoints.map((location, key) => {
                    return ({
                        alt: Number(this.state.altitude),
                        "lat": location.lat,
                        "lon": location.lng,
                        speed: Number(this.state.speed),
                    })

                })
        }

        this.setState((state) => ({
            ...state,
            latLongList: latLongList
        }))
        return latLongList;
    }

    convertToFencePoint = () => {
        let polyPoints = this.state.polygon.toGeoJSON().geometry.coordinates[0]
        var latLongList1 = {
            geofence:
                polyPoints.map((location, key) => {
                    return ({
                        "lat": location[1],
                        "lon": location[0],
                        "alt": Number(this.state.altitude),
                    })
                })
        }
        this.setState((state) => ({
            ...state,
            latLongList1: latLongList1
        }))
        return latLongList1;
    }

    DGCAfence = () => {
        let polyPoints = this.state.fencePolygon.toGeoJSON().geometry.coordinates[0]
        var DGCAfenceList = {
            geofence:
                polyPoints.map((location, key) => {
                    return ({
                        "lat": location[1],
                        "lon": location[0],
                        "alt": Number(this.state.altitude),
                    })
                })
        }

        this.setState((state) => ({
            ...state,
            DGCAfenceList: DGCAfenceList
        }))
        return DGCAfenceList;
    }

    getStaticstics = () => {
        let stat =
        {
            "missionStatistics": [
                {
                    "cameraFocalLength": Number(this.state.focalLength),
                    "cameraSensorHeight": Number(this.state.sensorHeight),
                    "cameraSensorWidth": Number(this.state.sensorWidth),
                    "cameraType": Number(this.state.cameraIndex),
                    "corridorAltitude": 0,
                    "corridorWidth": 0,
                    "imageCount": Number(this.state.imageCount),
                    "imageFrontOverlap": Number(this.state.frontOverlap),
                    "imageSideOverlap": Number(this.state.sideOverlap),
                    "landAltLatitue": 0,
                    "landAltLongitude": 0,
                    "landAltitude": 0,
                    "landLatitue": 0,
                    "landLongitude": 0,
                    "missionDefaultAltitude": Number(this.state.altitude),
                    "missionDefaultSpeed": Number(this.state.speed),
                    "missionHomeAltitude": 0,
                    "missionHomeLatitude": Number(this.surveyWayPoints[0].lat),
                    "missionHomeLongitude": Number(this.surveyWayPoints[0].lng),
                    "missionType": 1,
                    "planeMaxSpeed": Number(this.state.speed),
                    "planeMinSpeed": Number(this.state.speed),
                    "speed": Number(this.state.speed),
                    "surveyCamTriggerDist": Number(this.state.Height_Ftp),
                    "surveyCamTriggerFlag": 1,
                    "surveyCoordCount": Number(this.surveyWayPoints.length),
                    "takeOffAltitude": 0,
                    "takeOffLatitue": 0,
                    "takeOffLongitude": 0,
                    "totalPlanDistance": Number(this.state.surveyLength),
                    "vehicleType": "QuadCopter",
                    "turnaroundDistance": Number(this.state.turnAroundDist),
                    "crossGridEnable": 0,
                    "imageFootprintHeight": Number(this.state.Height_Ftp),
                    "imageFootprintWidth": Number(this.state.Width_Ftp),
                    "verticalMission": 0,
                    "surveyAltitude": Number(this.state.altitude),
                    "surveyArea": Number(this.state.surveyArea),
                    "turnaroundAngle": Number(this.state.turnAroundAngle),
                    "verticalRularScaleMaxValue": 0,
                    "wpRadius": 0
                }
            ]
        }
        return (stat)
    }


    validateWaypoints = async (latLongList) => {
        let counter = 0;
        for (let j = 0; j < latLongList.waypoints.length; j++) {
            let point = { lat: parseFloat(latLongList.waypoints[j].lat), lng: parseFloat(latLongList.waypoints[j].lon) }
            let temp = await this.isMarkerInsidePolygon(point, this.state.fencePolygon)
            if (!temp) {
                counter++;
            }
        }
        return counter;
    };

    getflightSrc = async () => {
        let lat = this.state.latLongList.waypoints[0].lat;
        let lng = this.state.latLongList.waypoints[0].lon;

        // console.log('getflightSrc', lat, lng);

        let add = await Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].address_components[0].short_name;
                // planAddress.flightSource=address
                return address;
                // add = address
            },
            (error) => {
                console.error(error);
            }
        );
        return add;
    };

    getFlightDest = async () => {
        // let add;
        let length = this.state.latLongList.waypoints.length;
        let lat1 = this.state.latLongList.waypoints[length - 1].lat;
        let lng1 = this.state.latLongList.waypoints[length - 1].lon;
        let add = await Geocode.fromLatLng(lat1, lng1).then(
            (response) => {
                const address = response.results[0].address_components[0].short_name;
                // planAddress.flightDestination=address
                // add = address
                return address;
            },
            (error) => {
                console.error(error);
            }
        );
        return add;
    };

    resizedataURL = (datas, wantedWidth, wantedHeight) => {
        return new Promise(async function (resolve, reject) {
            // We create an image to receive the Data URI
            var img = document.createElement("img");

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL();

                // This is the return of the Promise
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;
        });
    }; // Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

    getAsset = async () => {
        this.setState((prevState) => ({
            ...prevState,
            creatingPlan: this.state.editPlan ? false : true,
            itemName: "Plan",
            processPopupIcon: "WAIT",
            processAction: this.state.editPlan ? "UPDATE" : "ADD",
            processMessage: null,
            showProcessPopup: true,
        }));
        // take screenshot of plan using html-to-image library
        var newDataUri;
        let a = await htmlToImage
            .toPng(document.getElementById("rmp-map"))
            .then(async (dataUrl) => {
                let newDataUri1;
                newDataUri = await this.resizedataURL(dataUrl, 1080, 720); // reduce the image size
                return newDataUri1;
                // download(dataUrl, 'map.png');
            });
        return newDataUri;
    };

    getPlan = async () => {
        let latLongList = await this.convertToWaypoints();
        let latLongList1 = await this.convertToFencePoint();
        let DGCAfenceList = await this.DGCAfence();
        let planStatistics = await this.getStaticstics();
        let inside1 = await this.validateWaypoints(latLongList); // call waypoint validation function
        if (inside1 > 0) {
            if (latLongList.length < 0) {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plan",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Mission is outside geofence area.",
                    showProcessPopup: true,
                }));
            } else {
                this.setState((state) => ({
                    ...state,
                    itemName: "Plan",
                    processPopupIcon: "ERROR",
                    processAction: "ADD",
                    processMessage: "Please select geofence area.",
                    showProcessPopup: true,
                }));
            }
        }
        else {

            // add all details of plan in single JSON object
            let Plan = {};
            let planAddress = {};

            let flightSrc = await this.getflightSrc();
            let flightDest = await this.getFlightDest();

            planAddress.flightDestination = flightDest;
            planAddress.flightSource = flightSrc;
            let myAsset = await this.getAsset();

            let Assets = {
                "planMap.png": myAsset,
            };
            let dt = await new Date();
            let date = dt.getDate().toString().padStart(2, "0");
            let month = (dt.getMonth() + 1).toString().padStart(2, "0");
            let year = dt.getFullYear();
            let hour = dt.getHours();
            let minute = dt.getMinutes();
            let seconds = dt.getSeconds();
            let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;

            let planConfiguration = {
                projectDetails: [
                    {
                        clientName: this.state.clientName,
                        'dateTime': new Date().toISOString(),
                        flightCompletedCount: 0,
                        projectName: this.state.projectName,
                        projectId: this.props.location.state.project.id,
                        planName: this.state.planName,
                        planCompletedStatus: 0,
                        softwareVerison: "1.1",
                        linkedMap: this.state.linked_map
                    }
                ]
            }
            Plan.planConfiguration = await planConfiguration;
            console.log('planConfiguration', planConfiguration);
            Plan.planAddress = await planAddress;
            console.log('planAddress', planAddress);
            Plan.missionStatistics = await planStatistics;
            console.log('planStatistics', planStatistics);
            Plan.DGCAfence = await DGCAfenceList;
            console.log('DGCAfenceList', DGCAfenceList);
            Plan.RallyPointData = {};
            Plan.corridor = {};
            Plan.Mission = await latLongList;
            console.log('latLongList', latLongList);
            Plan.Geofence = await latLongList1;
            console.log('latLongList1', latLongList1);
            Plan.Assets = await Assets;
            // Plan.Geofence.push(latLongList1[0]);
            return Plan;

        }
    };

    createPlan = async (e) => {
        if (this.state.editPlan == false && this.props.location.state.drawPlan == true) {
            this.setState((state) => ({
                ...state,
                editPlan: true
            }));
        }
        let plan = await this.getPlan();
        console.log('get all plan data', plan);
        if (this.state.editPlan == true) {
            API.editPlan(plan, this.props.activeTeamId).then(
                (data) => {
                    if (data.message == "OK") {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: null,
                            showProcessPopup: true,
                            redirect: true,
                        }));
                    } else {
                        //check if plan already exist the show msg in popup
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: data.message,
                            showProcessPopup: true,
                        }));
                    }
                },
                (e) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Plan",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: e,
                        showProcessPopup: true,
                    }));
                }
            );
        } else {
            API.createPlan(plan, this.props.activeTeamId).then(
                async (data) => {
                    console.log("INSIDE DATA", data);
                    if (data.message == "OK") {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: null,
                            showProcessPopup: true,
                            redirect: true,
                            plan: {
                                plan_name: this.props.planName,
                                id: data?.plan?.id
                            }
                        }));
                    } else if (data.message == "Plan already exists") {
                        //check if plan already exist the show msg in popup
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: "Plan name already exist",
                            showProcessPopup: true,
                        }));
                    } else if (data.error) {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: data.error,
                            showProcessPopup: true,
                        }));
                    } else {
                        this.setState((prevState) => ({
                            ...prevState,
                            itemName: "Plan",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: data.message,
                            showProcessPopup: true,
                        }));
                    }
                },
                (e) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "Plan",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: e,
                        showProcessPopup: true,
                    }));
                }
            );
        }
    }

    initMap = () => {
        let { map, center } = this.state
        console.log("Center : ", center);
        this.drawPolygon(map, center, false)
        onUpdateLayer(map, this.polygonUpdated)
        this.overlayTilesLayerOf("orthomosaic")
    };

    getTilesBounds = async (taskId) => {
        return new Promise((resolve, reject) => {
            let sas = encodeURIComponent(this.state.permissions?.st)
            let blobContainer = this.state.permissions?.container
            API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
                this.setState({
                    tilesBounds: data.tilesBounds,
                    bounds: data.bounds,
                    //defaultOrtho: true,
                    band1: "1",
                    band2: "2",
                    band3: "3",
                }, () => {
                    resolve(this.state.bounds)
                })
            })
        })
    }

    removeLayer = async (layer) => {
        let removeLayer = this.state[layer]
        this.setState({ [layer]: undefined }, () => {
            if (removeLayer) {
                console.log(removeLayer)
                removeLayer.remove()
            }
        })
    }

    updatePlanStyle = (type) => {
        //-------- Change polyline, arrows, polygon and marker color according to layer --------
        if (this.state.planLine)
            this.state.planLine.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf' })
        if (this.state.toggle && this.state.fencePolygon)
            this.state.fencePolygon.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'green' : 'white' })
        if (this.state.arrowArray && this.state.arrowMarkerLayer) {
            this.state.arrowMarkerLayer.remove();
            let arrows = getArrows(this.state.arrowArray, this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf', 1, this.state.map)
            let arrowMarkerLayer = L.featureGroup(arrows).addTo(this.state.map);
            this.setState((state) => ({
                ...state,
                arrowMarkerLayer: arrowMarkerLayer,
            }))
        }
    }


    overlayTilesLayerOf = async (type) => {
        let { map } = this.state
        this.removeLayer("orthoLayer").then(async () => {
            if (this.state.linked_map) {
                this.updatePlanStyle(type)
                let taskid = this.state.linked_map
                let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
                let blobContainer = this.state.permissions?.container;
                let sas = encodeURIComponent(this.state.permissions?.st);
                await this.getTilesBounds(taskid)
                let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
                console.log(bounds);
                let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
                if (type == "plantHealth") {
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    this.setState({ orthoLayer })
                } else {
                    console.log("in else part...");
                    let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
                        tileSize: 256,
                        maxZoom: 28,
                        bounds: bounds
                    }).addTo(map);
                    console.log(orthoLayer);
                    orthoLayer.on("load", () => { this.setState({ mapLoading: false }) });
                    this.setState({ orthoLayer })
                }
            }
        })
    }

    drawPolygon = (map, location, drawPolygon) => {
        this.setState((state) => ({
            ...state,
            reversePointPlot: false,
            intersectReversePointPlot: false
        }), () => {
            let markersLayer = L.featureGroup().addTo(map)
            this.setState({ markersLayer })
            if (location) {
                let distanceFromClick = 0.0004
                let distanceFromClick1 = 0.0006
                let lat = Number(location.lat)
                let lng = Number(location.lng)
                let initialPolyPoint1 = [lat - distanceFromClick, lng - distanceFromClick1]
                let initialPolyPoint2 = [lat + distanceFromClick, lng - distanceFromClick1]
                let initialPolyPoint3 = [lat + distanceFromClick, lng + distanceFromClick1]
                let initialPolyPoint4 = [lat - distanceFromClick, lng + distanceFromClick1]

                drawPolygon = this.state.editPlan ? this.state.editPolygon : [initialPolyPoint1, initialPolyPoint2, initialPolyPoint3, initialPolyPoint4]

                let polygon = new L.Polygon(drawPolygon, {
                    color: 'red',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(map).on('click', () => { })

                if (this.state.toggle == false) {
                    polygon.enableEdit()
                }
                let bounds = polygon.getBounds()
                map.fitBounds(bounds);
                let surveyArea = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0])
                this.setState((state) => ({
                    ...state,
                    polygon,
                    surveyArea
                }), () => {
                    this.drawFence()
                })
            } else {
                let polygon = new L.Polygon(drawPolygon.map((latLng => [latLng.lat, latLng.lng])), {
                    color: 'red',
                    fillOpacity: 0,
                    weight: 3,
                    zIndex: 1,
                }).addTo(map).on('click', () => { })
                if (this.state.toggle == false) {
                    polygon.enableEdit()
                }
                let surveyArea = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0])
                this.setState((state) => ({
                    ...state,
                    polygon,
                    surveyArea
                }), () => {
                    this.drawFence()
                })
            }
        })
    }

    polygonUpdated = (updatedPolygon) => {
        this.surveyWayPoints = []
        this.outerPolgonPoint = []
        this.pointsOnIntersectline = []
        this.visited = []
        this.unvisited = []
        if (this.state.markersLayer) this.state.markersLayer.remove();
        if (this.state.polygon) this.state.polygon.remove();
        if (this.state.arrowMarkerLayer) this.state.arrowMarkerLayer.remove();
        this.drawPolygon(this.state.map, false, updatedPolygon.getLatLngs()[0])
    }

    calculateFootPrint = (polygon) => {
        let Height_Ftp = this.state.altitude == 0 ? 1 : this.state.altitude * (this.state.sensorHeight / this.state.focalLength)
        let Width_Ftp = this.state.altitude == 0 ? 1 : this.state.altitude * (this.state.sensorWidth / this.state.focalLength)

        let calHeight_Ftp = Height_Ftp - (Height_Ftp * (Number(this.state.frontOverlap) / 100))
        let calWidth_Ftp = Width_Ftp - (Width_Ftp * (Number(this.state.sideOverlap) / 100))

        this.setState((state) => ({
            ...state,
            Height_Ftp: this.state.frontOverlap > 0 ? calHeight_Ftp : Height_Ftp,
            Width_Ftp: this.state.sideOverlap > 0 ? calWidth_Ftp : Width_Ftp,
        }), () => {
            if (polygon) {
                this.polygonUpdated(polygon)
            }
        })
    }

    calcDistanceFromCenter = async (center) => {
        let polyPoints = this.state.polygon.getLatLngs()[0]
        let calcDistnce = 0
        var calculatedFence = []
        for (let i = 0; i < polyPoints.length; i++) {
            let distance = center.distanceTo(polyPoints[i])
            if (calcDistnce < distance) { calcDistnce = distance }
            let bearing = await this.bearingAngle(polyPoints[i], center)
            let polygonOffSet = 50
            let fencePoint = await this.calcDestinationGeocoordFromHeading(polyPoints[i], polygonOffSet, bearing)
            calculatedFence.push(fencePoint)
        }
        return ({ calcDistnce: calcDistnce, calculatedFence: calculatedFence })
    }

    drawFence = async () => {
        let center = this.state.polygon.getBounds().getCenter();
        var imagenaryPolygon = []
        let calcFun = await this.calcDistanceFromCenter(center)
        let calcCircleDistnce = calcFun.calcDistnce
        let calculatedFence = calcFun.calculatedFence
        console.log('calcCircleDistnce', calcCircleDistnce);
        let fencePolygon = new L.Polygon(calculatedFence, {
            color: this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'green' : 'white',
            fillOpacity: 0,
            weight: 3,
        })
        if (this.state.toggle == true) {
            fencePolygon.addTo(this.state.markersLayer)
            // .enableEdit()
        }
        let fenceCircle = L.circle(center, calcCircleDistnce, {
            color: 'blue',
            fillOpacity: 0,
            weight: 3,
        }).addTo(this.state.markersLayer)
        let polyPoints = [fenceCircle.getBounds().getSouthWest(), fenceCircle.getBounds().getNorthWest(), fenceCircle.getBounds().getNorthEast(), fenceCircle.getBounds().getSouthEast()]
        fenceCircle.remove()
        for (let i = 0; i < polyPoints.length; i++) {
            let bearing = await this.bearingAngle(polyPoints[i], center)
            let distance = center.distanceTo(polyPoints[i])
            let cords = await this.calcDestinationGeocoordFromHeading(center, distance + 70, bearing + Number(this.state.turnAroundAngle))
            imagenaryPolygon.push(cords)
        }

        let polygon1 = new L.Polygon(imagenaryPolygon, {
            color: 'orange',
            fillOpacity: 0,
            weight: 3,
        })
        // .addTo(this.state.markersLayer)
        this.setState((state) => ({
            ...state,
            imagenaryPolygon: polygon1,
            fencePolygon: fencePolygon,
            fenceCircle: fenceCircle
        }), () => {
            this.getPolygonHeightWidth(polygon1)
            this.wayPoints()
        })
    }

    bearingAngle = (startingPoint, endingPoint) => {
        let PI = 3.14
        let center_to_start = Math.atan2(startingPoint.lng - endingPoint.lng, startingPoint.lat - endingPoint.lat);
        let bearing = (center_to_start * 180 / PI);
        return (bearing)
    }

    calcDestinationGeocoordFromHeading = (sourceCoord, distanceToTravel, bearingAngle) => {
        let sourceLatitude = this.qDegreesToRadians(sourceCoord.lat);
        let distanceToEarthRad = (distanceToTravel / (EARTH_RADIUS));
        let sourceLongitude = this.qDegreesToRadians(sourceCoord.lng);
        let lat1 = (Math.sin(sourceLatitude) * Math.cos((distanceToEarthRad)));
        let lat2 = (Math.cos(sourceLatitude) * Math.sin(((distanceToEarthRad))) * Math.cos(this.qDegreesToRadians(bearingAngle)));
        let m_destinationLatitude = Math.asin(lat1 + lat2);
        let destlon = (Math.sin(this.qDegreesToRadians(bearingAngle)) * Math.sin(distanceToEarthRad) * Math.cos(sourceLatitude));
        let destlon2 = ((Math.cos(distanceToEarthRad)) - (Math.sin(sourceLatitude) * Math.sin(m_destinationLatitude)));
        let atanValue = (Math.atan2(destlon, destlon2));
        let m_destinationLongitude = (sourceLongitude + atanValue);
        return { lat: this.qRadiansToDegrees(m_destinationLatitude), lng: this.qRadiansToDegrees(m_destinationLongitude) };
    }

    qDegreesToRadians = (radians) => {
        let pi = Math.PI;
        return radians * (pi / 180);
    }

    qRadiansToDegrees = (degrees) => {
        return (degrees * 180) / Math.PI;

    }

    wayPoints = async () => {
        let { map, imagenaryPolygon, markersLayer } = this.state
        let markerLocation = { lat: imagenaryPolygon.getLatLngs()[0][0].lat, lng: imagenaryPolygon.getLatLngs()[0][0].lng }
        let angle = 20 + Number(this.state.turnAroundAngle)
        let cords = await this.calcDestinationGeocoordFromHeading(markerLocation, 5, angle);
        this.outerPolgonPoint.push(cords)
        this.drawWayPoints()
    }

    isMarkerInsidePolygon = (marker, poly) => {
        let polyPoints = poly.getLatLngs()[0];
        let x = marker.lat, y = marker.lng;
        let inside = false;
        for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside
            }
        }
        return inside;
    };

    getSortInsidePoints = (insidePointList) => {
        for (let i = 0; i < insidePointList.length - 1; i++) {
            if (i == 1) {
                insidePointList[i]['line'] = 'start'
            }
            if (i == insidePointList.length - 2) {
                insidePointList[i]['line'] = 'end'
            }
            if (i > 0) {
                this.unvisited.push(insidePointList[i])
            }

        }
        return (true)
    }

    checkPointInsideOutside = async (lineLength) => {
        let insidePointList = []
        for (let a = 0; a < lineLength; a++) {
            let newWayPoint12 = await this.calcDestinationGeocoordFromHeading(this.pointsOnIntersectline[this.pointsOnIntersectline.length - 1], 1, this.state.reversePointPlot ? (180 + Number(this.state.turnAroundAngle)) : (0 + Number(this.state.turnAroundAngle)));
            let result = await this.isMarkerInsidePolygon(newWayPoint12, this.state.polygon)
            if (result) {
                insidePointList.push(newWayPoint12)
            }
            this.pointsOnIntersectline.push(newWayPoint12)
        }
        return (insidePointList)
    }

    getPolygonInsidePoints = async () => {
        let startPoint = this.outerPolgonPoint[this.outerPolgonPoint.length - 1]
        let widthIns = 0
        for (let k = 0; k < 100000000; k++) {
            let newWayPoint = await this.calcDestinationGeocoordFromHeading(this.outerPolgonPoint[this.outerPolgonPoint.length - 1], this.state.polygonHeight, (0 + Number(this.state.turnAroundAngle)));
            let lineCords = L.polyline([this.outerPolgonPoint[this.outerPolgonPoint.length - 1], newWayPoint])
            let lineIntersectPolygon = turf.lineIntersect(lineCords.toGeoJSON(), this.state.polygon.toGeoJSON())
            if (lineIntersectPolygon.features.length > 1) {
                let lineLength = Math.round(L.latLng(this.outerPolgonPoint[this.outerPolgonPoint.length - 1]).distanceTo(L.latLng(newWayPoint)))
                this.state.reversePointPlot ? this.pointsOnIntersectline.push(newWayPoint) : this.pointsOnIntersectline.push(this.outerPolgonPoint[this.outerPolgonPoint.length - 1])
                let insidePointList = await this.checkPointInsideOutside(lineLength)
                await this.getSortInsidePoints(insidePointList)
            }
            this.setState((state) => ({
                ...state,
                reversePointPlot: !this.state.reversePointPlot,
            }))
            this.outerPolgonPoint.push(newWayPoint)
            widthIns = widthIns + this.state.Width_Ftp
            let newWayPoint1 = await this.calcDestinationGeocoordFromHeading(startPoint, widthIns, (90 + Number(this.state.turnAroundAngle)));
            let result1 = await this.isMarkerInsidePolygon(newWayPoint1, this.state.imagenaryPolygon)
            if (result1 == false) {
                break;
            } else {
                this.outerPolgonPoint.push(newWayPoint1)
            }
        }
        return (true)
    }

    distancePointInOutSide = (lineLength, pointsInsidePolygon, bearingAngle) => {
        let width = 0
        let inside = 0
        let outside = 0
        for (let k = 0; k < lineLength - 1; k++) {
            width = width + 1
            let newWayPoint1 = this.calcDestinationGeocoordFromHeading(pointsInsidePolygon, width, bearingAngle);
            let result2 = this.isMarkerInsidePolygon(newWayPoint1, this.state.polygon)
            if (result2) {
                inside = inside + 1
            } else {
                outside = outside + 1
            }
        }
        return ({ inside, outside })
    }

    getSortedVisitedList = () => {
        let pointsInsidePolygon = this.unvisited
        this.unvisited = []
        for (let j = 0; j < pointsInsidePolygon.length - 1; j++) {
            pointsInsidePolygon[pointsInsidePolygon.length - 2]['line'] = 'end'
            let lineLength = Math.round(L.latLng(pointsInsidePolygon[j]).distanceTo(L.latLng(pointsInsidePolygon[j + 1])))
            if (lineLength > 1) {
                if (this.state.intersectReversePointPlot) {
                    this.unvisited.push(pointsInsidePolygon[j])
                    if (this.unvisited.length > 0) { this.unvisited[this.unvisited.length - 1]['line'] = 'end' }
                } else {
                    this.visited.push(pointsInsidePolygon[j])
                    if (this.visited.length > 0) { this.visited[this.visited.length - 1]['line'] = 'end' }
                }
                pointsInsidePolygon[j + 1]['line'] = 'start'
                if (this.state.intersectReversePointPlot) {
                    let lineLength1 = Math.round(L.latLng(this.visited[this.visited.length - 1]).distanceTo(L.latLng(pointsInsidePolygon[j])))
                    let bearingAngle1 = this.bearingAngle(pointsInsidePolygon[j], this.visited[this.visited.length - 1])
                    let insideOutsideCount1 = this.distancePointInOutSide(lineLength1, this.visited[this.visited.length - 1], bearingAngle1)
                    console.log('insideOutsideCount1 j', insideOutsideCount1);
                    let line1 = L.polyline([this.visited[this.visited.length - 1], pointsInsidePolygon[j + 1]])
                    let intersect1 = turf.lineIntersect(line1.toGeoJSON(), this.state.polygon.toGeoJSON())
                    if (intersect1.features.length < 1) {
                        this.setState({ intersectReversePointPlot: false })
                    }
                }
                else {
                    let bearingAngle = this.bearingAngle(pointsInsidePolygon[j + 1], pointsInsidePolygon[j])
                    let insideOutsideCount = this.distancePointInOutSide(lineLength, pointsInsidePolygon[j], bearingAngle)
                    let line = L.polyline([pointsInsidePolygon[j], pointsInsidePolygon[j + 1]])
                    let intersect = turf.lineIntersect(line.toGeoJSON(), this.state.polygon.toGeoJSON())
                    if (insideOutsideCount.outside > 0 && intersect.features.length > 0) {
                        this.setState({ intersectReversePointPlot: true })
                    }
                }
            } else {
                this.state.intersectReversePointPlot ? this.unvisited.push(pointsInsidePolygon[j]) :
                    this.visited.push(pointsInsidePolygon[j])
            }
        }
        return (true)
    }

    getNearestPoint = (waypoint, polygon) => {
        let polygonPoints = polygon.getLatLngs()[0]
        let shortDistance = 0
        let index = 0
        for (let i = 0; i < polygonPoints.length; i++) {
            let distance = Math.round(L.latLng(waypoint).distanceTo(L.latLng(polygonPoints[i])))
            if (shortDistance === 0) {
                shortDistance = distance
                index = i
            } else {
                if (shortDistance > distance) {
                    shortDistance = distance
                    index = i
                }
            }
        }
        return ({ shortDistance, index })
    }

    getLeftTraversePath = (visitedNearPoint, unvisitedNearPoint) => {
        let polygonPoints = this.state.polygon.getLatLngs()[0]
        let indexPolyPoint = visitedNearPoint.index
        let indexPolyPoint1 = unvisitedNearPoint.index
        let leftTaverseArray = []
        for (let k = 0; k < polygonPoints.length; k++) {
            polygonPoints[indexPolyPoint]['line'] = 'boundaryPoint'
            leftTaverseArray.push(polygonPoints[indexPolyPoint])
            if (indexPolyPoint == polygonPoints.length - 1 && !(indexPolyPoint == indexPolyPoint1)) {
                indexPolyPoint = 0
            } else if (indexPolyPoint == indexPolyPoint1) {
                break;
            } else {
                indexPolyPoint = indexPolyPoint + 1
            }
        }
        return (leftTaverseArray)
    }

    getRightTraversPath = (visitedNearPoint, unvisitedNearPoint) => {
        let polygonPoints = this.state.polygon.getLatLngs()[0]
        let indexPolyPoint = visitedNearPoint.index
        let indexPolyPoint1 = unvisitedNearPoint.index
        let rightTaverseArray = []
        for (let k = 0; k < polygonPoints.length; k++) {
            polygonPoints[indexPolyPoint]['line'] = 'boundaryPoint'
            rightTaverseArray.push(polygonPoints[indexPolyPoint])
            if (indexPolyPoint == 0 && !(indexPolyPoint == indexPolyPoint1)) {
                indexPolyPoint = polygonPoints.length - 1
            } else if (indexPolyPoint == indexPolyPoint1) {
                break;
            } else {
                indexPolyPoint = indexPolyPoint - 1
            }
        }
        return (rightTaverseArray)
    }

    getPathDistance = (path, distance) => {
        let pathDistance = distance
        for (let i = 0; i < path.length - 1; i++) {
            let lineLength = Math.round(L.latLng(path[i]).distanceTo(L.latLng(path[i + 1])))
            pathDistance = pathDistance + lineLength
        }
        return (pathDistance)
    }

    findSortesPath = () => {
        let visitedNearesPointOfPolygon = this.getNearestPoint(this.visited[this.visited.length - 1], this.state.polygon)
        let unvisitedNearesPointOfPolygon = this.getNearestPoint(this.unvisited[0], this.state.polygon)
        let leftTraversPath = this.getLeftTraversePath(visitedNearesPointOfPolygon, unvisitedNearesPointOfPolygon)
        let rightTraversPath = this.getRightTraversPath(visitedNearesPointOfPolygon, unvisitedNearesPointOfPolygon)
        let leftTraverseDistance = this.getPathDistance(leftTraversPath, (visitedNearesPointOfPolygon.shortDistance + unvisitedNearesPointOfPolygon.shortDistance))
        let rightTraversDistance = this.getPathDistance(rightTraversPath, (visitedNearesPointOfPolygon.shortDistance + unvisitedNearesPointOfPolygon.shortDistance))

        if (leftTraverseDistance < rightTraversDistance) {
            this.visited = this.visited.concat(leftTraversPath)
        } else if (rightTraversDistance < leftTraverseDistance) {
            this.visited = this.visited.concat(rightTraversPath)
        } else {
            this.visited = this.visited.concat(leftTraversPath)
        }
    }

    getLineStartEndPoint = () => {
        for (let i = 0; i < this.visited.length; i++) {
            if (this.state.turnAroundDist > 0) {
                if (this.visited[i].line == 'start') {
                    let startBeringAngle = Math.round(this.bearingAngle(this.visited[i], this.visited[i + 1]))
                    let turnAroundPoint = this.calcDestinationGeocoordFromHeading(this.visited[i], Number(this.state.turnAroundDist), startBeringAngle);
                    this.surveyWayPoints.push(turnAroundPoint)
                } else if (this.visited[i].line == 'end') {
                    let endBeringAngle = Math.round(this.bearingAngle(this.visited[i], this.visited[i - 1]))
                    let turnAroundPoint = this.calcDestinationGeocoordFromHeading(this.visited[i], Number(this.state.turnAroundDist), endBeringAngle);
                    this.surveyWayPoints.push(turnAroundPoint)
                } else if (this.visited[i].line == 'boundaryPoint') {
                    this.surveyWayPoints.push(this.visited[i])
                }
            } else {
                if (this.visited[i].line) {
                    this.surveyWayPoints.push(this.visited[i])
                }
            }
        }
    }

    drawWayPoints = async () => {
        await this.getPolygonInsidePoints()
        do {
            if (this.visited.length > 0 && this.unvisited.length > 0) {
                await this.findSortesPath()
            }
            await this.getSortedVisitedList()
            this.setState({ intersectReversePointPlot: false })
        } while (this.unvisited.length != 0)
        await this.getLineStartEndPoint()
        this.plotWayPointOnMap()
    }


    plotWayPointOnMap = async () => {
        let planLine = L.polyline(this.surveyWayPoints, {
            color: this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf',
            weight: 3,
        }).addTo(this.state.markersLayer)
        let surveyLength = 0
        let arrowArray = []
        this.setState({ planLine })
        this.surveyWayPoints.map(async (cords, index) => {
            if (index == 0 || index == this.surveyWayPoints.length - 1) {
                let tempMarker = L.circleMarker(cords, {
                    color: 'black',
                    fillColor: '#2989CF',
                    fillOpacity: 2,
                    radius: 4,
                    weight: 1,
                }).addTo(this.state.markersLayer)
                tempMarker.bindTooltip(`${index}`)
            }
            if (index == this.surveyWayPoints.length - 1) {
                let arrows = getArrows(arrowArray, this.state.selectedLayer == 'Orthomosaic' || this.state.selectedLayer == 'Base' ? 'orange' : '#2989cf', 1, this.state.map)
                let arrowMarkerLayer = L.featureGroup(arrows).addTo(this.state.map);
                let surveyTime = await this.convertHMS(surveyLength / this.state.speed)
                this.setState((state) => ({
                    ...state,
                    surveyLength: Math.round(surveyLength),
                    imageCount: Math.round(this.state.surveyArea / (this.state.Width_Ftp * this.state.Height_Ftp)),
                    surveyTime: surveyTime,
                    arrowMarkerLayer: arrowMarkerLayer,
                    arrowArray: arrowArray
                }))
            } else {
                let lat = cords.lat;
                let lng = cords.lng;
                let latlng = [lat, lng];
                arrowArray.push(latlng);
                let distance = L.latLng(cords).distanceTo(L.latLng(this.surveyWayPoints[index + 1]))
                surveyLength = (surveyLength + distance)
            }
        })
    }

    convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }


    getPolygonHeightWidth = (imagenaryPolygon) => {
        let polygonWidth = imagenaryPolygon.getBounds().getSouthWest().distanceTo(imagenaryPolygon.getBounds().getSouthEast())
        let polygonHeight = imagenaryPolygon.getBounds().getSouthWest().distanceTo(imagenaryPolygon.getBounds().getNorthWest())
        this.setState((state) => ({
            ...state,
            polygonWidth: Math.round(polygonWidth),
            polygonHeight: Math.round(polygonHeight),
        }))
    }

    handleMapClick = (e) => {
        console.log(e)
    }
    handleMoveStart = (e) => {
        console.log(e)
    }
    HandleCameraChange = (e) => {
        e.preventDefault();
        let t = e.target.value;
        this.state.cameras.map((camera, index) => {
            if (camera.name === t) {
                this.setState((state) => ({
                    ...state,
                    selectedCamera: camera,
                    focalLength: camera.focal_length,
                    sensorHeight: camera.sensor_height,
                    sensorWidth: camera.sensor_width,
                    cameraIndex: index,
                }), () => {
                    this.calculateFootPrint(this.state.polygon)
                })
            }
        })
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: "/remote_mission/assignDroneToPlan",
                        state: {
                            gmarkers: this.state.gmarkers,
                            gmarkers1: this.state.gmarkers1,
                            gpoly: this.state.gpoly,
                            gpoly1: this.state.gpoly1,
                            user: this.state.user,
                            type: this.state.type,
                            center: this.state.center,
                            planName: this.props.planName,
                            projectName: this.props.projectName,
                            plan: {
                                id: this.props.location?.state?.plan?.id || this.state.plan?.id,
                                plan_name: this.props.location?.state?.plan?.plan_name || this.state.plan?.plan_name,
                                project_name: this.props.location?.state?.project?.project_name
                            },
                            project: {
                                id: this.props.location?.state?.project ? (this.props.location.state.project.project_id || this.props.location.state.project.id) : {},
                                project_name: this.props.location?.state?.project?.project_name
                            },
                            sidebarMode: this.state.sidebarMode,
                        },
                    }}
                />
            );
        }
    };

    convertFenceToPoint = (Geofence) => {
        console.log('fence', Geofence);
        let poly = []
        for (let i = 0; i < Geofence.length - 1; i++) {
            let polyPoint = { lat: Geofence[i].geofence['geofence X'], lng: Geofence[i].geofence['geofence Y'] }
            console.log('polyPoint', polyPoint);
            poly.push(polyPoint)
        }
        return (poly)
    }

    convertFenceToPoint1 = (Geofence) => {
        console.log('fence', Geofence);
        let poly = []
        for (let i = 0; i < Geofence.geofence.length - 1; i++) {
            let polyPoint = { lat: Geofence.geofence[i].lat, lng: Geofence.geofence[i].lon }
            console.log('polyPoint', polyPoint);
            poly.push(polyPoint)
        }
        return (poly)
    }

    getPlanData = () => {
        API.getPlan(this.state.projectName, this.state.planName)
            .then(async (planData) => {
                if (planData.planConfiguration[0]) {
                    console.log('plan data for edit', planData);
                    let editPolygon = await this.convertFenceToPoint(planData.Geofence)
                    console.log('editPolygon', editPolygon);
                    let MissionStatistics = planData.MissionStatistics[0].MissionStatistics
                    this.setState((state) => ({
                        ...state,
                        sensorHeight: MissionStatistics.Camera_Sensor_Height,
                        sensorWidth: MissionStatistics.Camera_Sensor_Width,
                        cameraIndex: MissionStatistics.Camera_Type,
                        selectedCamera: this.state.cameras[MissionStatistics.Camera_Type],
                        Height_Ftp: MissionStatistics.ImageFootprintHeight,
                        Width_Ftp: MissionStatistics.ImageFootprintWidth,
                        imageCount: MissionStatistics.Image_Count,
                        frontOverlap: MissionStatistics.Image_Front_Overlap,
                        sideOverlap: MissionStatistics.Image_Side_Overlap,
                        speed: MissionStatistics.Speed,
                        altitude: MissionStatistics.Survey_Altitude,
                        surveyArea: MissionStatistics.Survey_Area,
                        surveyLength: MissionStatistics.TotalPlanDistance,
                        turnAroundAngle: MissionStatistics.Turnaround_Angle,
                        turnAroundDist: MissionStatistics.Turnaround_Distance,
                        editPolygon: editPolygon
                    }), () => {
                        this.setState({ loading: false })
                    })
                } else {
                    console.log('plan data for edit', planData);
                    let editPolygon = await this.convertFenceToPoint1(planData.Geofence)
                    console.log('editPolygon', editPolygon);
                    let MissionStatistics = planData.MissionStatistics.missionStatistics[0]
                    this.setState((state) => ({
                        ...state,
                        sensorHeight: MissionStatistics.cameraSensorHeight,
                        sensorWidth: MissionStatistics.cameraSensorWidth,
                        cameraIndex: MissionStatistics.cameraType,
                        selectedCamera: this.state.cameras[MissionStatistics.cameraType],
                        Height_Ftp: MissionStatistics.imageFootprintHeight,
                        Width_Ftp: MissionStatistics.imageFootprintWidth,
                        imageCount: MissionStatistics.imageCount,
                        frontOverlap: MissionStatistics.imageFrontOverlap,
                        sideOverlap: MissionStatistics.imageSideOverlap,
                        speed: MissionStatistics.speed,
                        altitude: MissionStatistics.surveyAltitude,
                        surveyArea: MissionStatistics.surveyArea,
                        surveyLength: MissionStatistics.totalPlanDistance,
                        turnAroundAngle: MissionStatistics.turnaroundAngle,
                        turnAroundDist: MissionStatistics.turnaroundDistance,
                        editPolygon: editPolygon
                    }), () => {
                        this.setState({ loading: false })
                    })
                }
            })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Type:Survey";
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {          
            this.setState({ permissions }, async () => {
                if (this.props.location.state.editPlan) {
                    let { user, type, flightType, planName, center, projectName, editPlan, sidebarMode, project, plan, clientName, linked_map } = this.props.location.state;
                    this.props.addProjectDetails({
                        projectName: projectName,
                        clientName: "",
                        planName: planName,
                        dateAndTime: "",
                        activeTeam: await getActiveTeam()
                    });
                    this.setState((state) => ({
                        ...state,
                        flightType,
                        type,
                        user,
                        planName,
                        projectName,
                        editPlan,
                        sidebarMode,
                        project,
                        plan,
                        center: center ? center : this.state.center,
                        linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
                        clientName: clientName ? clientName : "asd"
                    }), () => {
                        this.getPlanData()
                    })
                } else if (this.props.location.state) {
                    let { altitude, center, drawPlan, flightType, speed, speedd, type, user, project, planName, projectName, linked_map, clientName } = this.props.location.state;
                    console.log('this.props.location.state', this.props.location.state);
                    this.setState((state) => ({
                        ...state,
                        altitude,
                        center: center ? center : this.state.center,
                        flightType,
                        speed,
                        speedd,
                        planName,
                        projectName,
                        type,
                        user,
                        project,
                        linked_map,
                        clientName
                    }), () => {
                        this.setState({ loading: false })
                        this.calculateFootPrint()
                    })
                }
                else {
                    window.location.replace("/login");
                }
            })
        }).catch(e => window.location.replace("/"))
    }

    render() {

        return (
            <>
                <div className="wrapper">
                    {this.successRedirectComponent()}

                    <div className="right-content-new" style={{ overflowY: "hidden" }}
                        onClick={
                            () => {
                                this.setState((state) => ({
                                    ...state, sidebarMode: 'shrunk'
                                }))
                            }
                        }
                    >

                        <ActivityPopup
                            item={this.state.itemName}
                            open={this.state.showProcessPopup}
                            icon={this.state.processPopupIcon}
                            action={this.state.processAction}
                            msg={this.state.processMessage}
                            close={() => {
                                this.setState((state) => ({
                                    ...state,
                                    showProcessPopup: false,
                                    processMessage: null
                                }))
                            }}
                            onClickOk={() => { }}
                        />

                        <div className="top-bar-new" >
                            <div className="top-bar-text-new" >
                                <BreadCrumbs
                                    data={[
                                        {
                                            displayName: "AeroGCS",
                                            pathname: "/",
                                            state: {
                                                user: this.state.user
                                            }
                                        },
                                        {
                                            displayName: 'Flight Plan',
                                        }
                                    ]} />
                            </div>
                        </div>

                        {/* Layer selection popup */}
                        {this.state.linked_map ? <div className='slide' style={{ textAlign: 'center', zIndex: 1, fontWeight: 500, border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '20px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', fontSize: "12px" }}
                            onClick={() => {
                                this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                            }}>
                            {this.state.selectedLayer}
                        </div> : <></>}
                        {this.state.layerSelectionPopup && this.state.linked_map ?
                            <div style={{ zIndex: 1, position: 'absolute', width: "105px", fontWeight: 500, fontSize: "12px", bottom: '52px', right: '118px', backgroundColor: 'white', borderRadius: '5px', padding: "1px" }} classname="cardscon content-text">
                                <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    Orthomosaic
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    NDVI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    VARI
                                </div>
                                <hr style={{ margin: "3px" }}></hr>
                                <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                                    BASE
                                </div>
                            </div> :
                            <></>
                        }

                        {/* changeBaseMapView  */}
                        <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '20px', right: '18px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                            onClick={() => {
                                this.switchBaseLayer()
                            }}>
                            <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
                        </div>

                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div id="rmp-map" className="main-content-new" style={{ padding: "0", height: "calc(100vh - 70px)", }}>
                                <SingleMap
                                    setBaseLayerToState={this.setBaseLayerToState}
                                    initCenter={[this.state.center.lat, this.state.center.lng]}
                                    initZoom={18}
                                    // bounds={this.getCurrentMapExtent()}
                                    handleMapClick={this.handleMapClick}
                                    // handleMoveStart={this.props.handleMoveStart}
                                    maxZoom={23}
                                    setMap={(map) => {
                                        this.setState({ map }, this.initMap)
                                    }}
                                />
                            </div>}
                        {this.state.loading ? '' : <div
                            style={{
                                top: "80px",
                                right: '10px',
                                zIndex: "1",
                                position: 'absolute',
                                height: '465px',
                                borderRadius: '15px',
                                width: "330px",
                                borderRadius: '15px',
                                background: 'white',
                                padding: '1px'
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    width: "100%",
                                    padding: "2px 4px"
                                }}
                            >
                                <div style={{ padding: '7px 25px', display: "flex" }}>
                                    <div
                                        style={{
                                            fontFamily: "Poppins",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "15px",
                                            color: "black",
                                        }}
                                    >
                                        Survey
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "35px",
                                            fontFamily: "Poppins",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            color: "#0000006e",
                                        }}
                                    >
                                        Mission
                                    </div>
                                    <div
                                        className="custom-control custom-switch"
                                        style={{ marginLeft: "15px" }}
                                    >
                                        <input
                                            className="input-feild-rm custom-control-input"
                                            type="checkbox"
                                            id="customSwitches"
                                            style={{ cursor: 'pointer' }}
                                            onChange={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    toggle: !this.state.toggle,
                                                }), () => {
                                                    this.polygonUpdated(this.state.polygon)
                                                });
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            for="customSwitches"
                                        ></label>
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                            fontFamily: "Poppins",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            color: "#0000006e",
                                        }}
                                    >
                                        Fence
                                    </div>
                                </div>
                                <hr style={{ width: '100%', margin: '0px', padding: '1px' }}></hr>
                                <div style={{ height: "78%", overflow: 'auto', padding: '2px 18px', }}>
                                    <div className="add-user-form-row" style={{ justifyContent: 'space-between', height: '65px' }}>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Camera Type</div>
                                            <select
                                                name="flightType"
                                                className="add-user-form-text-survey"
                                                value={this.state.selectedCamera.name}
                                                onChange={(e) => { this.HandleCameraChange(e) }}
                                                style={{ width: "280px", cursor: 'pointer' }}
                                            >
                                                {
                                                    this.state.cameras.map((camera, key) => {
                                                        return <>
                                                            <option>{camera.name}</option>
                                                        </>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ justifyContent: 'space-between', height: '65px' }}>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Sensor Height</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"

                                                value={this.state.sensorHeight}
                                                onChange={(e) => {
                                                    let sensorHeight = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        sensorHeight: sensorHeight == '' || sensorHeight > 100 || sensorHeight < 0 ? 2 : sensorHeight
                                                    }))
                                                }}
                                                disabled={!(this.state.selectedCamera.name == 'Custom Camera')} />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "6rem" }}
                                            >
                                                mm
                                            </div>
                                        </div>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Sensor Width</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.sensorWidth}
                                                onChange={(e) => {
                                                    let sensorWidth = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        sensorWidth: sensorWidth == '' || sensorWidth > 100 || sensorWidth < 0 ? 2 : sensorWidth
                                                    }))
                                                }}
                                                disabled={!(this.state.selectedCamera.name == 'Custom Camera')} />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "6rem" }}
                                            >
                                                mm
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ justifyContent: 'space-between', height: '65px' }}>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Focal Length</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.focalLength}
                                                onChange={(e) => {
                                                    let focalLength = e.target.value
                                                    this.setState((state) => ({
                                                        ...state,
                                                        focalLength: focalLength == '' || focalLength > 100 || focalLength < 0 ? 2 : focalLength
                                                    }))
                                                }}
                                                disabled={!(this.state.selectedCamera.name == 'Custom Camera')} />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "6rem" }}
                                            >
                                                mm
                                            </div>
                                        </div>
                                    </div>

                                    <hr style={{ width: '100%', margin: '5px 0px', padding: '0px' }}></hr>

                                    <div className="add-user-form-row" style={{ justifyContent: 'space-between', height: '65px' }}>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Front Overlap</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.frontOverlap}
                                                onChange={(e) => {
                                                    let frontOverlap = e.target.value
                                                    if (e.target.value > 90 || e.target.value < 0) {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            itemName: "frontOverlap",
                                                            processPopupIcon: "ERROR",
                                                            processAction: "ADD",
                                                            processMessage: "frontOverlap value greater then 90 or less then 0",
                                                            showProcessPopup: true,
                                                        }))
                                                    } else {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            frontOverlap: frontOverlap == '' ? 0 : frontOverlap
                                                        }), () => {
                                                            this.calculateFootPrint()
                                                        })
                                                    }
                                                }}
                                            />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "7rem" }}
                                            >
                                                %
                                            </div>
                                        </div>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Side Overlap</div>
                                            <input
                                                min="0" max="100"
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.sideOverlap}
                                                onChange={(e) => {
                                                    let sideOverlap = e.target.value
                                                    if (e.target.value > 90 || e.target.value < 0) {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            itemName: "sideOverlap",
                                                            processPopupIcon: "ERROR",
                                                            processAction: "ADD",
                                                            processMessage: "sideOverlap value greater then 90 or less then 0",
                                                            showProcessPopup: true,
                                                        }))
                                                    } else {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            sideOverlap: sideOverlap == '' ? 0 : sideOverlap
                                                        }), () => {
                                                            this.calculateFootPrint()
                                                        })
                                                    }
                                                }}
                                            />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "7rem" }}
                                            >
                                                %
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ justifyContent: 'space-between', height: '65px' }}>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Altitude</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.altitude}
                                                onChange={(e) => {
                                                    let altitude = e.target.value
                                                    if (altitude < 1 || altitude > 121) {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            itemName: "Altitude",
                                                            processPopupIcon: "ERROR",
                                                            processAction: "ADD",
                                                            processMessage: "Altitude is between 1 to 121",
                                                            showProcessPopup: true,
                                                        }))
                                                    } else {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            altitude: altitude == '' || altitude == 0 ? 1 : altitude
                                                        }), () => {
                                                            this.calculateFootPrint()
                                                        })
                                                    }
                                                }}
                                            />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "7rem" }}
                                            >
                                                m
                                            </div>
                                        </div>
                                        <div>
                                            <div className='add-user-form-label add-plan-form-label'>Turnaround Dist</div>
                                            <input
                                                className="add-user-form-text-survey"
                                                type="number"
                                                value={this.state.turnAroundDist}
                                                onChange={(e) => {
                                                    let turnAroundDist = e.target.value
                                                    if (turnAroundDist < 100) {
                                                        this.setState((state) => ({
                                                            ...state,
                                                            turnAroundDist: turnAroundDist == '' || turnAroundDist < 0 ? 0 : turnAroundDist
                                                        }))
                                                    }
                                                }}
                                            />
                                            <div
                                                className="units1"
                                                style={{ marginLeft: "7rem" }}
                                            >
                                                m
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-user-form-row" style={{ flexDirection: 'column', height: '50px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className='add-user-form-label add-plan-form-label'>Turnaround Angle</div>
                                            <div className='add-user-form-label add-plan-form-label'>{this.state.turnAroundAngle}</div>

                                        </div>
                                        <input type="range" min="0" max="360" value={this.state.turnAroundAngle} style={{ width: '100%', cursor: 'pointer' }} onChange={(e) => {
                                            let turnAroundAngle = e.target.value
                                            this.setState((state) => ({
                                                ...state,
                                                turnAroundAngle: turnAroundAngle
                                            }), () => {
                                                this.polygonUpdated(this.state.polygon)
                                            })
                                        }} />
                                    </div>


                                    <hr style={{ width: '100%', margin: '5px 0px', padding: '0px' }}></hr>
                                    <div style={{ marginTop: '15px', display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%', fontSize: '12px' }} className='add-user-form-label add-plan-form-label'>Survey Area</div>
                                        <div style={{ width: '50%', fontSize: '12px', fontWeight: '500' }} className='add-user-form-label add-plan-form-label'>{this.state.surveyArea.toFixed(2)} Sq.m</div>
                                    </div>

                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%', fontSize: '12px' }} className='add-user-form-label add-plan-form-label'>Images Count</div>
                                        <div style={{ width: '50%', fontSize: '12px', fontWeight: '500' }} className='add-user-form-label add-plan-form-label'>{this.state.imageCount}</div>
                                    </div>

                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%', fontSize: '12px' }} className='add-user-form-label add-plan-form-label'>Survey Dist</div>
                                        <div style={{ width: '50%', fontSize: '12px', fontWeight: '500' }} className='add-user-form-label add-plan-form-label'>{this.state.surveyLength} m</div>
                                    </div>

                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%', fontSize: '12px' }} className='add-user-form-label add-plan-form-label'>Foot Print</div>
                                        <div style={{ width: '50%', fontSize: '12px', fontWeight: '500' }} className='add-user-form-label add-plan-form-label'>{this.state.Width_Ftp.toFixed(4)}m * {this.state.Height_Ftp.toFixed(4)}m</div>
                                    </div>

                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ width: '50%', fontSize: '12px' }} className='add-user-form-label add-plan-form-label'>Flight Time</div>
                                        <div style={{ width: '50%', fontSize: '12px', fontWeight: '500' }} className='add-user-form-label add-plan-form-label'>{this.state.surveyTime}</div>
                                    </div>
                                </div>
                                <hr style={{ width: '100%', margin: '5px 0px' }}></hr>
                                <div style={{ padding: '2px 25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div
                                        className="add-user-form-submit-button2"
                                        style={{ cursor: 'pointer', margin: '0%' }}
                                        onClick={() => {
                                            this.polygonUpdated(this.state.polygon)
                                        }}
                                    >
                                        Apply
                                    </div>
                                    <div
                                        className="add-user-form-submit-button2"
                                        style={{ cursor: 'pointer', margin: '0%' }}
                                        onClick={() => {
                                            this.createPlan()
                                        }}
                                    >
                                        Save
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        projectName: state.project.projectName,
        clientName: state.project.clientName,
        planName: state.project.planName,
        dateAndTime: state.project.dateAndTime,
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addPlan: (payload) => dispatch(addPlan(payload)),
        addProjectDetails: (payload) => dispatch(addProjectDetails(payload))
    };
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing", "places"],
    LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(RMSurveyFlightPlan));
