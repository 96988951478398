import React, { Component } from "react";
import "./style.css";
import { Link, Redirect } from 'react-router-dom';
import * as API from './api.js';
import backbutton from "./Icon/backbutton.png";
import {
    Map,
    Marker,
    InfoWindow,
    GoogleApiWrapper,
    LoadingContainer,
} from "google-maps-react";
import service from "./sidebaricon/Services.png";

class aeroStreamOfflineMapView extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {
            key: "AS_TELE_DATA",
            label: 1,
            dirty: 0,
            logIndex: 0,
            skipFactor: 0,
            count: 0,
            playedSeconds: 0,
            latLng: [],
            center: { lat: '20.632784', lng: '75.698613' },
            circleStrokeColor: "orange",
            circleFillColor: "orange",
            flightId: "",
            user: {},
            style: {
                position: 'relative',
                width: '100%', height: '100%', left: '302px', inset: "-20px 0px 0px -2px"
            },
            type: "",
            zoom: 18,
            mapScrollWeel: false,
            mapDoubleClick: false,
            mapZoom: false,
            mapMode: "small"
        };
    }
    initMap = (mapProps, map) => {
        var self = this;
        var marker
        const { google } = mapProps;
        var bounds = new google.maps.LatLngBounds();
        this.addMarker = async (icon, droneName, lat, lng, i) => {
            marker = await new google.maps.Marker({
                position: new google.maps.LatLng(
                    lat, lng
                ),
                map: map,
                draggable: false,
                index: i,
                // icon: `https://download.aeromegh.in/293ab8a4-33e2-4adc-af1d-4e2c06b392a1.png`,
                icon: icon,
                name: droneName,
                title: droneName,
                label: {
                    text: 'Drone',
                    fontSize: "8px",
                    color: "white",
                },
            });
        }
        this.setDrones = async () => {
            console.log("INSIDE SET DRONES ");
            let lat = parseFloat(this.state.lat);
            let lng = parseFloat(this.state.lng);
            let latlng = { lat: lat, lng: lng }
            bounds.extend(new google.maps.LatLng(lat, lng));
            let icon = await {
                url: "https://download.aeromegh.in/file/5d9629eb-9fea-4e4c-ad60-1d9d9a2e0524.png",
                labelOrigin: new window.google.maps.Point(14, 26),
                anchor: new window.google.maps.Point(22, 22),
                scaledSize: new window.google.maps.Size(28, 28)
            };
            let droneName = this.state.drone_id
            let addmarker;
            if (this.state.dirty < 1) {
                addmarker = await this.addMarker(icon, droneName, lat, lng, 0)
            } else {
                // console.log('DIRTY');
                // console.log(new google.maps.LatLng(lat, lng));
                // console.log("LAT ", lat);
                // console.log(" LNG", lng);
                // marker.setMap(null)
                marker.setPosition(new google.maps.LatLng(lat, lng))
                // marker.setPosition(new google.maps.LatLng(lat, lng))
            }
        }
    }
    componentDidMount() {
    }
    // shouldComponentUpdate() {
    //     if (this.state.play = true) {
    //         this.getDroneLocation()
    //     }
    // }
    getDroneLocation = () => {
        API.getFlightDetails(this.state.flight_id, true, 10000000, 0).then(async (data) => {
            console.log(data);
            if (data.logs && data.logs.length > 0) {
                let logs = await data.logs;
                let lat = JSON.parse(data.logs[0].data).lat;
                let lng = JSON.parse(data.logs[0].data).lon;
                // let logs = awaitdata.logs;
                let latlng = { lat: lat, lng: lng }
                this.setState((state) => ({
                    ...state,
                    logs: logs,
                    center: latlng,
                    lat: lat,
                    lng: lng,
                    latLng: latlng,
                    count: logs.length
                    // flight: data.flight,
                }), () => {

                    this.setDrones()
                    this.onTrigger();
                    const mapInterval = setInterval(() => {
                        if (this.props.play == true) {
                            this.setState((state) => ({
                                ...state,
                                play: true
                            }))
                        }
                        if (this.state.play == true && this.props.playedSeconds && this.props.videoDuration) {
                            let playedSeconds = Math.round(this.props.playedSeconds);
                            let skipFactor = Math.round(this.state.logs.length / this.props.videoDuration)
                            let temp = playedSeconds * skipFactor;
                            console.log(playedSeconds);
                            if (temp >= this.state.logs.length) {
                                return 0
                            } else {
                                // let logIndex = this.state.logIndex;
                                console.log(skipFactor);
                                let lat = JSON.parse(this.state.logs[playedSeconds * skipFactor].data).lat;
                                let lng = JSON.parse(this.state.logs[playedSeconds * skipFactor].data).lon;
                                console.log(lat);
                                console.log(lng);
                                let latlng = { lat, lng }
                                this.setState((state) => ({
                                    ...state,
                                    latLng: latlng,
                                    center: latlng,
                                    lat,
                                    lng,
                                    // logIndex: logIndex + 1,
                                    dirty: this.state.dirty + 1
                                }), () => {
                                    this.onTrigger();
                                    this.setDrones()
                                })
                            }
                        }
                    }, 1000);
                })
            }
            // }
        }, (err) => {
            console.log(err)
        })
    }
    componentWillMount() {
        if (this.props && this.props.drone_id) {
            let state1 = this.props;
            this.setState((state) => ({
                ...state,
                user: state1.user,
                type: state1.type,
                sidebarMode: state1.sidebarMode,
                drone_id: state1.drone_id,
                flight_id: state1.flightId,
                videoDuration: state1.videoDuration,
                play: state1.play && state1.play == true ? state1.play : false,
                style: {
                    position: 'relative',
                    width: '100%', height: '100%', left: '302px', inset: "-20px 0px 0px -2px"
                },
                mapScrollWeel: true
            }
            ), () => {
                this.getDroneLocation()

                // setInterval(() => {
                //     if (this.state.play == true) {
                // this.getDroneLocation()
                //     }
                // }, 1000);

            })
        }
    }
    onTrigger = (event) => {
        this.props.parentCallback({ latLng: this.state.latLng, flightId: this.state.flight_id });
        // event.preventDefault();
    }
    render() {
        return (
            <>
                <Map
                    id="mapComp"
                    style={this.state.style}
                    google={this.props.google}
                    onReady={this.initMap}
                    disableDefaultUI={true}                    
                    initialCenter={this.state.center ? this.state.center : {lat: 20.008482, lng: 73.756834 }}
                    center={this.state.center}
                    zoomControl={false}
                    showIcons={true}
                    mapType={"satellite"}
                    zoom={this.state.zoom}
                    yesIWantToUseGoogleMapApiInternals
                ></Map>
                {
                    this.state.mapMode == 'large' ?
                        <div className="dcc-all-drones-drone-count" style={{ top: '20px', cursor: 'pointer' }} onClick={() => {
                            window.history.back()
                        }}>
                            <img className="icons" src={backbutton} alt="back" />
                            {/* </Link> */}
                        </div> : <div />
                }
            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
    libraries: ["drawing"],
    LoadingContainer: LoadingContainer,
})(aeroStreamOfflineMapView)
