import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import btnAdd from '../icons/btnAdd.svg';
import deleteIcon from "../trash.svg";
import validator from 'validator'
import { Link } from "react-router-dom";
import SideBar from "../SideBar";
import Loader from '../icons/loader.svg';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux'
import AeroGCSInactive from '../sidebaricon/AeroGCSInactive.png';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup.js';
import leaseActive from '../icons/leaseActive.png'
import Dropdown from 'react-bootstrap/Dropdown';
import MenuDark from '../icons/MenuDark.png';
import noCustomerVector from '../icons/noCustomerVector.png'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <img src={MenuDark} style={{ height: "14px" }} alt="I am alt" />
    </a>
));


class userCustomer extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        addCustomerPopUp: this.props.location.state.addCustomerPopUp ? this.props.location.state.addCustomerPopUp : false,
        customerAddress: '',
        contactNumber: '',
        customerEmail: '',
        customerGovIdNo: '',
        customerName: '',
        customerPilotLicenseId: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        usersCustomers: [],
        deleteUserCust: '',
        customerId: '',
        gst_no: '',
        custEdit: false,
        editCustId: '',
    };



    componentWillUnmount() { updateOutTime(this.state.outId) }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "User-Customers";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => { this.getAllUserCustomer() })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => { this.getAllUserCustomer() })
            }, (e) => {
                window.location.replace("/login")
            })
        }
    }

    onChangeHandler = (e) => {
        let t = e.target
        if (t.name == 'contactNumber') {
            console.log(t.value.length);
            if (t.value.length < 14) {
                this.setState((state) => ({
                    ...state,
                    [t.name]: t.value
                }))
            } else { console.log('contact number length is greater than 10'); }
        } else if (t.name == 'customerEmail') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toLowerCase()
            }))
        } else if (t.name == 'customerGovIdNo') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toUpperCase()
            }))
        } else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }

    updateUserCustomer = () => {
        console.log('info added', this.state.customerName, this.state.contactNumber, this.state.customerEmail, this.state.customerAddress, this.state.customerGovIdNo, this.state.customerPilotLicenseId,);
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.updateUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, this.state.editCustId).then((custAdd) => {
                    console.log("addUserCustomer", custAdd);
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: 'Customer details updated sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: 'Something went wrong while updating customer details.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }

    addUserCustomer = () => {
        console.log('info added', this.state.customerName, this.state.contactNumber, this.state.customerEmail, this.state.customerAddress, this.state.customerGovIdNo, this.state.customerPilotLicenseId,);
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.addUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, 'lease').then((custAdd) => {
                    console.log("addUserCustomer", custAdd);
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Customer added sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding customer.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    getAllUserCustomer = () => {
        API.getAllUserCustomer('lease', 0).then((data) => {
            console.log(data);
            if (data.usersCustomers && data.usersCustomers.length > 0) {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: data.usersCustomers
                }), () => {
                    this.setState({ loading: false })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: []
                }), () => {
                    this.setState({ loading: false })
                })
            }
        }, (e) => {
            console.log(e)
        })
    }

    deleteUserCustomer = () => {
        console.log("customer", this.state.deleteUserCust);
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Customer",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomer(this.state.deleteUserCust).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Customer Deleted sucessfully',
                        showProcessPopup: true,
                        deleteUserCust: ''
                    }), () => {
                        this.getAllUserCustomer()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting customer.',
                    showProcessPopup: true,
                }))
            })
        })
    }

    render() {
        let { user, type, } = this.state;
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: AeroGCSInactive,
                    text: "AeroGCS",
                    path: "/"
                },
                // {
                //     icon: DronesInactive,
                //     text: "Drones",
                //     path: "/drone_management/drones",
                // },
                // {
                //     icon: RPAS,
                //     text: "Drone Control Center",
                //     path: "/drone_control_center/all_drones",
                // },
                // {
                //     icon: ProjectsInActive,
                //     text: "Projects",
                //     path: "/projects",
                // }
                {
                    icon: leaseActive,
                    text: "Drone Lease",
                    path: "/drone_management/lease_dashboard",
                    selected: true
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode, // or expanded
            aeroGCSHelp: true,
        };

        // if (this.props.location.state) {
        return <div className="wrapper">
            <SideBar
                config={sidebarConfigurations}
                parentSidebarSwitch={this.parentSidebarSwitch}
            />
            <div
                className="right-content"
                onClick={() => {
                    this.setState((state) => ({
                        ...state,
                        sidebarMode: "shrunk",
                    }));
                }}
            >
                <div className="top-bar">
                    <div className="top-bar-text">User Customers</div>
                </div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteUserCustomer()}
                />
                <AnimatedModal
                    isOpen={this.state.addCustomerPopUp}
                    height="480px"
                    width="600px"
                >
                    <div className="content-text" style={{ width: "100%", height: "100%", padding: '10px 20px' }}>
                        <div className="services-content-title"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            Add Customer
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%' }} />
                        <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Name<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='30' name="customerName" value={this.state.customerName} className="add-user-form-text" placeholder="Name" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Contact Number<span style={{ color: 'red', }}>*</span></div>
                                <input type="number" name="contactNumber" value={this.state.contactNumber} className="add-user-form-text" placeholder="Contact Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                {this.state.contactNumberErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactNumberErromsg}</div> : ''}
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Address<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='30' name="customerAddress" value={this.state.customerAddress} className="add-user-form-text" placeholder="Address" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Government Id Number<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='20' name="customerGovIdNo" value={this.state.customerGovIdNo} className="add-user-form-text" placeholder="PAN or Addhar Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                        </div>

                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Email<span style={{ color: 'red', }}>*</span></div>
                                <input type="text" maxLength='25' name="customerEmail" value={this.state.customerEmail} className="add-user-form-text" placeholder="Email" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                {this.state.contactEmailErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactEmailErromsg}</div> : ''}
                            </div>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">Pilot License Id</div>
                                <input type="text" maxLength='20' name="customerPilotLicenseId" value={this.state.customerPilotLicenseId} className="add-user-form-text" placeholder="Pilot License Id Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                        </div>
                        <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                            <div style={{ textAlign: "left", width: '45%', }}>
                                <div className="content-text">GST Number</div>
                                <input type="text" maxLength='25' name="gst_no" value={this.state.gst_no} className="add-user-form-text" placeholder="gst_no" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div>
                            {/* <div style={{ textAlign: "left", width: '45%', }}>
                                <div style={{ fontSize: "13px", }} >Pilot License Id</div>
                                <input type="text" maxLength='20' name="customerPilotLicenseId" value={this.state.customerPilotLicenseId} className="add-user-form-text" placeholder="Pilot License Id Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                            </div> */}
                        </div>
                        <div style={{ display: 'flex', margin: '30px 20px', }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addCustomerPopUp: false,
                                        customerName: '',
                                        contactNumber: '',
                                        customerEmail: '',
                                        customerAddress: '',
                                        customerGovIdNo: '',
                                        customerPilotLicenseId: '',
                                        gst_no: ''
                                    }));
                                }}
                            >
                                cancel
                            </button>
                            {this.state.customerName && this.state.contactNumber && this.state.customerAddress && this.state.customerGovIdNo && this.state.customerEmail ? <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    this.state.custEdit ? this.updateUserCustomer() : this.addUserCustomer()
                                }}
                            >
                                Save
                            </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok">
                                Save
                            </button>}
                        </div>
                    </div>
                    {/* </div> */}


                </AnimatedModal>

                {/* --------------------------------------------------------------- */}
                {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                    <div className="main-content">
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', }}>
                            <div className="services-content-title">{`Customers : ${this.state.usersCustomers.length}`}</div>
                            <div style={{ marginLeft: "auto", width: "fit-content", display: 'flex', alignItems: 'center' }}>
                                {/* <Link
                                    to={{
                                        pathname: path: "/drone_management/lease_dashboard",,
                                        state: {
                                            user: this.state.user,
                                            type: this.state.type,
                                        },
                                    }}
                                >
                                    <div
                                        className="add-user-form-submit-button"
                                        style={{ cursor: "pointer" }}
                                    >
                                       View All Lease
                                    </div>
                                </Link> */}
                                {
                                    <img src={btnAdd} style={{ height: '26px', width: '30px', cursor: 'pointer', margin: '0px 14px' }} onClick={() => {
                                        this.setState({ addCustomerPopUp: true })
                                    }} />
                                }
                            </div>

                        </div>
                        <div style={{ height: 'calc(91vh - 60px)', overflow: 'auto', marginTop: '22px' }}>
                            {this.state.usersCustomers.length ? <table className="customers-table" style={{ marginTop: '0px' }}>
                                <col style={{ width: '30%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '25%' }} />
                                <col style={{ width: '5%' }} />
                                <tr className="customers-table-header-row">
                                    <th>Name</th>
                                    <th style={{ textAlign: 'center' }}>Phone Number</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                                {this.state.usersCustomers.map((cust, key) => (
                                    <tr className="customers-table-data-row">
                                        <td>
                                            <Link style={{ color: '#3c3c3c' }} to={{
                                                pathname: "/drone_management/rent-drone_customer",
                                                state: { user: this.state.user, type: this.state.type, userCustomer: cust }
                                            }}>
                                                {cust.customer_name}
                                            </Link>

                                        </td>
                                        <td style={{ textAlign: 'center' }}>{cust.contact_number}</td>
                                        <td>{cust.email ? cust.email : '-'}</td>
                                        <td>{cust.address ? cust.address : '-'}</td>
                                        <td style={{ textAlign: 'right', }}>
                                            <Dropdown >
                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu size="sm" title="" className="custom-dropdown-dashboard1">
                                                    {/* <Dropdown.Item eventKey="" className="dropdown-set-padding-dashboard">
                                                        <div style={{ display: "flex", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    custEdit: true,
                                                                    editCustId: cust.id,
                                                                    customerName: cust.customer_name,
                                                                    contactNumber: cust.contact_number,
                                                                    customerEmail: cust.email,
                                                                    customerAddress: cust.address,
                                                                    customerGovIdNo: cust.government_id,
                                                                    customerPilotLicenseId: cust.pilot_license_id,
                                                                    gst_no: cust.gst_no
                                                                }), () => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        addCustomerPopUp: true
                                                                    }))
                                                                })
                                                            }}>
                                                            <img src={editIcon} style={{ height: "16px" }} />
                                                            <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Edit</div>
                                                        </div>
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item eventKey="rename" className="dropdown-set-padding-dashboard">
                                                        <div style={{ display: "flex", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    deleteUserCust: cust.id
                                                                }), () => {
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        itemName: "Customer",
                                                                        processPopupIcon: "WARNING",
                                                                        processAction: "DELETE",
                                                                        processMessage: "Are you sure you want to delete this Customer ?",
                                                                        showProcessPopup: true,
                                                                    }))
                                                                })
                                                            }} >
                                                            <img src={deleteIcon} style={{ height: "16px" }} />
                                                            <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Delete</div>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown >
                                        </td>
                                    </tr>
                                ))}
                            </table> :
                                <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '80vh', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={noCustomerVector} style={{ width: "18%", }} />
                                    <div>No Customers Found</div>
                                </div>
                            }
                        </div>
                    </div>}
            </div>
        </div>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(userCustomer)