import React from 'react';
import blueIcon from '../assets/Icons/dropdownLogo.png';

const NormalSelector = ({ blueDropDownIcon, text, isBorder, margin, icon, width, height, onClick, isDisabled, isDropDownOpen, textColor, fontSize, textFontSize, padding, textFontWeight, css }) => {
  let style = {
    display: "flex",
    margin: margin,
    justifyContent: "space-between",
    alignItems: 'center',
    padding: padding || "5px 5px",
    fontWeight: " 400",
    width,
    height,
    opacity: isDisabled ? "0.5" : "1",
    cursor: isDisabled ? "" : "pointer",
    fontSize: fontSize,
    ...css
  }
  return (
    <div style={{
      border: isBorder ? "1px solid #b4b4bb" : undefined,
      borderRadius: isBorder ? '5px' : undefined,
      width: width || "max-content",
    }}>
      <div style={style} onClick={onClick} >
        <div style={{ fontSize: textFontSize || '15px', color: textColor || "#3c3c3c", fontWeight: textFontWeight || '500', textTransform: 'capitalize', overflowX: "" }}>{text}</div>
        <img
          id="icon"
          alt="icon"
          src={blueDropDownIcon ? blueIcon : icon}
          style={{
            width: "10px",
            transform: isDropDownOpen && !isDisabled ? "rotate(180deg)" : "rotate(0deg)",
            transition: "0.3s all",
            marginLeft: "10px",
          }}
        />
      </div >
    </div>

  )
}
export default NormalSelector;