import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import { addUserAnalytic, updateOutTime } from '../ReusableComponents/reusableFunctions.js';
import btnAdd from '../icons/GreenPlusIcon.png';
import deleteIcon from "../trash.svg";
import dateFormat from 'dateformat';
import validator from 'validator'
import SideBar from "../SideBar";
import Loader from '../icons/loader.svg';
import { AnimatedModal } from '../ReusableComponents/AnimatedModal'
import { connect } from "react-redux";
import { updateProfileDetails } from '../redux'
import Dropdown from 'react-bootstrap/Dropdown';
import MenuDark from '../icons/MenuDark.png';
import noCustomerVector from '../icons/noCustomerVector.png'
import AeroMeghLogoWhite from '../icons/AeroMeghLogoWhite.png'
import AeroGcsWhite from '../icons/AeroGcsWhite.png'
import people from '../icons/people.png';
import userp from '../icons/userp.png';
import searchlogo from '../../src/icons/SearchIcon.png';
import reportWhiteIcon from '../icons/reportWhiteIcon.png'
import greenReportWhite from '../icons/greenReportWhite.png'
import customersGreenActive from '../icons/customersGreenActive.png'
import { ActivityPopup } from "../ReusableComponents/ActivityPopup.js";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >        {children}
        <img src={MenuDark} style={{ height: "14px" }} alt="I am alt" />    </a>));
class AeroGCSGreenCustomers extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        addCustomerPopUp: this.props.location.state.addCustomerPopUp ? this.props.location.state.addCustomerPopUp : false,
        customerAddress: '',
        contactNumber: '',
        customerEmail: '',
        customerGovIdNo: '',
        customerName: '',
        customerPilotLicenseId: '',
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        usersCustomers: [],
        deleteUserCust: '',
        customerId: '',
        gst_no: '',
        custEdit: false,
        editCustId: '',
        userDetailSelect: '',
        userDetailSelectIndex: 0,
        userOnPopUp: false,
        // monthlist: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        searchBar: '',
        customersLastMonthCount: 0,
    };
    componentDidMount() {
        this.lastMonthPercent();
    }
    lastMonthPercent = () => {
        // console.log((lastMonthCount * 100)/ totalCount);
    }
    handleSearchBar = (e) => {
        console.log(e.target.value);
        this.setState({
            searchBar: e.target.value,
        }, () => {
            this.getAllUserCustomer();
        });
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "Green-User-Customers";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => { this.getAllUserCustomer() })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => { this.getAllUserCustomer() })
            }, (e) => { window.location.replace("/login") })
        }
    }
    onChangeHandler = (e) => {
        let t = e.target
        if (t.name == 'contactNumber') {
            console.log(t.value.length);
            if (t.value.length <= 10) {
                this.setState((state) => ({
                    ...state,
                    [t.name]: t.value
                }))
            } else { console.log('contact number length is greater than 10'); }
        } else if (t.name == 'customerEmail') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toLowerCase()
            }))
        } else if (t.name == 'customerGovIdNo') {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value.toUpperCase()
            }))
        } else {
            this.setState((state) => ({
                ...state,
                [t.name]: t.value
            }))
        }
    }
    updateUserCustomer = () => {
        console.log('info added', this.state.customerName, this.state.contactNumber, this.state.customerEmail, this.state.customerAddress, this.state.customerGovIdNo, this.state.customerPilotLicenseId,);
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.updateUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, this.state.editCustId).then((custAdd) => {
                    console.log("addUserCustomer", custAdd);
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "UPDATE",
                            processMessage: 'Customer details updated sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "UPDATE",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "UPDATE",
                        processMessage: 'Something went wrong while updating customer details.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }
    addUserCustomer = () => {
        console.log('info added', this.state.customerName, this.state.contactNumber, this.state.customerEmail, this.state.customerAddress, this.state.customerGovIdNo, this.state.customerPilotLicenseId,);
        if (this.state.contactNumber.length > 13 || this.state.contactNumber.length < 10) {
            this.setState((state) => ({
                ...state,
                contactNumberErromsg: 'Please enter valid contact number'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactNumberErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (!(validator.isEmail(this.state.customerEmail))) {
            this.setState((state) => ({
                ...state,
                contactEmailErromsg: 'Enter valid email address.'
            }), () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        ...state,
                        contactEmailErromsg: ''
                    }))
                }, 5000)
            })
        }
        if (validator.isEmail(this.state.customerEmail) && this.state.contactNumber.length > 9) {
            this.setState((state) => ({
                ...state,
                addCustomerPopUp: false,
                itemName: "Customer",
                processPopupIcon: "WAIT",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
            }), () => {
                let customerEmail = this.state.customerEmail == '' ? ' ' : this.state.customerEmail
                let customerAddress = this.state.customerAddress == '' ? ' ' : this.state.customerAddress
                let customerGovIdNo = this.state.customerGovIdNo == '' ? ' ' : this.state.customerGovIdNo
                let customerPilotLicenseId = this.state.customerPilotLicenseId == '' ? ' ' : this.state.customerPilotLicenseId
                let gst_no = this.state.gst_no == '' ? ' ' : this.state.gst_no
                API.addUserCustomer(this.state.customerName, this.state.contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, 'green').then((custAdd) => {
                    console.log("addUserCustomer", custAdd);
                    if (custAdd.message == "OK") {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "COMPLETE",
                            processAction: "ADD",
                            processMessage: 'Customer added sucessfully',
                            showProcessPopup: true,
                        }), () => {
                            this.getAllUserCustomer()
                            this.setState((state) => ({
                                ...state,
                                addCustomerPopUp: false,
                                customerName: '',
                                contactNumber: '',
                                customerEmail: '',
                                customerAddress: '',
                                customerGovIdNo: '',
                                customerPilotLicenseId: '',
                                gst_no: '',
                            }))
                        })
                    } else {
                        this.setState((state) => ({
                            ...state,
                            addCustomerPopUp: false,
                            itemName: "Customer",
                            processPopupIcon: "ERROR",
                            processAction: "ADD",
                            processMessage: custAdd.message,
                            showProcessPopup: true,
                        }))
                    }
                }, (e) => {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "ADD",
                        processMessage: 'Something went wrong while adding customer.',
                        showProcessPopup: true,
                    }))
                })
            })
        }
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null,
        }))
    }

    getAllUserCustomer = () => {
        let searchString = !this.state.searchBar || this.state.searchBar == '' ? undefined : this.state.searchBar
        API.getAllUserCustomer('green', 0, searchString).then(async (data) => {
            console.log(data);

            if (data.usersCustomers && data.usersCustomers.length > 0) {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: data.usersCustomers,
                    userDetailSelect: data.usersCustomers[0],
                    userDetailSelectIndex: 0,
                    customersLastMonthCount: data.customersLastMonthCount
                }), () => {
                    this.setState({ loading: false })
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    usersCustomers: []
                }), () => {
                    this.setState({ loading: false })
                })
            }
        }, (e) => {
            console.log(e)
        })
    }
    deleteUserCustomer = () => {
        console.log("customer", this.state.deleteUserCust);
        this.setState((state) => ({
            ...state,
            addCustomerPopUp: false,
            itemName: "Customer",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: null,
            showProcessPopup: true,
        }), () => {
            API.deleteUserCustomer(this.state.deleteUserCust).then((deleteRes) => {
                console.log('deleteRes', deleteRes);
                if (deleteRes.message == "OK") {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "COMPLETE",
                        processAction: "DELETE",
                        processMessage: 'Customer Deleted sucessfully',
                        showProcessPopup: true,
                        deleteUserCust: ''
                    }), () => {
                        this.getAllUserCustomer()
                    })
                } else {
                    this.setState((state) => ({
                        ...state,
                        addCustomerPopUp: false,
                        itemName: "Customer",
                        processPopupIcon: "ERROR",
                        processAction: "DELETE",
                        processMessage: deleteRes.message,
                        showProcessPopup: true,
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    addCustomerPopUp: false,
                    itemName: "Customer",
                    processPopupIcon: "ERROR",
                    processAction: "DELETE",
                    processMessage: 'Something went wrong while deleting customer.',
                    showProcessPopup: true,
                }))
            })
        })
    }
    render() {
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: servicesWhite,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: AeroGcsWhite,
                    text: "AeroGCS",
                    path: "/",
                },
                {
                    icon: greenReportWhite,
                    text: "Green-E",
                    path: "/aerogcsenterprise/green_dashboard",

                },
                {
                    icon: reportWhiteIcon,
                    text: "Reports",
                    path: "/aerogcsenterprise/green_all_reports",
                },
                {
                    icon: customersGreenActive,
                    text: "Customers",
                    path: "/aerogcsenterprise/green_all_customers",
                    selected: true,
                },
            ],
            user: this.state.user,
            type: this.state.type,
            mode: this.state.sidebarMode,
            aeroGCSHelp: true,
        }
        return <div className="wrapper">
            <SideBar backgroundColor={'#043323'} selectedColor={'#F8C12E'} nonSelectedColor={'white'} borderColor={'#F8C12E'} AeroMeghLogo={AeroMeghLogoWhite} config={sidebarConfigurations} showMainDashboardIcons={this.state.showBackIcon} parentSidebarSwitch={this.parentSidebarSwitch} />
            <div
                className="right-content-new"
                onClick={() => {
                    this.setState((state) => ({
                        ...state,
                        sidebarMode: "shrunk",
                    }));
                }}
            >
                <div className="top-bar-new">
                    <div className="top-bar-text-new" style={{ color: 'black' }}>User Customers</div>
                </div>
                <ActivityPopup
                    item={this.state.itemName}
                    open={this.state.showProcessPopup}
                    icon={this.state.processPopupIcon}
                    action={this.state.processAction}
                    msg={this.state.processMessage}
                    close={() => this.closeActivityPopup()}
                    onClickOk={() => this.deleteUserCustomer()}
                />
                <AnimatedModal
                    isOpen={this.state.addCustomerPopUp}
                    height="400px"
                    width="600px"
                >
                    <div style={{ width: "100%", height: "100%" }}>
                        <div className="services-content-titleGreen" style={{ padding: '10px 40px 0px 40px', textAlign: 'left' }}>
                            Add Customer
                        </div>
                        <hr style={{ margin: '5px 0px 0px 0px', padding: '0px', width: '100%', background: '#9AD8AB' }} />
                        <div style={{ padding: '0px 20px 10px 20px', }}>
                            <div style={{ margin: '15px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">Name<span style={{ color: 'red', }}>*</span></div>
                                    <input type="text" maxLength='30' name="customerName" value={this.state.customerName} className="add-user-form-text" placeholder="Name" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                </div>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">Contact Number<span style={{ color: 'red', }}>*</span></div>
                                    <input type="number" name="contactNumber" value={this.state.contactNumber} className="add-user-form-text" placeholder="Contact Number" style={{ width: "100%" }} onChange={(e) => {
                                        let check = /^[0-9]*$/;
                                        if (check.test(e.target.value) && e.target.value.length <= 10) this.onChangeHandler(e)
                                    }} />

                                    {this.state.contactNumberErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactNumberErromsg}</div> : ''}
                                </div>
                            </div>
                            <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">Address<span style={{ color: 'red', }}>*</span></div>
                                    <input type="text" maxLength='30' name="customerAddress" value={this.state.customerAddress} className="add-user-form-text" placeholder="Address" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                </div>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">Government Id Number<span style={{ color: 'red', }}>*</span></div>
                                    <input type="text" maxLength='16' name="customerGovIdNo" value={this.state.customerGovIdNo} className="add-user-form-text" placeholder="PAN or Addhar Number" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                </div>
                            </div>
                            <div style={{ margin: '5px 20px 0px 20px', display: 'flex', justifyContent: 'space-between', height: '75px', }}>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">Email<span style={{ color: 'red', }}>*</span></div>
                                    <input type="text" maxLength='25' name="customerEmail" value={this.state.customerEmail} className="add-user-form-text" placeholder="Email" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                    {this.state.contactEmailErromsg ? <div style={{ fontSize: '11px', color: 'red' }} >{this.state.contactEmailErromsg}</div> : ''}
                                </div>
                                <div style={{ textAlign: "left", width: '45%', }}>
                                    <div className="itemKey1">GST Number</div>
                                    <input type="text" maxLength='25' name="gst_no" value={this.state.gst_no} className="add-user-form-text" placeholder="gst_no" style={{ width: "100%" }} onChange={this.onChangeHandler} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', margin: '30px 20px', }}>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            addCustomerPopUp: false,
                                            customerName: '',
                                            contactNumber: '',
                                            customerEmail: '',
                                            customerAddress: '',
                                            customerGovIdNo: '',
                                            customerPilotLicenseId: '',
                                            gst_no: ''
                                        }));
                                    }}
                                >
                                    cancel
                                </button>
                                {this.state.customerName && this.state.contactNumber && this.state.customerAddress && this.state.customerGovIdNo && this.state.customerEmail ? <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.state.custEdit ? this.updateUserCustomer() : this.addUserCustomer()
                                    }}
                                >
                                    Save
                                </button> : <button style={{ opacity: '0.5', cursor: 'auto' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok">
                                    Save
                                </button>}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </AnimatedModal>

                {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                    <div className="main-content-new" style={{ background: '#F2F1EF' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'calc(100% + 9px)' }}>
                            <div className="greenAllSideShadowDiv" style={{ height: '100%', width: '76%', borderRadius: '5px' }}>
                                <div style={{ borderRadius: '5px', background: 'white', display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '10px 5px', }}>
                                    <div className="services-content-titleGreen">Customers</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: "flex", justifyContent: 'space-between', borderRadius: '7px', alignItems: 'center', backgroundColor: '#EAEAEA' }}>
                                                <input onChange={(e) => this.handleSearchBar(e)} className="searchbar" type='text' placeholder="Search" />
                                                <img style={{ height: '12px', width: '12px', marginRight: '10px', cursor: 'pointer' }} src={searchlogo} alt="search logo" />
                                            </div>



                                            <div style={{ marginLeft: "auto", width: "fit-content", display: 'flex', alignItems: 'center' }}>
                                                <img src={btnAdd} style={{ height: '25px', width: '25px', cursor: 'pointer', margin: '0px 14px' }} onClick={() => {
                                                    this.setState({ addCustomerPopUp: true })
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: 'calc(100% - 60px)', overflow: 'auto', padding: '0px 5px', background: 'white' }}>
                                    {this.state.usersCustomers && this.state.usersCustomers.length ? <table className="greenCustomersTable" style={{ marginTop: '0px' }}>
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '22%' }} />
                                        <col style={{ width: '22%' }} />
                                        <col style={{ width: '21%' }} />
                                        <col style={{ width: '5%' }} />
                                        {this.state.usersCustomers.map((cust, key) => (
                                            <tr className="greenCustomersTableDataRow" style={{ background: key == this.state.userDetailSelectIndex ? '#9AD8AB ' : '#eee9e9' }} >
                                                <td style={{ borderRadius: '5px 0px 0px 5px' }}>
                                                    <div className="leasedData" style={{ cursor: 'pointer', width: 'fit-content' }}
                                                        onClick={() => {
                                                            this.setState((state) => ({
                                                                ...state,
                                                                userDetailSelect: cust,
                                                                userDetailSelectIndex: key
                                                            }), () => {
                                                                // this.setState({ userOnPopUp: true })
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            cust.customer_name && cust.customer_name.length >= 30 ?
                                                                <> {cust.customer_name.slice(0, 27)} . . . </> :
                                                                cust.customer_name
                                                        }
                                                    </div>
                                                </td>
                                                <td >{cust.totalAcres ? cust.totalAcres : '0'} Acr</td>
                                                <td >{cust.totalRevenue ? cust.totalRevenue : '0'} Rs</td>
                                                <td >{cust.register_date ? dateFormat(cust.register_date, 'dS mmmm') : '-'}</td>
                                                <td style={{ borderRadius: '0px 5px 5px 0px' }}>
                                                    <Dropdown style={{ position: "" }} >
                                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu size="sm" title="" stylr className="custom-dropdown-dashboard">
                                                            <Dropdown.Item eventKey="rename" className="dropdown-set-padding-dashboard">
                                                                <div style={{ display: "flex", cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setState((state) => ({
                                                                            ...state,
                                                                            deleteUserCust: cust.id
                                                                        }), () => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                itemName: "Customer",
                                                                                processPopupIcon: "WARNING",
                                                                                processAction: "DELETE",
                                                                                processMessage: "Are you sure you want to delete this Customer ?",
                                                                                showProcessPopup: true,
                                                                            }))
                                                                        })
                                                                    }} >
                                                                    <img src={deleteIcon} style={{ height: "16px" }} />
                                                                    <div style={{ marginLeft: '7px', color: "#3c3c3c" }}>Delete</div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown >
                                                </td>
                                            </tr>))}
                                    </table> :
                                        <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '80vh', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={noCustomerVector} style={{ width: "18%", }} />
                                            <div>No Customers Found</div>
                                        </div>}
                                </div>
                            </div>
                            <div className="cardDiv" >
                                <div className="card1" >
                                    <div style={{ display: 'flex', height: '80%', paddingRight: '15px' }}>
                                        <div style={{ margin: '8% 0% 0% 6%', width: '75px', height: '55px', backgroundColor: '#0D9164', borderRadius: '36px' }}>
                                            <img style={{ height: '80%', width: '80%', margin: '5px 1px 1px 5px' }} src={people} alt="people logo" />
                                        </div>
                                        <div style={{ width: '100%', textAlign: 'right' }}>
                                            <div style={{ fontFamily: 'poppins', fontSize: "20px", margin: '12% 0% 0% 30%', color: '#3C3C3C' }}> {this.state.usersCustomers ? this.state.usersCustomers.length : '0'} </div>
                                            <div style={{ margin: '0% 0% 0% 30%', fontSize: "12px", fontFamily: 'poppins', color: '#3C3C3C' }}> Total Customers</div>
                                        </div>
                                    </div>
                                    <div style={{ height: '20%', textAlign: 'right' }}>
                                        <p style={{ fontSize: '12px', fontFamily: 'poppins', margin: '1% 6% 1% 4%' }}>  <span style={{ color: '#0D9164' }}>{this.state.customersLastMonthCount}</span> increase in last Season</p>
                                    </div>
                                </div>
                                <div className="card2" style={{ overflow: 'hidden' }}>
                                    {this.state.userDetailSelect ? <>
                                        <div style={{ height: '140px', padding: '0px 15px' }}>
                                            <p className="services-content-titleGreen" style={{ fontSize: '12px', }}>Customer Profile</p>
                                            <div style={{ display: 'flex', justifyContent: 'center ', marginTop: '15px' }}>
                                                <div style={{ width: '60px', height: '60px', backgroundColor: '#0D9164', borderRadius: '36px', alignContent: 'center', }} >
                                                    <img style={{ width: '65%', margin: '10px 0 10px 10px' }} src={userp} alt="user logo" />
                                                </div>
                                            </div>
                                            <div style={{ fontFamily: 'poppins', textAlign: 'center', margin: '5px 2px 2px 2px' }}>
                                                {this.state.userDetailSelect.customer_name && this.state.userDetailSelect.customer_name.length >= 22 ?
                                                    <>{this.state.userDetailSelect.customer_name.slice(0, 19)} . . .</> :
                                                    this.state.userDetailSelect.customer_name
                                                }
                                            </div>
                                        </div>
                                        <hr style={{ borderTop: '0.5px solid #a39e9e' }} />
                                        <div style={{ height: 'calc(100% - 180px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', height: '50px', padding: '0px 15px' }}>
                                                <div style={{ width: '60%' }}>
                                                    <p className="cardLabel1" >Contact Number</p>
                                                    <p className="cardLabel" >{this.state.userDetailSelect.contact_number ? this.state.userDetailSelect.contact_number : <>-</>}</p>
                                                </div>
                                                <div style={{ width: '40%' }}>
                                                    <p className="cardLabel1">Acreage</p>
                                                    <p className="cardLabel">{this.state.userDetailSelect.totalAcres ? this.state.userDetailSelect.totalAcres : <>-</>}</p>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '5px', height: '', padding: '0px 15px' }}>
                                                <p className="cardLabel1">Address</p>
                                                <p className="cardLabel" style={{ wordWrap: "break-word" }}>{this.state.userDetailSelect.address ? this.state.userDetailSelect.address : <>-</>}</p>
                                            </div>
                                            <div style={{ marginTop: '5px', padding: '0px 15px', display: 'flex', height: '50px', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                                <div style={{ width: '60%' }}>
                                                    <p className="cardLabel1">government id No</p>
                                                    <p className="cardLabel">{this.state.userDetailSelect.government_id ? this.state.userDetailSelect.government_id : <>-</>}</p>
                                                </div>
                                                <div style={{ width: '40%' }}>
                                                    <p className="cardLabel1">total Revenue</p>
                                                    <p className="cardLabel" style={{ wordWrap: 'break-word' }}>
                                                        {this.state.userDetailSelect.totalRevenue ?
                                                            <>{this.state.userDetailSelect.totalRevenue} Rs</> :
                                                            <> 0 Rs </>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '5px', height: 'calc(100% - 160px)' }}>
                                                <div className="cardLabel1" style={{ padding: '0px 15px' }}>Crops</div>
                                                <div className="cardLabel" style={{ padding: '0px 15px' }}>{this.state.userDetailSelect.reportStatCrop && this.state.userDetailSelect.reportStatCrop.length ? this.state.userDetailSelect.reportStatCrop.length : '0'} Crop</div>
                                                <div className="cardLabel" style={{ height: 'calc(100% - 45px)', padding: '0px 15px', overflow: 'auto', fontWeight: '400' }}>
                                                    {this.state.userDetailSelect.reportStatCrop && this.state.userDetailSelect.reportStatCrop.length > 0 ? this.state.userDetailSelect.reportStatCrop.map((cpr) => { return <label style={{ margin: '0px 5px' }}>{cpr.crop_type}</label> }) : ''}

                                                </div>
                                            </div>

                                        </div>
                                    </> : <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '80vh', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={noCustomerVector} style={{ width: "18%", }} />
                                        <div>No Customers Found</div>
                                    </div>}

                                </div>
                            </div>

                        </div>
                    </div>}
            </div>
        </div>
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};
const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AeroGCSGreenCustomers);
