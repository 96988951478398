import React, { useEffect, useState } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DropDownWithButton from "../ReusableComponents/DropDownWithButton";
import closeIcon from '../assets/toolbarIcons/closeButton.png'
import editIcon from '../edit.svg'
import AppConstants from "../AppConstants";
import * as API from '../api.js';
import { enterpriseSubscriptionPlans } from "../AeroGCSEnterprise/Plans/EnterpriseSubscriptionPlans.js";
import dateFormat from 'dateformat';
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";

const SubscriptionModel = ({ subPlan, state, updateState, buyDrone, subLoading }) => {

    const [percentage, setPercentage] = useState(0);
    const [serviceDetails, setServiceDetails] = useState(undefined);
    const [subscriptionPlan, setSubscription] = useState([]);
    const [addOnPlan, setAddOnPlan] = useState([]);
    const [droneDetails, setDroneDetails] = useState(undefined);

    const getTaskCreditsDetails = async () => {
        try {
            let storageData = await API.getUserStorage();
            let limitDetails = await API.getUserDroneLimits(AppConstants.SERVICES.AEROGCS)
            let storageDetails = storageData.storageDetails.filter(s => s.service == "AeroGCS Enterprise")[0];

            setDroneDetails({
                planDrones: limitDetails.serviceDronesLimits || 0,
                totalDrones: limitDetails.serviceDronesLimits + limitDetails.addOnDronesLimits || 0,
                registerDrones: limitDetails.droneUsage || 0,
                availableDrones: ((limitDetails.serviceDronesLimits + limitDetails.addOnDronesLimits) - limitDetails.droneUsage) || 0,
                addOnDrones: limitDetails.addOnDronesLimits || 0
            })

            setServiceDetails([
                {
                    sd: { "used": Number(storageDetails?.used) / 1024, "limit": Number(storageDetails?.limit) / 1024 },
                    usedStoragePercentage: (Number(storageDetails.used) / 1024 / Number(storageDetails.limit)) * 100,
                    cd: { used: undefined, limit: undefined },
                    ld: {
                        limits: Number(limitDetails.addOnDronesLimits) + Number(limitDetails.serviceDronesLimits)
                    }
                }
            ])
            setPercentage((((Number(storageDetails.used) / 1024) / (Number(storageDetails.limit) / 1024)) * 100).toFixed(6))
        }
        catch (err) { console.log("err : ", err); }
    }

    useEffect(() => {
        getTaskCreditsDetails()
        setSubscription(subPlan.filter(p => p.service_name == AppConstants.SERVICES.AEROGCS));
        setAddOnPlan(subPlan.filter(p => p.service_name == AppConstants.SERVICES.DRONE_CONTROL_CENTER));
    }, [percentage]);

    return subLoading ? <LoaderComponent />
        : <div className="wrapper">
            <div className='setting-containt' >

                <div style={{ marginBottom: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <div>Billing</div>   </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", gap: "25px" }}>


                        <div className="subscription-detail-card" >
                            <div className="subscription-detail-card-heading">
                                Current Bill
                            </div>
                            <div style={{ fontSize: "13px", fontWeight: "600", }} ></div>
                            <div >
                                <span style={{
                                    fontSize: "20px",
                                    fontWeight: "600"
                                }}> {subPlan[0].plan_cost ? subPlan[0].plan_cost : "NA"}</span>
                                <span style={{ color: "#3988F9", fontSize: "20px", marginLeft: "5px" }}>₹</span>
                                <span style={{ fontSize: "10px", marginLeft: "10px" }} >/month</span>
                            </div>
                        </div>

                        <div className="subscription-detail-card">
                            <div className="subscription-detail-card-heading">
                                Payment Due
                            </div>
                            <div style={{ fontSize: "13px", fontWeight: "600", }} >
                                {dateFormat(subscriptionPlan?.[0]?.expiry_date ? new Date(subscriptionPlan?.[0]?.expiry_date) : new Date(), "dd mmm , yyyy")}
                            </div>
                            <div className="subscription-detail-card-button" style={{ background: " #32BD26" }}
                                onClick={() => {
                                    updateState({
                                        showCancelSubsPopup: true,
                                        selectedSubs: subPlan.filter(p => p.service_name == AppConstants.SERVICES.AEROGCS)[0],
                                        cancelSubsText: "If you want to modify, existing active subscription will be cancelled, would you like to continue ?",
                                        cancelOkAction: AppConstants.CANCEL_SUBSCRIPTION,
                                        cancellingSubText: "Cancelling subscription please wait...",
                                        upgrade: true
                                    })
                                }}
                            >PAY NOW</div>
                        </div>

                        <div className="subscription-detail-card">
                            <div className="subscription-detail-card-heading">
                                Total Drone
                            </div>
                            <div style={{ fontSize: "13px", fontWeight: "600", }} > {droneDetails?.totalDrones} Drone</div>
                            <div className="subscription-detail-card-button"
                                style={{ background: "#3988F9", }}
                                onClick={() => { buyDrone() }}
                            >BUY DRONE</div>
                        </div>
                    </div>
                </div>
                {subscriptionPlan.length > 0 &&
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <div>Current Plan</div>
                            <div><a href='http://aerogcs.com/product-pricing/' target="_blank" style={{ fontSize: '12px' }}>View all plans</a></div>
                        </div>
                        <div style={{ border: '1px solid #8080804a', borderRadius: '7px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#E1EDFF', padding: '15px 25px' }}>
                                <div style={{ fontSize: '13px', fontWeight: '500' }}>
                                    {subscriptionPlan?.[0].plan_name.includes('Trial') ? 'Trial Plan'
                                        : subscriptionPlan?.[0].plan_name.includes('Pro') ? 'Pro Plan'
                                            : subscriptionPlan?.[0].plan_name.includes('Business') ? 'Business Plan'
                                                : subscriptionPlan?.[0].plan_name.includes('Enterprise') ? 'Enterprise Plan'
                                                    : 'Plan Details'
                                    }
                                </div>
                                <DropDownWithButton
                                    list={[{
                                        name: "Modify",
                                        listImg: {
                                            imgPosition: 'right',
                                            imgURL: editIcon,
                                            rightImgStyle: { height: '12px', width: '12px' }
                                        },
                                        onClick: () => {
                                            updateState({
                                                showCancelSubsPopup: true,
                                                selectedSubs: subPlan.filter(p => p.service_name == AppConstants.SERVICES.AEROGCS)[0],
                                                cancelSubsText: "If you want to modify, existing active subscription will be cancelled, would you like to continue ?",
                                                cancelOkAction: AppConstants.CANCEL_SUBSCRIPTION,
                                                cancellingSubText: "Cancelling subscription please wait...",
                                                upgrade: true
                                            })
                                        }
                                    }, {
                                        name: "Cancel",
                                        listImg: {
                                            imgPosition: 'right',
                                            imgURL: closeIcon,
                                            rightImgStyle: { height: '12px', width: '12px' }
                                        },
                                        onClick: () => {
                                            updateState({
                                                showCancelSubsPopup: true,
                                                cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                                cancelOkAction: AppConstants.CANCEL_SUBSCRIPTION,
                                                cancellingSubText: "Cancelling subscription please wait...",
                                                selectedSubs: subPlan.filter(p => p.service_name == 'AeroGCS Enterprise')
                                            })
                                        }
                                    }]}
                                    selectedItem={'Edit'}
                                    isButton
                                    textColor={'white'}
                                    width={'85px'}
                                    style={{
                                        backgroundColor: "#3988F9",
                                        padding: "4px 15px",
                                        borderRadius: "7px"
                                    }}
                                />
                            </div>
                            <div style={{ backgroundColor: '#FCFCFC', padding: '15px 25px' }}>

                                <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓  {`Max ${droneDetails?.planDrones} Drones Supported`}</div>
                                <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓  {`Max ${(serviceDetails?.[0]?.sd?.limit)?.toFixed(2)} GB Storage for Plan`}</div>
                                {subscriptionPlan?.[0].plan_type == 'Trial' ?
                                    <>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Project Synchronization</div>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Live Tracking of drones on Cloud</div>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Business Analytics</div>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Drone Performance Analytics</div>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Replay of Flight</div>
                                        <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ Live Video Streaming</div>
                                    </>
                                    : subscriptionPlan?.[0].plan_type == 'Yearly' ?
                                        subscriptionPlan?.[0].plan_name == 'AeroGCS Enterprise Pro' ?
                                            enterpriseSubscriptionPlans.yearly.ProPlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })
                                            : subscriptionPlan?.[0].plan_name == 'AeroGCS Enterprise Business' ?
                                                enterpriseSubscriptionPlans.yearly.BusinessPlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })
                                                : enterpriseSubscriptionPlans.yearly.EnterprisePlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })
                                        : subscriptionPlan?.[0].plan_name == 'AeroGCS Enterprise Pro' ?
                                            enterpriseSubscriptionPlans.monthly.ProPlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })
                                            : subscriptionPlan?.[0].plan_name == 'AeroGCS Enterprise Business' ?
                                                enterpriseSubscriptionPlans.monthly.BusinessPlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })
                                                : enterpriseSubscriptionPlans.monthly.EnterprisePlan.DescriptionData.map(l => { return (<div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {`${l}`}</div>) })}
                            </div>
                        </div>
                    </div>
                }

                {addOnPlan.length > 0 &&
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <div>ADD ON Plan</div>
                            <div><a href='https://aeromegh.com/product-pricing/' target="_blank" style={{ fontSize: '12px' }}>View all plans</a></div>
                        </div>
                        <div style={{ border: '1px solid #8080804a', borderRadius: '7px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#E1EDFF', padding: '15px 25px' }}>
                                <div style={{ fontSize: '13px', fontWeight: '500' }}> Business Plan </div>
                                <DropDownWithButton
                                    list={[{
                                        name: "Modify",
                                        listImg: {
                                            imgPosition: 'right',
                                            imgURL: editIcon,
                                            rightImgStyle: { height: '12px', width: '12px' }
                                        },
                                        onClick: () => {

                                            updateState({
                                                showCancelSubsPopup: true,
                                                selectedSubs: subPlan.filter(p => p.service_name == AppConstants.SERVICES.DRONE_CONTROL_CENTER)[0],
                                                cancelSubsText: "If you want to modify, existing active subscription will be cancelled, would you like to continue ?",
                                                cancelOkAction: AppConstants.CANCEL_SUBSCRIPTION,
                                                cancellingSubText: "Cancelling subscription please wait...",
                                                upgrade: true

                                            })
                                        }
                                    }, {
                                        name: "Cancel",
                                        listImg: {
                                            imgPosition: 'right',
                                            imgURL: closeIcon,
                                            rightImgStyle: { height: '12px', width: '12px' }
                                        },
                                        onClick: () => {
                                            updateState({
                                                showCancelSubsPopup: true,
                                                cancelSubsText: 'Are you sure you want to cancel subscription ?',
                                                cancelOkAction: AppConstants.CANCEL_SUBSCRIPTION,
                                                cancellingSubText: "Cancelling subscription please wait...",
                                                selectedSubs: subPlan.filter(p => p.service_name == 'Drone Control Center')
                                            })
                                        }
                                    }]}
                                    selectedItem={'Edit'}
                                    isButton
                                    textColor={'white'}
                                    width={'85px'}
                                    style={{

                                        backgroundColor: "#3988F9",
                                        padding: "4px 15px",
                                        borderRadius: "7px",
                                        // color: "white",

                                    }}
                                />
                            </div>
                            <div style={{ backgroundColor: '#FCFCFC', padding: '15px 25px' }}>
                                <div style={{ fontSize: '12px', fontWeight: '400' }}> ✓ {droneDetails?.addOnDrones} Drones added.</div>
                            </div>
                        </div>
                    </div>
                }

                <div style={{ marginBottom: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <div>Usage this month</div>
                    </div>

                    {serviceDetails?.[0] && serviceDetails[0].sd ? <div style={{ border: '1px solid #8080804a', borderRadius: '7px', marginBottom: '20px', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#E1EDFF', padding: '15px 25px' }}>
                            <div style={{ fontSize: '13px', fontWeight: '500' }}> Storage </div>
                        </div>
                        <div style={{ backgroundColor: '#FCFCFC', padding: '15px 25px' }}>
                            <div style={{ fontSize: '12px', fontWeight: '400' }}>
                                <div style={{ backgroundColor: '#FCFCFC' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
                                        <div style={{ width: '10%', }}>
                                            <CircularProgressbar value={percentage}
                                                text={`${Number(percentage).toFixed(2)}%`}
                                            />
                                        </div>
                                        <div>
                                            <div style={{ fontSize: '15px', fontWeight: '500' }}>Storage</div>
                                            <div style={{ fontSize: '13px', fontWeight: '300' }}>
                                                {`Used ${(serviceDetails?.[0].sd.used).toFixed(4)} GB out of ${(serviceDetails?.[0].sd.limit).toFixed(4)} GB`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> : <></>}

                    <div style={{ border: '1px solid #8080804a', borderRadius: '7px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#E1EDFF', padding: '15px 25px' }}>
                            <div style={{ fontSize: '13px', fontWeight: '500' }}> Drones </div>
                        </div>
                        <div style={{ backgroundColor: '#FCFCFC', padding: '15px 25px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ fontSize: '13px', fontWeight: '400' }}>Drones</div>
                                <div style={{ fontSize: '13px', fontWeight: '400' }}>{droneDetails?.registerDrones}/{droneDetails?.totalDrones} (Drones) </div>
                            </div>
                            <div style={{
                                height: '10px',
                                backgroundColor: '#C9C9C9',
                                borderRadius: '5px',
                            }}>
                                <div style={{
                                    width: `${(((Number(droneDetails?.registerDrones) / Number(droneDetails?.totalDrones)) * 100).toFixed(6)) || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#2D75DD',
                                    borderRadius: '10px'
                                }}>
                                </div>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <div style={{ fontSize: '13px', fontWeight: '400' }}>Total Drones - {droneDetails?.totalDrones} {droneDetails?.addOnDrones > 0 && <span style={{ fontSize: "10px", opacity: "0.8" }}>(AddOn Drones - {droneDetails?.addOnDrones})</span>} </div>
                                <div style={{ fontSize: '13px', fontWeight: '400' }}>Register Drones - {droneDetails?.registerDrones}</div>
                                <div style={{ fontSize: '13px', fontWeight: '400' }}>Available Drones - {droneDetails?.availableDrones}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
}

export default SubscriptionModel;
