
import React, { Component, useEffect } from "react";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from '../redux'
import AppConstants from "../AppConstants";
import SearchIcon from '../Icon/Search.png'
import closeButton from '../assets/toolbarIcons/closeToolbar.svg'
import * as API from '../api.js';
import Loader from '../icons/loader.svg';
import PicStork from '../icons/AeroCapture.svg';
import DroneNaksha from '../icons/AeroFarm.svg';
import AeroGCS from '../icons/AeroGCSEnterprise.svg';
import PicStorkInactive from '../icons/AeroCaptureInactive.png';
import DroneNakshaInactive from '../icons/AeroFarmInactive.png';
import AeroGCSInactive from '../icons/AeroGCSInactive.png';
import SearchDark from '../Icon/Search.png'

class GlobalSearch extends Component {
  constructor(props) {


    super(props);
    this.state = {
      redirect: false,
      searchResult: [],
      searchString: '',
      selectedService: '',
      filters: ['Flights', 'Imagesets', 'Plans', 'Projects'],
      selectedFilters: ['Flights', 'Imagesets', 'Plans', 'Projects'],
      services: [AppConstants.SERVICES.PICSTORK, AppConstants.SERVICES.DRONENAKSHA, AppConstants.SERVICES.AEROGCS],
      redirectURL: '',
      loading: true,
      noContentMessage: "No search results found",
      location: props.location,
      user: props.user,
      type: props.type,
      stateData: null,
      selectedType: null,
      selectServicePopup: false,
      DNIcon: DroneNakshaInactive,
      GCSIcon: AeroGCSInactive,
      PSIcon: PicStorkInactive,
      recentSearches: this.props.recentSearches && this.props.recentSearches.data ? this.props.recentSearches.data : []
    };
  }

  componentWillMount() {
  }

  search(key) {
    this.setState((state) => ({
      ...state,
      searchString: key
    }), () => this.getSearchResult())
  }

  getSearchResult() {
    let { searchString, selectedFilters, selectedService } = this.state
    if (searchString.length > 1) {
      this.setState((state) => ({
        ...state,
        loading: true
      }))
      let path = this.state.location.pathname
      // let service = path.indexOf(AppConstants.SERVICES.PICSTORK.toLowerCase()) > -1 ? AppConstants.SERVICES.PICSTORK :
      //   path.indexOf(AppConstants.SERVICES.DRONENAKSHA.toLowerCase()) > -1 ? AppConstants.SERVICES.DRONENAKSHA :
      //     path.indexOf('aerogcsenterprise') > -1 ? AppConstants.SERVICES.AEROGCS : selectedService
      API.globalSearch(searchString, selectedFilters).then((result) => {
        if (result) {
          let keys = Object.keys(result)
          for (let i = 0; i < keys.length - 1; i++)
            result[keys[i]].showMore = false
        }
        this.setState((state) => ({
          ...state,
          searchResult: result,
          loading: false
        }))
      }, (e) => {
        console.log(e);
        this.setState((state) => ({
          ...state,
          loading: false,
          noContentMessage: "Cannot fetch data"
        }))
      })
    }
  }

  close = (key, service) => {
    if (service != AppConstants.SERVICES.AEROGCS) {
      this.props.updateProfileDetails({
        activeTeamId: '',
        activeTeam: '',
        isLoggedInUserOwner: false,
      })
    }
    let recentSearches = JSON.parse(localStorage.getItem("recentSearches"))
    recentSearches = recentSearches ? recentSearches : { data: [] }
    let ispresent = recentSearches.data.indexOf(this.state.searchString)
    if (ispresent == -1) {
      if (recentSearches.data.length == 5)
        recentSearches.data.splice(0, 1) // to remove 1st element & only 5 recent searches in the array
      recentSearches.data.push(this.state.searchString)
      localStorage.setItem("recentSearches", JSON.stringify(recentSearches))
    }
    else {
      recentSearches.data.splice(ispresent, 1) // to remove existing element & store recent searches in the array
      recentSearches.data.push(this.state.searchString)
      localStorage.setItem("recentSearches", JSON.stringify(recentSearches))
    }
    if (this.state.location.pathname == this.getURLToRedirect(key, service ? service : this.state.selectedService, this.state.stateData?.tasktype)) {
      window.location.reload()
    }
    this.props.close()
  }

  onChangeListener = (e) => {
    let t = e.target;
    this.setState((state) => ({
      ...state,
      [t.id]: t.value,
      selectedService: t.id
    }))
  }

  showPopup = (key, data) => {
    let path = this.state.location.pathname
    let selectedService = path.indexOf(AppConstants.SERVICES.PICSTORK.toLowerCase()) > -1 ? AppConstants.SERVICES.PICSTORK :
      path.indexOf(AppConstants.SERVICES.DRONENAKSHA.toLowerCase()) > -1 ? AppConstants.SERVICES.DRONENAKSHA :
        path.indexOf('aerogcsenterprise') > -1 ? AppConstants.SERVICES.AEROGCS : AppConstants.SERVICES.PICSTORK
    this.setState((state) => ({
      ...state,
      selectedType: key,
      stateData: data,
      selectedService: selectedService,
      selectServicePopup: true
    }))
  }

  closeServicePopup = () => {
    this.setState((state) => ({
      ...state,
      selectServicePopup: false,
      selectedService: ''
    }))
  }

  changeIconOnHover = (service, icon) => {
    if (service == "DN") {
      this.setState((state) => ({
        ...state,
        DNIcon: icon
      }))
    } else if (service == "PS") {
      this.setState((state) => ({
        ...state,
        PSIcon: icon
      }))
    } else if (service == "GCS") {
      this.setState((state) => ({
        ...state,
        GCSIcon: icon
      }))
    }
  }

  getURLToRedirect = (selectedType, service, taskType) => {
    var url = ''
    let selectedService = service
    // FOR REDIRECTING WITHIN THE SERVICE 
    // selectedService = path.indexOf(AppConstants.SERVICES.PICSTORK.toLowerCase()) > -1 ? AppConstants.SERVICES.PICSTORK :
    //   path.indexOf(AppConstants.SERVICES.DRONENAKSHA.toLowerCase()) > -1 ? AppConstants.SERVICES.DRONENAKSHA :
    //     path.indexOf('aerogcsenterprise') > -1 ? AppConstants.SERVICES.AEROGCS : this.state.selectedService
    if (selectedType == 'Tasks') url = `${taskType === 1 ? '/picstork/trainingTask' : (taskType === 2 ? '/picstork/detectionTask' : '/picstork/annotationReportTask')}`;
    else if (selectedType == 'Maps') url = '/dronenaksha/view_plan';
    else if (selectedType == 'Flights') url = '/remote_mission/flightLogs';
    else if (selectedType == 'Imagesets') url = `${selectedService == AppConstants.SERVICES.PICSTORK ? '/picstork/images' : selectedService == AppConstants.SERVICES.DRONENAKSHA ? '/dronenaksha/images' : '/remote_mission/my_images'}`;
    else if (selectedType == 'Plans') url = `${selectedService == AppConstants.SERVICES.PICSTORK ? '/picstork/plan' : selectedService == AppConstants.SERVICES.DRONENAKSHA ? '/dronenaksha/plan' : '/remote_mission/assignDroneToPlan'}`;
    else if (selectedType == 'Projects') url = `${selectedService == AppConstants.SERVICES.PICSTORK ? '/picstork/project' : selectedService == AppConstants.SERVICES.DRONENAKSHA ? '/dronenaksha/project' : '/plans'}`;
    return url
  }

  toggleShowMore = (key) => {
    let result = this.state.searchResult
    result[key].showMore = !result[key].showMore
    this.setState((state) => ({
      ...state,
      searchResult: result
    }))
  }

  getStates = (key, data) => {
    if (key && data) {
      let states = {
        user: this.state.user,
        type: this.state.type,
        project: { id: data && data.id ? data.id : data.project_id, project_name: data.project_name },
      }
      if (key != "Projects") {
        states.plan = { id: data.plan_id, plan_name: data.plan_name, project_name: data.project_name }
        states.project.project_name = data.project_name
      }
      if ((key == "Imagesets" || key == "Maps") && data) {
        states.layerShow = "RGBShow"
        states.orthoImageCollectionId = data.id
        states.project = { id: data.project_id, project_name: data.project_name, sharedData: data.sharedData, images: data.images_count, collection_id: data.id }
        states.plan = { id: data.plan_id, plan_name: data.plan_name, sharedData: data.sharedData, images: data.images_count, project_name: data.project_name, collection_id: data.id }
        states.collection = data
      }
      if (key == "Plans" && data && (this.state.selectedService == AppConstants.SERVICES.AEROGCS || this.state.location.pathname.indexOf('aerogcsenterprise') > -1)) {
        states.planName = data.plan_name
        states.projectName = data.project_name
      }
      if (key == "Tasks" && data) {
        states.project = { id: data.project_id, project_name: data.project_name, collection_id: data.image_collection_id }
        states.plan = { id: data.plan_id, plan_name: data.plan_name, sharedData: data.sharedData, images: data.images_count, project_name: data.project_name, collection_id: data.image_collection_id }
        states.collection = { id: data.image_collection_id }
        states.task = { task_id: data.task_id }
      }
      return states
    }
    return {}
  }
  render() {
    return (
      <>
        <div className="right-content" style={{ zIndex: 1000, display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", background: "rgba(0,0,0,0.6)", left: "0px" }}>
          <div style={{ zIndex: "1000", position: "absolute", top: "45px", width: "100%", display: "flex", justifyContent: "center" }}>
            <div style={{ height: this.state.searchString.length > 1 ? '87vh' : this.state.recentSearches.length > 0 ? "300px" : '170px', padding: "10px", width: '680px', boxShadow: '0px 0px 5px #a8a8a8', background: "white", borderRadius: "10px" }}>

              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div className="services-content-title" style={{ fontSize: "14px", color: "#0A4ED5", marginTop: "10px", paddingLeft: "26px" }}> Search</div>
                <div style={{ display: "flex", width: "22px", height: "22px", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "#7F7F7F" }}>
                  <img src={closeButton} style={{ height: "10px", width: "10px", cursor: "pointer" }} onClick={this.props.close} />
                </div>
              </div>
              <div className="add-user-form-text-wrapper">
                <img src={SearchIcon} style={{ height: "25px", width: "25px", position: 'relative', left: '588px' }} />
                <input autoFocus type="text" className="add-user-form-text input" placeholder="Search Task, Flight, Map, Imageset, Project or Plan"
                  style={{ height: '30px !important', background: "#EDF7FF", width: "600px", padding: "21px 12px", paddingRight: '35px', border: 'none', borderRadius: "12px", marginTop: "5px" }}
                  value={this.state.searchString}
                  onChange={(event) => this.search(event.target.value)}
                />
              </div>
              {/* Filters */}
              <div style={{ margin: "17px 20px" }}>
                {this.state.filters.map((type, key) => {
                  return (
                    <button style={{ padding: "2px", margin: "3px", outline: "none", border: "none", background: "none", color: "#109cf1", fontSize: "12px" }}>
                      <div className={this.state.selectedFilters.includes(type) ? 'active-tag' : 'inactive-tag'}
                        onClick={() => {
                          let temp = this.state.selectedFilters;
                          if (temp.includes(type))
                            temp.splice(temp.findIndex((data) => data == type), 1)
                          else
                            temp.push(type)
                          this.setState(
                            (state) => ({
                              ...state,
                              selectedFilters: temp,
                            }), () => {
                              this.getSearchResult();
                            }
                          );
                        }}
                      >
                        {type}
                      </div>
                    </button>
                  );
                })}
              </div>

              {/* search result */}
              {
                this.state.searchString.length > 1 ?
                  <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    {!this.state.loading ?
                      this.state.searchResult.totalData > 0 ?
                        <div style={{ width: "100%", marginLeft: "5px", marginTop: "5px", height: "65vh", overflowY: "scroll", }}>
                          {
                            Object.keys(this.state.searchResult).map((key) => {
                              return (
                                this.state.searchResult[key].length > 0 ?
                                  <div style={{ paddingTop: "3px" }}>
                                    <div className="itemKey" style={{ color: "#3c3c3c", fontSize: "10px", fontWeight: "500", padding: "4px 0px 0px 18px" }}>{key} {`(${this.state.searchResult[key].length})`}</div>
                                    <div>
                                      {this.state.searchResult[key].map((data, i) => {
                                        return (
                                          i < 5 || this.state.searchResult[key].showMore ?
                                            <div style={{ margin: "8px 0px" }}>
                                              {
                                                // this.state.location.pathname == '/' &&
                                                (key == 'Projects' || key == 'Plans' || key == 'Imagesets') ?
                                                  <>
                                                    {data.service_name ?
                                                      <Link
                                                        onClick={() =>
                                                          this.close(key, data.service_name ? data.service_name : AppConstants.SERVICES.PICSTORK)
                                                        }
                                                        to={{
                                                          pathname: this.getURLToRedirect(key, data.service_name ? data.service_name : AppConstants.SERVICES.PICSTORK),
                                                          state: this.getStates(key, data)
                                                        }}
                                                      >
                                                        <div
                                                          style={{ padding: "1px 0px 1px 18px", fontSize: "10px" }} className="content-text hover">
                                                          {data.project_name}{data.plan_name ? `/${data.plan_name}` : ''}{data.collection_name ? `/${data.collection_name}` : ''}{data.task_name ? `/${data.task_name}` : ''}
                                                        </div>
                                                      </Link>
                                                      : <div
                                                        onClick={() => this.showPopup(key, data)}
                                                        style={{ padding: "1px 0px 1px 18px", fontSize: "10px" }} className="content-text hover">
                                                        {data.project_name}{data.plan_name ? `/${data.plan_name}` : ''}{data.collection_name ? `/${data.collection_name}` : ''}{data.task_name ? `/${data.task_name}` : ''}
                                                      </div>
                                                    }
                                                  </>
                                                  :
                                                  <Link onClick={() => this.close(key, null)} to={{ pathname: this.getURLToRedirect(key, this.state.selectedService, data.tasktype), state: this.getStates(key, data) }}>
                                                    {key != 'Flights' ?
                                                      <div style={{ padding: "1px 0px 1px 18px", fontSize: "10px" }} className="content-text hover">
                                                        {data.project_name}{data.plan_name ? `/${data.plan_name}` : ''}{data.collection_name ? `/${data.collection_name}` : ''}{data.task_name ? `/${data.task_name}` : ''}
                                                        <span style={{ marginLeft: "10px", color: "#2989cf" }}>{data.service_name ? `(${data.service_name})` : <></>}</span>
                                                      </div>
                                                      : <div style={{ padding: "1px 0px 1px 18px", fontSize: "10px" }} className="content-text hover">
                                                        {data.project_name}{data.plan_name ? `/${data.plan_name}` : ''} : {data.flights}
                                                        <span style={{ marginLeft: "10px", color: "#2989cf" }}>{data.service_name ? `(${data.service_name})` : <></>}</span>
                                                      </div>
                                                    }
                                                  </Link>
                                              }
                                            </div> : <></>
                                        )
                                      })}
                                    </div>
                                    {this.state.searchResult[key].length > 5 ?
                                      <div>
                                        <div onClick={() => this.toggleShowMore(key)} style={{ textAlign: "right", right: "20px", top: "-20px", position: "relative", fontSize: "10px", color: "#2989cf", height: "2px" }} className="content-text hover">{this.state.searchResult[key].showMore ? 'view less' : 'view more'}</div>
                                      </div> :
                                      <></>
                                    }
                                    <hr style={{ margin: "0px 5px 5px 10px", borderTop: "1px solid rgba(102,102,102,.2)", width: "96%" }} />
                                  </div> : <></>
                              )
                            })
                          }
                        </div> :
                        <div className="flex-center" style={{ height: "58vh" }}>{this.state.noContentMessage}</div>
                      :
                      <div className="flex-center" style={{ height: "58vh", width: "100%" }}>
                        <img src={Loader} style={{ height: "95px", width: "auto", marginLeft: "7%" }} />
                      </div>
                    }
                  </div>
                  : this.state.recentSearches.length > 0 ?
                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                      <div style={{ width: "100%", marginLeft: "7px", marginTop: "5px", height: "100px", overflowY: "auto", }}>
                        <div>
                          <div className="itemKey" style={{ color: "#3c3c3c", fontSize: "12px", fontWeight: "500", padding: "0px 0px 0px 14px" }}>Recent searches </div>
                        </div>
                        {
                          this.state.recentSearches.reverse().slice(0, 5).map((data, i) => {
                            return (
                              <div style={{ margin: "5px 0px" }}>
                                {
                                  <div style={{ display: "flex" }}>
                                    <img src={SearchDark} style={{ margin: "0px 8px 1px 16px", height: "18px", width: "18px" }} />
                                    <div onClick={() => { this.search(data) }} style={{ padding: "1px 0px", fontSize: "10px" }} className="content-text hover">{data}</div>
                                  </div>
                                }
                                <hr style={{ margin: "0px 5px 5px 14px", borderTop: "0px solid rgba(102,102,102,.2)", width: "96%" }} />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div> : <></>
              }
            </div>
          </div>

          {
            this.state.searchString.length > 1 && this.state.selectServicePopup ? <>
              {/* Select service popup */}
              <div className="right-content" style={{ zIndex: 1001, display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", background: "rgba(0,0,0,0.6)", left: "0px" }}>

                <div style={{ zIndex: 1002, position: "absolute", height: "90%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ height: '180px', borderRadius: "10px", width: '322px', boxShadow: '0px 0px 5px #a8a8a8', background: "white" }}>
                    <div style={{ background: "#FAFAFA", borderRadius: "10px 10px 0px 0px", padding: "2% 4%", display: "flex", justifyContent: "space-between", alignItems: "center", height: "55px" }}>
                      <div className='content-text' style={{ fontSize: "14px", fontWeight: "500", marginLeft: "3%", color: "#2989cf" }}>
                        <div>Select Service </div>
                      </div>
                      <img src={closeButton} style={{ height: "18px", width: "18px", cursor: "pointer", position: "relative", top: "-14px", right: "-8px" }} onClick={this.closeServicePopup} />
                    </div>
                    <div className='content-text' style={{ margin: "1% 0%", display: "flex", justifyContent: "space-evenly", width: "315px" }}>
                      {
                        this.state.selectedType === "Plans" ? <></>
                          :
                          <div className="flex-center" style={{ width: "100px", flexDirection: "column" }}>
                            <Link onClick={() => this.close(this.state.selectedType, AppConstants.SERVICES.PICSTORK)} onMouseEnter={() => this.changeIconOnHover("PS", PicStork)} onMouseLeave={() => this.changeIconOnHover("PS", PicStorkInactive)} to={{ pathname: this.getURLToRedirect(this.state.selectedType, AppConstants.SERVICES.PICSTORK), state: this.getStates(this.state.selectedType, this.state.stateData) }}>
                              <div style={{ marginTop: "24px" }} >
                                <img src={this.state.PSIcon} style={{ height: "40px", width: "auto" }} />
                              </div>
                              <div className="content-text" style={{ color: "#3c3c3c", marginTop: "5px", fontSize: "10px", fontWeight: "500" }}>
                                {AppConstants.SERVICES.PICSTORK}
                              </div>
                            </Link>
                          </div>}
                      <div className="flex-center" onMouseEnter={() => this.changeIconOnHover("DN", DroneNaksha)} onMouseLeave={() => this.changeIconOnHover("DN", DroneNakshaInactive)} style={{ width: "100px", flexDirection: "column" }}>
                        <Link onClick={() => this.close(this.state.selectedType, AppConstants.SERVICES.DRONENAKSHA)} to={{ pathname: this.getURLToRedirect(this.state.selectedType, AppConstants.SERVICES.DRONENAKSHA), state: this.getStates(this.state.selectedType, this.state.stateData) }}>

                          <div style={{ marginTop: "24px", }} >
                            <img src={this.state.DNIcon} style={{ height: "40px", width: "auto" }} />
                          </div>
                          <div className="content-text" style={{ color: "#3c3c3c", marginTop: "5px", fontSize: "10px", fontWeight: "500" }}>
                            {AppConstants.SERVICES.DRONENAKSHA}
                          </div>
                        </Link>
                      </div>
                      <div className="flex-center hover" onMouseEnter={() => this.changeIconOnHover("GCS", AeroGCS)} onMouseLeave={() => this.changeIconOnHover("GCS", AeroGCSInactive)} style={{ color: "#3c3c3c", width: "98px", flexDirection: "column" }}>
                        <Link onClick={() => this.close(this.state.selectedType, AppConstants.SERVICES.AEROGCS)} to={{ pathname: this.getURLToRedirect(this.state.selectedType, AppConstants.SERVICES.AEROGCS), state: this.getStates(this.state.selectedType, this.state.stateData) }}>
                          <div style={{ marginTop: "24px", }} >
                            <img src={this.state.GCSIcon} style={{ height: "40px", width: "auto" }} />
                          </div>
                          <div className="content-text" style={{ marginTop: "5px", fontSize: "10px", fontWeight: "500" }}>
                            {AppConstants.SERVICES.AEROGCS}
                          </div>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* /-------------------------------------------------------------- */}
            </> : <></>
          }
        </div >
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload))
  };
};


const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    firstTimeLoginGCSE: state.profile.firstTimeLoginGCSE,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);