import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import { Redirect } from 'react-router-dom';
import dateFormat from 'dateformat';
import Loader from './icons/loader.svg';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';
export default class RMFlightLogsByDroneId extends Component {

    state = {
        user: {},
        type: "",
        key: " ",
        fromDate: '-30610245208',
        droneId: '',
        toDate: Date.now(),
        refreshing: false,
        userperpage: 20,
        currentpage: 1,
        totalCount: 0,
        taskdisplay: [],
        logsDisplay: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        loading: true,
        outId: "",
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Logs - RMP";
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        if (this.props.location.state) {
            let { user, type, droneId } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                droneId: droneId
            }))
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }))
            }, (e) => { window.location.replace("/login"); })
        }
    }


    getLogs = () => {
        const { user, type, taskdisplay, currentpage, userperpage, key, counts, fromDate, toDate, droneId } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = taskdisplay.slice(firstpage, lastIndex);
        const totalpages = taskdisplay.length / userperpage;
        let state = this.props.location.state
        API.getFlightLogsByDroneId(droneId, fromDate, toDate, firstpage, userperpage).then(async (data) => {
            let logs = await data.logs;
            let count = await data.count
            let flightLogs = [];
            let counter = 0
            if (data.logs.length == 0) {
                this.setState(state => ({
                    ...state,
                    logsDisplay: data.logs,
                    totalCount: data.count,
                    loading: false
                }))
            }
            data.logs.forEach((element, i) => {
                if (element.data == "undefined" || element.data == undefined) {
                    element.isData = false
                    let temp = new Date(element.timestamp)
                    // element.timestamp = temp
                    flightLogs.push(element)
                    counter++
                } else {
                    let data;

                    data = JSON.parse(element.data)
                    element.isData = true
                    element.data = data;
                    let temp = new Date(element.timestamp)
                    flightLogs.push(element)
                    counter++
                }
                if (counter == data.logs.length) {
                    this.setState(state => ({
                        ...state,
                        logsDisplay: logs,
                        totalCount: count,
                        loading: false
                    }))
                }
            });
        }, (e) => {
            console.log(e);
            this.setState({
                loading: false
            })
        })
    }
    componentDidMount() {
        this.getLogs()
    }
    fromDate = (date) => {
        if (date.length > 0) {
            let d = new Date(date + " 00:00:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                fromDate: date1,
                currentpage: 1
            }),
                () => this.getLogs());
        }
        else {
            this.setState((state) => ({
                ...state,
                fromDate: '-30610245208',
                currentpage: 1
            }),
                () => this.getLogs());
        }
    }
    toDate = (date) => {
        if (date.length > 0) {
            let d = new Date(date + " 23:00:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                toDate: date1,
                currentpage: 1
            }),
                () => this.getLogs());
        } else {
            this.setState((state) => ({
                ...state,
                toDate: Date.now(),
                currentpage: 1
            }),
                () => this.getLogs());
        }
    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            firstPage: key,
            currentpage: 1
        }),
            () => this.getLogs());
    }
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getLogs()
            });
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            }), () => {
                this.getLogs()
            }
            );
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getLogs()
            });
        }
    }
    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.totalCount / this.state.userperpage)
            }), () => {
                this.getLogs()
            });
        }
    }

    changepage(num) {
        if (num >= 1 && num <= (Math.ceil(this.state.totalCount / this.state.userperpage))) {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getLogs());
        }

    }


    render() {
        const { taskdisplay, currentpage, userperpage, sidebarMode } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = taskdisplay.slice(firstpage, lastIndex);
        const totalpages = taskdisplay.length / userperpage;
        let { user, type } = this.props.location.state

        return (
            <div className="wrapper">
                {/* <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">FLIGHT LOGS</div>
                    </div>
                    {this.state.loading ?
                        <div style={{ height: "416px", width: "100%", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                        </div>
                        :
                        <div className="main-content-new" style={{ height: 'calc(100vh - 41px)', minHeight: "calc(100vh - 41px) " }}>
                            <div className="services-content-title">{"Total Logs"} : {this.state.totalCount}</div>
                            <div style={{ display: "flex", marginTop: "20px", marginLeft: "auto", width: "fit-content" }}>
                                <div className="add-user-form-row-left" style={{ marginLeft: "50px" }}>
                                    <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                        <label className='add-user-form-label' style={{ paddingTop: "8px" }}>From Date :</label>
                                        <input
                                            className="add-user-form-text"
                                            type="date"
                                            max='9000-01-01'
                                            onChange={(event) => this.fromDate(event.target.value)}
                                            style={{
                                                marginLeft: "8px",
                                                borderRadius: '10px',
                                                height: "30px",
                                                width: "135px",
                                            }} />
                                    </div>
                                </div>
                                <div className="add-user-form-row-left" style={{ marginLeft: "50px" }}>
                                    <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                        <label className='add-user-form-label' style={{ paddingTop: "8px" }}>To Date :</label>
                                        <input
                                            className="add-user-form-text"
                                            type="date"
                                            max='9000-01-01'
                                            onChange={(event) => this.toDate(event.target.value)}
                                            style={{
                                                marginLeft: "8px",
                                                borderRadius: '10px',
                                                height: "30px",
                                                width: "135px",
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%", height: "calc(100% - 170px)", marginTop: "22px", overflowY: 'auto' }}>
                                <table className="customers-table" style={{ marginTop: "0px" }}>
                                    <col style={{ width: '15%' }} />
                                    <tr className="customers-table-header-row" style={{ color: "#42454a" }}>
                                        <th>Date/Time</th>
                                        <th>Drone ID</th>
                                        <th>Project Name</th>
                                        <th>Plan Name</th>
                                        <th>Details</th>
                                    </tr>
                                    {this.state.logsDisplay.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="5">No Logs Available</td>
                                        </tr> :
                                        this.state.logsDisplay.map((logs_info, id) =>
                                            <tr className="customers-table-data-row"
                                                style={{ height: "45px" }}>
                                                <td
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        window.location.href = '/drone_control_center/fly_view?drone=' + logs_info.droneid;
                                                    }}
                                                >
                                                    {dateFormat(logs_info.timestamp, "dd mmm yyyy")} {new Date(logs_info.timestamp).toLocaleString().split(',')[1]}
                                                </td>
                                                <td >{logs_info.droneid}</td>
                                                <td >{logs_info.project_name}</td>
                                                <td >{logs_info.plan_name}</td>
                                                {logs_info.isData ? <td>
                                                    {logs_info.data.lat == undefined ? 'not found' : ('lattitude:' + logs_info.data.lat + ', longitude:' + logs_info.data.lon)}
                                                </td> : <td>{"not found"}
                                                </td>}
                                            </tr>
                                        )}
                                </table>
                            </div>
                            <footer className="footer" style={{ visibility: (this.state.totalCount > 20 ? "visible" : "hidden") }} >
                                <div style={{ float: "left", marginTop: "2rem", display: "flex" }}>
                                    <div style={{ color: "#109cf1", marginTop: "11px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                        Page : {currentpage}
                                    </div>
                                </div>
                                <div style={{ float: "right", marginTop: "2rem", display: "flex" }}>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            First
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Prev
                                        </div>
                                    </button>
                                    <form>
                                        <div style={{ display: "flex" }}>
                                            <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                                Go to page :
                                            </div>
                                            <div className="add-user-form-text-wrapper">
                                                <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }}
                                                    onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                                    onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                            </div>
                                        </div>
                                    </form>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Next
                                        </div>
                                    </button>
                                    <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                        <div className="addcustomer-button-empty-dashboard" >
                                            Last
                                        </div>
                                    </button>
                                </div>
                            </footer>
                        </div>}
                </div>
            </div>
        );
    }
}
