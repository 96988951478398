import React, { useState, useEffect } from 'react'
import './RecentFligthsReplay.css'
import closebtn from '../../../icons/crossIcon.svg'
import backBtn from '../../../Icon/backbutton.png'
import * as API from '../../../api.js';
import SingleMap from '../../../ReusableComponents/map/index.js';
import dateFormat from 'dateformat';
import L, { layerGroup, marker } from "leaflet";
import Loader from "../../../icons/loader.svg"
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent.js';
import NoContent from '../../../ReusableComponents/NoContent.js';

export default function RecentFligthsReplay({ flightID, onClickFlight, handleScrollActive }) {

    const [flights, setFlights] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [hasMoreItems, setHasmore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(true)

    const getFlightsReplay = () => {
        API.getFlightsReplay(flightID, pageNumber, limit)
            .then((data) => {
                if (data.flights) {
                    if (data.flights < limit || data.flights == 0) setHasmore(false)
                    let dataLogs = data.flights
                    for (let i = 0; i < dataLogs.length; i++) {
                        let annotations = []
                        if (pageNumber == 1) {
                            if (i < 10) dataLogs[i]['visible'] = true
                            else dataLogs[i]['visible'] = false
                        }

                        let fence = dataLogs[i].fence_boundary ? JSON.parse(dataLogs[i].fence_boundary) : []
                        let plan = dataLogs[i].plan_boundary ? JSON.parse(dataLogs[i].plan_boundary) : []
                        let trail = dataLogs[i].trailData ? dataLogs[i].trailData : []
                        if (fence.length > 2) annotations.push(new L.Polygon(fence, { color: 'red', fillOpacity: 0, }))

                        if (plan.length > 2) annotations.push(new L.Polygon(plan, { color: 'yellow', fillOpacity: 0, }))

                        if (trail.length > 0) annotations.push(new L.Polygon(trail, { color: 'orange', fillOpacity: 0, }))
                        dataLogs[i]['annotations'] = annotations
                    }
                    setPageNumber(pageNumber + 1)
                    setLoading(false)
                    setFlights(flights.concat(dataLogs))
                }
            })
            .catch(err => { console.log("error :", err) })
    }

    useEffect(() => { getFlightsReplay() }, []);

    const handleScroll = () => {
        handleScrollActive()
        flights.map((f, i) => {
            if (f.id != flightID) {
                const div = document.getElementById(f.id)
                if (isElementInViewport(div)) {
                    flights[i]['visible'] = true
                } else {
                    flights[i]['visible'] = false
                }
            }
        })
    };

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
            rect.right > 0
        );
    };

    return <>
        {!isOpen && <div style={{
            position: "fixed",
            right: "0",
            zIndex: "12",
            width: "30px",
            background: "white",
            top: "75px",
            opacity: "0.5",
            textAlign: "center",
            cursor: "pointer"
        }}
            onClick={() => { setIsOpen(!isOpen) }}
        >  <img src={backBtn} alt='close videos' style={{ width: "15px" }} /></div >}
        <div className={`replay_fligths_wrapper ${isOpen ? 'open' : ''}`}>
            <div style={{ background: "rgba(117, 117, 117, 0.1)", height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", borderBottom: "1px solid #b9b7b7" }}>
                    <div style={{ fontSize: "13px", fontWeight: "500" }}> Recent Flights </div>
                    <img onClick={() => { setIsOpen(!isOpen) }} src={closebtn} alt='close videos' style={{ width: "10px", cursor: "pointer" }} />
                </div>
                {loading ? <LoaderComponent />
                    : <div style={{ height: "calc(100% - 60px)" }}>
                        {flights.length > 0 ? < InfiniteScroll
                            className='scroll-container constainerHeight'
                            height={'100vh'}
                            onScroll={() => { handleScroll() }}
                            dataLength={flights.length} //This is important field to render the next data
                            next={() => {
                                setHasmore(true)
                                getFlightsReplay()
                            }}
                            hasMore={hasMoreItems}
                            loader={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <img src={Loader} style={{ width: "30px", }} />
                            </div>}
                        >
                            {flights?.map((flight, i) => {
                                if (flight.id !== flightID) {
                                    return <div
                                        id={flight.id}
                                        style={{ margin: "15px 10px", cursor: 'pointer' }}
                                        onClick={() => { onClickFlight(flight) }}>
                                        <div style={{
                                            position: "relative",
                                            zIndex: "1",
                                            color: "white",
                                            fontSize: "13px",
                                            marginBottom: "-30px",
                                            padding: "5px 10px",
                                        }}>Flight replay</div>
                                        <div style={{ height: "100px", width: "100%" }}>
                                            {flight.visible && <SingleMap
                                                id={`map-${i}`}
                                                setBaseLayerToState={() => { }}
                                                initCenter={[19.993982, 73.790416]}//lat: 19.993982, lng: 73.790416
                                                initZoom={22}
                                                handleMapClick={() => { }}
                                                maxZoom={22}
                                                setMap={(map) => { }}
                                                disableDragging
                                                disableZoom
                                                style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }}
                                                annotations={flight.annotations}
                                            />}
                                        </div>
                                        <div style={{ background: "#353232", width: "100%", height: "45px", padding: "5px", borderBottomLeftRadius: "7px", borderBottomRightRadius: "7px" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px" }}>
                                                <div style={{ color: "white", fontSize: "12px" }}>{flight?.project_name}/{flight?.plan_name}</div>
                                                <div style={{ fontSize: "10px", color: "white" }}>{`${dateFormat(flight?.last_activity, "dd/mmm/yyyy")}`}</div>
                                            </div>
                                            <div style={{ color: "white", fontSize: " 10px", opacity: " 0.7", }}>{flight?.id}</div>
                                        </div>
                                    </div>
                                }
                            })}
                        </InfiniteScroll>
                            : isOpen ? <NoContent /> : <></>
                        }
                    </div>}
            </div >
        </div >
    </>
}
