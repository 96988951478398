import React, { Component } from 'react';
import Logo from "./icons/logo.png";
import * as API from './api.js';
import './style.css';
import loaderGIF from './res/loader.gif';
import Help from "./sidebaricon/Help.png";
import Settings from './sidebaricon/SettingsActive.png';
import footer from './res/footer.jpg';
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import AppConstants from './AppConstants';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';

export default class Invoice extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        invoiceID: this.props.location.state.invoiceId,
        activityPopupObj: {
            item: undefined,
            open: undefined,
            msg: undefined,
        }
    }


    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentDidMount() {
        if (this.props.location.state.invoice) {
            let { invoice } = this.props.location.state
            console.log(invoice)
            let product = invoice.product
            let basePrice = invoice.price
            //let additionalCost = product.additional_cost * invoice.units
            //let subTotal = basePrice + additionalCost
            let discount = basePrice * (invoice.discount / 100)
            discount = discount.toFixed(2)
            let discounted = basePrice - discount
            //let tax = discounted * (product.tax_percent / 100)
            let total = discounted.toFixed(2)

            this.setState({
                invoice,
                basePrice,
                //additionalCost,
                //subTotal,
                discount,
                //discounted,
                //tax,
                total,
                loading: false
            })
        } else
            API.getInvoice(this.state.invoiceID).then(res => {
                let { invoice } = res
                let product = invoice.product
                let basePrice = invoice.price
                //let additionalCost = product.additional_cost * invoice.units
                //let subTotal = basePrice + additionalCost
                let discount = basePrice * (invoice.discount / 100)
                discount = discount.toFixed(2)
                let discounted = basePrice - discount
                //let tax = discounted * (product.tax_percent / 100)
                let total = discounted.toFixed(2)

                this.setState({
                    invoice,
                    basePrice,
                    //additionalCost,
                    //subTotal,
                    discount,
                    //discounted,
                    //tax,
                    total,
                    loading: false
                })
            }).catch(e => {
                this.setState({
                    activityPopupObj: {
                        item: 'ERROR',
                        open: true,
                        msg: "Currupted Invoice",
                    }
                })
            })
    }

    openCheckout = (successHandler, verificationFailedHandler, paymentFailedHandler) => {
        let options = {
            "key": "rzp_test_zHFPt3QKW4mk9p",
            "amount": this.state.total * 100,
            "name": "PDRL",
            "currency": "USD",
            "description": this.state.invoice.product,
            "image": Logo,
            "order_id": this.state.invoice.payment.razorpayorderid,
            "handler": function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
                API.settlePayment(
                    response.razorpay_order_id,
                    response.razorpay_payment_id,
                    response.razorpay_signature
                )
                    .then(response2 => {
                        successHandler()
                    }).catch(e => {
                        console.log("VERIFICATION ERROR", e)
                        verificationFailedHandler(`OID: {this.state.invoice.currency_symbol}{response.razorpay_order_id} PID: {this.state.invoice.currency_symbol}{response.razorpay_payment_id}`)
                    })
            },
            "prefill": {
                "name": this.state.user.first_name + " " + this.state.user.last_name,
                "email": this.state.user.username
            },
            "notes": {
            },
            "theme": {
                "color": "#59afff"
            }
        };
        //   let Razorpay = 'https://checkout.razorpay.com/v1/checkout.js';
        let rzp = new window.Razorpay(options);
        rzp.on('payment.failed', function (response) {
            paymentFailedHandler(`OID: {this.state.invoice.currency_symbol}{response.razorpay_order_id} PID: {this.state.invoice.currency_symbol}{response.razorpay_payment_id}`)
        });
        rzp.open();
    }

    componentWillMount() {
        document.title = `Invoice - ${this.state.invoiceID} - AeroMegh`;
        if (this.props.location.state) {
            let { user, type, organizationID, modelNumber } = this.props.location.state;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }

            this.setState((state) => ({
                ...state,
                user,
                type,
                organizationID,
                modelNumber
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/",
                // },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                    selected: true,
                },
                {
                    icon: Help,
                    text: "Help",
                    path: "/feedback",
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            user.username ? <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    <ActivityPopup
                        item={this.state.activityPopupObj?.item}
                        open={this.state.activityPopupObj?.open}
                        msg={this.state.activityPopupObj?.msg}
                        close={() => window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))}
                    />

                    {this.state.redirectToInvoicePage && <Redirect to={{
                        pathname: `/invoice`,
                        state: this.state
                    }}></Redirect>}
                    <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}>
                        <div className="top-bar-text-new">Invoice</div>
                    </div>
                    <div className="main-content">
                        <div className="print-button-wrapper">
                            <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                window.print();
                            }}>
                                Print Invoice
                            </button>
                            {/* {!this.state.loading && <button className="add-user-form-submit-button" style={{ width: "max-content", margin: "0px 0px 0px auto", opacity: this.state.invoice.payment.settled_time ? "0.5" : "1" }} disabled={this.state.invoice.payment.settled_time} onClick={() => {
                                this.openCheckout(
                                    () => {
                                        alert("Payment successfully!")
                                        if (this.state.invoice.product.name.includes("AEROMEGH")) {
                                            API.activateAeroMeghPlanSubscriptionOrder(this.state.invoice.id).then(res => {
                                                alert("Your new subscription has been activated. Please log in again.")
                                                window.location.href = "/logout";
                                            }).catch(e => {
                                                alert(e)
                                            })
                                        }
                                    },
                                    (e) => {
                                        alert("Payment verification failed. Error: " + e)
                                    },
                                    (e) => {
                                        alert("Payment failed. Error: " + e)
                                    }
                                )
                            }}>
                                {this.state.invoice.payment.settled_time ? "Paid" : "Pay"}
                            </button>} */}

                        </div>
                        <div className="page" style={{ padding: "0px", height: "auto", minHeight: "600px", paddingBottom: "0px", width: "627px", minWidth: "627px" }}>
                            <div id="invoice-page">
                                {
                                    this.state.loading ? <img src={loaderGIF} style={{ margin: "auto" }} /> : <div id="invoice-contents">
                                        <div style={{ height: "100px", "width": "100%", display: "flex" }}><h3 style={{ margin: "auto", fontWeight: "bold" }}>INVOICE</h3></div>
                                        <table id="invoice-metadata-table">
                                            <tr id="invoice-metadata-table-row1">
                                                <th id="invoice-metadata-table-row1-invoice">ID</th>
                                                <th id="invoice-metadata-table-row1-created">Date: </th>
                                                <td id="invoice-metadata-table-row1-created-date">{new Date(this.state.invoice?.payment?.date_time || this.state.invoice.date_time).toLocaleDateString()}</td>
                                            </tr>
                                            <tr id="invoice-metadata-table-row2">
                                                <th id="invoice-metadata-table-row1-id">{this.state.invoice?.invoice_id || this.state.invoice.id}</th>
                                                <th id="invoice-metadata-table-row1-paid">Status: </th>
                                                <td id="invoice-metadata-table-row1-paid-date">{this.state.invoice?.payment?.payment_status || this.state.invoice.payment_status}</td>
                                            </tr>
                                        </table>
                                        <div id="invoice-pdrl">
                                            <h6>
                                            </h6>
                                        </div>
                                        <table id="invoice-product-table">
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS && <tr>
                                                <th>Product</th>
                                                <td>{`${this.state.invoice.name} (${Number(this.state.invoice.credits) / 1024} GB)`}</td>
                                            </tr>}
                                            <tr>
                                                <th>{this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS ? "Payment id" : "Subscription:"}</th>
                                                <td>{this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS ? this.state.invoice.payment_id : this.state.invoice.product.replaceAll("_", " ").split(" ").map(word => word.split("").map((letter, i) => i === 0 ? letter.toUpperCase() : letter.toLowerCase()).join("")).join(" ")} </td>
                                            </tr>
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.SERVICES_PLAN && <tr>
                                                <th>Subscription id:</th>
                                                <td>{this.state.invoice.payment?.subscription_id} </td>
                                            </tr>}
                                        </table>
                                        <table id="invoice-cost-breakdown-table">
                                            <tr style={{ margin: "1% 0%" }}>
                                                <th className="tr-left">Description</th>
                                                <th className="tr-right">Amount</th>
                                            </tr>
                                            <tr>
                                                <td className="tr-left">Price</td>
                                                <td className="tr-right">{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state.basePrice}</td>
                                            </tr>
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS && <>
                                                <tr>
                                                    <td colspan="2">
                                                        <hr />
                                                    </td>
                                                </tr> <tr>
                                                    <td className="tr-left">Quantity</td>
                                                    <td className="tr-right">×{this.state.invoice.units}</td>
                                                </tr>
                                            </>}
                                            {/* <tr>
                                                <td className="tr-left">Additional Cost</td>
                                                <td className="tr-right">+{this.state.invoice.currency_symbol}{this.state.additionalCost}</td>
                                            </tr> */}


                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td className="tr-left"><h5>Sub-total</h5></td>
                                                <td className="tr-right">{this.state.invoice.currency_symbol}{this.state.subTotal}</td>
                                            </tr> */}
                                            <tr>
                                                <td className="tr-left">Discount</td>
                                                <td className="tr-right">-{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state.discount}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td className="tr-left"><h5>Discounted</h5></td>
                                                <td className="tr-right">{this.state.invoice.currency_symbol}{this.state.discounted}</td>
                                            </tr> */}
                                            {/* <tr>
                                                <td className="tr-left">Tax</td>
                                                <td className="tr-right">+{this.state.invoice.currency_symbol}{this.state.tax}</td>
                                            </tr> */}

                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tr-left"><h4>Total</h4></td>
                                                <td className="tr-right">{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state?.invoice?.units ? (Number(this.state.invoice.units) * this.state.total) : this.state.total}</td>
                                            </tr>
                                        </table>
                                    </div>
                                }
                            </div>
                            <img src={footer} style={{ height: "auto", width: "100%" }} />
                        </div>


                    </div>
                </div>
            </div> : <div />
        );
    }
}
