import React, { useEffect, useState } from 'react';
import "./FlightDetails.css"
import dateFormat from 'dateformat';
import { getFormatedDate } from '../../ReusableComponents/reusableFunctions';
import Button from '../../ReusableComponents/Button/Button';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import closeIcon from "../../icons/blackClose.png"

const FlightDetailsBanner = ({ user, type, planName, projectName, requestId, date, flightID, startTime, endTime, avgAltitude, avgSpeed, onReplayFlight, onViewDetaiils, areaCovered, areaCoveredMethod, distanceCovered, droneId, firmwareVersion, greenBuildNumber, droneName, droneUIN, software_edition, plotArea, reportData }) => {
    const [showReport, setShowReport] = useState(false);
    return <>
        {reportData && <AnimatedModal
            isOpen={showReport}
            height={"360px"}
            width={"500px"}
        >
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '5px' }}>
                <div style={{ fontSize: '15px', fontWeight: '500', letterSpacing: '1px', color: 'black', padding: '5px 10px' }}>Report Details</div>
                <img
                    id="close-popup"
                    src={closeIcon} style={{
                        width: "auto",
                        height: "22px",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer"
                    }} onClick={() => { setShowReport(false) }} />
                <hr style={{ margin: '5px 0px' }} />
                <div style={{ width: '100%', height: 'calc(100% - 40px)', overflow: 'auto', padding: '0px 30px' }}>
                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Customer Name</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.customer_name ? reportData?.customer_name.length > 20 ?
                                `${reportData?.customer_name.substring(0, 20)}...` :
                                `${reportData?.customer_name}` : 'Unknown'}</div>
                        </div>
                        <div style={{ width: '40%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Crop Type</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.crop_type ? reportData?.crop_type.length > 20 ?
                                `${reportData?.crop_type.substring(0, 20)}...` :
                                `${reportData?.crop_type}` : 'Unknown'}</div>
                        </div>
                    </div>

                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Project Name</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.project_name ? reportData?.project_name.length > 20 ?
                                `${reportData?.project_name.substring(0, 20)}...` :
                                `${reportData?.project_name}` : 'Unknown'}</div>
                        </div>
                        <div style={{ width: '40%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Plan Name</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.plan_name ? reportData?.plan_name.length > 20 ?
                                `${reportData?.plan_name.substring(0, 20)}...` :
                                `${reportData?.plan_name}` : 'Unknown'}</div>
                        </div>
                    </div>

                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Flight Time</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.flight_time}</div>
                        </div>
                        <div style={{ width: '40%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Sprayed Area</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.spray_area} Acr</div>
                        </div>
                    </div>

                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Amount</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.amount} Rs.</div>
                        </div>
                        <div style={{ width: '40%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Created Date</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{dateFormat(reportData?.created_datetime, 'dd/mm/yyyy')}</div>
                        </div>
                    </div>
                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Pesticide</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.pesticide ? reportData?.pesticide.length > 10 ? `${reportData?.pesticide.substring(0, 10)}...` : `${reportData?.pesticide}` : "NA"}</div>
                        </div>
                        <div style={{ width: '40%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Spray Volume</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.spray_volume ? reportData?.spray_volume.length > 10 ? `${reportData?.spray_volume.substring(0, 10)}...` : `${reportData?.spray_volume}` : "NA"}</div>
                        </div>
                    </div>
                    <div style={{ margin: '5px 0px 0px 0px', display: 'flex', width: '100%' }}>
                        <div style={{ width: '60%' }}>
                            <div className="grid_title" style={{ textAlign: "left", lineHeight: '20px' }}>Plot Area</div>
                            <div className="grid_data" style={{ textAlign: "left", lineHeight: '25px' }}>{reportData?.plot_area ? `${reportData?.plot_area} Acr` : 'NA'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedModal>}
        <div className='flight_details_banner_container'>
            <div className='flight_details_banner_header' >
                <div className='flight_details_banner_header_right' style={{ width: '60%' }} >

                    <div className='grid_container' style={{ width: "50%" }}>
                        <div className='grid_data' style={{ color: 'white', fontSize: "20px" }}> {`${projectName} / ${planName}`}</div>
                        <div className='grid_title' style={{ color: 'white' }}>  {flightID}</div>
                    </div>

                    {requestId && <div className='grid_container' style={{ width: "50%" }}>
                        <div className='grid_data' style={{ color: 'white', fontSize: "20px" }}>Request ID</div>
                        <div className='grid_title' style={{ color: 'white' }}>{requestId}</div>
                    </div>}

                </div>
                <div className='flight_details_banner_header_left'  >
                    {flightID && reportData && <Button
                        className={'new-btn-lg'}
                        isBtnActive={true}
                        text={"Report"}
                        onClick={() => { setShowReport(true) }}
                    />}

                    <Button
                        className={'new-btn-lg'}
                        isBtnActive={true}
                        text={"Replay"}
                        onClick={onReplayFlight}
                    />

                    <Button
                        className={'new-btn-lg'}
                        isBtnActive={true}
                        text={"View Details"}
                        linkData={{
                            pathname: '/remote_mission/detailedlog',
                            state: {
                                user, flightId: flightID,
                                planName: planName,
                                projectName: projectName,
                                date: date,
                                startTime: startTime,
                                endTime: endTime,
                                avgAltitude: avgAltitude,
                                avgSpeed: avgSpeed,
                                onViewDetaiils: onViewDetaiils,
                                areaCovered: areaCovered,
                                areaCoveredMethod: areaCoveredMethod,
                                distanceCovered: distanceCovered,
                                droneId: droneId,
                                firmwareVersion: firmwareVersion,
                                greenBuildNumber: greenBuildNumber,
                                droneName: droneName,
                                droneUIN: droneUIN,
                                software_edition: software_edition,
                                plotArea: plotArea,
                            }
                        }}
                    />
                </div>
            </div>
            <div className='flight_details_banner_info' style={{ display: "flex", flexDirection: 'column', padding: "20px 30px", justifyContent: "center", background: "#fcfcfc" }}>
                <div className='flight_details_banner_data'>
                    <div className='grid_container'>
                        <div className='grid_title'>Start Time
                            {!startTime && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{startTime ? dateFormat(startTime, 'hh:MM:ss TT') : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>End Time
                            {!endTime && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{endTime ? dateFormat(endTime, 'hh:MM:ss TT') : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Duration
                            {!(startTime && endTime) && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{(startTime && endTime) ? getFormatedDate(startTime, endTime) : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Date
                            {!date && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{date ? dateFormat(date, "dd mmm yyyy") : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>{software_edition === 'GREEN' ? 'Sprayed Area'
                            : software_edition === 'ORANGE' ? 'Survey Area'
                                : `${areaCoveredMethod && areaCoveredMethod != 'null' ? `Sprayed Area (by ${areaCoveredMethod})` : 'Area'}`}</div>
                        {!areaCovered && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        <div className='grid_data'>{areaCovered ? `${areaCovered} Acres` : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Plot Area
                            {!plotArea && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{plotArea ? `${plotArea} Acres` : 'NA'}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Avg Altitude
                            {!avgAltitude && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{avgAltitude ? `${avgAltitude.toFixed(2)} m` : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Avg Speed
                            {!avgSpeed && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{avgSpeed ? `${avgSpeed.toFixed(2)} m/sec` : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Drone UIN
                            {!droneUIN && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{droneUIN ? droneUIN : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Drone ID
                            {!droneId && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{droneId ? droneId : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Drone Name
                            {!droneName && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{droneName ? droneName : "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>Firmware Version
                            {!firmwareVersion && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{firmwareVersion || "NA"}</div>
                    </div>
                    <div className='grid_container'>
                        <div className='grid_title'>{software_edition === 'GREEN' || areaCoveredMethod && areaCoveredMethod != 'null' ? 'AeroGCS GREEN Build Number'
                            : software_edition === 'ORANGE' ? 'AeroGCS ORANGE Build Number'
                                : 'AeroGCS Build Number'}
                            {!greenBuildNumber && <span className='grid_sub_title' style={{ marginRight: "5px", fontSize: '15px' }}> * </span>}
                        </div>
                        <div className='grid_data'>{greenBuildNumber ? greenBuildNumber : "NA"}</div>
                    </div>
                </div>

                <div style={{ marginTop: '20px' }}>

                    {!droneName &&
                        <div className='grid_sub_title' style={{ color: "black", display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <div className='grid_sub_title' style={{ marginRight: "5px", paddingTop: '1vh' }}> * </div>
                            <div>Note : {`${droneId} `}This drone is currently not registered</div>
                        </div>
                    }

                    {(!plotArea || !droneUIN || !droneId || !droneName || !firmwareVersion || !avgSpeed || !avgAltitude || !areaCovered || !startTime || !endTime) && <div className='grid_sub_title' style={{ color: "black", display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div className='grid_sub_title' style={{ marginRight: "5px", paddingTop: '1vh' }}> * </div>
                        <div>NA : You may be using older version of {software_edition === 'GREEN' || areaCoveredMethod && areaCoveredMethod != 'null' ? 'AeroGCS GREEN'
                            : software_edition === 'ORANGE' ? 'AeroGCS ORANGE'
                                : 'AeroGCS'}</div>
                    </div>}

                </div>
            </div>
        </div>
    </>


}

export default FlightDetailsBanner