import React, { useState } from 'react';
import expandArrows from "../assets/expandArrowsWhite.png";
import closeIcon from '../assets/toolbarIcons/closeToolbar.svg'
import playBtn from '../icons/Play.png'
import pauseBtn from '../icons/Pause.png'
const MakeDragable = ({ children, height, width, bottom, right, zIndex, Title, onClickAction, dragWindow = true, onCloseAction, pauseReplay, pausePlayOnClickAction, onHover }) => {
    const [iconShow, setIconShow] = useState(false)

    const dragElement = (elmnt) => {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    const onMouseEnter = (e) => {
        setIconShow(e)
        onHover(e)
    }

    return <div id="mydiv"
        onLoad={() => { dragWindow && dragElement(document.getElementById("mydiv")) }}
        onMouseEnter={() => { onMouseEnter(true) }}
        onMouseLeave={() => { onMouseEnter(false) }}
        style={{
            position: "fixed",
            bottom: bottom || "30px",
            right: right || "30px",
            zIndex: zIndex || "99",
            boxShadow: "5px 5px 8px grey",
            height: height || "160px",
            width: width || "280px",
            overflow: 'hidden',
            borderRadius: '6px',
            background: 'white'
        }}>
        {iconShow && <>
            <div style={{
                position: 'absolute',
                zIndex: "2",
                height: "calc(100% - 50px)",
                width: "100%",
                background: '#08142580',// '#08142566',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {pausePlayOnClickAction && pauseReplay ?
                    <img src={playBtn} style={{ position: 'absolute', width: "25px", height: '25px', cursor: "pointer", top: '50%', zIndex: "9" }} onClick={() => { pausePlayOnClickAction() }} />
                    : <img src={pauseBtn} style={{ position: 'absolute', width: "20px", height: '25px', cursor: "pointer", top: '50%', zIndex: "9" }} onClick={() => { pausePlayOnClickAction() }} />}
                {onClickAction && <img src={expandArrows} style={{ position: 'absolute', width: "20px", height: '20px', cursor: "pointer", left: '15px', top: '15px', zIndex: "9" }} onClick={() => { onClickAction() }} />}
            </div>
        </>}
        {(Title || onCloseAction) && <div id="mydivheader" style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 5px",
            position: 'absolute',
            top: '5px',
            left: '0px',
            margin: '0px',
        }} >
            {Title && <div style={{ fontSize: '16px', fontWeight: '600', color: 'white', zIndex: "1", opacity: iconShow ? 0.4 : 1 }}>{Title}</div>}
            <div style={{ position: Title ? '' : 'absolute', right: Title ? '' : "10px", zIndex: "3", }}>
                {onCloseAction && <img src={closeIcon} style={{ height: "15px", cursor: "pointer", margin: '0px 5px' }} onClick={() => { onCloseAction() }} />}
            </div>
        </div>}
        {children}
    </div>
}

export default MakeDragable;