import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

const Dropdown = ({ top, left, width, listLeft, listWidth, listHeight, maxHeight, show, list, closeDropdown, selectedItem, isDisabled, textAlign, isBtnList }) => {

  let myDropdownStyle = {
    position: 'absolute',
    textTransform: "capitalize",
    border: '1px solid #ccc',
    width: listWidth ? listWidth : width || 'max-content',
    padding: '0',
    lineHeight: "14px",
    backgroundColor: 'white',
    overflow: 'auto',
    zIndex: '99',
    top: top || "10px",
    left: listLeft ? listLeft : left,
    height: listHeight,
    maxHeight,
    textAlign: textAlign
  }
  let myBtnDropdownStyle = {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    rowGap: "5px",
    position: 'absolute',
    textTransform: "capitalize",
    width: '100%',
    padding: '0',
    lineHeight: "14px",
    top: "10px",
    left: "0px",
    overflow: 'auto',
    zIndex: '99',
    height: listHeight,
    maxHeight,
    textAlign: textAlign,
  }
  return (
    show && !isDisabled ? <>
      <div id="dropDownOverlay" style={{
        position: "absolute", width: '100%', height: '100%', zIndex: 2, left: 0, top: 0,

      }} onClick={closeDropdown}></div>
      <div style={{
        position: 'relative',
      }}>
        <div style={isBtnList ? myBtnDropdownStyle : myDropdownStyle}>
          {list && list.length ?
            list.map((item, key) => {
              return isBtnList ?
                <ButtonWithIcon
                  backgroundColor={"#F5F6F8"}
                  isDisabled={item.isDisabled}
                  text={item.name}
                  onClick={() => {
                    closeDropdown()
                    if (item.onClick) item.onClick()
                  }}
                />
                : <div className="dropdownItem" style={{
                  display: "flex", position: "relative",
                  backgroundColor: item.name === selectedItem ? '#2989cf' : undefined,
                  color: item.name === selectedItem ? 'white' : undefined
                }}
                  onClick={() => {
                    closeDropdown()
                    if (item.onClick && !item.isDisabled) item.onClick()
                  }}
                >
                  {(item.listImg && item.listImg.imgPosition == 'left') &&
                    <img
                      src={item.listImg.imgURL}
                      style={{
                        opacity: item.isDisabled ? "0.5" : "1",
                        left: "5px",
                        height: "15px",
                        width: "15px",
                        top: "5px",
                        marginRight: "5px",
                        ...item.listImg.leftImgStyle
                      }}
                    />
                  }

                  <div key={key}
                    style={{ opacity: item.isDisabled ? "0.5" : "1" }}
                  >
                    {item.name}
                  </div>

                  {(item.listImg && item.listImg.imgPosition == 'right') &&
                    <img
                      src={item.listImg.imgURL}
                      style={{
                        opacity: item.isDisabled ? "0.5" : "1",
                        position: "absolute",
                        right: "5px",
                        height: "15px",
                        width: "15px",
                        top: "5px",
                        ...item.listImg.rightImgStyle
                      }}
                    />}

                </div>
            })
            : <></>}
        </div >
      </div >
    </> : <></>
  );
}

export default Dropdown