import React, { Component, createRef, useRef } from "react";
import * as API from "./api.js";
import "./style.css";
import ProjectsInActive from './icons/ProjectsInactive.png';
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png';
import DronesActive from "./dronesActive.png";
import RPAS from "./rpas.png";
import MenuDark from './icons/MenuDark.png';
import { Redirect, withRouter } from "react-router-dom";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import TableView from "./ReusableComponents/TableView/TableView.js";
import { getFlightTime } from "./AeroGCSEnterprise/Flights/Flights.js";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import dateFormat from 'dateformat';
import Button from "./ReusableComponents/Button/Button.js";
import { GCS_FEATURES, getFeaturesPermissions } from "./Teams/index.js";
import { Popup, addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import { AnimatedModal } from './ReusableComponents/AnimatedModal.js';
import flightReplay from "./replayVideo.svg";
import lockIcon from './userAuth/assets/lock.svg'
import eyeIcon from './userAuth/assets/eye.svg'
import eyeOffIcon from './userAuth/assets/eye-off.svg'
import closeBtn from "./icons/close.png"
import DroneErrorLogsBtn from "./ReusableComponents/ErrorLogsBtn.js";

let otpInterval = null


class DrnMgmtDroneDetails extends Component {
  state = {
    user: {},
    type: "",
    deleteRedirect: false,
    drone: {},
    model: {},
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    showUnregisterDronePopup: false,
    addLeaseDronePopUp: false,
    usersCustomers: [],
    selectedCustomer: '',
    selectedCustomerName: '',
    fromDate: '',
    toDate: '',
    lease: [],
    edit: false,
    editLease: '',
    minDate: new Date(),
    lease_drone: false,
    leaseActivityPopup: false,
    droneRentEdit: false,
    rentAmount: 0,
    isLoading: true,
    filter: {
      searchString: ' ',
      frmDate: new Date(2016, 0, 1).setHours(0, 0, 0, 1),
      toDate: new Date().setHours(23, 59, 59, 999)
    },
    activityPopup: undefined,
    pageSize: 15,
    pageNumber: 1,
    permissions: undefined,
    deleteData: undefined,
    outId: "",
    activityPopup: undefined,
    deleteFlightLogs: false,
    droneDataDeleteOtpPopup: false,
    errorOtp: '',
    showOtp: false,
    otpTimer: ``,
    timeOutFlag: false,
    verifyOtpAndDeleteData: false,
    otp: '',
    droneDelete: false,
  };
  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      activityPopup: undefined,
      deleteData: undefined,
      deleteFlightLogs: false,
      errorOtp: '',
      showOtp: false,
      otpTimer: ``,
      timeOutFlag: false,
      verifyOtpAndDeleteData: false,
      otp: '',
      otpTimer: '',
      droneDelete: false,
    }))
    if (this.state.deleteRedirect) window.history.back();
  }

  unregister = () => {
    this.setState((state) => ({
      activityPopup: {
        open: true,
        msg: "Unregistering Drone , Please Wait...",
        item: "WAIT",
        icon: "WAIT",
      }
    }), () => {
      API.removeGCSDrones(this.state.drone.drone_id)
        .then((results) => {
          console.log("result:", results);
          this.setState((prevState) => ({
            ...prevState,
            activityPopup: {
              open: true,
              msg: "Drone unregistered successfully.",
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }), () => {
            this.getDronesData()
          });
        })
        .catch((e) => {
          this.setState((state) => ({
            activityPopup: {
              open: true,
              msg: e.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          }))
          try {
            console.log(JSON.stringify(e));
          } catch (exception) {
            console.log(e);
          }
        });
    })

  };


  getRPASDetails = () => {
    API.getOwnedDrones(this.state.organizationID, this.state.modelNumber).then(
      (result) => {
        console.log(result);
        let { model, drones } = result;

        drones = [...drones];

        this.setState(
          (state) => ({
            ...state,
            model,
            drones,
            loading: false,
          }),
          () => {
            console.log(this.state);
          }
        );
      },
      (e) => {
        console.error(e);
      }
    );
  };

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    if (this.props.location.state) {
      document.title =
        this.props.location.state.drone.drone_id +
        " - Drone Details - Management Services";
      let { user, type, organizationID, drone } = this.props.location.state;
      getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        this.setState({
          user,
          type,
          drone,
          permissions,
        }, async () => {
          this.getDronesData()
          this.getFlightsData()
          const flightsStats = await API.getGCSFlightStats({ drones: [this.state.drone.drone_id] })
          this.setState({ totalFlights: flightsStats.total_flights })
        });
      })
    } else {
      addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
      window.location.replace(
        process.env.REACT_APP_CLIENT_PROTOCOL +
        "://" +
        process.env.REACT_APP_CLIENT_HOST +
        (process.env.REACT_APP_CLIENT_PORT
          ? process.env.REACT_APP_CLIENT_PORT
          : "") +
        "/management_services/dashboard"
      );
      return;
    }
  }

  deleteDroneRedirectComponent = () => {
    if (this.state.deleteRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/drone_management/drones",
            state: {
              user: this.state.user,
              type: this.state.type,
              sidebarMode: this.state.sidebarMode,
            },
          }}
        />
      );
    }
  };

  getDronesData = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        const result = await API.gcsDrones(this.state.drone.id)
        console.log("result : ", result);
        result.drones.map((drone1, key) => {
          if (drone1.drone_id == this.state.drone.drone_id) {
            this.setState(state => ({
              ...state,
              drone: drone1,
              isLoading: false
            }))
          }
        })
      })
    }
    catch (e) {
      console.error(e)
      window.alert(e.message || "Something went wrong while getting drone data.")
    }
  }

  getFlightsData = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let { frmDate, toDate } = this.state.filter;
        const flights = await API.getGCSFlights({ drones: [this.state.drone.drone_id], fromDateTime: new Date(frmDate).toISOString(), toDateTime: new Date(toDate).toISOString(), orderBy: undefined, orderType: undefined, pageSize: this.state.pageSize, pageNumber: (this.state.pageNumber), projectName: undefined, planName: undefined })
        this.setState({ flights, isLoading: false })
      })
    }
    catch (e) {
      console.error(e)
      window.alert(e.message || "Something went wrong while getting flights data.")
    }
  }

  deleteFlight = async () => {
    try {
      this.setState({
        activityPopup: {
          open: true,
          msg: 'Deleting flight and related data please wait...',
          item: "WAIT",
          icon: "WAIT",
        }
      })
      const data = await API.deleteFlightData(this.state.deleteData.id)
      this.setState({
        deleteData: undefined,
        activityPopup: {
          open: true,
          msg: data.message,
          item: "COMPLETE",
          icon: "COMPLETE",
        }
      })
      this.getDronesData()
      this.getFlightsData()
      const flightsStats = await API.getGCSFlightStats({ drones: [this.state.drone.drone_id] })
      this.setState({ totalFlights: flightsStats.total_flights })
    }
    catch (e) {
      this.closeActivityPopup()
      Popup.alert("ERROR", e?.message || e)
    }
  }


  deleteDrone = () => {
    this.setState({
      activityPopup: {
        open: true,
        msg: "Deleting drone please wait...",
        item: "WAIT",
        icon: "WAIT",
      }
    }, () => {
      API.deleteDrone(this.state.drone.drone_id).then(async (result) => {
        let res = await result.json()
        if (result.status == 200) {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message,
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }, () => {
            setTimeout(() => {
              window.history.back()
            }, 1000)
          })
        } else if (result.status == 401) {
          this.setState({
            deleteFlightLogs: true,
            activityPopup: {
              open: true,
              msg: `This drone has ${res.flightCount} associated flights. Are you sure you want to delete them as well?`,
              item: "WARNING",
              icon: "WARNING",
            }
          })
        } else {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          })
        }
      }).catch((e) => {
        this.setState({
          activityPopup: {
            open: true,
            msg: e.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      })
    })
  }

  sendOtpForDeleteLogs = () => {
    API.sendOtpForDeleteLogs(this.state.drone.drone_id).then(async (result) => {
      let res = await result.json()
      if (otpInterval) clearInterval(otpInterval);
      if (result.status == 200) {
        let timerData = res.remainTime
        let minutes = Number(timerData.split(':')[0])
        let seconds = Number(timerData.split(':')[1])
        console.log('time', minutes, seconds);
        otpInterval = setInterval(() => {
          let min = `0${minutes}`
          let sec = seconds < 10 ? `0${seconds}` : seconds
          this.setState((state) => ({
            ...state,
            otpTimer: min < 1 ? `Resend OTP in ${min} : ${sec} sec` : `Resend OTP in ${min} : ${sec} min`
          }))
          seconds--;
          if (seconds < 0) {
            minutes--;
            seconds = 59;
            if (minutes < 0) {
              clearInterval(otpInterval);
              this.setState((state) => ({
                ...state,
                otpTimer: ``,
                timeOutFlag: true
              }))
            }
          }
        }, 1000);
        this.setState({ activityPopup: undefined, droneDataDeleteOtpPopup: true, timeOutFlag: false, resend: false, deleteFlightLogs: false })
      } else {
        this.setState({
          activityPopup: {
            open: true,
            msg: res.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      }
    }).catch((e) => {
      this.setState({
        activityPopup: {
          open: true,
          msg: e.message || 'Error',
          item: "ERROR",
          icon: "ERROR",
        }
      })
    })
  }

  verifyOtpAndDeleteDroneDataWithDrone = () => {
    this.setState({
      verifyOtpAndDeleteData: false,
      activityPopup: {
        open: true,
        msg: 'Deleting drone and all associated flights. please wait...',
        item: "WAIT",
        icon: "WAIT",
      }
    }, () => {
      API.verifyOtpAndDeleteDroneDataWithDrone(this.state.drone.drone_id, this.state.otp).then(async (result) => {
        let res = await result.json()
        if (result.status == 200) {
          this.setState({
            droneDataDeleteOtpPopup: false,
            activityPopup: {
              open: true,
              msg: 'The drone and all associated flights have been successfully deleted.',
              item: "COMPLETE",
              icon: "COMPLETE",
            }
          }, () => {
            setTimeout(() => {
              window.history.back()
            }, 1000)
          })
        } else {
          this.setState({
            activityPopup: {
              open: true,
              msg: res.message || 'Error',
              item: "ERROR",
              icon: "ERROR",
            }
          })
        }
      }).catch((e) => {
        this.setState({
          activityPopup: {
            open: true,
            msg: e.message || 'Error',
            item: "ERROR",
            icon: "ERROR",
          }
        })
      })
    })
  }

  render() {
    let { user, type, model, drone, flights, sidebarMode } = this.state;
    let sidebarConfigurations = null;
    sidebarConfigurations = {
      options: [
        // {
        //   icon: service,
        //   text: "Services",
        //   path: "/"
        // },
        {
          icon: AeroGCSInactive,
          text: "AeroGCS",
          path: "/"
        },
        {
          icon: DronesActive,
          text: "Drones",
          path: "/drone_management/drones",
          selected: true,
        },
        {
          icon: RPAS,
          text: "Drone Control Center",
          path: "/drone_control_center/all_drones",
        },
        {
          icon: ProjectsInActive,
          text: "Projects",
          path: "/projects",
        }
      ],
      user,
      type,
      mode: this.state.sidebarMode,
      aeroGCSHelp: true,
    };

    return user.username ? (
      <div className="wrapper">
        {/* <SideBar
          config={sidebarConfigurations}
          parentSidebarSwitch={this.parentSidebarSwitch}
        /> */}
        <div
          className="right-content-new"
          onClick={() => {
            this.setState((state) => ({
              ...state,
              sidebarMode: "shrunk",
            }));
          }}
        >

          <ActivityPopup
            item={this.state.activityPopup?.item}
            open={this.state.activityPopup?.open}
            icon={this.state.activityPopup?.icon}
            msg={this.state.activityPopup?.msg}
            close={() => { this.closeActivityPopup() }}
            onClickOk={() => {
              if (this.state.deleteData) {
                this.deleteFlight()
              } else if (this.state.verifyOtpAndDeleteData) {
                this.verifyOtpAndDeleteDroneDataWithDrone()
              } else if (this.state.deleteFlightLogs) {
                this.setState({
                  activityPopup: {
                    open: true,
                    msg: "Sending OTP to delete flights associated with this drone. Please wait...",
                    item: "WAIT",
                    icon: "WAIT",
                  }
                }, () => {
                  this.sendOtpForDeleteLogs()
                })
              } else if (this.state.drone.is_registered == false && this.state.droneDelete) {
                this.deleteDrone()
              } else {
                this.unregister()
              }
            }}
          />
          <AnimatedModal
            isOpen={this.state.droneDataDeleteOtpPopup}
            height="350px"
            width="500px"
          >
            <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Verify Account</div>
            <img src={closeBtn} style={{
              width: "12px",
              height: "12px",
              cursor: "pointer",
              top: "12px",
              right: '17px',
              position: 'absolute'
            }} onClick={() => {
              this.setState({
                droneDataDeleteOtpPopup: false
              })
              this.closeActivityPopup()
            }} />
            <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />
            <div style={{ width: '100%', textAlign: 'center', lineHeight: '20px', marginTop: '10px', fontSize: '14px', fontWeight: '400', padding: '0px 20px' }}>An OTP to delete the drone-associated data has been sent to your registered email.</div>

            <div class="login-error" id="otpErrorMsg" style={{ height: '30px', fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.errorOtp}</div>

            <div style={{ textAlign: 'center' }}>
              <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px' }} src={lockIcon} />
              <input
                autoFocus
                class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="otp"
                type={this.state.showOtp ? "text" : "password"} onChange={(e) => {
                  this.setState({
                    errorOtp: '',
                    otp: e.target.value
                  })
                }}
                placeholder="OTP" maxlength="6" required />
              <img className="login-password-wrapper-img" id="view-password" style={{ cursor: 'pointer', top: '175px', left: '360px' }}
                src={this.state.showOtp ? eyeOffIcon : eyeIcon} onClick={() => {
                  this.setState({ showOtp: !this.state.showOtp })
                }} />
            </div>
            <div style={{ height: '25px', fontSize: '14px', marginTop: '10px' }}>
              {this.state.otpTimer}
            </div>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              {this.state.timeOutFlag === false ?
                this.state.otp ?
                  <button type="button" class="loginButton" name="otpButton" id="otpButton" onClick={() => {
                    this.setState({
                      verifyOtpAndDeleteData: true,
                      deleteFlightLogs: false,
                      activityPopup: {
                        open: true,
                        msg: 'Are you sure you want to delete this drone and all associated flights?',
                        item: "WARNING",
                        icon: "WARNING",
                      }
                    })
                  }}>Verify</button>
                  : <button type="button" class="loginButton" name="otpButton" id="otpButton" style={{ opacity: '0.5', cursor: 'not-allowed' }}>Verify</button>
                : <button type="button" style={{ opacity: this.state.resend ? '0.5' : '1' }} class="loginButton" name="resendButton" id="resendButton" disabled={this.state.resend}
                  onClick={() => {
                    this.setState({ resend: true })
                    this.sendOtpForDeleteLogs()
                  }}>Resend OTP</button>}
            </div>
          </AnimatedModal>

          <div className="top-bar-new">
            <div className="top-bar-text-new">
              <BreadCrumbs
                data={[
                  {
                    displayName: "AeroGCS",
                    pathname: "/",
                    state: {
                      user
                    }
                  },
                  {
                    displayName: 'Drones',
                    pathname: "/drone_management/drones",
                    state: {
                      user
                    }
                  },
                  {
                    displayName: 'Drone Details',
                  }
                ]} />
            </div>
          </div>
          <div className="main-content-new">
            <>
              <div className="drone-details-card-view" style={{ marginTop: '15px' }}>
                <div className="drone-details-card-view-heading">
                  <div style={{ display: 'flex', gap: "10px" ,alignItems:'center'}}>
                    <div className="drone-details-card-view-heading-text" title={drone.drone_name || "-"}>{drone.drone_name ? drone.drone_name : "-"}</div>
                    <DroneErrorLogsBtn
                      linkState={{
                        drone: { drone_id: drone.drone_id, drone_name: drone.drone_name }
                      }}
                    />
                  </div>

                  {drone.is_registered ? <Button isBtnActive text={'Unregister'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                    onClick={() => {
                      this.setState({
                        activityPopup: {
                          open: true,
                          msg: 'Are you sure you want to unregister this drone ?',
                          item: "WARNING",
                          icon: "WARNING",
                        }
                      })
                    }} />
                    : <Button isBtnActive text={'Delete'} isDisabled={this.state.user.subs.length == 0} className='new-btn-lg'
                      onClick={() => {
                        this.setState({
                          droneDelete: true,
                          activityPopup: {
                            open: true,
                            msg: 'Are you sure you want to delete this drone ?',
                            item: "WARNING",
                            icon: "WARNING",
                          }
                        })
                      }} />}
                </div>
                <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />
                <div className="drone-details-grid" >
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Drone Name</div>
                    <div className="drone-details-card-view-details-div-text" title={drone.drone_name || "-"}>
                      {drone.drone_name ? drone.drone_name : "-"}
                    </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Drone UIN</div>
                    <div className="drone-details-card-view-details-div-text" title={drone.uin || "-"}> {drone.uin || "-"} </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Total Number of flights</div>
                    <div className="drone-details-card-view-details-div-text"> {this.state.totalFlights || 0} </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Pilots worked under this drone</div>
                    <div className="drone-details-card-view-details-div-text"> {drone.pilot_count || 0} </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Drone ID</div>
                    <div className="drone-details-card-view-details-div-text">{drone.drone_id ? drone.drone_id : "-"} </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Registered Date</div>
                    <div className="drone-details-card-view-details-div-text">
                      {dateFormat(drone.registered_date, "dd mmm yyyy") || "-"}
                    </div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Total Number of Hours</div>
                    <div className="drone-details-card-view-details-div-text">  {getFlightTime(drone.total_flight_time) || "-"}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Board Type</div>
                    <div className="drone-details-card-view-details-div-text">{drone.board_type || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">AeroGCS Build Number</div>
                    <div className="drone-details-card-view-details-div-text">{drone.green_build_number || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Device RAM</div>
                    <div className="drone-details-card-view-details-div-text">{drone.device_ram || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Android Version</div>
                    <div className="drone-details-card-view-details-div-text">{drone.android_version || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Device Model Name</div>
                    <div className="drone-details-card-view-details-div-text">{drone.device_model_name || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Firmware Version</div>
                    <div className="drone-details-card-view-details-div-text">{drone.firmware_version || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">License Type</div>
                    <div className="drone-details-card-view-details-div-text">{drone.license_type || 'NA'}</div>
                  </div>
                  <div className="drone-details-card-view-details-div">
                    <div className="drone-details-card-view-details-div-heading">Status</div>
                    <div className="drone-details-card-view-details-div-text">{drone.is_registered ? 'Registered' : 'Unregistered' || 'NA'}</div>
                  </div>
                </div>
              </div>
              <div className="drone-mgt-table-height">
                <TableView
                  isLoading={this.state.isLoading}
                  onStateChange={(changedState) => {
                    this.setState((state) => ({
                      ...state,
                      pageNumber: changedState.pageNumber,
                    }), () => {
                      this.getFlightsData()
                    })
                  }}
                  tableHeading={`Flights`}
                  onClickRow={(flight) => {
                    this.props.history.push('/remote_mission/flight_details', {
                      user: user,
                      flightId: flight.id,
                      planName: flight.plan_name,
                      projectName: flight.project_name,
                      date: flight.end_time,
                      startTime: flight.start_time,
                      end_time: flight.end_time,
                      streamKey: flight.streamkey,
                      areaCovered: flight.area_covered,
                      areaCoveredMethod: flight.area_calculation_method,
                      distanceCovered: flight.distance_covered,
                      droneId: flight.drone_id,
                      firmwareVersion: flight.firmware_version_number,
                      greenBuildNumber: flight.green_build_number,
                      droneName: flight.drone_name,
                      droneUIN: flight.uin,
                      software_edition: flight.software_edition
                    });
                  }}
                  onClickLink={(flight) => {
                    this.props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: this.state.user, replyData: 0 })
                  }}
                  columns={[
                    { Header: "Project Name", accessor: "project_name", width: '11%' },
                    { Header: "Plan Name", accessor: "plan_name", width: '11%' },
                    { Header: "Start Time", accessor: "start_time", width: '11%', isDate: true },
                    { Header: "End Time", accessor: "end_time", width: '11%', isDate: true },
                    { Header: "Total Flight Time", accessor: "total_flight_time", width: '11%' },
                    { Header: "Distance (M)", accessor: "distance_covered", width: '11%' },
                    { Header: "Area (Acr)", accessor: "area_covered", width: '11%' },
                    { Header: "Replay", accessor: "link_data", width: '11%', isLink: true, isCenter: true },
                  ]}
                  count={this.state.totalFlights || 0}
                  data={this.state.flights?.map((flight) => {
                    return ({
                      ...flight,
                      total_flight_time: getFlightTime(flight.total_flight_time),
                      area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                      link_data: { img: flightReplay }
                    })
                  }) || []}
                  activities={{
                    disableDelete: !this.state.permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                    onClickDelete: (flight) => {
                      this.setState({
                        deleteData: flight,
                        activityPopup: {
                          open: true,
                          msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                          item: "WARNING",
                          icon: "WARNING",
                        }
                      })
                    }
                  }}
                />
              </div>

            </>

          </div>
        </div >
      </div >
    ) : (
      <div />
    );
  }
}

export default withRouter(DrnMgmtDroneDetails)