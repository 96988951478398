import React, { useEffect, useState } from "react"
import { deleteFlightData, getGCSFlightPilots, getGCSFlightPilotsGraphStats, getGCSFlightPilotsStats, getGCSFlights } from "../../api"
import InteractiveGraph from "../Components/InteractiveGraph"
import FlightsFilter from "../Components/FlightsFilter/FlightsFilter"
import "../Flights/Flights.css"
import TableView from "../../ReusableComponents/TableView/TableView"
import { useHistory } from "react-router-dom"
import { getFlightTime } from "../Flights/Flights"
import { BreadCrumbs } from "../../ReusableComponents/BreadCrumbs.js"
import { Popup, addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions.js"
import Cookies from "universal-cookie"
import LoaderComponent from "../../ReusableComponents/LoaderComponent.js"
import { useFirstRender } from "../../ReusableComponents/firstRender.js"
import { GCS_FEATURES, getFeaturesPermissions } from "../../Teams/index.js"
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup.js"
import flightReplay from "../../replayVideo.svg";
const Pilots = (props) => {
    const cookies = new Cookies()
    const history = useHistory();
    const { user } = props.location.state
    const [flights, setFlights] = useState(null)
    const [flightsPilotsStats, setPilotsFlightsStats] = useState(null)
    const [flightsPilotsGraphStats, setFlightsPilotsGraphStats] = useState(null)
    const [flightsPilots, setFlightsPilot] = useState(null)
    const [pilotsTableState, setPilotsTableState] = useState(null)
    const [flightsTableState, setFlightsTableState] = useState({
        pageNumber: 1,
        pageSize: 15
    })
    const [filterState, setFilterState] = useState(null)
    const [flightsTableLoading, setFlightsTableLoading] = useState(false)
    const [flightsPilotTableLoading, setFlightsPilotTableLoading] = useState(false)
    const [cookieData, setCookieData] = useState()
    const [permissions, setPermissions] = useState()
    const [activityPopup, setActivityPopup] = useState(undefined)
    const [deleteData, setDeleteData] = useState(undefined)
    const [outId, setOutId] = useState("")



    const getFlights = async () => {
        try {
            setFlightsTableLoading(true)
            const flights = await getGCSFlights({ ...filterState, ...flightsTableState })
            setFlights(flights)
            setFlightsTableLoading(false)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
            setFlightsTableLoading(false)
        }
    }

    const getFlightsStats = async () => {
        try {
            setPilotsFlightsStats(null)
            const flightsPilotsStats = await getGCSFlightPilotsStats({ ...flightsTableState, ...filterState })
            setPilotsFlightsStats(flightsPilotsStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getFlightsGraphStats = async () => {
        try {
            setFlightsPilotsGraphStats(null)
            const flightsPilotsGraphStats = await getGCSFlightPilotsGraphStats(filterState)
            setFlightsPilotsGraphStats(flightsPilotsGraphStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getFlightsPilots = async () => {
        try {
            setFlightsPilotTableLoading(true)
            const flightsPilots = await getGCSFlightPilots({ ...filterState, ...pilotsTableState })
            setFlightsPilot(flightsPilots)
            setFlightsPilotTableLoading(false)
        } catch (e) {
            setFlightsPilotTableLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    useEffect(() => {
        if (filterState) {
            const page_data = cookies.get("page_data");
            const newPageData = { ...page_data, pilots: { ...page_data?.pilots, ...filterState } }
            cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
            getFlightsGraphStats()
            getFlightsStats()
            getFlightsPilots()
            getFlights()
        }
    }, [filterState])

    const firstRender = useFirstRender()
    useEffect(() => {
        if (!firstRender)
            if (flightsTableState) {
                const page_data = cookies.get("page_data");
                const newPageData = { ...page_data, pilots: { ...page_data?.pilots, flightsTablePageNumber: flightsTableState.pageNumber } }
                cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
                getFlights()
            }
    }, [flightsTableState])

    useEffect(() => {
        if (!firstRender)
            if (pilotsTableState) {
                const page_data = cookies.get("page_data");
                const newPageData = { ...page_data, pilots: { ...page_data?.pilots, pilotTablePageNumber: pilotsTableState.pageNumber } }
                cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
                getFlightsPilots()

            }
    }, [pilotsTableState])

    useEffect(() => {
        if (!cookieData) {
            const page_data = cookies.get("page_data");
            const data = page_data?.pilots || {}
            if (data?.pilotTablePageNumber)
                setPilotsTableState(prev => ({ ...prev, pageNumber: data.pilotTablePageNumber }))
            if (data?.flightsTablePageNumber)
                setFlightsTableState(prev => ({ ...prev, pageNumber: data.flightsTablePageNumber }))
            setCookieData(data)
        }
        addUserAnalytic(props.location.pathname).then(id => { setOutId(id) })
        getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => { setPermissions(permissions) })
    }, [])

    useEffect(() => { return () => { updateOutTime(outId) } }, [outId])

    const getCookieData = () => {
        return cookies.get("page_data")
    }

    const closeActivityPopup = () => {
        setActivityPopup(undefined)
    }

    const deleteFlight = async () => {
        try {
            setActivityPopup({
                open: true,
                msg: 'Deleting flight and related data please wait...',
                item: "WAIT",
                icon: "WAIT",
            })
            const data = await deleteFlightData(deleteData.id)
            setActivityPopup({
                open: true,
                msg: data.message,
                item: "COMPLETE",
                icon: "COMPLETE",
            })
            setDeleteData(undefined)
            getFlightsGraphStats()
            getFlightsStats()
            getFlightsPilots()
            getFlights()
        }
        catch (e) {
            setDeleteData(undefined)
            closeActivityPopup()
            Popup.alert("ERROR", e?.message || e)
        }

    }

    return <div className="wrapper" style={{ fontFamily: 'Lato' }}>
        <div className="right-content-new">
            <div className="top-bar-new">
                <div className="top-bar-text-new">
                    <BreadCrumbs
                        data={[
                            {
                                displayName: "AeroGCS",
                                pathname: "/",
                                state: {
                                    user
                                }
                            },
                            {
                                displayName: 'Pilots',
                            }
                        ]} />
                </div>
            </div>
            <div className="main-content-new flights-main-content" >
                <ActivityPopup
                    item={activityPopup?.item}
                    open={activityPopup?.open}
                    icon={activityPopup?.icon}
                    msg={activityPopup?.msg}
                    close={() => { closeActivityPopup() }}
                    onClickOk={() => { deleteFlight() }}
                />
                {
                    cookieData ?
                        <>
                            <FlightsFilter
                                stats={{
                                    "Total Pilots": `${flightsPilotsStats?.totalPilots ? flightsPilotsStats?.totalPilots : 0}`,
                                    "Active Pilots": flightsPilotsStats?.activePilots || 0,
                                    "Inactive Pilots": flightsPilotsStats?.inActivePilots || 0,
                                    "No. of Flights": flightsPilotsStats?.totalFlights || 0,
                                    "Utilize Drones": flightsPilotsStats?.total_drones_utilize || 0,
                                }}
                                selectedTab={props.location.state.interval || cookieData?.selectedTab}
                                selectedDrone={props.location.state.selectedDrone || cookieData?.selectedDrones?.[0]}
                                selectedPilot={cookieData?.selectedPilots?.[0]}
                                defaultFromDateTime={cookieData?.fromDateTime}
                                defaultToDateTime={cookieData?.toDateTime}
                                filterState={{ ...flightsTableState, ...filterState }}
                                onFilterDataChange={(data) => {
                                    if (!data?.firstTime) {
                                        setFlightsTableState(prev => ({ ...prev, pageNumber: 1 }))
                                        setPilotsTableState(prev => ({ ...prev, pageNumber: 1 }))
                                    }
                                    setFilterState(data)
                                }}
                                isPilotEnable={false}
                            />
                            <InteractiveGraph
                                showZoom
                                data={flightsPilotsGraphStats?.length > 1 ? flightsPilotsGraphStats : []}
                                loading={!flightsPilotsGraphStats}
                                lineType={0}
                            />
                            <div style={{ height: 'auto', maxHeight: '100%' }}>
                                <TableView
                                    pageNumber={getCookieData()?.pilots?.pilotTablePageNumber || 1}
                                    showSrNo
                                    isLoading={flightsPilotTableLoading}
                                    onStateChange={(state) => setPilotsTableState(state)}
                                    columns={[
                                        { Header: "Pilot Name", accessor: "pilot_name", width: '25%', isSortable: true },
                                        { Header: "Flights", accessor: "total_flights", width: '20%', isSortable: true },
                                        { Header: "Acres", accessor: "total_area_covered", width: '25%', isSortable: true },
                                        { Header: "Hours Worked", accessor: "total_flight_time", width: '25%', isSortable: true }
                                    ]}
                                    count={flightsPilotsStats?.activePilots}
                                    data={flightsPilots?.map((pilot) => {
                                        return ({
                                            ...pilot,
                                            total_flight_time: getFlightTime(pilot.total_flight_time),
                                            total_area_covered: (Number(pilot.total_area_covered) / 4046.85642).toFixed(4)
                                        })
                                    }) || []}
                                    user={user}
                                />
                            </div>
                            <TableView
                                pageNumber={getCookieData()?.pilots?.flightsTablePageNumber || 1}
                                isLoading={flightsTableLoading}
                                onClickRow={(flight) => {
                                    history.push('/remote_mission/flight_details', {
                                        user: user,
                                        flightId: flight.id,
                                        planName: flight.plan_name,
                                        projectName: flight.project_name,
                                        date: flight.end_time,
                                        startTime: flight.start_time,
                                        end_time: flight.end_time,
                                        streamKey: flight.streamkey,
                                        areaCovered: flight.area_covered,
                                        areaCoveredMethod: flight.area_calculation_method,
                                        distanceCovered: flight.distance_covered,
                                        droneId: flight.drone_id,
                                        firmwareVersion: flight.firmware_version_number,
                                        greenBuildNumber: flight.green_build_number,
                                        droneName: flight.drone_name,
                                        droneUIN: flight.uin,
                                        software_edition: flight.software_edition
                                    });
                                }}
                                onStateChange={(state) => {
                                    setFlightsTableState(state)
                                }}
                                tableHeading={"Flights"}
                                columns={[
                                    { Header: "Project Name", accessor: "project_name", width: '14%', isSortable: true },
                                    { Header: "Plan Name", accessor: "plan_name", width: '13%', isSortable: true },
                                    { Header: "Drone", accessor: "drone_name", width: '13%', isSortable: true },
                                    { Header: "Start Time", accessor: "start_time", width: '13%', isSortable: true, isDate: true },
                                    { Header: "End Time", accessor: "end_time", width: '13%', isSortable: true, isDate: true },
                                    { Header: "Total time", accessor: "total_flight_time", width: '9%', isSortable: true },
                                    { Header: "Distance (M)", accessor: "distance_covered", width: '10%', isSortable: true },
                                    { Header: "Area (Acr)", accessor: "area_covered", width: '10%', isSortable: true },
                                    { Header: "Replay", accessor: "link_data", width: '20%', isLink: true },
                                ]}
                                onClickLink={(flight) => { props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: user, replyData: 0 }) }}
                                count={flightsPilotsStats?.totalFlights}
                                data={flights?.map((flight) => {
                                    return ({
                                        ...flight,
                                        total_flight_time: getFlightTime(flight.total_flight_time),
                                        area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                                        link_data: { img: flightReplay }
                                    })
                                }) || []}
                                activities={{
                                    disableDelete: !permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                                    onClickDelete: (flight) => {
                                        setDeleteData(flight)
                                        setActivityPopup({
                                            open: true,
                                            msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                                            item: "WARNING",
                                            icon: "WARNING",
                                        })
                                    }
                                }}
                                user={user}
                            />
                            <div style={{ width: "100%", height: flightsPilotsStats?.totalFlights >= 15 ? "0" : "25px" }}></div>
                        </>
                        : <LoaderComponent />
                }
            </div>
        </div>
    </div >
}
export default Pilots