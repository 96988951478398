import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import { Redirect } from 'react-router-dom';
import dateFormat from 'dateformat';
import TableView from './ReusableComponents/TableView/TableView.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';

export default class RMPFlightStatusLogs extends Component {

    state = {
        user: {},
        key2: 'AS_FLIGHT_LOG',
        teleData: [],
        type: "",
        loading: true,
        userperpage: 20,
        currentpage: 1,
        totalCount: 0,
        taskdisplay: [],
        logsDisplay: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        activityPopup: undefined,
        filter: {
            pageSize: 15,
            pageNumber: 1,
            key2: 'AS_FLIGHT_LOG'
        },
        outId: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Status Logs - RMP";
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;

            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => { this.getLogs() })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => { this.getLogs() })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }


    getLogs = () => {
        this.setState({ loading: true }, () => {
            let state = this.props.location.state
            let { key2, pageNumber, pageSize, orderBy, orderType } = this.state.filter;

            API.log(state.flightId, key2, pageSize, (pageNumber - 1) * pageSize, orderBy, orderType).then((data) => {
                let count = 0;
                this.setState((state) => ({
                    ...state,
                    loading: false
                }))
                let teleData = []
                let temp1 = data.logs;
                data.logs.forEach(element => {
                    if (element.key == 'AS_FLIGHT_LOG') {
                        let temp = JSON.parse(element.data)
                        temp.time = element.timestamp
                        temp.key = element.key
                        teleData.push(temp)
                        count++
                        if (count == temp1.length) {
                            let totalCount = data.logs[0].totalCount.count
                            this.setState((state) => ({
                                ...state,
                                teleData: teleData,
                                totalCount: totalCount,
                                loading: false
                            }))
                        }
                    }
                });
            }).catch(err => {
                this.setState({
                    loading: false,
                    activityPopup: {
                        open: true,
                        item: "ERROR",
                        icon: "ERROR",
                        msg: err,
                    }
                })
            })
        })
    }

    render() {
        let { user } = this.props.location.state
        return (
            <div className="wrapper">
                <ActivityPopup
                    item={this.state.activityPopup?.item}
                    open={this.state.activityPopup?.open}
                    icon={this.state.activityPopup?.icon}
                    action={this.state.activityPopup?.action}
                    msg={this.state.activityPopup?.msg}
                    close={() => { this.setState({ activityPopup: undefined }) }}
                    onClickOk={() => { }}
                />
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new" style={{ display: "flex" }}>
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "AeroGCS",
                                        pathname: "/",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: "Flights",
                                        pathname: "/aerogcsenterprise/flights",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: "Flight Details",
                                        pathname: "/remote_mission/flight_details",
                                        state: { ...this.props.location.state }
                                    },
                                    {
                                        displayName: 'FLIGHT STATUS LOGS',
                                    }
                                ]} />
                        </div>
                    </div>
                    <div className="main-content-new">
                        <div className="services-content-title" style={{}}>{"Total Logs"} : {this.state.totalCount}</div>
                        <div style={{ display: "flex", marginLeft: "auto", width: "fit-content" }}>
                        </div>
                        <div style={{ marginTop: "22px", }} className="rmp-flight-status-logs-table-height" />
                        <TableView
                            showSrNo
                            isLoading={this.state.loading}
                            columns={[
                                { Header: 'Time', accessor: 'timestamp', width: '20%', isSortable: true },
                                { Header: 'Type', accessor: 'type', width: '20%', },
                                { Header: 'Details', accessor: 'details', width: '55%', },
                            ]}
                            data={this.state.teleData.filter((el, i) => {
                                if (el.key == 'AS_FLIGHT_LOG') {
                                    return el
                                }
                            }).map(logs_info => {
                                return {
                                    timestamp: `${dateFormat(logs_info.time, "dd mmm yyyy h:MM:ss TT")} ${new Date(logs_info.time).toLocaleString().split(',')[1]}`,
                                    type: logs_info.type,
                                    details: logs_info.message,
                                }
                            })}
                            user={user}
                            count={this.state.totalCount}
                            onStateChange={(changedState) => {
                                this.setState({
                                    filter: {
                                        ...this.state.filter,
                                        pageNumber: changedState.pageNumber,
                                        orderBy: changedState.orderBy,
                                        orderType: changedState.orderType,
                                    }
                                }, () => { this.getLogs() })
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
