import React, { Component } from 'react';
import * as API from '../../api.js';
import '../../style.css';
import Loader from '../../icons/loader.svg';
import { SortComponent } from '../../ReusableComponents/SortComponent';
import dateFormat from 'dateformat';
import { SelectComponent } from '../../ReusableComponents/SelectComponent.js';
import { Link } from 'react-router-dom';
import Chart from "react-google-charts";
import AppConstants from '../../AppConstants.js';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';


var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substring(0, 10);
let dt = someDate.getDate()
let date2 = new Date();
date2.setDate(dt + 7)
let dd = date2.toISOString().substring(0, 10)
var today = new Date();
var todayDate = dateFormat(today, "yyyy-mm-dd")
var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
let aa = lastWeek.toISOString().substr(0, 10);
var firstDateOfYear = dateFormat(new Date(new Date().getFullYear(), 0, 1), "yyyy-mm-dd")

const mapOptions = {
    legend: 'none',
    backgroundColor: "#EAEAEA",
    datalessRegionColor: "#FAF9F6",
    colorAxis: { colors: AppConstants.MAP_COLORS },
}

var backgroundColor = [
    "#ebf3ff",
    "#ebf3ff",
    "#ebf3ff",
    "#ebf3ff",
    "#ebf3ff",
    "#ebf3ff"
]

export default class CustomStatistics extends Component {

    state = {
        user: {},
        type: "",
        currentpage: 1,
        userperpage: 10,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        createIcon: "",
        popupMessage: "",
        addemailPopup: false,
        record: null,
        status: true,
        analytics: [],
        showAddemailPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        cancel: false,
        loading: true,
        count: 0,
        userDisplay: [],
        graphUnits: [{ value: "week", label: "This week" }, { value: "month", label: "This month" }, { value: "year", label: "This year" }, { value: 'custom', label: 'Custom' }],
        selectedGraphUnit: this.props.location.state.dateUnit,
        industries: [{ value: "Agriculture", label: "Agriculture" },
        { value: "Construction", label: "Construction" },
        { value: "Oil & Gas", label: "Oil & Gas" },
        { value: "Power & Utility", label: "Power & Utility" },
        { value: "Survey & Mapping", label: "Survey & Mapping" },
        { value: "Other", label: "Other" }],
        selectedValue: {},
        tableLoading: true,
        statType: '',
        statValue: '',
        count: 0,
        fromDate: '-',
        toDate: '-',
        color1: "#85b4ff",
        color2: '#b5d2ff',
        color3: "#ebf3ff",
        barGraphInput: { datasets: [] },
        chartLoading: true,
        outId: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    openAddemailPopup = () => {
        this.setState(state => ({
            ...state,
            record: null,
            status: true,
            addemailPopup: true
        }))
    }

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    initDate = () => {
        let todaysDate = new Date()
        let currentDate = dateFormat(todaysDate, "yyyy-mm-dd")
        function getLastWeek() {
            var today = new Date();
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return lastWeek;
        }

        var lastWeek = getLastWeek();
        var lastWeekMonth = lastWeek.getMonth() + 1;
        var lastWeekDay = lastWeek.getDate();
        var lastWeekYear = lastWeek.getFullYear();
        var lastWeekDisplayPadded = ("0000" + lastWeekYear.toString()).slice(-4) + "-" + ("00" + lastWeekMonth.toString()).slice(-2) + "-" + ("00" + lastWeekDay.toString()).slice(-2);

        this.setState((state) => ({
            fromDate: lastWeekDisplayPadded,
            toDate: currentDate
        }), () => {
            this.onChangeGraphUnit([this.state.selectedGraphUnit])
        })
    }

    onChangeGraphUnit = (unit) => {
        if (unit[0].value == 'week') {
            var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
            let aa = lastWeek.toISOString().substring(0, 10);
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: aa,
                    toDate: todayDate,
                    viewFlag: false,
                    tableLoading: true,
                    selectedGraphUnit: unit[0],
                }), () => this.getStatistics()
            );
        } else if (unit[0].value == 'month') {
            var toDate = new Date()
            var t = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
            var fdate = dateFormat(t, "yyyy-mm-dd")
            this.setState(
                (state) => ({
                    ...state,
                    fromDate: fdate,
                    toDate: todayDate,
                    viewFlag: false,
                    tableLoading: true,
                    selectedGraphUnit: unit[0],
                }), () => this.getStatistics()
            );
        } else if (unit[0].value == 'year') {
            this.setState((state) => ({
                ...this.state,
                fromDate: firstDateOfYear,
                toDate: todayDate,
                viewFlag: false,
                tableLoading: true,
            }), () => this.getStatistics()
            );
        } else {
            this.setState((state) => ({
                ...this.state,
                fromDate: '-',
                toDate: '-',
                selectedGraphUnit: unit[0],
                viewFlag: false,
            }));
        }
    }


    createBarGraphData = () => {
        API.getIndustryData(this.state.fromDate, this.state.toDate).then(data => {
            let sum = data.data.reduce((a, b) => a + b, 0)
            let stepSize = sum > 10 ? Math.floor(sum / 10) : 1
            let labels = ["Agriculture", "Construction", "Oil & Gas", "Power & Utility", "Survey & Mapping", "Other"]
            let barGraphTicks = {
                padding: 7,
                fill: "black",
                display: true,
                fontSize: 12,
                fontFamily: "Poppins",
                beginAtZero: true,
                fontStyle: "normal",
                fontColor: "#666666",
                lineHeight: "14px",
                stepSize: stepSize
            }
            const dummy_data = () => {
                return {
                    labels: labels,
                    datasets: [
                        {
                            data: [0, 0, 0, 0, 0, 0],
                            fill: true,
                            backgroundColor: backgroundColor,
                            borderColor: "#2989cf",
                            borderWidth: 0.5,
                        },
                    ],
                }
            }
            const bar_data = () => {
                return {
                    labels: labels,
                    datasets: [
                        {
                            data: data.data,
                            fill: true,
                            backgroundColor: backgroundColor,
                            borderColor: "#2989cf",
                            borderWidth: 0.5,
                        },
                    ],
                }
            }
            this.setState({
                barGraphInput: dummy_data(),
                barGraphTicks: barGraphTicks,
                chartLoading: false
            })
            this.setState((state) => ({
                ...state,
                barGraphInput: bar_data(),
                barGraphTicks: barGraphTicks,
                chartLoading: false
            }),
                () => {
                    try {
                        document.getElementsByTagName('canvas')[0].className = "flight-graph";
                    } catch (e) {
                    }
                }
            );
        })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        ChartJS.register(BarElement, CategoryScale,
            ArcElement,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,

        );
        document.title = "User statistics";
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        if (this.props.location.state) {
            let { user, type, statType, statValue, fromDate, toDate } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                statType,
                statValue,
                selectedValue: { value: statValue, label: statValue },
                fromDate,
                toDate,
                loading: false
            }), async () => {
                this.createBarGraphData()
                this.getStatistics();
                if (this.state.statType === 'country') {
                    this.getCountries()
                    this.constructGeoChartData()
                }
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    analytics: [],
                    loading: false
                }))
            }, (e) => { window.location.replace("/login"); })
        }
    }

    getCountries = () => {
        API.getCountries().then((data) => {
            let countryData = data.countries.map(obj => {
                return { value: obj.country, label: obj.country }
            })
            this.setState((state) => ({
                countries: countryData
            }))
        }, (e) => {
            console.log(e);
        })
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.userDisplay.sort((a, b) => a.first_name.localeCompare(b.first_name))
        this.setState((state) => ({
            ...state,
            userDisplay: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.userDisplay.sort((a, b) => b.first_name.localeCompare(a.first_name))
        this.setState((state) => ({
            ...state,
            userDisplay: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.userDisplay.sort((a, b) => a.created_on.localeCompare(b.created_on))
        this.setState((state) => ({
            ...state,
            userDisplay: sort,
        }));
    }

    constructGeoChartData = () => {
        API.getCountryData(this.state.fromDate, this.state.toDate).then(result => {
            this.setState((state) => ({
                ...state,
                mapLoading: false,
                countryData: result.data
            }))
        })
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.userDisplay.sort((a, b) => b.created_on.localeCompare(a.created_on))
        this.setState((state) => ({
            ...state,
            userDisplay: sort,
        }));
    }
    capitalize = (s) => {
        return s[0].toUpperCase() + s.slice(1);
    }


    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getStatistics());
    }

    onChangeStat = (item) => {
        this.setState({
            tableLoading: true,
            statValue: item[0].value
        }, () => {
            this.getStatistics()
        })
    }

    getSignupRecords() {
        const { role, fromDate, toDate } = this.state
        let country = ' '
        let industry = ' '
        if (this.state.statType === 'country') country = this.state.statValue
        if (this.state.statType === 'industry') industry = this.state.statValue
        API.downloadUserRecords(industry, country, role == "" ? " " : role, fromDate, toDate)
            .then(data => {
                this.setState((state) => ({
                }))
            })
    }

    getStatistics = async () => {
        if (this.state.fromDate !== '-' && this.state.toDate !== '-') {
            await API.getCustomStats(this.state.statType, this.state.statValue, this.state.fromDate, this.state.toDate).then((data) => {
                this.setState((state) => ({
                    ...state,
                    userDisplay: data.users,
                    count: data.users.length,
                    tableLoading: false
                }), () => {
                    if (this.state.statType === 'country') {
                        this.getCountries()
                        this.constructGeoChartData()
                    } else {
                        this.createBarGraphData()
                    }
                })
            }, (e) => {
                console.log(e);
            })
        } else {
            this.setState({
                tableLoading: false
            })
        }
    }

    render() {
        const { id, user, type, userDisplay } = this.state;
        // const lastIndex = currentpage * userperpage;
        // const firstpage = lastIndex - userperpage;
        // const currentuser = userDisplay.slice(firstpage, lastIndex);
        // const totalpages = userDisplay.length / userperpage;

        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <div className="right-content-new" >
                    <div className="top-bar-new">
                        <div className="top-bar-text-new">{this.state.statType === 'country' ? `User ${this.capitalize(this.state.statType)} Statistics` : `User ${this.capitalize(this.state.statType)} Statistics`}</div>
                    </div>
                    <div className="main-content-new" style={{ height: "93%" }}>
                        <div style={{ margin: "1% 0%" }}>

                            <div style={{ width: '100%' }}>
                                <div style={{ height: "380px", background: "white" }}>
                                    <div style={{ height: "100%" }}>

                                        <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>
                                            {this.state.chartLoading ?
                                                <div style={{ height: "67vh", width: "100%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                                    <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                                </div>
                                                :
                                                this.state.statType === 'country' ?

                                                    <div style={{ overflow: "hidden", width: '91vw', position: 'relative' }}>
                                                        <Chart
                                                            chartType="GeoChart"
                                                            width="100%"
                                                            height="400px"
                                                            chartEvents={[
                                                                {
                                                                    eventName: "select",
                                                                    callback: ({ chartWrapper }) => {
                                                                        const chart = chartWrapper.getChart();
                                                                        const selection = chart.getSelection();
                                                                        if (selection.length === 0) return;
                                                                        const region = this.state.countryData[selection[0].row + 1];

                                                                        if (region.length > 0 && this.state.fromDate !== '-' && this.state.toDate !== '-') {
                                                                            this.setState({
                                                                                statValue: region[0],
                                                                                tableLoading: true
                                                                            }, () => {
                                                                                this.getStatistics()
                                                                            })
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                            data={this.state.countryData}
                                                            options={mapOptions} />
                                                    </div>
                                                    :
                                                    this.state.barGraphInput?.datasets?.length > 0 ?
                                                        <div style={{ overflow: "hidden", width: '98%', height: '90%', position: 'relative' }}>
                                                            <Bar
                                                                width={"70vw"}
                                                                height={"20vh"}
                                                                onElementsClick={element => {

                                                                    if (element.length > 0 && this.state.fromDate !== '-' && this.state.toDate !== '-') {
                                                                        console.log(element[0])
                                                                        for (var i = 0; i < backgroundColor.length; i++) {
                                                                            backgroundColor[i] = '#ebf3ff';
                                                                        }
                                                                        backgroundColor[element[0]._index] = '#2989cf';
                                                                        this.setState((state) => ({
                                                                            statValue: element[0]._view.label,
                                                                            tableLoading: true,
                                                                        }), () => {
                                                                            this.getStatistics()
                                                                        })
                                                                    }
                                                                }}
                                                                options={{
                                                                    maintainAspectRatio: false,
                                                                    responsive: true,
                                                                    labels: {
                                                                        font: {
                                                                            family: "Poppins",
                                                                            size: "10",
                                                                            style: "normal",
                                                                            color: "#666666",
                                                                        },
                                                                    },
                                                                    plugins: {
                                                                        legend: {
                                                                            display: false,
                                                                        },
                                                                    },
                                                                    elements: {
                                                                        point: {
                                                                            pointStyle: 'circle',
                                                                            backgroundColor: "#2989CF",
                                                                        }
                                                                    },
                                                                    scales: {
                                                                        min: 0,
                                                                        x:
                                                                        {
                                                                            barPercentage: 0.5,
                                                                            gridLines: {
                                                                                color: "#2989cf",
                                                                                display: true,
                                                                                drawOnChartArea: false,
                                                                                drawTicks: false,
                                                                                lineWidth: 1
                                                                            },
                                                                            ticks: {
                                                                                padding: 5,
                                                                                fill: "black",
                                                                                display: true,
                                                                                fontSize: 8,
                                                                                fontFamily: "Poppins",
                                                                                fontStyle: "normal",
                                                                                fontColor: "#666666",
                                                                                lineHeight: "auto",
                                                                                beginAtZero: false
                                                                            },
                                                                            scaleLabel: {
                                                                                display: true,
                                                                                fontSize: 12,
                                                                                fontFamily: "Poppins",
                                                                                fontStyle: "normal",
                                                                                fontColor: "#666666",
                                                                                labelString: "Industry",
                                                                                borderColor: "#666666",
                                                                            },
                                                                        },
                                                                        y:
                                                                        {
                                                                            barPercentage: 1,
                                                                            gridLines: {
                                                                                color: "#2989cf",
                                                                                value: "none",
                                                                                display: true,
                                                                                drawOnChartArea: false,
                                                                                drawTicks: false,
                                                                                lineWidth: 1
                                                                            },
                                                                            ticks: this.state.barGraphTicks,
                                                                            scaleLabel: {
                                                                                fontSize: 12,
                                                                                fontFamily: "Poppins",
                                                                                fontStyle: "normal",
                                                                                fontColor: "#666666",
                                                                                display: true,
                                                                                labelString: "Users",
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                                data={this.state.barGraphInput}
                                                            />
                                                        </div>
                                                        : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1%' }}>
                                <div className="services-content-title">Users  <span className='data-count'>({this.state.count})</span></div>
                                {/* {this.state.fromDate != '-' && this.state.toDate != '-' ?
                                <span className='data-count' style={{ textAlign: "center" }}>
                                    : {dateFormat(this.state.fromDate, "dd-mm-yyyy") + "  to  " + dateFormat(this.state.toDate, "dd-mm-yyyy")}
                                </span> : <></>} */}
                                <div>
                                    <div className="addcustomer-button-empty-dashboard" style={{ padding: '8px 15px', cursor: "pointer", opacity: userDisplay.length > 0 ? 1 : 0.5 }} onClick={() => {
                                        if (userDisplay.length > 0) {
                                            this.getSignupRecords()
                                        }
                                    }}>Download data</div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", width: '22%', justifyContent: 'flex-start' }}>
                                    <div className="services-content-title">{this.state.statValue}</div>
                                    {/* <SelectComponent
                                        selected={[this.state.selectedValue]}
                                        itemsList={this.state.statType === "industry" ? this.state.industries : this.state.countries}
                                        onchangeHandler={(value) => { this.onChangeStat(value) }}
                                        fontSize={"13px"}
                                        fontWeight={500}
                                        border={true}
                                        disabled={this.state.loading}
                                    /> */}
                                    <div style={{ position: "relative", marginLeft: '10px' }}>
                                        <SelectComponent
                                            selected={[this.state.selectedGraphUnit]}
                                            itemsList={this.state.graphUnits}
                                            onchangeHandler={(value) => { this.onChangeGraphUnit(value) }}
                                            fontSize={"13px"}
                                            fontWeight={500}
                                            border={true}
                                            disabled={this.state.loading}
                                            width={"110px"}
                                        />
                                    </div>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>

                                        {this.state.selectedGraphUnit.value === 'custom' ?
                                            <div className="add-user-form-text-wrapper" style={{ display: "flex", paddingLeft: "30px", alignItems: 'center' }} >
                                                <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "14px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>From:</label>
                                                <input
                                                    id="dateRequired"
                                                    name="dateRequired"
                                                    defaultValue={this.state.fromDate}
                                                    className="add-user-form-text2"
                                                    // style={{border:'1px solid blue !important'}}
                                                    type="date"
                                                    max='9000-01-01'
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        this.setState((state) => ({
                                                            ...state,
                                                            fromDate: value,
                                                            viewFlag: false,
                                                            selectedGoStyle: {}
                                                        }))
                                                    }}
                                                    value={this.state.fromDate}
                                                    required
                                                    style={{
                                                        marginLeft: "5px",
                                                        height: "30px",
                                                        borderRadius: '10px',
                                                        paddingLeft: '5px',
                                                        width: "auto",
                                                    }}
                                                />
                                                <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "14px", paddingTop: "8px", marginLeft: "15px", fontWeight: '600' }}>To:</label>
                                                <input
                                                    id="dateRequired"
                                                    name="dateRequired"
                                                    defaultValue={this.state.toDate}
                                                    className="add-user-form-text2"
                                                    type="date"
                                                    max='9000-01-01'
                                                    onChange={(e) => {

                                                        let value = e.target.value;
                                                        this.setState((state) => ({
                                                            ...state,
                                                            toDate: value,
                                                            viewFlag: false,
                                                            selectedGoStyle: {}
                                                        }),
                                                        )

                                                    }}
                                                    value={this.state.toDate}
                                                    required
                                                    style={{
                                                        marginLeft: "5px",
                                                        height: "30px",
                                                        borderRadius: '10px',
                                                        paddingLeft: '5px',
                                                        width: "auto",
                                                    }}
                                                />

                                                <div className="add-user-form-submit-button"
                                                    style={{ height: "30px", margin: "2px 0px 2px 20px", cursor: this.state.fromDate != '-' && this.state.toDate != '-' ? "pointer" : 'not-allowed', opacity: this.state.fromDate != '-' && this.state.toDate != '-' ? 1 : 0.5, textAlign: "center", padding: "10px 20px 10px 20px" }}
                                                    onClick={() => {
                                                        if (this.state.fromDate != '-' && this.state.toDate != '-') {
                                                            this.setState(
                                                                (state) => ({
                                                                    ...state,
                                                                    tableLoading: true,
                                                                    viewFlag: true,
                                                                }), () => this.getStatistics()
                                                            )
                                                        }
                                                    }}
                                                >View</div>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>
                            </div>


                        </div>


                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <div style={{ width: "100%", height: '75vh', overflowY: "scroll", marginTop: "15px" }}>
                                <table className="customers-table" style={{ marginTop: '0' }}>
                                    <tr className="customers-table-header-row" >
                                        <th>Name
                                            <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                sortAscending={this.sortByName}
                                                sortDecending={this.reverseByName}
                                                sortASC={false}
                                            />
                                        </th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Industry</th>
                                        <th>Country</th>
                                        <th>Role</th>
                                        <th>Signup date
                                            <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                sortAscending={this.sortByDate}
                                                sortDecending={this.reverseByDate}
                                                sortASC={false}
                                            />
                                        </th>
                                    </tr>
                                    {
                                        this.state.tableLoading ?
                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                <td colspan="7">  <img src={Loader} style={{ margin: "auto", height: '6%', width: '6%' }} /></td>
                                            </tr>
                                            :
                                            userDisplay.length === 0 ?
                                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                    <td colspan="7">No Users Available</td>
                                                </tr> :

                                                userDisplay.map((user_info) =>

                                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                        <td>
                                                            <Link style={{ color: "#707683" }} to={{
                                                                pathname: `/analytictool/userdetails`,
                                                                state: { user, type, user_info, sidebarMode: this.state.sidebarMode }
                                                            }}>
                                                                {(user_info.first_name + " " + user_info.last_name).length > 20 ? (user_info.first_name + " " + user_info.last_name).substring(0, 20) : user_info.first_name + " " + user_info.last_name}
                                                            </Link></td>
                                                        <td>{user_info.username.length > 30 ? `${user_info.username.substring(0, 30)}...` : user_info.username}</td>
                                                        <td>{user_info.phone ? user_info.phone : "-"}</td>
                                                        <td>{user_info.industry ? user_info.industry : "-"}</td>
                                                        <td>{user_info.country ? user_info.country : "-"}</td>
                                                        <td>{user_info.user_role ? user_info.user_role : "-"}   </td>
                                                        <td>{user_info.created_on ? dateFormat(user_info.created_on, 'dd mmm yyyy hh:MM:ss TT') : "-"}</td>
                                                    </tr>
                                                )}

                                </table>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}
