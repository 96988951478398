import React from 'react'

export default function Replay({replay,srNo}) {
    const {
        thumbnail,
        time,
        title,
        creator,
    } = replay
    return (
        <div className='single_replay_wrapper'>
            <div className='replay_first_section'>
             <div className='sr_number'>{srNo}</div>
            </div>
            <div className='replay__second__section'>
                    <img src={thumbnail} alt={"thumbnail"} />
                <div className='video_time'>
                    {time}
                </div>
            </div>
            <div className='replay__third__section'>
                <p>{title}</p>
            </div>
        </div>
    )
}
