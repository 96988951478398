import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { v4 as uuid } from "uuid"
const cookie = new Cookies()

function arrayCompare(arr1, arr2) {
  return arr1.map(elem => arr2.includes(elem)).every(e => e)
}

function getWSConnectionString(topics) {
  return `${process.env.REACT_APP_KAFKA_MIDDLE_PROTOCOL + "://" + process.env.REACT_APP_KAFKA_MIDDLE_HOST + (process.env.REACT_APP_KAFKA_MIDDLE_PORT ? process.env.REACT_APP_KAFKA_MIDDLE_PORT : "") + "?server=temp?type=receiver?flightId=" + topics.toString() + "?token=ABCD"}`
}

export default function DCCFlyViewUserPinger(props) {
    useEffect(() => {
    	let { userID, droneID, pingersCallback } = props
	
		let ws = new WebSocket(getWSConnectionString([`${userID}-DCCFVVs-${droneID}`]))
		let currentSessionID = cookie.get('DCCFVVs-sessionID')
		if (!currentSessionID) {
			currentSessionID =  uuid()
			cookie.set("DCCFVVs-sessionID", currentSessionID, { path: "/", maxAge: 31536000 })
		}

		let pingersTable = {
			[currentSessionID]: {
				online: true,
				timestamp: Date.now()
			}
		}

		ws.onmessage = evt => {
	        let message = JSON.parse(evt.data);
	        let parsedMessage = (message.message)
	        if (pingersTable[parsedMessage.sessionID] == undefined) {
	        	pingersTable[parsedMessage.sessionID] = {
	        		timestamp: parseFloat(parsedMessage.timestamp),
	        		online: Date.now() - parsedMessage.timestamp < 3000
	        	}
	        } else {
				pingersTable[parsedMessage.sessionID].timestamp = parseFloat(parsedMessage.timestamp)
				pingersTable[parsedMessage.sessionID].online = Date.now() - parsedMessage.timestamp < 3000
	        }
	    }

	    let pingersCheckInterval = setInterval(() => {
	    	pingersTable[currentSessionID].timestamp = Date.now()
	    	let objectKeys = Object.keys(pingersTable)
	    	objectKeys.forEach((key, i) => {
	    		pingersTable[key].online = Date.now() - pingersTable[key].timestamp < 3000
	    		if (i == objectKeys.length - 1) {
	    			pingersCallback(pingersTable)
	    		}
	    	})
	    }, 2000)

	    let pingingInterval = setInterval(() => {
	    	pingersTable[currentSessionID].timestamp = Date.now()
	    	if (ws.readyState === 1) {
				ws.send(JSON.stringify({
					topic: `${userID}-DCCFVVs-${droneID}`,
					message: JSON.stringify({
						sessionID: currentSessionID,
						timestamp: Date.now()
					})
				}))
	    	}
	    }, 1000)
    	return () => {
    		clearInterval(pingingInterval)
    		clearInterval(pingersCheckInterval)
    	}
    }, [])

    return <div style={{
    	display: "none"
    }}/>
}