import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import { withRouter } from 'react-router-dom';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import TableView from './ReusableComponents/TableView/TableView.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader.js';

class RMFlightCompletedHours extends Component {
    state = {
        user: {},
        type: "",
        key: " ",
        loading: true,
        fromDate: '-30610245208',
        toDate: Date.now(),
        refreshing: false,
        userperpage: 15,
        currentpage: 1,
        totalCount: 0,
        taskdisplay: [],
        drones: [],
        outId: "",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        filterObj: {
            searchString: "",
            orderBy: 'total_flight_time',
            orderType: 'DESC',
            pageNumber: 1,
            pageSize: 15
        }
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        document.title = "Flight Completed Hours - RMP";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;

            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getFlightCompletedHours()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getFlightCompletedHours()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    getFlightCompletedHours = () => {
        try {

            let { searchString, orderBy, orderType, pageNumber, pageSize } = this.state.filterObj;

            this.setState({ loading: true }, () => {
                API.getAllFlightsCompletedHours(searchString || ' ', orderBy, orderType, pageNumber, pageSize).then(async (data) => {
                    this.setState({
                        drones: data.drones.drones,
                        totalCount: data.drones.count,
                        loading: false
                    })
                }).catch(err => {
                    console.log(err);
                })
            })
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        let { user } = this.props.location.state
        return (
            <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }>
                    <div className="top-bar-new" style={{ display: "flex" }}>
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "AeroGCS",
                                        pathname: "/",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: 'Flight Hours',
                                    }
                                ]} />
                        </div>
                    </div>
                    <div className="main-content-new">
                        <div className="services-content-title">{"Total Drones"} : {this.state.totalCount}</div>

                        <SearchHeader
                            placeholder={"Search drone.."}
                            onFilterDataChange={(str) => {
                                this.setState({
                                    filterObj: {
                                        ...this.state.filterObj,
                                        searchString: str.searchString
                                    }
                                }, () => {
                                    this.getFlightCompletedHours()
                                })
                            }}
                        />
                        <div className="drone-performance-table-container">
                            <TableView
                                isLoading={this.state.loading}
                                onClickRow={(row) => [
                                    this.props.history.push("/aerogcsenterprise/flights", {
                                        user: this.state.user,
                                        selectedDrone: row.drone_id
                                    })
                                ]}
                                columns={[
                                    { Header: 'Drone', accessor: 'drone_id', width: '40%' },
                                    { Header: 'Drone Name', accessor: 'drone_name', width: '40%', isSortable: true },
                                    { Header: 'Hours Completed', accessor: 'total_flight_time', width: '20%' }
                                ]}
                                data={this.state.drones.map(drn => {
                                    return {
                                        ...drn,
                                        total_flight_time: (((drn.total_flight_time.days == undefined ? 0 : drn.total_flight_time.days * 24 * 60)
                                            + (drn.total_flight_time.hours == undefined ? 0 : drn.total_flight_time.hours * 60)
                                            + (drn.total_flight_time.minutes == undefined ? 0 : drn.total_flight_time.minutes)
                                            + (drn.total_flight_time.seconds == undefined ? 0 : drn.total_flight_time.seconds / 60)
                                            + (drn.total_flight_time.milliseconds == undefined ? 0 : drn.total_flight_time.milliseconds / 60000)) / 60).toFixed(2)
                                    }
                                })}
                                user={user}
                                count={this.state.totalCount}
                                onStateChange={(changedState) => {
                                    this.setState({
                                        filterObj: {
                                            ...this.state.filterObj,
                                            orderBy: changedState.orderBy,
                                            orderType: changedState.orderType,
                                            pageNumber: changedState.pageNumber,
                                            pageSize: changedState.pageSize
                                        }
                                    }, () => {
                                        this.getFlightCompletedHours()
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RMFlightCompletedHours);