import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import { Link } from 'react-router-dom';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import Noimageset from './assets/Images/Noimageset.png';
import ImageSetThumb from './icons/ImageSetThumb.png'
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions';
import { DeleteComponentGrid, DeleteComponentTable } from './ReusableComponents/Menu';
import ImageSetInActive from './assets/sideBarIcons/ImageSetInactive.png';
import { SortComponent } from './ReusableComponents/SortComponent';
import dateFormat from 'dateformat';
import ListIcon from './icons/gridBlue.png'
import GridIcon from './icons/listBlue.png'
import AppConstants, { SERVICES, appConstants } from './AppConstants';
import { ActiveRole, getActiveTeam, GCS_FEATURES, getFeaturesPermissions } from './Teams';

class RMPImage_collection extends Component {

  state = {
    user: {},
    type: "",
    flightLoading: true,
    flights: [],
    services: [],
    collections: [],
    sidebarMode: this.props.location.state
      ? this.props.location.state.sidebarMode
      : "shrunk",
    itemName: "",
    showProcessPopup: false,
    processPopupIcon: "",
    processAction: "",
    processMessage: "",
    thumbURLs: [],
    oldCollectionId: '',
    showCopyImagePopup: false,
    newCollectionName: '',
    sortType: 'Last Modified',
    loading: true,
    outId: "",
    gridView: true
  };
  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  sortByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'A-Z'
    }));
    let sort = this.state.collections.sort((a, b) => a.collection_name.localeCompare(b.collection_name))
    this.setState((state) => ({
      ...state,
      collections: sort,
    }));

  }

  reverseByName = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Z-A'
    }));
    let sort = this.state.collections.sort((a, b) => b.collection_name.localeCompare(a.collection_name))
    this.setState((state) => ({
      ...state,
      collections: sort,
    }));
  }

  sortByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'First Modified'
    }));
    let sort = this.state.collections.sort((a, b) => a.created_on.localeCompare(b.created_on))
    this.setState((state) => ({
      ...state,
      collections: sort,
    }));
  }

  reverseByDate = () => {
    this.setState((state) => ({
      ...state,
      sortType: 'Last Modified'
    }));
    let sort = this.state.collections.sort((a, b) => b.created_on.localeCompare(a.created_on))
    this.setState((state) => ({
      ...state,
      collections: sort,
    }));
  }


  deleteImageCollection = () => {
    this.setState((state) => ({
      itemName: "Imageset",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }))
    if (this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE) API.deleteAerocaptureImageCollection(this.state.selectedCollectionId, SERVICES.AEROGCS).then((data) => {
      console.log(data);
      this.setState((state) => ({
        itemName: "Imageset",
        processPopupIcon: "COMPLETE",
        processAction: "DELETE",
        processMessage: null,
        showProcessPopup: true,
      }), () => {
        this.getImageCollections()
      })
    }, (e) => {
      //console.log(e);
      this.setState((state) => ({
        itemName: "Imageset",
        processPopupIcon: "ERROR",
        processAction: "DELETE",
        processMessage: "Error occured while deleting Imageset",
        showProcessPopup: true,
      }))
    })
  }


  createCollectionCopy = () => {
    this.setState((state) => ({
      ...state,
      showCopyImagePopup: false,
      itemName: "Imageset",
      processPopupIcon: "WAIT",
      processAction: "ADD",
      processMessage: "Duplicating your image collection please Wait...",
      showProcessPopup: true,
    }))

    API.createImageCollection(this.state.project.id, this.state.plan.id, this.state.newCollectionName).then((data) => {
      if (data.collection) {
        API.duplicateImageSet(this.state.user.blob_container, this.state.oldCollectionId, data.collection.id, AppConstants.SERVICES.AEROGCS).then((res) => {
          if (res.status == 201 || res.status == 202) {
            this.getImageCollections();
            this.setState((state) => ({
              ...state,
              newCollecton: data.collection,
              itemName: "Imageset",
              processPopupIcon: "COMPLETE",
              processAction: "ADD",
              processMessage: 'Imageset copied successfully.',
              showProcessPopup: true,
            }))
          } else {
            this.setState((state) => ({
              ...state,
              newCollecton: data.collection,
              itemName: "Imageset",
              processPopupIcon: "ERROR",
              processAction: "ADD",
              processMessage: 'Something went wrong when copying images.',
              showProcessPopup: true,
            }), () => {
              API.deleteAerocaptureImageCollection(data.collection.id, SERVICES.AEROGCS).then((data) => {
                console.log(data);
                this.getImageCollections()
              })
            })
          }

        })
      } else if (data.status == 409) {
        this.setState((state) => ({
          ...state,
          itemName: "Imageset",
          processPopupIcon: "ERROR",
          processAction: "ADD",
          processMessage: "Imageset already exist with same name.",
          showProcessPopup: true,
        }))
      } else {
        this.setState((state) => ({
          ...state,
          itemName: "Imageset",
          processPopupIcon: "ERROR",
          processAction: "ADD",
          processMessage: "Something went wrong",
          showProcessPopup: true,
        }))
      }
    }, (e) => {
      // console.log(e);
      this.setState((state) => ({
        ...state,
        itemName: "Imageset",
        processPopupIcon: "ERROR",
        processAction: "ADD",
        processMessage: "Something went wrong",
        showProcessPopup: true,
      }))
    })
  }


  getImageCollections = () => {
    this.setState((state) => ({
      ...state,
      loading: true
    }))
    API.getImageCollections(this.props.location.state.project.id, this.props.location.state.plan.id).then((data) => {
      if (data.collections) {
        this.setState((state) => ({
          ...state,
          collections: data.collections,
          loading: false
        }), () => {
          if (this.state.sortType === 'A-Z') {
            this.sortByName()
          } else if (this.state.sortType === 'Z-A') {
            this.reverseByName()
          } else if (this.state.sortType === 'First Modified') {
            this.sortByDate()
          } else if (this.state.sortType === 'Last Modified') {
            this.reverseByDate()
          }
        })
        this.loadTiff()
      } else {
        this.setState((state) => ({
          ...state,
          collections: [],
          loading: false
        }))
      }
    }, (e) => {
      //console.log(e);
      alert('Something went wrong');
      this.setState((state) => ({
        ...state,
        loading: false
      }))
    })
  }

  // function to load tiff thumbnails
  loadTiff = async () => {
    let { collections, user } = this.state
    for (let i = 0; i < this.state.collections.length; i++) {
      if (collections[i].file && collections[i].file.file_name && collections[i].file.file_name != undefined) {
        let name = collections[i].file.file_name.toLowerCase()
        let turl = `${AppConstants.BLOB_URL}/${this.state.permissions.container}/FlightImages/collection-${collections[i].id}/thumb-${collections[i].file.file_name}?${this.state.permissions.st}`
        collections[i].thumbURL = turl
        this.setState((state) => ({
          ...state,
          collections
        }))
      }
    }
  }

  handleChangeCopy = (e) => {
    let format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (format.test(e.target.value)) {
      // setError("Spacial characters are not allowed.")
    } else
      if (e.target.value.includes(' ')) {
        // setError("Spaces are not allowed.")
      } else {
        // setError(undefined)
        this.setState({ newCollectionName: e.target.value });
      }
  }


  componentWillMount() {
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam: activeTeam
      }, () => {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        getFeaturesPermissions([GCS_FEATURES.IMAGECOLLECTIONS, GCS_FEATURES.PLANS])
          .then(permissions => {
            this.setState(state => ({ ...state, permissions, }), () => {
              console.log("************props***************");
              console.log(this.props.location.state);
              if (!this.props.location.state) return;
              let { user, type, projectName, planName } = this.props.location.state;
              this.setState((state) => ({
                ...state,
                user,
                type
              }))
              console.log("API called");
              this.getImageCollections();
              API.getFlights().then(
                (data) => {
                  let flights = [];
                  let count = 0
                  if (data.keys.length == 0) {
                    this.setState((state) => ({
                      ...state,
                      flights: flights,
                      flightLoading: false,
                    }));
                  }
                  data.keys.forEach((element, i) => {

                    if (element.plan_name == this.props.location.state.plan.plan_name && element.project_name == this.props.location.state.project.project_name) {
                      API.getFlightImages(element.id, 10000, 0).then((data1) => {

                        element.images = data1.images;
                        element.sasToken = data1.sasToken;
                        flights.push(element);
                        count++
                        if (count == data.keys.length) {
                          this.setState((state) => ({
                            ...state,
                            flights: flights,
                            flightLoading: false
                          }));
                        }
                      }, (e) => {
                        alert(e)
                      })
                    }
                    else {
                      count++
                      if (count == data.keys.length) {
                        this.setState((state) => ({
                          ...state,
                          flights: flights,
                          flightLoading: false
                        }));
                      }
                    }
                  });
                },
                (e) => {
                  console.log(e);
                  alert(e)
                }
              );
            })
          })
      })
    })
  }


  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentDidMount() {
    document.title = "My Images - AeroMegh";
    if (!this.props.location.state) {
      API.getUserType().then(
        ([registered, type, user]) => {
          // if promise is resolved, update state and redirect user
          this.setState((state) => ({
            ...state,
            loading: false,
            registered,
            type,
            user,
          }));
        },
        (e) => {
          // if promise is rejected, log the error, and display message to user
          this.setState({
            user: {},
            loading: false,
            type: "unknown",
          });
        }
      );
    }
  }

  render() {
    const CustomSortToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <div className='sort-type-style'>{this.state.sortType}</div>
      </a>
    ));

    let { user, type, collections, sidebarMode, showBackIcon, } = this.state
    return (
      <div className="wrapper">

        {/* -------------- Activity Popup ----------------------- */}
        <ActivityPopup
          item={this.state.itemName}
          open={this.state.showProcessPopup}
          icon={this.state.processPopupIcon}
          action={this.state.processAction}
          msg={this.state.processMessage}
          close={() => this.closeActivityPopup()}
          height={this.state.processPopupIcon == "WARNING" ? "210px" : null}
          onClickOk={() => this.deleteImageCollection()}
        >
          <div id='message' className='content-text' style={{ fontSize: "13px", fontWeight: "500", textAlign: "left", margin: "0px 30px", color: '#555C67' }}>
            {this.state?.processMessage}</div>
        </ActivityPopup>
        {/* --------------------------------------------------------------- */}

        <Modal
          style={{ marginTop: '150px' }}
          show={this.state.showCopyImagePopup}
          onHide={() => {
            this.setState((state) => ({
              ...state,
              showCopyImagePopup: false,
              newCollectionName: ''

            }));
          }}
        >
          <Modal.Body >
            <div style={{ marginBottom: "30px" }}>
              <div className="services-content-title">{"Copy an Imageset"}</div>
              {/* <label className="font-color-secondary" style={{ fontSize: "14px" }}>To upload images you need to create an imageset.</label> */}
              <input type="text" value={this.state.newCollectionName} maxLength={AppConstants.POPUP_INPUT_TEXT_LIMIT} className="add-user-form-text" placeholder="eg. Imageset Name"
                onChange={(e) => { this.handleChangeCopy(e) }} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="addcustomer-button-empty-dashboard popup-btn-left"
              onClick={() => {
                this.setState((state) => ({
                  ...state,
                  showCopyImagePopup: false,
                }));
              }}
            >
              cancel
            </button>
            {this.state.newCollectionName ?
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                onClick={() => {
                  this.createCollectionCopy()
                }}
              >
                Ok
              </button> :
              <button
                className="add-user-form-submit-button popup-btn-right btn-ok"
                style={{ opacity: '0.5', cursor: 'default' }}
              >
                Ok
              </button>}

          </Modal.Footer>
        </Modal>

        <div className="right-content-new"
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <div className="top-bar-new">
            <div className="top-bar-text-new">
              <BreadCrumbs data={[
                {
                  displayName: "projects",
                  pathname: "/projects",
                  state: {
                    type,
                    user,
                  }
                },
                {
                  displayName: this.props.location.state.project.project_name,
                  pathname: "/plans",
                  state: {
                    type,
                    user,
                    plan: this.props.location.state.plan,
                    project: this.props.location.state.project,
                  }
                },
                {
                  displayName: this.props.location.state.plan.plan_name,
                  pathname: "/remote_mission/assignDroneToPlan",
                  state: {
                    type,
                    user,
                    plan: this.props.location.state.plan,
                    project: this.props.location.state.project,
                  }
                },
                {
                  displayName: "Collections"
                }
              ]} />
              <ActiveRole
                hideBrackets={true}
              />
            </div>
          </div>
          <div className="main-content-new">
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="services-content-title">{`Imagesets`}
                <span className='data-count' >({this.state.collections?.length || 0})</span>
              </div>
              <div className="add-user-form-text-wrapper" style={{ display: "flex", alignItems: 'center', }} >
                <div
                  onClick={() => {
                    this.setState((state) => ({
                      ...state,
                      gridView: !state.gridView,
                    }))
                  }}>
                  <img src={this.state.gridView ? GridIcon : ListIcon} style={{ height: '24px', width: '24px', cursor: 'pointer', margin: '0px 18px' }}
                  />
                </div>
              </div>
            </div>
            {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
              <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div> : <>
              {this.state.collections?.length > 0 && this.state.gridView ? <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '25px', width: '100%' }}>
                  <div style={{ width: "99.5%", borderTop: "1px solid rgba(102, 102, 102, 0.2)" }} />
                </div>
                <div style={{ height: "30px" }}>
                  <SortComponent
                    label={'Name'}

                    sortAscending={this.sortByName}
                    sortDecending={this.reverseByName}
                    sortASC={true}
                  />
                </div>
              </> : <></>}
              {collections != undefined && collections.length > 0 ? this.state.gridView ?
                <div className="aerocapture-cards-tray-projects scroll-container" style={{ paddingTop: "10px", gridTemplateColoumn: "repeat(auto-fill, minmax(190px, 200px));" }}>
                  {

                    collections.map((collection, key) => {
                      return (
                        <div>
                          <Link to={{
                            pathname: "/remote_mission/my_images",
                            state: {
                              user,
                              type,
                              collection,
                              project: this.props.location.state.project,
                              plan: this.props.location.state.plan,
                            }
                          }}>
                            {<div key={key} className="customer-cards-dashboard card" style={{ boxShadow: '0px 6px 10px rgb(0 0 0 / 30%)', marginBottom: "30px" }}>
                              <div className="card-header customer-cards-dashboard-logo project-card-header" style={{
                                // backgroundImage=`url(${'./assets/map_placeholder.png'})`
                              }}>
                                {
                                  collection && collection.file && collection.file.file_name && collection.thumbURL ?
                                    <img loading='lazy' id="ProjectImageRPM" style={{ objectFit: 'cover' }} src={collection.thumbURL} /> :
                                    < img loading='lazy' id='ProjectImageRPM' src={ImageSetThumb} />
                                  // <img id="ProjectImage" src={thumb} />
                                }
                                <div>
                                  <DeleteComponentGrid
                                    style={{ position: "absolute", top: "5px", right: "5px" }}
                                    disableDelete={!this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE}
                                    onClickDelete={(e) => {
                                      e.preventDefault()
                                      this.setState((state) => ({
                                        ...state,
                                        selectedCollectionId: collection.id,
                                        deleteItem: "IMAGESET",
                                        itemName: "Imageset",
                                        processPopupIcon: "WARNING",
                                        processAction: "DELETE",
                                        processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                          "\n Are you sure you want to delete this Imageset ?",
                                        showProcessPopup: true,
                                      }))
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ paddingTop: "3px !important" }}
                                className="card-body change-color customer-cards-dashboard-text project-card-body"
                              >
                                <div>
                                  <span>{
                                    collection.collection_name.length > 14 ?
                                      `${collection.collection_name.substring(0, 13)}...` :
                                      `${collection.collection_name}`
                                  }</span>
                                  <div style={{ display: "flex", flexDirection: "row", fontSize: "10px", justifyContent: "space-between", }}>
                                    <span>{
                                      collection && collection.file ? collection.file.count : '0'
                                    }</span>
                                    <span>{
                                      (() => {
                                        let date = (new Date(collection.created_on)).toGMTString().split(' ').slice(1, 4).join(' ');
                                        return `${date}`
                                      })()
                                    }</span>
                                  </div>
                                </div>
                                {/*<div style={{ height: "24px", overflow: "hidden",fontSize:'14px' }}>{project.name+".jpg"}</div>*/}
                              </div>
                            </div>}
                          </Link>
                        </div>)
                    })
                  }
                </div> :
                <div id='table' className='scroll-container' style={{ marginTop: '30px', height: "76vh", overflow: 'auto' }}>
                  <table className="customers-table" style={{ marginTop: '0px' }}>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '5%' }} />
                    <tr className="customers-table-header-row" style={{ background: "#EAEAEA" }}>
                      <th></th>
                      <th>Name
                        <SortComponent
                          style={{ marginLeft: '5px' }}

                          sortAscending={this.sortByName}
                          sortDecending={this.reverseByName}
                          sortASC={false}
                        />
                      </th>
                      <th>Images</th>
                      <th>Date Created
                        <SortComponent
                          style={{ marginLeft: '5px' }}

                          sortAscending={this.sortByDate}
                          sortDecending={this.reverseByDate}
                          sortASC={false}
                        />
                      </th>
                      <th></th>
                    </tr>
                    {
                      this.state.collections.map((collection, i) => {
                        return collection && collection.collection_name ?
                          <tr className="customers-table-data-row" style={{ height: "45px" }}>
                            <td>
                              {
                                collection && collection.thumbURL ?
                                  <img id="ProjectTableImage" loading='lazy' style={{ objectFit: 'cover', padding: 'unset' }} src={collection.thumbURL} onError={(e) => { e.target.onerror = null; e.target.style = { undefined }; e.target.src = ImageSetInActive }} /> :
                                  <img id="ProjectTableImage" loading='lazy' src={ImageSetInActive} />
                                // <img id="ProjectImage" src={thumb} />
                              }
                            </td>
                            <td>
                              <Link
                                style={{ color: "#707683" }}
                                to={{
                                  pathname: "/remote_mission/my_images",
                                  state: {
                                    user,
                                    type,
                                    collection,
                                    project: this.props.location.state.project,
                                    plan: this.props.location.state.plan,
                                  }
                                }}
                              >
                                {collection.collection_name}
                              </Link>
                            </td>
                            <td>{collection && collection.file ? collection.file.count : '0'}</td>
                            <td>{
                              (() => {
                                let date = (new Date(collection.created_on)).toLocaleDateString('en-US')
                                return `${dateFormat(date, "dd mmm yyyy")}`
                              })()
                            }</td>
                            <td>
                              <DeleteComponentTable
                                style={{ margin: "0 20px" }}
                                disableDelete={!this.state.permissions[GCS_FEATURES.IMAGECOLLECTIONS].DELETE}
                                onClickDelete={(e) => {
                                  e.preventDefault()
                                  this.setState((state) => ({
                                    ...state,
                                    selectedCollectionId: collection.id,
                                    deleteItem: "IMAGESET",
                                    itemName: "Imageset",
                                    processPopupIcon: "WARNING",
                                    processAction: "DELETE",
                                    processMessage: "All data including orthomosaic images, tasks, annotations, gcp points, images will be deleted." +
                                      "\n Are you sure you want to delete this Imageset ?",
                                    showProcessPopup: true,
                                  }))
                                }}
                                onclickDuplicate={(e) => {
                                  e.preventDefault()
                                  this.setState((state) => ({
                                    ...state,
                                    showCopyImagePopup: true,
                                    oldCollectionId: collection.id,
                                    newCollectionName: `${collection.collection_name}Copy`,
                                  }))
                                }}
                              />
                            </td>
                          </tr> : ''
                      }
                      )}
                  </table>
                </div> :
                <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                  <img style={{ height: '180px' }} src={Noimageset} />
                  <p style={{ marginTop: '10px' }}>No Imagesets available</p>
                </div>
              }
            </>}
          </div>
        </div >
      </div >
    );
  }
}

export default RMPImage_collection;

