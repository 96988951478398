import React from 'react'
import NoContentIcon from "../assets/Icons/noData.png"
const NoContent = ({ msg, icon, width, height }) => {
    return (
        <div style={{ width: width || "100%", height: height || "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <div className='no-content-container'>
                <img className='no-content-img' src={icon || NoContentIcon} />
                <div className='no-content-msg empty-data-message'>{msg || 'No data found'}</div>
            </div>
        </div>
    )
}

export default NoContent