import { Link } from 'react-router-dom';
import React from 'react';
import "./style.css";
import { Component } from 'react';
import allServices from "./allServices"
import { AnimatedModal } from './ReusableComponents/AnimatedModal'
import reqServiceIcon from './assets/reqService.png'
import * as API from "./api.js";
import { getActiveTeam } from './Teams';


export default class ServicesTileTray extends Component {
    state = {
        tiles: [],
        type: '',
        inactiveTiles: [],
        requestServiceModal: false,
        reqMessage: "Thank you for your interest, we will activate selected service in your account at the earliest once requested.",
        reqSent: {
            0: false,
            1: false,
            2: false
        },
        reqSentMessage: "The confirmation email will be sent to your account",
        mainServices: "false"
        // sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    componentWillMount() {
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam: activeTeam
            }, () => {

                let { user, type, sidebarMode, tiles } = this.props;
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    sidebarMode,
                    tiles
                }), () => {
                    const { tiles, mainServices } = this.props
                    if (mainServices == "true") {
                        let inactiveTiles = allServices.services.filter(({ title: id1 }) => !tiles.some(({ title: id2 }) => id2 === id1));
                        this.setState((state) => ({
                            ...state,
                            inactiveTiles,
                            mainServices
                        }))
                    }
                })
            })
        })

    }

    sendServiceRequest = (serviceTitle) => {
        API.request_service(serviceTitle).then(response => {
            let activeKey = this.state.key
            this.setState((state) => ({
                ...state,
                reqSent: { ...state.reqSent, [activeKey]: true }
            }))
        })
    }

    render() {
        let { user, type, sidebarMode, tiles, inactiveTiles, requestServiceModal, clickedInactiveService } = this.state;
        return <>
            {clickedInactiveService ? <AnimatedModal
                isOpen={requestServiceModal}
                onRequestClose={() => {
                    this.setState((state) => ({
                        ...state,
                        requestServiceModal: false,
                    }))
                }}
                height="310px"
                width="550px"
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ width: "35%", backgroundColor: "#EAEAEA", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                        <img src={reqServiceIcon} style={{ margin: "8%" }} />
                    </div>
                    <div style={{ width: "65%", display: "flex", flexDirection: "column", height: "100%" }}>

                        <div
                            style={{ textAlign: "center", margin: "0px 50px 0px 50px" }}
                        >
                            <div
                                style={{
                                    textAlign: "left",
                                    margin: "15% 0 12% 0",
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    color: "#3C3C3C",
                                }}
                            >
                                {clickedInactiveService.title}
                                <div style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    color: "#666666"
                                }}>{clickedInactiveService.description}</div>
                            </div>
                            <div
                                style={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#666666",
                                    height: "45%"
                                }}
                            >
                                {this.state.reqSent[this.state.key] ? this.state.reqSentMessage : this.state.reqMessage}
                            </div>
                            {this.state.reqSent[this.state.key] ? <div style={{ display: "flex" }}>
                                <div style={{ textAlign: "left" }}>
                                    <button
                                        onClick={() => this.setState((state) => ({
                                            ...state,
                                            requestServiceModal: false
                                        }))}
                                        className="defaultButtonSuccess"
                                    >Ok</button>
                                </div>
                            </div> : <div style={{ display: "flex" }}>
                                <div style={{ textAlign: "left" }}>
                                    <button
                                        onClick={() => this.sendServiceRequest(clickedInactiveService.title)}
                                        className="defaultButtonSuccess"
                                    >Request</button>
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
            </AnimatedModal> : <></>}

            {this.state.mainServices == "true" ? <div style={{ display: "flex" }}>
                <div className="services-content-title">{"Subscribed Services"}</div>
            </div> : <></>}
            <div className="servicesCardstray" id="cardstray">
                {tiles.map((tile) => {
                    return (
                        <Link to={{
                            pathname: !this.state.activeTeam?.isOwner && tile.title === 'AeroGCS Enterprise' ? '/projects' : tile.redirectURL || tile.redirecturl,
                            state: {
                                user,
                                type,
                                tile,
                                sidebarMode
                            }
                        }} className="servicesCardscon" style={{ border: 'none', background: 'none' }}>
                            <div>
                                <img className="servicesCardimage" src={tile.image} alt="imageIcon" />
                                <div className="services-title">{tile.title}</div><br />
                                <div className="servicesPara">{tile.description.length <= 50 ? tile.description : tile.description.substr(0, 47) + "..."}</div>
                            </div>
                        </Link>
                    )
                })
                }
            </div>
        </>
    }
}
