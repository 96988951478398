import React, { useEffect, useState } from 'react'
import { ActivityPopup } from './ActivityPopup'
import * as API from "../api.js"
import AppConstants from '../AppConstants'

const DeleteProject = ({ projectId, service, show, onClose,projectsState,selectedPageChanged }) => {
    const [processPopup, setProcessPopup] = useState({
        icon: "",
        msg: ""
    })
    useEffect(() => {
        if(show)
        setProcessPopup({ ...processPopup, icon: "WARNING", msg: service
            ? `Your ${service === AppConstants.SERVICES.PICSTORK
                ? "Imagesets, Training tasks, Annotation report tasks, Detection tasks, Annotations "
                : service === AppConstants.SERVICES.DRONENAKSHA
                    ? "Plans, Imagesets, Maps data, Floor plans, Measurements, Reports"
                    : service === AppConstants.SERVICES.AEROGCS
                        ? "Plans"
                        : "data"
            } will be deleted, Are you sure you want to delete this project ?`
            : "Are you sure you want to delete this project ?" })
    }, [show])


    const deleteProject = async () => {
        try {
            setProcessPopup({ ...processPopup, msg: "Deleting project. Please wait...", icon: "WAIT" })
            if(projectsState.projectCount==1 &&projectsState.pageNumber>0){
                selectedPageChanged(true)
            }
            const data = await API.deleteAerocaptureProject(projectId)
            if (data.message === "OK") {
                onClose(true)
            }
            else {
                setProcessPopup({ ...processPopup, msg: "An error occurred while deleting project!", icon: "ERROR" })
            }
        } catch (error) {
            console.log(error)
            setProcessPopup({ ...processPopup, msg: "An error occurred while deleting project!", icon: "ERROR" })
        }
    }


    return (
        <div>
            <ActivityPopup
                height={"170px"}
                open={show}
                icon={processPopup.icon}
                msg={processPopup.msg}
                close={() => onClose()}
                onClickOk={deleteProject}
            />
        </div>
    )
}

export default DeleteProject