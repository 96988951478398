import React from 'react';
import SingleMap from './ReusableComponents/map';
import droneLive from './icons/droneGreen.png'
import droneOffline from './icons/droneGrey.png'
import portOffline from './icons/port-offline.png'
import portOnline from './icons/port-online.png'
import roadMapView from './assets/Images/roadMapViewzz.svg';
import sateliteView from './assets/Images/sateliteView.svg'
import L, { layerGroup, marker } from "leaflet"
import "leaflet-draw";
import "leaflet-editable"
import "leaflet.path.drag"
import Loader from './icons/loader.svg';
import * as API from './api.js';
import AppConstants, { MAP_URLS } from './AppConstants.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
const INITIAL_LAT = -83.804303
const INITIAL_LON = 74.489302
let extendBounds = 0.00075
var map = {}

export default class MapComponent extends React.Component {
  state = {
    showingInfoWindow: false,  //Hides or the shows the infoWindow
    activeMarker: {},          //Shows the active marker upon click
    selectedDrone: null,       //Shows the infoWindow to the selected place upon a marker
    activeStream: null,
    stream: false,
    videoJsOptions: null,
    loading: true,
    map: undefined,
    user: this.props.user,
    flightReplayMode: this.props.flightReplayMode || false,
    replayDroneID: this.props.replayDroneID || "",
    center: { lat: 19.993982, lng: 73.790416 },
    zoom: 22,
    didDupMarkerFix: false,
    ports: [],
    replayTeleData: {
      lat: INITIAL_LON,
      lon: INITIAL_LAT
    },
    polylineColor: '#FFFFFF',
    drones: {},
    trailLogs: [],
    processTileIndex: "NDVI",
    processTileMin: -0.283,
    processTileMax: 0.181,
    processTileType: "RGN",
    baseLayer: '',
    activeBaseLayer: 'satelite',
    selectedLayer: 'Orthomosaic',
    layerSelectionPopup: false,
    selectedLayerType: 'orthomosaic',
    layer: '',
    hasCentered: this.props.recenterMap || false,
  };

  locationsSave = this.props.locations

  topicsSave = this.props.topics

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      if (this.state.activeBaseLayer === 'terrain' && this.state.selectedLayerType === 'base') {
        this.setState({ polylineColor: '#2989CF' })
      } else if (this.state.activeBaseLayer === 'satelite' && this.state.selectedLayerType === 'base') {
        this.setState({ polylineColor: '#FFFFFF' })
      }
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })

  }

  getPlansForLinkedMap = () => {
    let key = this.props.planName;
    API.getPlans(this.props.projectName, key == "" ? " " : key, 100, 0, this.props.activeTeamId).then((results) => {
      results.plans.map((plan) => {
        if (plan.plan_name === this.props.planName) {
          this.setState(
            (state) => ({
              ...state,
              linked_map: plan.linked_map ? plan.linked_map : null,
            }), () => {
              this.overlayTilesLayerOf('orthomosaic')
            })
        }
      })
    }).catch(err => {
      try {
        console.log(JSON.parse(err))
      } catch (e) {
        console.log(err)
      }
    })
  }

  changeSelectedLayer = (layer) => {
    if (layer == 'NDVI') {
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "NDVI",
        processTileType: "RGN",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'VARI') {
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "VARI",
        processTileType: "RGB",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'Orthomosaic') {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'orthomosaic'
      })
      this.overlayTilesLayerOf("orthomosaic")
    } else {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'base',
        polylineColor: '#FFFFFF'
      })
      this.removeLayer("orthoLayer")
    }
  }

  updateHistogram = async () => {
    return new Promise((resolve, reject) => {
      let taskId = this.state.linked_map ? this.state.linked_map : null
      let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
      let sas = encodeURIComponent(this.state.user.sas_token)
      let blobContainer = user.blob_container
      let algorithm = processTileIndex
      API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
        this.setState((state) => ({
          ...state,
          processTileMin: data.statistics[1].percentiles[0].toFixed(4),
          processTileMax: data.statistics[1].percentiles[1].toFixed(4),
          processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
        }), () => {
          this.overlayTilesLayerOf("plantHealth")
        })
      })
    })
  }

  getTilesBounds = async (taskId) => {
    return new Promise((resolve, reject) => {
      let sas = encodeURIComponent(this.state.user.sas_token)
      let blobContainer = this.state.user.blob_container
      API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
        this.setState({
          tilesBounds: data.tilesBounds,
          bounds: data.bounds,
          //defaultOrtho: true,
          band1: "1",
          band2: "2",
          band3: "3",
        }, () => {
          resolve(this.state.bounds)
        })
      })
    })
  }


  overlayTilesLayerOf = async (type) => {
    let { map, plan, linked_map } = this.state
    this.removeLayer("orthoLayer").then(async () => {
      if (linked_map) {
        //-------- Change polyline, arrows, polygon and marker color according to layer --------
        this.setState({ polylineColor: type == 'orthomosaic' ? 'orange' : '#2989CF' })
        let taskid = linked_map
        let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
        let blobContainer = this.state.user.blob_container;
        let sas = encodeURIComponent(this.state.user.sas_token);
        await this.getTilesBounds(taskid)
        let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
        let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
        if (type == "plantHealth") {
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
            tileSize: 256,
            maxZoom: 22,
            bounds: bounds
          }).addTo(map);
          orthoLayer.on("load", () => {
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        } else {
          let orthoLayer = L.tileLayer(`$${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
            tileSize: 256,
            maxZoom: 22,
            bounds: bounds
          }).addTo(map);
          orthoLayer.on("load", () => {
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        }
      }
    })
  }

  removeLayer = async (layer) => {
    let removeLayer = this.state[layer]
    this.setState({ [layer]: undefined }, () => {
      if (removeLayer) {
        removeLayer.remove()
      }
    })
  }

  callback = (topic, message) => {
    if (topic[2] == "location") {
      Object.keys(this.state.topics).forEach((drone) => {
        if (this.state.topics[drone].topic == topic[1]) {
          this.setState((state) => {
            return {
              ...state,
              locations: {
                ...state.locations,
                [drone]: {
                  "latitude": parseFloat(message.split(' ')[0]),
                  "longitude": parseFloat(message.split(' ')[1])
                }
              }
            }
          })
        }
      })
    } else if (topic.length == 1) {
      // This is an AeroStack message.
      Object.keys(this.state.topics).forEach((drone) => {
        if (this.state.topics[drone].topic == topic[0]) {
          let messageParsed = JSON.parse(message);
          switch (messageParsed.key) {
            case "AS_KEEP_ALIVE":

              break;
            case "AS_TELE_DATA":
              let { data } = messageParsed;
              switch (data.dir) {
                case 'N':
                  data.dir = "North"
                  break;
                case 'E':
                  data.dir = "East"
                  break;
                case 'W':
                  data.dir = "West"
                  break;
                case 'S':
                  data.dir = "South"
                  break;
              }
              this.setState((prevState) => ({
                ...prevState,
                locations: {
                  ...prevState.locations,
                  [drone]: {
                    latitude: parseFloat(data.lat),
                    longitude: parseFloat(data.lon),
                  }
                }
              }))
              this.props.teleDataCallback(drone, data)
              break;

            default:

          }
        }
      })
    }
  }

  drawBoundaries = () => {
    if (this.props.fenceBoundary) {
      let fenceBoundary = JSON.parse(this.props.fenceBoundary)
      let polygonView = new L.Polygon([fenceBoundary], {
        color: 'red',
        fillOpacity: 0,
      }).addTo(this.state.map)
      let bounds = polygonView.getBounds()
      this.state.map.fitBounds(bounds);
    }
    if (this.props.planBoundary) {
      let planBoundary = JSON.parse(this.props.planBoundary)
      L.polygon([planBoundary], {
        color: "yellow",
        fillOpacity: 0,
      }).addTo(this.state.map)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drones !== this.props.drones) {
      if (!this.state.didDupMarkerFix && (Object.keys(this.props.drones).length > 0 || (this.props.replayTeleData && this.props.replayTeleData.lat))) {
        console.log("did update ran")
        let drones = JSON.parse(JSON.stringify(this.props.drones))
        let ports = JSON.parse(JSON.stringify(this.props.ports))
        let count1 = 0
        Object.keys(drones).forEach((drone) => {
          drones[drone].teleData = {
            ...drones[drone].teleData,
            lat: INITIAL_LAT,
            lon: INITIAL_LON
          }
          count1++
          if (count1 == Object.keys(drones).length) {
            let count2 = 0
            ports.forEach(port => {
              port.location = `${INITIAL_LAT}, ${INITIAL_LON}`
              count2++
              if (count2 == ports.length) {
                this.setState((prevState) => ({
                  ...prevState,
                  drones,
                  ports,
                  replayTeleData: {
                    lat: INITIAL_LAT,
                    lon: INITIAL_LON
                  },
                }), () => {
                  this.setState((prevState) => ({
                    ...prevState,
                    drones: this.props.drones,
                    ports: this.props.ports,
                    replayTeleData: this.props.replayTeleData,
                    didDupMarkerFix: true
                  }))
                })
              }
            })
          }
        })
      } else if (this.state.didDupMarkerFix) {
        this.setState((prevState) => ({
          ...prevState,
          drones: this.props.drones,
          trailLogs: this.props.trailLogs,
          replayTeleData: this.props.replayTeleData
        }), () => {
          // if (this.state.markersLayer) this.state.markersLayer.remove();
          this.initMap()
        })
      }

    }
  }

  updateLocationsAndTopics = (selectedDrone) => {
    if (selectedDrone == "ALL") {
      this.setState((state) => ({
        ...state,
        locations: this.locationsSave,
        topics: this.topicsSave
      }));
    } else {
      let newLocations = {}
      let newTopics = {}
      Object.keys(this.locationsSave).forEach((drone, i) => {
        if (drone == selectedDrone) {
          newLocations[drone] = this.locationsSave[drone]
          newTopics[drone] = this.topicsSave[drone]
        }
        if (i == Object.keys(this.locationsSave).length - 1) {
          this.setState((state) => ({
            ...state,
            locations: newLocations,
            topics: newTopics
          }));
        }
      });
    }
  }

  componentDidMount() {
    this.checkStatusInterval = setInterval(() => {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].lastActive) {
          let currentTimestamp = new Date();
          let droneLastActive = new Date(this.state.drones[drone].lastActive);
          if (((currentTimestamp - droneLastActive) / 1000) <= 60) {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: true,
                }
              },
              loading: false,
            }))
          } else {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: false,
                }
              },
              loading: false,
            }))
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [drone]: {
                ...prevState.drones[drone],
                online: false,
              }
            },
            loading: false,
          }))
        }
      })
    }, 6000)

    if (!this.state.didDupMarkerFix && (Object.keys(this.props.drones).length > 0 || (this.props.replayTeleData && this.props.replayTeleData.lat))) {
      let drones = JSON.parse(JSON.stringify(this.props.drones))

      let ports = JSON.parse(JSON.stringify(this.props.ports))

      Object.keys(drones).forEach((drone) => {
        drones[drone].teleData = {
          ...drones[drone].teleData,
          lat: INITIAL_LAT,
          lon: INITIAL_LON
        }
      })

      ports.forEach(port => {
        port.location = `${INITIAL_LAT}, ${INITIAL_LON}`
      })

      this.setState((prevState) => ({
        ...prevState,
        drones,
        trailLogs: this.props.trailLogs,
        ports,
        loading: false,
        hasCentered: true,
        replayTeleData: {
          lat: INITIAL_LAT,
          lon: INITIAL_LON
        },
      }), () => {
        this.setState((prevState) => ({
          ...prevState,
          drones: this.props.drones,
          ports: this.props.ports,
          trailLogs: this.props.trailLogs,
          loading: false,
          replayTeleData: this.props.replayTeleData,
          didDupMarkerFix: true
        }))
      })
    } else if (this.state.didDupMarkerFix) {

      this.setState((prevState) => ({
        ...prevState,
        drones: this.props.drones,
        loading: false,
        hasCentered: true,
        replayTeleData: this.props.replayTeleData
      }))
    } else {
      this.setState({ loading: false, hasCentered: true, })
    }

  }

  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }

  boundsExtendDrones = (bounds, callback) => {
    if (Object.keys(this.state.drones).length > 0) {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].teleData && this.state.drones[drone].teleData.lat && this.state.drones[drone].teleData.lon) {
          bounds.extend({ lat: this.state.drones[drone].teleData.lat, lng: this.state.drones[drone].teleData.lon });
        }
        if (i == Object.keys(this.state.drones).length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }

  boundsExtendPlan = (bounds, callback) => {
    if (this.props.plan && this.props.plan.length > 0) {
      this.props.plan.forEach((wp, i) => {
        bounds.extend({ lat: wp.lat, lng: wp.lon });
        if (i == this.props.plan.length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }

  boundsExtendGeofence = (bounds, callback) => {
    if (this.props.geofence && this.props.geofence.length > 0) {
      this.props.geofence.forEach((wp, i) => {
        bounds.extend({ lat: wp.lat, lng: wp.lon });
        if (i == this.props.geofence.length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }

  boundsExtendPorts = (bounds, callback) => {
    if (this.state.ports && (this.state.ports.length > 0) && this.props.includePortsInRecenter) {
      this.state.ports.forEach((port, i) => {
        bounds.extend({ lat: parseFloat(port.location.split(", ")[0]), lng: parseFloat(port.location.split(", ")[1]) });
        if (i == this.state.ports.length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }

  recenter = () => {
    try {
      if (this.props.fenceBoundary) {
        let fenceBoundary = JSON.parse(this.props.fenceBoundary)
        let polygonView = new L.Polygon([fenceBoundary])
        let bounds = polygonView.getBounds()
        this.state.map.fitBounds(bounds);
        this.setState({ hasCentered: false, })
      }
      else {
        let bounds = new L.latLngBounds()
        this.boundsExtendDrones(bounds, (bounds2) => {
          this.boundsExtendPlan(bounds2, (bounds3) => {
            this.boundsExtendGeofence(bounds3, (bounds4) => {
              this.boundsExtendPorts(bounds4, (bounds5) => {
                this.state.map.fitBounds(bounds5)
                this.setState({ hasCentered: false, })
              })
            })
          })
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  initMap1 = () => {
    this.initMap()
    this.drawBoundaries()
    if (!this.props.recenterMap || !this.state.replayDroneID) {
      this.recenter()
    }
    if (this.props.componentType === 'DCCflyView') {
      this.getPlansForLinkedMap()
    }
  }
  droneOffline = {}
  droneOnline = {}

  initMap = () => {
    if (this.state.map) {
      Object.keys(this.state.drones).map((drone) => {
        if (this.state.drones[drone].online) {
          if (this.droneOffline && this.droneOffline[drone]) { this.droneOffline[drone].remove() }
          if (this.droneOffline && this.droneOffline[drone]) { this.droneOffline[drone].remove() }
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lat == "string") this.state.drones[drone].teleData.lat = parseFloat(this.state.drones[drone].teleData.lat);
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lon == "string") this.state.drones[drone].teleData.lon = parseFloat(this.state.drones[drone].teleData.lon);
          if (this.state.drones[drone].teleData && this.state.drones[drone].teleData?.lat && this.state.drones[drone].teleData?.lon && (this.state.drones[drone].teleData.lat != 0 || this.state.drones[drone].teleData.lat != 0)) {
            if (this.droneOnline[drone]) {
              return (this.droneOnline[drone].setLatLng(new L.latLng(this.state.drones[drone].teleData?.lat, this.state.drones[drone].teleData?.lon)))
            } else {
              return (
                this.droneOnline[drone] = new L.Marker({ lat: this.state.drones[drone].teleData?.lat, lng: this.state.drones[drone].teleData?.lon }, {
                  clickable: true,
                  icon: L.icon({
                    iconUrl: droneLive,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                  })
                }).addTo(this.state.map)
                  .on("click", (e) => {
                    this.props.markerClickCallback(drone)
                  })
              )
            }
          }
        } else {
          if (this.droneOnline[drone]) { this.droneOnline[drone].remove() }
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lat == "string") this.state.drones[drone].teleData.lat = parseFloat(this.state.drones[drone].teleData.lat);
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lon == "string") this.state.drones[drone].teleData.lon = parseFloat(this.state.drones[drone].teleData.lon);
          if (this.state.drones[drone].teleData && (this.state.drones[drone].teleData.lat != 0 || this.state.drones[drone].teleData.lat != 0) && this.state.drones[drone].teleData.lat && this.state.drones[drone].teleData.lon) {

            if (this.droneOffline[drone]) {
              return (this.droneOffline[drone].setLatLng(new L.latLng(this.state.drones[drone].teleData?.lat, this.state.drones[drone].teleData?.lon)))
            } else {
              return (this.droneOffline[drone] = new L.Marker({ lat: this.state.drones[drone].teleData.lat, lng: this.state.drones[drone].teleData.lon }, {
                icon: L.icon({
                  iconUrl: droneOffline,
                  iconSize: [45, 45],
                  iconAnchor: [22, 22]
                })
              }).addTo(this.state.map))
            }
          }
        }
      })
      if ((this.props.recenterMap || this.props.replayDroneID) && this.state.hasCentered) {
        this.recenter()
      }
      if (this.state.trailLogs) {
        this.dronetrail ? this.dronetrail.setLatLngs(this.state.trailLogs) :
          this.dronetrail = L.polyline(this.state.trailLogs, {
            color: this.state.polylineColor,
            weight: 2,
          }).addTo(this.state.map)
      }
      {
        this.state.ports.map((port, i) => {
          if (port.online) {
            if (this.portOffline) { this.portOffline.remove() }
            return (
              this.portOnline ? this.portOnline.setLatLng(L.latLng(parseFloat(port.location.split(", ")[0]), parseFloat(port.location.split(", ")[1]))) :
                this.portOnline = new L.Marker({ lat: parseFloat(port.location.split(", ")[0]), lng: parseFloat(port.location.split(", ")[1]) }, {
                  icon: new L.DivIcon({
                    className: 'leafLetDivIconDiv',
                    html: `<img class="leafLetDivIcon" src="${portOnline}" /> <div class= "leafLetDivIconDroneLabel">${port.id.length > 10 ? (port.id.substring(0, 8) + "...") : port.id}</div>`,
                  })
                }).addTo(this.state.map)
            )
          } else {
            if (this.portOnline) { this.portOnline.remove() }
            return (
              this.portOffline ? this.portOffline.setLatLng(L.latLng(parseFloat(port.location.split(", ")[0]), parseFloat(port.location.split(", ")[1]))) :
                this.portOffline = new L.Marker({ lat: parseFloat(port.location.split(", ")[0]), lng: parseFloat(port.location.split(", ")[1]) }, {
                  icon: new L.DivIcon({
                    className: 'leafLetDivIconDiv',
                    html: `<img class="leafLetDivIcon" src="${portOffline}" /> <div class= "leafLetDivIconDroneLabel">${port.id.length > 10 ? (port.id.substring(0, 8) + "...") : port.id}</div>`,
                  })
                }).addTo(this.state.map)
            )
          }

        })
      }
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? <LoaderComponent /> :
          <>
            {this.props.showIcons && <>
              <img
                src="https://static.thenounproject.com/png/1746022-200.png"
                className='slide mapCenterIcon'
                style={{ bottom: this.props.replayPlayback ? "160px" : "125px", }}
                onClick={this.recenter}
              />
              {this.state.linked_map ?
                <div className='slide' style={{ textAlign: 'center', border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '24px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', zIndex: '1', fontSize: "12px", fontWeight: 500 }}
                  onClick={() => {
                    this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                  }}>
                  {this.state.selectedLayer}
                </div>
                : <></>}
              {this.state.layerSelectionPopup && this.state.linked_map ?
                <div style={{ position: 'absolute', width: "105px", fontSize: "12px", bottom: '56px', right: '118px', backgroundColor: 'white', borderRadius: '5px', zIndex: '1', padding: "1px", fontWeight: 500 }} className="content-text">
                  <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    Orthomosaic
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    NDVI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    VARI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("BASE")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    BASE
                  </div>
                </div> : <></>}
              <img className='slide mapSwitchIcon' style={{ bottom: this.props.replayPlayback ? "70px" : "24px", }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} onClick={() => {
                this.switchBaseLayer()
              }} />
            </>}
            <SingleMap
              setBaseLayerToState={this.setBaseLayerToState}
              initCenter={[this.state.center.lat, this.state.center.lng]}
              initZoom={22}
              handleMapClick={this.handleMapClick}
              maxZoom={22}
              setMap={(map) => {
                this.setState({ map }, this.initMap1,)
              }}
            />
          </>}
      </>
    )
  }
}
