import React, { useEffect, useState } from 'react'
import { AnimatedModal } from './ReusableComponents/AnimatedModal';
import { addSoftwareVersion, updateSoftwareVersion } from './api';
import { Popup } from './ReusableComponents/reusableFunctions';
import AppConstants, { SOFTWARES } from './AppConstants';

const SoftwareVersionPopup = ({ version, onRequestClose, onVersionAddCallback, onVersionUpdateCallback }) => {


    const [softwareVersion, setSoftwareVersion] = useState({ version_number: "", software_type: "", admin_email: "", created_on: "" })
    const [updateFlag, setUpdateFlag] = useState(version?.id)
    const [error, setError] = useState("")
    function validateGreenOrangeBuildNo(buildNumber) {
        setError("")
        // Define the regular expression pattern to match the flexible format
        const buildNumberPattern = /^\d+\.\d+\.\d{8}\.\d+$/;

        // Test if the build number matches the pattern
        const match = buildNumberPattern.test(buildNumber);
        if (!match) {
            setError('Invalid build number format. Ensure it follows the pattern: NNN.BBB.YYYYMMDD.SS');
        }

        // Extract the components
        const [features, bugs, date, buildCount] = buildNumber.split(".");

        // Validate date format (YYYYMMDD)
        if (date && !isValidYearMonthDay(date)) {
            setError('Invalid date format. Please use YYYYMMDD.');
        }

        if (features && parseInt(features, 10) < 0) {
            setError('Feature/enhancements count must be a positive number.');
        }
        if (bugs && parseInt(bugs, 10) < 0) {
            setError('Bugs count must be a positive number.');
        }
        // Check if build count is a valid number

        if (buildCount && parseInt(buildCount, 10) < 0) {
            setError('Build count must be a positive number.');
        }
    }
    function validateFirmwareVersion(buildNumber) {
        setError("")
        const regex = /^\d+\.\d+\.\d+$/;
        const match = regex.test(buildNumber);
        if (!match) {
            setError("Please enter valid firmware version with format N.N.N")
        }
    }
    // Helper function to validate the date format (YYYYMMDD)
    function isValidYearMonthDay(date) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10);
        const day = parseInt(date.substring(6, 8), 10);

        const isValidDate = (year > 0 && month >= 1 && month <= 12 && day >= 1 && day <= 31);
        return isValidDate;
    }
    useEffect(() => {
        if (version) {
            setSoftwareVersion(version)
            setUpdateFlag(version.id)
        }
    }, [version])
    const handleFormSubmit = async (e) => {
        try {
            e.preventDefault()
            if (updateFlag) {
                Popup.alert('WAIT', "Updating software version, please wait...")
                const updatedVersion = await updateSoftwareVersion(softwareVersion)
                onVersionUpdateCallback(updatedVersion)
                Popup.alert('COMPLETE', "Software version updated successfully")
            } else {
                Popup.alert('WAIT', "Adding software version, please wait...")
                const addedVersion = await addSoftwareVersion(softwareVersion)
                onVersionAddCallback(addedVersion)
                Popup.alert('COMPLETE', "Software version added successfully")
            }
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e.message)
        }
    }
    const handleOnChange = (e) => {
        const { name, value } = e.target
        if (version.software_type == SOFTWARES.GREEN) {
            validateGreenOrangeBuildNo(value)
        } else if (version.software_type == SOFTWARES.ORANGE) {
            validateGreenOrangeBuildNo(value)
        } else if (version.software_type == SOFTWARES.FIRMWARE) {
            validateFirmwareVersion(value)
        }
        setSoftwareVersion(prev => ({ ...prev, [name]: value }))
    }
    const isBtnEnabled = () => {
        return softwareVersion.version_number && softwareVersion.software_type
    }
    return (
        <AnimatedModal
            isOpen={true}
            onRequestClose={onRequestClose}
            height="max-content"
            width="550px"
        >
            <div className='content-text' style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '20px', fontWeight: '500' }}>{version?.id ? "Update Version/Build" : "Add New Version/Build"}</div>
            <div style={{ height: '5px', width: '100%' }}><hr /></div>
            <form onSubmit={handleFormSubmit} >
                <div style={{ margin: '10px 50px', display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: "20px" }}>
                    <div>
                        <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>Version/Build Number</div>
                        <div className="add-user-form-text-wrapper">
                            <input
                                className="sub-add-user-form-text"
                                type="text"
                                name="version_number"
                                placeholder="Enter version number"
                                onChange={handleOnChange}
                                value={softwareVersion.version_number}
                            />
                            <div style={{ color: 'red', fontSize: '10px', textAlign: "left", width: '100%' }}>{error}</div>
                        </div>
                    </div>
                    {!updateFlag && <div>
                        <div className='sub-add-user-form-label' style={{ display: "flex" }}>Software type</div>
                        <select
                            className="sub-add-user-form-text"
                            style={{ width: "100%", borderRadius: "5px", fontSize: "12px" }}
                            name='software_type'
                            value={softwareVersion.software_type}
                            onChange={handleOnChange}
                        >
                            <option value={''} selected disabled>Select software type</option>
                            <option value={SOFTWARES.GREEN}>{SOFTWARES.GREEN}</option>
                            <option value={SOFTWARES.ORANGE}>{SOFTWARES.ORANGE}</option>
                            <option value={SOFTWARES.FIRMWARE}>{SOFTWARES.FIRMWARE}</option>
                        </select>
                    </div>}
                </div>
                <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                    <button
                        type='button'
                        className="addcustomer-button-empty-dashboard popup-btn-left"
                        onClick={onRequestClose}
                    >
                        Cancel
                    </button>
                    {updateFlag ?
                        <button
                            className="add-user-form-submit-button popup-btn-right btn-ok"
                            type='submit'
                        >
                            Update
                        </button>
                        :
                        isBtnEnabled() ?
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                type='submit'
                            >
                                Add
                            </button>
                            : <button disabled style={{ opacity: '0.5' }}
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                            >
                                Add
                            </button>
                    }

                </div>
            </form>
        </AnimatedModal>
    )
}

export default SoftwareVersionPopup