import React from 'react'
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { updateProfileDetails, updateSubscriptionData } from './redux';
import { connect } from "react-redux";
import * as API from './api.js';
const cookie = new Cookies();


class Logout extends React.Component {

   state = {

   }


   redirectIt() {
      if (this.state.redirect) {
         return <Redirect to='/login' />
      } else {
         return <div />
      }
   }

   componentDidMount() {
     
      this.props.activesubscription({
         activesubscription: undefined,
      });
      this.props.updateProfileDetails({
         activeTeamId: undefined,
         activeTeam: undefined,
         user: '',
         type: '',
         isLoggedInUserOwner: false
      })
      this.props.updateSubscriptionData({
         subscriptionData: undefined,
         user_sub_plans: undefined
      })
   }

   render() {
      API.logMeOut().then(r => {
         cookie.set('session_IAMAero', "", { path: '/' })
         sessionStorage.clear();
         localStorage.clear()
         window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))
      }, e => {
         console.log(e)
      })

      return (
         <div />
      )
   }

}
const mapDispatchToProps = dispatch => {
   return {
      updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
      updateSubscriptionData: (payload) => dispatch(updateSubscriptionData(payload))
   };
};

export default connect(mapDispatchToProps)(Logout);
