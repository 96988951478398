import { addProjectDetails } from "./redux";
import { addSidebarDetails } from "./redux";
import React, { Component, useEffect } from "react";
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import { Modal } from "react-bootstrap";
import * as API from "./api.js";
import "./style.css";
import { connect, useSelector } from "react-redux";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import location from "./icons/location.png";
import prev from "./icons/prev.png";
import next from "./icons/next.png";
import waiting from './res/waiting.png';
import alertIcon from './res/alert.png';
import done from './res/done.png';
import Geocode from "react-geocode";
import Popup from "reactjs-popup";
import closeButton from './close-button.png';
import { updateProfileDetails } from './redux'
import dateFormat from 'dateformat'
import { addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions";
import SingleMap from './ReusableComponents/map'
import L, { layerGroup } from "leaflet";
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import close from './icons/close.png'
import {
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import getArrows from './ReusableComponents/ArrowOnPolyline'
import ButtonWithIcon from "./ReusableComponents/ButtonWithIcon";
import { ActiveRole, getActiveTeam, getFeaturesPermissions, GCS_FEATURES } from "./Teams";
import AppConstants, { MAP_URLS } from "./AppConstants";
import Loader from './icons/loader.svg';
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");

// set response language. Defaults to english.
Geocode.setLanguage("en");

var planMarkers = []
var fenceMarkers = []
class RMAssignDrone extends Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      center: { lat: 20.008482, lng: 73.756834 },
      viewStatus: false,
      onlineDrones: 0,
      currentPage: 1,
      maxItemsPerPage: 5,
      selectedDroneId: 0,
      drones: [],
      onlineDrones: [],
      mission: [],
      geofence: [],
      displayPauseResume: false,
      loading: true,
      displayLatLong: false,
      fence: false,
      user: {},
      videoJsOptions: null,
      locations: "nashik",
      streamKeys: this.props.streamKeys,
      topics: this.props.topics,
      zoom: 12,
      circleStrokeColor: "orange",
      sendingError: false,
      sendingErrorMessage: "",
      circleFillColor: "orange",
      altitude: "",
      speed: "",
      project: {},
      plan: {},
      showingInfoWindow: false,
      type: "",
      speed: "",
      flightType: "",
      toggle: false,
      toggle1: false,
      toggle2: false,
      label: 1,
      label1: 1,
      lattitude: "",
      longitude: "",
      background: "white",
      displayWaypoint: false,
      displaySurvey: false,
      displaySpraying: false,
      sending: false,
      outId: "",
      sidebarMode: this.props.location.state
        ? this.props.location.state.sidebarMode
        : "shrunk",
      redirect: false,
      planEmptyModal: false,
      redirectToCreatePlan: false,
      processTileIndex: "NDVI",
      processTileMin: -0.283,
      processTileMax: 0.181,
      processTileType: "RGN",
      baseLayer: '',
      activeBaseLayer: 'satelite',
      selectedLayer: 'Orthomosaic',
      layerSelectionPopup: false,
      selectedLayerType: 'orthomosaic',
      layer: '',
      polyline: null,
      polygon: null,
      markers1: [],
      markers2: [],
      arrows: [],
      outId: ""
    };
  }

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  updateHistogram = async () => {
    return new Promise((resolve, reject) => {
      let taskId = this.state.plan && this.state.plan.linked_map ? this.state.plan.linked_map : null
      let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
      let sas = encodeURIComponent(this.state.activeTeam.teamOwner.st)
      let blobContainer = this.state.activeTeam.teamOwner.container
      let algorithm = processTileIndex
      API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
        this.setState((state) => ({
          ...state,
          processTileMin: data.statistics[1].percentiles[0].toFixed(4),
          processTileMax: data.statistics[1].percentiles[1].toFixed(4),
          processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
        }), () => {
          this.overlayTilesLayerOf("plantHealth")
        })
      })
    })
  }

  // To change base layer of map
  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })
  }

  removeLayer = async (layer) => {
    let removeLayer = this.state[layer]
    this.setState({ [layer]: undefined }, () => {
      if (removeLayer) {
        removeLayer.remove()
      }
    })
  }

  getTilesBounds = async (taskId) => {
    return new Promise((resolve, reject) => {
      let sas = encodeURIComponent(this.state.activeTeam.teamOwner.st)
      let blobContainer = this.state.activeTeam.teamOwner.container
      API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
        this.setState({
          tilesBounds: data.tilesBounds,
          bounds: data.bounds,
          //defaultOrtho: true,
          band1: "1",
          band2: "2",
          band3: "3",
        }, () => {
          resolve(this.state.bounds)
        })
      })
    })
  }

  updatePlanStyle = (type) => {
    let { map, plan, markers1, arrows } = this.state
    //-------- Change polyline, arrows, polygon and marker color according to layer --------
    let planColor = type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf'
    let fenceColor = type == 'orthomosaic' || type == 'base' ? 'red' : 'white'
    planMarkers.forEach(m => {
      m.setStyle({ color: planColor, fillColor: planColor })
    });
    if (arrows && arrows.length > 0)
      arrows.forEach(arrow => {
        arrow.remove()
      });
    arrows = getArrows(markers1, planColor, 1, map)
    L.featureGroup(arrows).addTo(map);
    this.setState({ arrows })
    fenceMarkers.forEach(m => {
      m.setStyle({ color: fenceColor, fillColor: fenceColor })
    });
    this.state.polyline.setStyle({ color: planColor });
    this.state.polygon.setStyle({ color: fenceColor });
    // ------------------------------------------------------------------------------
  }

  overlayTilesLayerOf = async (type) => {
    let { map, plan, markers1, arrows } = this.state
    this.removeLayer("orthoLayer").then(async () => {
      if (plan && plan.linked_map) {
        this.updatePlanStyle(type)
        let taskid = this.state.plan.linked_map
        let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
        let blobContainer = this.state.activeTeam.teamOwner.container;
        let sas = encodeURIComponent(this.state.activeTeam.teamOwner.st);
        await this.getTilesBounds(taskid)
        let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
        let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
        if (type == "plantHealth") {
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          orthoLayer.on("load", () => {
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        } else {
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          orthoLayer.on("load", () => {
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        }
      }
    })
  }

  changeSelectedLayer = (layer) => {
    if (layer == 'NDVI') {
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "NDVI",
        processTileType: "RGN",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'VARI') {
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "VARI",
        processTileType: "RGB",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'Orthomosaic') {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'orthomosaic'
      })
      this.overlayTilesLayerOf("orthomosaic")
    } else {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'base'
      })
      this.updatePlanStyle('base')
      this.removeLayer('orthoLayer')
    }
  }

  initMap = () => {
    let projectName = this.props.location.state.project.project_name;
    let planName = this.props.location.state.plan.plan_name;
    API.getPlan(projectName, planName).then(
      (data) => {
        try {
          if (data.planConfiguration[0]) {
            data.planConfiguration[0].ProjectDetails.plan_name = data.planConfiguration[0].ProjectDetails.Plan_Name
            let missionType = data.MissionStatistics[0].MissionStatistics.Mission_Type
            let resMission = data.Mission;
            let resGeofece = missionType == 1 || missionType == 2 ? data.DGCAfence : data.Geofence;
            let lat = resMission[0].waypoints["MSG x"] ? resMission[0].waypoints["MSG x"] : this.state.center.lat; // get lat 
            let lng = resMission[0].waypoints["Msg y"] ? resMission[0].waypoints["Msg y"] : this.state.center.lng; // get lng
            let latlng = { lat: lat, lng: lng };
            this.setState((state) => ({
              ...state,
              center: latlng,
              plan: data.planConfiguration[0].ProjectDetails,
              loading: false
            }));
            this.overlayTilesLayerOf("orthomosaic")
            this.setFlyView(resMission, resGeofece);
          } else {
            if (data.obstacle) {
              if (data.obstacle.polygons && data.obstacle.polygons.length > 0) {
                this.plotObstacleOnPlanView(data.obstacle.polygons)
              }
              if (data.obstacle.circles && data.obstacle.circles.length > 0) {
                this.plotCircleObstacleOnPlanView(data.obstacle.circles)
              }
            }
            data.planConfiguration.projectDetails[0].planName = data.planConfiguration.projectDetails[0].planName
            let missionType = data.MissionStatistics.missionStatistics[0].missionType
            let resMission = data.Mission;
            let resGeofece = missionType == 1 || missionType == 2 ? data.DGCAfence : data.Geofence;
            let lat = resMission.waypoints[0].lat ? resMission.waypoints[0].lat : this.state.center.lat; // get lat 
            let lng = resMission.waypoints[0].lon ? resMission.waypoints[0].lon : this.state.center.lng; // get lng
            let latlng = { lat: lat, lng: lng };
            this.setState((state) => ({
              ...state,
              center: latlng,
              plan: data.planConfiguration.projectDetails[0],
              loading: false
            }));
            this.overlayTilesLayerOf("orthomosaic")
            this.setFlyView1(resMission, resGeofece);  // call setFlyView
          }

        } catch (e) {
          console.log(e);
          this.setState({ planEmptyModal: true, loading: false })
        }
      },
      (e) => {
        console.log(e);
      }
    );

  };

  setFlyView = (mission, geofence) => {

    let { map } = this.state
    let markers1 = [];
    let markers2 = [];

    //initialize geofence circle
    var circle = {
      weight: 1,
      fillColor: this.state.circleFillColor,
      fillOpacity: 1,
      color: this.state.circleFillColor,
      radius: 9,
      zIndex: 2
    };

    var circle1 = {
      weight: 1,
      fillColor: "red",
      fillOpacity: 1,
      color: "red",
      radius: 7,
      zIndex: 2
    };

    for (let i = 0; i < mission.length; i++) {
      let lat = JSON.parse(mission[i].waypoints["MSG x"]);
      let lng = JSON.parse(mission[i].waypoints["Msg y"]);
      let latlng = [lat, lng];
      markers1.push(latlng);
    }

    let k = geofence.length;
    for (let i = 0; i < k - 1; i++) {
      let lat = JSON.parse(geofence[i].geofence["geofence X"]);
      let lng = JSON.parse(geofence[i].geofence["geofence Y"]);
      let latlng = [lat, lng];
      markers2.push(latlng);
      let marker = L.circleMarker(latlng, circle1)
      marker.addTo(map)
    }
    var polygon = L.polygon(markers2, {
      color: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      fillOpacity: 0.2,
      fillColor: '#3c3c3c',
      zIndex: 0
    }).addTo(map);

    let polyline = new L.polyline(markers1, {
      color: "orange",
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 1
    }).addTo(map);

    let arrows = getArrows(markers1, 'orange', 1, map)
    L.featureGroup(arrows).addTo(map);

    markers1.forEach((m, i) => {
      let marker = L.circleMarker(m, circle).bindTooltip(`${i + 1}`,
        {
          className: 'tooltip-label',
          permanent: true,
          direction: 'center',
        })
      marker.addTo(map)
      planMarkers.push(marker)
    })

    markers2.forEach((m, i) => {
      let marker = L.circleMarker(m, circle1)
      marker.addTo(map)
      fenceMarkers.push(marker)
    })
    map.fitBounds(polygon.getBounds());
    this.setState({ polygon, polyline, markers1, markers2 })
  };

  setFlyView1 = (mission, geofence) => {

    let { map } = this.state
    let markers1 = [];
    let markers2 = [];

    //initialize geofence circle
    var circle = {
      weight: 1,
      fillColor: this.state.circleFillColor,
      fillOpacity: 1,
      color: this.state.circleFillColor,
      radius: 9,
      zIndex: 2
    };

    var circle1 = {
      weight: 1,
      fillColor: "red",
      fillOpacity: 1,
      color: "red",
      radius: 7,
      zIndex: 2
    };

    for (let i = 0; i < mission.waypoints.length; i++) {
      let lat = JSON.parse(mission.waypoints[i].lat)
      let lng = JSON.parse(mission.waypoints[i].lon)
      let latlng = [lat, lng];
      markers1.push(latlng);
    }

    let k = geofence.geofence.length;
    for (let i = 0; i < k - 1; i++) {
      let lat = JSON.parse(geofence.geofence[i].lat);
      let lng = JSON.parse(geofence.geofence[i].lon);
      let latlng = [lat, lng];
      markers2.push(latlng);
      let marker = L.circleMarker(latlng, circle1)
      marker.addTo(map)
    }
    var polygon = L.polygon(markers2, {
      color: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      fillOpacity: 0.2,
      fillColor: '#3c3c3c',
      zIndex: 0
    }).addTo(map);

    let polyline = new L.polyline(markers1, {
      color: "orange",
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 1
    }).addTo(map);

    let arrows = getArrows(markers1, 'orange', 1, map)
    // L.featureGroup(arrows).addTo(map);

    markers1.forEach((m, i) => {
      if (i == 0 || i == markers1.length - 1) {
        let marker = L.circleMarker(m, circle).bindTooltip(`${i + 1}`,
          {
            className: 'tooltip-label',
            permanent: true,
            direction: 'center',
          })
        marker.addTo(map)
        planMarkers.push(marker)
      }
    })

    markers2.forEach((m, i) => {
      let marker = L.circleMarker(m, circle1)
      marker.addTo(map)
      fenceMarkers.push(marker)
    })
    map.fitBounds(polygon.getBounds());
    this.setState({ polygon, polyline, markers1, markers2 })
  };

  plotObstacleOnPlanView = (obstaclePolygaon) => {
    let { map } = this.state
    for (let i = 0; i < obstaclePolygaon.length; i++) {
      var obstaclePolygon = L.polygon(obstaclePolygaon[i].vertices.map((latlng) => { return { lat: latlng.lat, lng: latlng.lon } }), {
        color: '#2989CF',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        fillOpacity: 0.2,
        fillColor: '#3c3c3c',
        zIndex: 0
      }).addTo(map);
    }
  }

  plotCircleObstacleOnPlanView = (obstacleCircle) => {
    let { map } = this.state
    for (let i = 0; i < obstacleCircle.length; i++) {
      var obstacleCir = L.circle({ lat: obstacleCircle[i].centroid.lat, lng: obstacleCircle[i].centroid.lon }, obstacleCircle[i].radius, {
        color: '#2989CF',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        fillOpacity: 0.2,
        fillColor: '#3c3c3c',
        zIndex: 0
      }).addTo(map);
    }
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  setTeam = async () => {
    return new Promise((resolve, reject) => {
      API.getTeamsList().then(async (teams) => {
        let ownerTeam = teams.find(team => this.state.user.userid == team.owner_id)
        resolve()
      })
    })
  }

  successRedirectComponent = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: `/drone_control_center/fly_view?drone=${this.state.selectedDroneId}`,
            state: {
              user: this.state.user,
              type: this.state.type,
              center: this.state.center,
              sidebarMode: this.state.sidebarMode,
            },
          }}
        />
      );
    }
  };
  expandButtons = (event) => {
    event.preventDefault();
    if (this.state.displayPauseResume === false) {
      this.setState((prevState) => ({
        ...prevState,
        displayPauseResume: true,
      }));
    } else if (this.state.displayPauseResume === true) {
      this.setState((prevState) => ({
        ...prevState,
        displayPauseResume: false,
      }));
    }
  };
  expandLatLong = (event) => {
    event.preventDefault();
    if (this.state.displayLatLong === false) {
      this.setState((prevState) => ({
        ...prevState,
        displayLatLong: true,
      }));
    } else if (this.state.displayLatLong === true) {
      this.setState((prevState) => ({
        ...prevState,
        displayLatLong: false,
      }));
    }
  };

  changePage = (direction) => {
    if (direction == "prev") {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    } else if (direction == "next") {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };

  getOwnerTeam = async (teams) => {
    return new Promise((resolve, reject) => {
      let { user } = this.state
      teams.map(team => {
        if (user.userid == team.owner_id) {
          resolve(team)
        }
      })
    })
  }

  uploadPlan = (droneId) => {
    let planName = this.props.location.state.plan.plan_name;
    let projectName = this.props.location.state.project.project_name;
    API.uploadPlan(droneId, projectName, planName).then(
      (data) => {
        if (data.message == "OK") {
          this.setState((prevState) => ({
            ...prevState,
            sending: true,
            sendingError: false,
            selectedDroneId: droneId,
            viewStatus: false
          }), () => {
            document.getElementById("send-plan-popup-button").click();
          })

        } else if (data.message == 'No such AeroStack found') {
          alert("This drone is not linked with an AeroStack")
        } else if (data.error) {
          alert(data.error)
        }
      },
      (e) => {
        console.log(e);
      }
    );
  };
  componentDidMount() {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.popup-content { width: 30% !important; }';
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  componentWillUnmount() {
    updateOutTime(this.state.outId)
    clearInterval(this.pollDronesInterval)
  }

  pollDrones = (isFirstTime) => {
    let state = this.props.location.state;
    API.GetAllDronesStatus().then(
      async (data) => {
        let result = [];
        let count = 0;
        if (data.drones.length) {
          data.drones.forEach(async (element, i) => {
            if (
              element.teleData == "undefined" ||
              element.teleData == undefined
            ) {
              element.Address = "Not available";
              result.push(element);
              count++;
              if (count == data.drones.length) {
                if (result.filter(drone => drone.online).length == 0) {
                  this.setState({
                    selectedDroneIsBusy: false
                  })
                }
                if (isFirstTime) {
                  this.setState((prevState) => ({
                    ...prevState,
                    drones: result,
                    selectedDroneId: result.filter(drone => drone.online).length ? result.filter(drone => drone.online)[0].drone_id : null,
                    loading: false
                  }));
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    drones: result,
                    selectedDroneId: result.filter(drone => drone.online).length ? result.filter(drone => drone.online)[0].drone_id : null,
                    loading: false
                  }));
                }
              }
            } else {
              let lat = element.teleData.lat;
              let lng = element.teleData.lon;
              let add = "asd"
              element.Address = add;
              result.push(element);
              count++;
              if (count == data.drones.length) {
                if (result.filter(drone => drone.online).length == 0) {
                  this.setState({
                    selectedDroneIsBusy: false
                  })
                }
                if (isFirstTime) {
                  this.setState((prevState) => ({
                    ...prevState,
                    drones: result,
                    selectedDroneId: result.filter(drone => drone.online).length ? result.filter(drone => drone.online)[0].drone_id : null,
                  }));
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    drones: result,
                    selectedDroneId: result.filter(drone => drone.online).length ? result.filter(drone => drone.online)[0].drone_id : null,
                  }));
                }

              }
            }
            // }
            i++;
          });
        } else {
          if (isFirstTime) {
            this.setState((prevState) => ({
              ...prevState,
              drones: [],
              selectedDroneId: null,
            }));
          } else {
            this.setState((prevState) => ({
              ...prevState,
              drones: [],
            }));
          }
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }
  redirectToCreatePlan = () => {
    let { user, type, project } = this.state
    if (this.state.redirectToCreatePlan) {
      return <Redirect to={{
        pathname: "/remote_mission/selectLocation",
        state: {
          user,
          type,
          sidebarMode: this.state.sidebarMode,
          drawPlan: true,
          editPlan: true,
          projectName: this.state.project.project_name ? this.state.project.project_name : this.state.project.projectName,
          planName: this.state.plan.plan_name ? this.state.plan.plan_name : this.state.plan.planName,
          project
        }

      }} />
    }
  }
  getPlan = async () => {
    await this.props.addProjectDetails(
      {
        projectName: this.props.location.state.project.project_name,
        clientName: "",
        planName: this.props.location.state.plan.plan_name,
        dateAndTime: dateFormat(new Date(), 'mmm dd, yyyy HH:MM:ss ')
      })
    this.setState((state) => ({
      redirectToCreatePlan: true
    }));
  }
  drawPlan = () => {
    if (this.props.location.state.project.project_name != "" &&
      this.props.location.state.plan.plan_name != "") {
      this.getPlan()
    }
    else {
      alert('All fields are necessary')
    }
  }


  componentWillMount() {
    this.props.addSidebarDetails(
      {
        plan: this.props.location.state.plan,
        project: this.props.location.state.project,
      })
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam: activeTeam
      }, () => {
        getFeaturesPermissions([GCS_FEATURES.PLANS])
          .then(permissions => {
            this.setState(state => ({ ...state, permissions }), () => {
              addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
              try {
                this.pollDrones(true)
                setTimeout(() => {
                  this.pollDrones(true)
                }, 2000)
                setTimeout(() => {
                  this.pollDrones(true)
                }, 5000)
              } catch (e) {

              }
              // get drones status at every 15 seconds
              this.pollDronesInterval = setInterval(() => {
                try {
                  this.pollDrones()
                } catch (e) {

                }
              }, 15000)

              if (this.props.location.state.project && this.props.location.state.plan) {
                this.setState((prevState) => ({
                  ...prevState,
                  project: this.props.location.state.project,
                  plan: this.props.location.state.plan
                }));
              }


              this.setState((prevState) => ({
                ...prevState,
                gmarkers: this.props.location.gmarkers,
                gmarkers: this.props.location.gmarkers,
              }));

              if (!this.props.location.state) {
                API.getUserType().then(
                  ([registered, type, user]) => {
                    // if promise is resolved, update state and redirect user
                    this.setState((state) => ({
                      ...state,
                      registered,
                      type,
                      user,
                    }));
                  },
                  (e) => {
                    // if promise is rejected, log the error, and display message to user
                    this.setState({
                      user: {},
                      type: "unknown",
                    });
                    window.location.replace("/login");
                  }
                );
              } else {
                if (this.props.location.state.config) {
                  this.setState((state) => ({
                    ...state,
                    user: this.props.location.state.config.user,
                    type: this.props.location.state.config.type,
                  }));
                } else if (this.props.location.state.user) {
                  this.setState((state) => ({
                    ...state,
                    user: this.props.location.state.user,
                    type: this.props.location.state.type,
                  }));
                }
              }
            })
          })
      })
    })
  }


  changeDrone = (e) => {
    e.preventDefault();
    let temp = e.target.value;
    this.state.drones.forEach((drone) => {
      if (drone.drone_id == temp) {
        this.setState((state) => ({
          ...state,
          selectedDroneIsBusy: drone.busy
        }));
      }
    })
    this.setState((state) => ({
      ...state,
      selectedDroneId: temp
    }));
  };

  render() {
    let { user, type, sidebarMode } = this.state;
    return (
      <>
        <div className="wrapper">
          {this.successRedirectComponent()}
          {this.redirectToCreatePlan()}
          <div className="right-content-new"
            onClick={
              () => {
                this.setState((state) => ({
                  ...state, sidebarMode: 'shrunk'
                }))
              }
            }
            style={{ overflowY: "hidden" }}>
            {/* plan empty modal */}
            <Modal
              style={{ marginTop: "150px" }}
              show={this.state.planEmptyModal}
              onHide={() => {
                this.setState((state) => ({
                  ...state,
                  planEmptyModal: false,
                }));
              }}
            >
              <Modal.Body>
                <p>There is no mission plan, Would you like to create one ?</p>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                  <ButtonWithIcon
                    text="Cancel"
                    width="100px"
                    onClick={() => {
                      this.setState((state) => ({
                        ...state,
                        planEmptyModal: false,
                      }));
                    }}
                  />
                  <ButtonWithIcon
                    text="Create"
                    width="100px"
                    isBtnActive
                    onClick={() => {
                      this.setState(
                        (state) => ({
                          ...state,
                          planEmptyModal: false,
                        }), () => {
                          this.drawPlan()
                        }
                      );
                    }}
                  />
                </div>
              </Modal.Footer>
            </Modal>
            {/* plan empty modal end */}
            <Popup
              style={{ width: "min-content" }}
              trigger={<button id="send-plan-popup-button" style={{
                display: "none"
              }}></button>}
              modal
            >
              <img src={closeButton} style={{
                width: "auto",
                height: "26px",
                position: "absolute",
                top: "-13px",
                right: "-13px",
                cursor: "pointer"

              }} onClick={() => { document.getElementById("send-plan-popup-button").click(); }}></img>
              {
                this.state.sending ? (this.state.sendingError ? <div >
                  <div style={{ width: "100%", display: "flex" }}>
                    <img src={alertIcon} style={{
                      width: "auto",
                      height: "64px",
                      margin: "30px auto"
                    }} />
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>Error</div>
                  <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>{this.state.sendingErrorMessage}</div>
                </div> : <div >
                  <div style={{ width: "100%", display: "flex" }}>
                    <img src={waiting} style={{
                      width: "auto",
                      height: "64px",
                      margin: "30px auto"
                    }} />
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>Receive plan command sent to drone.</div>
                  <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>Waiting for drone to get plan from server...</div>
                </div>) : <div>
                  <div style={{ width: "100%", display: "flex" }}>
                    <img src={done} style={{
                      width: "auto",
                      height: "64px",
                      margin: "30px auto"
                    }} />
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>Plan received by drone!</div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <div
                      className="addcustomer-button-empty-dashboard"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/drone_control_center/fly_view?drone=' + this.state.selectedDroneId;
                      }}
                      style={{
                        margin: "20px auto",
                        cursor: "pointer"
                      }}
                    >
                      Go to fly view
                    </div>
                  </div>
                </div>
              }
            </Popup>
            <div className="top-bar-new" style={{ display: 'flex' }}>
              <div className="breadCrumbs">
                <BreadCrumbs data={[
                  {
                    displayName: "projects",
                    pathname: "/projects",
                    state: {
                      type,
                      user,
                    }
                  },
                  {
                    displayName: this.state.project.project_name,
                    pathname: "/plans",
                    state: {
                      type,
                      user,
                      project: this.state.project
                    }
                  },
                  {
                    displayName: this.state.plan.plan_name ? this.state.plan.plan_name : this.state.plan.planName
                  }
                ]} />
                <ActiveRole
                  hideBrackets={true}
                />
              </div>
            </div>

            <div className="main-content-new" style={{ padding: "0", height: "calc(100vh - 40px)", position: "relative" }}>
              {/* <SnackBar  user={user} sidebarMode={sidebarMode} type={type} style = {snackBarStyle} /> */}
              {this.state.loading ? <div style={{ height: "100vh", width: "91vw", display: "flex", textAlign: "center", zIndex: 1 }}>
                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
              </div> : <> </>}
              <div style={{ width: "100%", height: "calc(100vh - 70px)", }}>
                <SingleMap
                  setBaseLayerToState={this.setBaseLayerToState}
                  initCenter={[this.state.center.lat, this.state.center.lng]}
                  initZoom={18}
                  // bounds={this.getCurrentMapExtent()}
                  handleMapClick={this.handleMapClick}
                  // handleMoveStart={this.props.handleMoveStart}
                  maxZoom={23}
                  setMap={(map) => {
                    this.setState({ map }, this.initMap)
                  }}
                />
              </div>
              {/* changeBaseMapView  */}
              <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '24px', right: '18px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                onClick={() => {
                  this.switchBaseLayer()
                }}>
                <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
              </div>

              {/* Layer selection popup */}
              {this.state.plan && this.state.plan.linked_map ? <div className='slide' style={{ textAlign: 'center', border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '24px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', zIndex: '1', fontSize: "12px", fontWeight: 500 }}
                onClick={() => {
                  this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                }}>
                {this.state.selectedLayer}
              </div> : <></>}
              {this.state.layerSelectionPopup && this.state.plan && this.state.plan.linked_map ?
                <div style={{ position: 'absolute', width: "105px", fontSize: "12px", bottom: '56px', right: '118px', backgroundColor: 'white', borderRadius: '5px', zIndex: '1', padding: "1px", fontWeight: 500 }} className="content-text">
                  <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    Orthomosaic
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    NDVI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    VARI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    BASE
                  </div>
                </div> : <></>}


              {this.state.viewStatus && (
                <div
                  className="page2"
                  style={{
                    top: "20%",
                    width: "auto",
                    minWidth: "500px",
                    // maxWidth:"580px",
                    height: "auto",
                    minHeight: "432px",
                    left: "30%",
                    position: 'absolute',
                    // padding: "20px 20px 5px 26px",
                    padding: "0px",
                    borderRadius: "0px",
                    zIndex: "0",
                  }}
                >
                  <div
                    style={{
                      marginTop: "14px",
                      marginLeft: "25px",
                      marginBottom: "14px",
                      fontFamily: "Poppins",
                      display: "flex"
                    }}
                  >
                    <div className="services-content-title" >Currently Available Drones</div>
                    <div style={{ marginLeft: "auto", marginRight: "12px" }}>
                      <img src={close} style={{ height: "16px", width: "16px" }} type="button" onClick={() => {
                        this.setState((prevState) => ({
                          ...prevState,
                          viewStatus: false
                        }))
                      }} />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#0089CF",
                      height: "2px",
                      width: "100%",
                    }}
                  ></div>
                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <div style={{ width: "30px" }}>
                      {this.state.currentPage > 1 ? (
                        <img
                          style={{
                            cursor: "pointer",
                            height: "25px",
                            width: "25px",
                            marginTop: "150px",
                            marginLeft: "15px",
                          }}
                          src={prev}
                          onClick={() => this.changePage("prev")}
                        />
                      ) : (
                        <img
                          src={prev}
                          style={{
                            marginTop: "150px",
                            width: "25px",
                            height: "25px",
                            opacity: "0.3",
                            marginLeft: "15px",
                          }}
                        />
                      )}
                    </div>

                    <div>
                      <div style={{ width: "auto", minWidth: "430px" }}>
                        {this.state.drones
                          .slice(
                            this.state.currentPage *
                            this.state.maxItemsPerPage -
                            this.state.maxItemsPerPage,
                            this.state.currentPage * this.state.maxItemsPerPage
                          )
                          .map((log) => (
                            <div>
                              <div
                                style={{ display: "flex", marginTop: "-5px" }}
                              >
                                <div>
                                  <div className="font-color-primary"
                                    style={{
                                      marginLeft: "25px",
                                      marginTop: "0px",
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {log.drone_name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "23px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <img
                                      style={{ height: "20px", width: "20px" }}
                                      src={location}
                                    />
                                    <div className="font-color-secondary"
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "14px",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      {log.Address}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="addcustomer-button-empty-dashboard" style={{ borderRadius: "10px", marginLeft: "auto", padding: "6px", marginRight: "20px", marginTop: "-8px" }}>Send To Drone</div> */}
                                {log.online == true ? <>  <button
                                  className="addcustomer-button-empty-dashboard"
                                  // type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.uploadPlan(log.drone_id);
                                  }}
                                  style={{
                                    borderRadius: "10px",
                                    marginLeft: "auto",
                                    padding: "6px",
                                    marginTop: "10px",
                                    height: "28px",
                                    marginRight: "16px",
                                    fontWeight: "400",
                                    cursor: "pointer"
                                  }}
                                // disabled="true"
                                >
                                  Send To Drone
                                </button>
                                  <button
                                    className="addcustomer-button-empty-dashboard"
                                    // type="button"
                                    onClick={(e) => {
                                      { window.open("/drone_control_center/fly_view?drone=" + log.drone_id, '_blank'); }

                                    }}
                                    style={{
                                      borderRadius: "10px",
                                      marginLeft: "auto",
                                      marginLeft: "0px",
                                      padding: "6px",
                                      marginTop: "10px",
                                      height: "28px",
                                      marginRight: "10px",
                                      fontWeight: "400",
                                      cursor: "pointer"
                                    }}
                                  >
                                    Fly View
                                  </button> </>
                                  : <>

                                  </>}

                              </div>
                              <hr
                                style={{
                                  marginLeft: "23px",
                                  marginTop: "10px",
                                }}
                              />
                            </div>
                          ))}
                        {this.state.drones.length == 0 && (
                          <div
                            style={{
                              fontColor: "grey",
                              marginTop: "20px",
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              marginLeft: "105px",
                              opacity: "0.5",
                            }}
                          >
                            <p>No Drone Available Now</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ width: "30px" }}>
                      {this.state.drones.length - 1 >
                        this.state.currentPage * this.state.maxItemsPerPage ? (
                        <img
                          style={{
                            cursor: "pointer",
                            height: "25px",
                            width: "25px",
                            marginTop: "150px",
                            marginLeft: "auto",
                            marginRight: "12px",
                          }}
                          src={next}
                          onClick={() => this.changePage("next")}
                        />
                      ) : (
                        <img
                          src={next}
                          style={{
                            height: "25px",
                            width: "25px",
                            marginTop: "150px",
                            marginLeft: "auto",
                            marginRight: "12px",
                            opacity: "0.3",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectName: state.project.projectName,
    clientName: state.project.clientName,
    planName: state.project.planName,
    dateAndTime: state.project.dateAndTime,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    addProjectDetails: (payload) => dispatch(addProjectDetails(payload)),
    addSidebarDetails: (payload) => dispatch(addSidebarDetails(payload))
  };
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(RMAssignDrone));