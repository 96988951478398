import React, { Component } from 'react';

import * as API from './api.js';
import './style.css';
import { Modal } from "react-bootstrap";
import success from './assets/check_circle_24px.png';
import fail from './assets/error.png';
import closeButton from './close-button.png';
import waiting from './res/waiting.png';

import { Link, StaticRouter, Redirect } from 'react-router-dom';
import AppConstants from './AppConstants.js';
export default class AdminAddChangeSubscription extends Component {

    state = {
        user: {},
        type: "",
        plans: [],
        plan_id: 0,
        users: {
            first_name: "",
            last_name: "",
            username: "",
            address: "",
            password: "",
            phone: "",
            password: "-"
        },
        redirect: false,
        successParams: {
            title: "EDIT USER",
            planLengthDays: 0,
            message1: "User has been edited successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "View Users",
            buttonLink: "/admin/users",
        },
        config: {},
        planItems: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        addSubPopup: false,
        popupText: "",
        updateSubPopup: false,
        selected_plan: {},
        alertPopup: false
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getSubscriptionPlan = () => {
        API.getSubscriptionPlan().then((data) => {
            let planData = []
            let userSubServices = this.state.user_info.subs.map((sub) => { return sub.plan_name })
            planData = data.plans.filter((plan) => userSubServices.indexOf(plan.plan_name) == -1 && plan.status == "Active" && (plan.service == AppConstants.SERVICES.AEROGCS || plan.service == AppConstants.SERVICES.DRONE_CONTROL_CENTER))
            this.setState((state) => ({
                ...state,
                plans: planData,
                loading: false,
                plan_id: planData[0].id
            }), () => {
                this.getSubscriptionPlanItems()
            })
        })
    }

    // Get subscription plan items list under the plan
    getSubscriptionPlanItems = () => {
        let sel_plan = this.state.plans.find(data => data.id == this.state.plan_id)
        let existing_plan = this.state.user_info.subs.find((data) => data.service == sel_plan.service)

        this.setState((state) => ({
            ...state,
            selected_plan: sel_plan,
            existing_plan: existing_plan
        }))

        API.getSubscriptionPlanItems(this.state.plan_id).then((data) => {
            this.setState((state) => ({
                ...state,
                planItems: data.items,
                loading: false
            }))
        })
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }), () => {
            this.getSubscriptionPlanItems()
        })
    }

    componentWillMount() {
        document.title = "Edit Users - Management Services";
        if (!this.props.location.state) {
            API.getUserType().then(([registered, type, user, user_info]) => {
                // if promise is resolved, update state and redirect user
                this.setState(state => ({
                    ...state,
                    loading: false,
                    registered,
                    type,
                    user,
                    user_info
                }))
            }, (e) => {
                // if promise is rejected, log the error, and display message to user
                this.setState({
                    user: {},
                    loading: false,
                    type: 'unknown'
                })
                window.location.replace("/login");
            })
        } else {
            if (this.props.location.state.config) {
                this.setState((state) => ({
                    ...state,
                    user: this.props.location.state.config.user,
                    loading: false,
                    type: this.props.location.state.config.type,
                    user_info: this.props.location.state.user_info
                }), () => {
                    this.getSubscriptionPlan()
                })
            } else if (this.props.location.state.user) {
                this.setState((state) => ({
                    ...state,
                    user: this.props.location.state.user,
                    loading: false,
                    type: this.props.location.state.type,
                    user_info: this.props.location.state.user_info
                }), () => {
                    this.getSubscriptionPlan()
                })
            }
        }
    }

    addOrUpgradePlan = () => {
        let popupMessage = this.state.existing_plan === undefined ? "Adding Subscription Plan, Please Wait..." : "Updating Subscription Plan, please wait..."
        this.setState((state) => ({
            ...state,
            popupText: popupMessage,
            createIcon: waiting,
        }))
        let date = new Date()
        var expiry_date = new Date(date.setMonth(date.getMonth() + this.state.planLengthDays));
        expiry_date = expiry_date.toISOString()
        let type = "Manual"
        if (this.state.existing_plan === undefined) {
            console.log("New Service")
            API.saveUserSubscriptionPlan(this.state.plan_id, expiry_date, this.state.user_info.userid, type).then(async (data) => {
                if (data.status == 200) {
                    this.setState((state) => ({
                        ...state,
                        popupText: `User is subscribed to plan '${this.state.selected_plan.plan_name}' Successfully`,
                        createIcon: success,
                    }))
                } else {
                    let res = await data.json()
                    this.setState((state) => ({
                        ...state,
                        popupText: res.message,
                        createIcon: fail
                    }))
                }
            }, (e) => {
                this.setState((state) => ({
                    ...state,
                    popupText: "Something went wrong... ",
                    createIcon: fail
                }))
            })
        }
        else {
            console.log("Upgrade service : ", this.state.existing_plan);
            API.updateUserSubscriptionPlan(this.state.existing_plan.id, this.state.plan_id, expiry_date, this.state.user_info.userid, type).then((data) => {
                if (data.message == "User subscription plan updated.") {
                    this.setState((state) => ({
                        ...state,
                        popupText: 'Plan Updated Successfully',
                        createIcon: success,
                    }))
                } else {
                    this.setState((state) => ({
                        ...state,
                        popupText: data.message,
                        createIcon: fail
                    }))
                }
            }, (e) => {
                console.log(e);
                this.setState((state) => ({
                    ...state,
                    popupText: "Something went wrong... ",
                    createIcon: fail
                }))
            })
        }
    }

    successRedirectComponent = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/admin/userdetails",
                state: {
                    user: this.props.location.state.user,
                    type: this.props.location.state.type,
                    user_info: this.state.user_info,
                    sidebarMode: this.state.sidebarMode
                }
            }} />
        }
    }
    render() {
        let { user, user_info, type, customerDisplay, organizations } = this.state

        return (
            user.username ? <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    {/* ----- Update comfirmation popup ----- */}
                    <Modal
                        // style={{ marginTop: '150px' }}
                        show={this.state.updateSubPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                updateSubPopup: false,

                            }));
                        }}
                        className="modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body >
                            <p>Your existing plan '{this.state.existing_plan ? this.state.existing_plan.plan_name : ''}' will be replaced by the new plan : '{this.state.selected_plan.plan_name}' .</p>
                            <p>Are you sure you want to update existing plan ?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button
                                    className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            updateSubPopup: false,
                                        }));
                                    }}
                                >
                                    No
                                </button>
                            </div>
                            <div>
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    onClick={() => {
                                        this.setState((state) => ({
                                            ...state,
                                            updateSubPopup: false,
                                            createIcon: waiting,
                                            addSubPopup: true
                                        }), () => {
                                            this.addOrUpgradePlan()
                                        })
                                    }}
                                >
                                    Yes
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    {/* ------------------------------------- */}

                    <Modal
                        // style={{ marginTop: '150px' }}
                        show={this.state.addSubPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                addSubPopup: false,
                                redirect: true
                            }));
                        }}
                        className="modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body >
                            <img
                                id="close-popup"
                                src={closeButton} style={{
                                    width: "auto",
                                    height: "26px",
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-13px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addSubPopup: false,
                                        redirect: true,
                                    }))
                                }}></img>
                            <div >
                                <div style={{ width: "100%", display: "flex" }}>
                                    <img src={this.state.createIcon} style={{
                                        width: "auto",
                                        height: "64px",
                                        margin: "30px auto",
                                    }} />
                                </div>
                                <>
                                    <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                        this.state.popupText
                                    }</div>
                                </>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* ------------- Alert popup ------------ */}
                    <Modal
                        // style={{ marginTop: '150px' }}
                        show={this.state.alertPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                alertPopup: false,
                            }));
                        }}
                        className="modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body >
                            <img
                                id="close-popup"
                                src={closeButton} style={{
                                    width: "auto",
                                    height: "26px",
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-13px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        alertPopup: false,
                                    }))
                                }}></img>
                            <div >
                                <div style={{ width: "100%", textAlign: "center", marginTop: '30px' }}>{
                                    this.state.popupText
                                }</div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* ----------------------------------------- */}

                    <div className="top-bar-new"><div className="top-bar-text-new">ADD SUBSCRIPTION</div></div>
                    <div className="main-content">
                        <div className="page" style={{ minHeight: "88vh", minWidth: "60vw", width: "60vw" }}>
                            {/* <div className="services-content-title">{"User Information"}</div> */}
                            {this.successRedirectComponent()}
                            <div style={{ display: "flex", width: "92vw" }}>
                                <div style={{ display: "flex", width: "58%" }}>
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">User Name:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.first_name} ${user_info.last_name}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">User Email:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.username}`}</div>
                                    </div>
                                </div>
                            </div>

                            <form >
                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                            Plan
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{ width: "25vw" }}>
                                            <select
                                                name="plan_id"
                                                onChange={this.onChangeListener}
                                                value={this.state.plan_id}
                                                className="sub-add-user-form-text"
                                            >
                                                {this.state.plans.map((plan) => {
                                                    return <option value={plan.id}>{plan.plan_name} {plan.plan_type}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="add-user-form-row-right" style={{ marginLeft: "3%" }}>
                                        <div className="add-user-form-label">
                                            Subscription Period
                                        </div>
                                        <div className="add-user-form-text-wrapper" style={{
                                            display: "flex",
                                            width: "28vw"
                                        }}>
                                            <input className="sub-add-user-form-text" type="number" value={this.state.planLengthDays} onChange={(e) => {
                                                let v = e.target.value
                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    planLengthDays: parseInt(v)
                                                }))
                                            }} min="0"></input>
                                            <div style={{ position: "relative", right: "70px", top: "11px" }}>months</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-row" style={{ display: "block" }}>
                                    <div style={{
                                        fontWeight: "bold",
                                        fontSize: "24px",
                                        marginBottom: "20px"
                                    }}>Plan Details</div>
                                </div>
                                <div style={{ width: "92%", height: "300px", marginLeft: "4%" }}>
                                    <table className="customers-table">
                                        <col style={{ width: '70%' }} />
                                        <col style={{ width: '45%' }} />

                                        <tr className="customers-table-header-row">
                                            <th>Name</th>
                                            <th >Limit</th>
                                        </tr>
                                        {this.state.planItems.length === 0 ?
                                            <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                                <td colspan="4">
                                                    <p style={{ fontSize: '14px' }}>No Items available</p>
                                                </td>
                                            </tr> :
                                            this.state.planItems.map((item) =>
                                                <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                                    <td> {item.item_name} </td>
                                                    <td > {item.limits} {item.unit != 'count' ? item.unit : ''} </td>
                                                </tr>
                                            )}
                                    </table>
                                </div>

                                <div className="add-user-form-action-row" style={{ float: "right" }}>
                                    <button type="submit" className="add-user-form-submit-button" style={{
                                        width: "min-content"
                                    }} onClick={(e) => {
                                        e.preventDefault()
                                        if (!this.state.planLengthDays || this.state.planLengthDays == 0) {
                                            this.setState((state) => ({
                                                ...state,
                                                popupText: 'Please Enter Subscription Period.',
                                                alertPopup: true
                                            }))
                                        }
                                        else if (this.state.existing_plan === undefined) {
                                            this.setState((state) => ({
                                                ...state,
                                                addSubPopup: true
                                            }), () => {
                                                this.addOrUpgradePlan()
                                            })
                                        }
                                        else {
                                            this.setState((state) => ({
                                                ...state,
                                                updateSubPopup: true
                                            }))
                                        }
                                    }}>
                                        Add
                                    </button>
                                    <div className="addcustomer-button-empty-dashboard" onClick={() => {
                                        window.history.back();
                                    }} style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        textDecoration: "none"
                                    }}>
                                        Cancel
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> : <div />
        );
    }
}
