import React, { useEffect, useState } from 'react'
import Ping from 'ping.js'

export default function NetworkChecker(props) {
	let { bandwidthServer, pingServers, bandwidthCallback, pingCallback } = props
	let ping = new Ping()
	let [inBandwidthInterval, setInBandwidthInterval] = useState(false)
	let [inPingInterval, setInPingInterval] = useState(false)

	useEffect(() => {
		const pingInterval = setInterval(() => {
			try {
				if (!inPingInterval) {
					setInPingInterval(true)
					let ret = {}
					let count = 0
					Object.keys(pingServers).forEach(server => {
						ping.ping(pingServers[server], (err, data) => {
							if (!err) {
								pingCallback([
									server, data
									])
							} else {
							}
						})
					})
				}
			} catch(e) {
				console.log(e)
			}
		}, 3000)

		const bandwidthInterval = setInterval(() => {
			try {
				if (!inBandwidthInterval) {
					setInBandwidthInterval(true)
					let xhr = new XMLHttpRequest();
					let startTime = null
					xhr.open('GET', bandwidthServer.url + "?qwe=" + Date.now(), true);
					xhr.onloadstart = function () {
						startTime = Date.now()
					}
					xhr.onload = function() {
						let timeTaken = (Date.now() - startTime) / 1000
						bandwidthCallback(bandwidthServer.size / timeTaken)
						setInBandwidthInterval(false)
					}.bind(this);
					xhr.onerror = function(e) {
						console.log(e)
					}
					xhr.send(null)
				}
			} catch(e) {
				console.log(e)
			}
		}, 6000)
		return () => {
			clearInterval(pingInterval)
			clearInterval(bandwidthInterval)
		}
	}, [])
	return <div style={{height: "0px", width: "0px", display: "none"}}/>
}