import React, { Component } from "react";
import * as API from "../api.js";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import aeromeghLogo from './assets/aeromeghLogo.png'
import emailIcon from './assets/email.svg'

class ForgotPassword extends Component {
    state = {
        email: "",
        emailSent: false,
        error: null
    }

    componentWillMount() {
        document.title = "AeroMegh - Forgot Password";
    }

    resetPassword = (e) => {
        e.preventDefault()
        API.resetPassoword(this.state.email).then(() => {
            this.setState({ emailSent: true })
        }).catch((e) => {
            e.then(error => {
                this.setState({ error: error.message || "Something went wrong..." })
            })
        })
    }

    render() {
        return (
            <div className="authPage">
                <div style={{ height: '28rem' }} className="cardLogin login-card">
                    <img src={aeromeghLogo} className="login-logo" />
                    <div className="greet login-greet">FORGOT PASSWORD</div>
                    {!this.state.emailSent ?
                        <form onSubmit={this.resetPassword} action={this.resetPassword}>
                            <div className="login-error">{this.state.error || <></>}</div>
                            <div className="login-email-wrapper">
                                <img className="login-email-wrapper-img" src={emailIcon} />
                                <input className="text-input login-text-input loginSignupInput"
                                    autoFocus
                                    onChange={(e) => {
                                        this.setState({
                                            error: null,
                                            email: e.target.value.toLowerCase()
                                        })
                                    }}
                                    id="username" style={{ width: '18rem' }} name="username" type="email"
                                    placeholder="E-mail" />
                            </div>
                            <div className="spacer" style={{ height: '24px' }}> </div>
                            <button disabled={this.state.error || !this.state.email} style={this.state.error || !this.state.email ? { opacity: '0.5' } : { cursor: 'pointer' }} className="loginButton" name="loginButton"
                                id="loginButton">Reset Password</button>
                            <div className="spacer" style={{ height: '15px' }}> </div>
                            {/* <div className="signup" style={{ display: 'flex', width: '100%', alignItems: 'center', textAlign: 'center', fontSize: '12px', justifyContent: 'center', marginTop: '15px' }}>
                        ------ OR ------
                    </div> */}
                            {/* <div style={{ display:'flex', width:'100%', alignItems:'center', justifyContent:'center', marginTop:'10px' }}>
                        <Link to={{
                            pathname: "/auth/google" style={{ textDecoration:'none' }}>
                            <div className="signup sigin-oauth" 
                            style= {{ display:'flex', border:'1px solid #c1c1c1', height:'40px', cursor:'pointer', width:'18rem', alignItems:'center', textAlign:'center', justifyContent:'center', marginTop:'5px' }}>
                                <img src={googleIcon} style={{ height:'17px', width:'17px', marginRight:'10px' }} />
                                Sign in with Google
                            </div>
                        </a>
                    </div> */}
                        </form> :
                        <div>
                            <div className="content-text" style={{ padding: '20px', fontSize: "16px", lineHeight: '32px', color: 'black' }}>
                                Password reset link has been sent to your email address.
                            </div>
                            <div class="spacer" style={{ height: '4.9vh' }}> </div>
                            <Link to={{
                                pathname: "/login",
                            }}><button style={{ cursor: 'pointer' }} className="loginButton">LOGIN</button></Link>
                            <div class="spacer" style={{ height: '4.9vh' }}> </div>
                        </div>}
                </div>
            </div>
        );
    }
}

export default ForgotPassword
