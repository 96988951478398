import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as API from '../../api.js';
import '../../style.css';
import { addUserAnalytic, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';

export default class AnalyticUserDetails extends Component {
    state = {
        service: {
            servs: [],
            allservs: []
        },
        npnt_config: {
            vm_ip: '-',
            vm_domain: '-'
        },
        showDeletePopup: false,
        npntConfig: false,
        services: [],
        allServices: [],
        serviceStatus: [],
        user: {},
        models: [],
        invoicesPageNumber: 1,
        invoices: [],
        type: "",
        user_info: {
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            password: "",
            phone: "",
            userid: ""
        },
        successParams: {
            title: "DELETE USER",
            message1: "User has been deleted successfully",
            message2: "To go back to dashboard, click",
            hereLink: "/",
            buttonText: "View All Users",
            buttonLink: "/admin/users",
        },
        config: {},
        redirect: false,
        deleteRedirect: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        confirmCancelSub: false,
        cancelSubscription: false,
        popupText: "",
        subId: '',
        subName: '',
        today: new Date().toISOString(),
        userAnalytic: false,
        outId: "",
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode === "shrunk" ? "expanded" : "shrunk")
        }))
    }

    getUser = () => {
        API.getUser(this.state.user_info.userid, false).then(res => {
            this.setState({
                user_info: res.users[0],
            })
        }).catch(e => {
            console.log(e)
        })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
        if (this.props.location.state) {
            document.title = " Users Details";
            let { user, type, user_info } = this.props.location.state;

            this.setState((state) => ({
                ...state,
                user,
                type,
                user_info,
            }), () => {
                this.getSubbedServices()
                this.getUser();
            })
        } else window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/");
    }

    getSubbedServices = () => {
        API.getUserSubbedServices(this.state.user_info.username).then((response) => {
            let serviceStatus = response[1]
            serviceStatus.forEach((service, i) => {
                console.log(service)
                if (response[0].find((element) => {
                    return element.id === service.id
                })) {
                    service.assigned = true;
                } else {
                    service.assigned = false;
                }
            });
            this.setState((state) => ({
                ...state,
                services: response[0],
                allServices: response[1],
                serviceStatus
            }), () => {
            })
        })
    }

    render() {
        let { user, type, user_info, npnt_config } = this.state
        return (
            user && user.username ? <div className="wrapper">
                <div className="right-content-new" >
                    <div className="top-bar-new">
                        <div className="top-bar-text-new">USER DETAILS</div>
                    </div>
                    <div className="main-content-new">
                        <div className="page" style={{ padding: "0px 0px 20px 0px", height: "auto", minHeight: "815px" }}>

                            <div className="customer-details-top-section">
                                <img className="customer-details-logo" style={{ borderRadius: "50%" }} src={`/images/${user_info.first_name[0].toLowerCase()}.PNG`} />
                                <div className="services-content-title customer-details-company-name">{user_info.first_name + " " + user_info.last_name}</div>
                                <div className="customer-details-actions-row" style={{ width: "fit-content", marginLeft: "auto", marginTop: "5px", marginRight: "40px" }}>
                                    <Link
                                        style={{ display: 'flex', marginRight: '16px' }}
                                        to={{
                                            pathname: "/analytictool/user_tracking_logs",
                                            state: {
                                                user, type, user_info: user_info,
                                                sidebarMode: this.state.sidebarMode,
                                                serviceStatus: this.state.serviceStatus,
                                                services: this.state.services
                                            }
                                        }}
                                    >
                                        <button style={{ padding: "0", outline: "none", border: "none", backgroundColor: "white" }}>
                                            <div className="addcustomer-button-empty-dashboard" style={{ width: "6rem", display: 'flex', justifyContent: 'center' }} >
                                                Analytics
                                            </div>
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            <div className="customer-details-middle-section">
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">First Name:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.first_name}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Last Name:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.last_name}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Email:</div>
                                        <div className="customer-details-primary-row-left-value">{`${user_info.username}`}</div>
                                    </div>
                                    <div className="customer-details-primary-row-right">
                                        <div className="customer-details-primary-row-left-title">Phone Number:</div>
                                        <div className="customer-details-primary-row-left-value">
                                            <div>{`${user_info.phone}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customer-details-primary-row">
                                    <div className="customer-details-primary-row-left">
                                        <div className="customer-details-primary-row-left-title">Address:</div>
                                        <div className="customer-details-primary-row-left-value">{user_info.address}</div>
                                    </div>
                                </div>
                            </div>

                            {this.state.reloadThePageViaRedirect && <Redirect to={{
                                pathname: "/analytictool/userdetails",
                                state: {
                                    user: this.props.location.state.user,
                                    type: this.props.location.state.type,
                                    user_info: this.state.user_info,
                                    sidebarMode: this.state.sidebarMode
                                }
                            }} />}


                        </div>
                    </div>
                </div>
            </div> : <div />
        );
    }
}
