
import L from 'leaflet'
/**
To use this popup you have to pass following props
  1) onRequestClose as function
  2) show as flag
  3) if You want to give height then pass width otherwise it take 50% by default

*/
const getArrows = (arrLatlngs, color, arrowCount, mapObj) => {
  if (typeof arrLatlngs === undefined || arrLatlngs == null ||
    (!arrLatlngs.length) || arrLatlngs.length < 2)
    return [];

  if (typeof arrowCount === 'undefined' || arrowCount == null)
    arrowCount = 1;

  if (typeof color === 'undefined' || color == null)
    color = '';
  else
    color = 'color:' + color;

  var result = [];
  for (var i = 1; i < arrLatlngs.length; i++) {
    var icon = L.divIcon({ className: 'arrow-icon', bgPos: [5, 2], html: '<div style="' + color + ';transform: rotate(' + getAngle(arrLatlngs[i - 1], arrLatlngs[i], -1).toString() + 'deg)">▶</div>' });
    for (var c = 1; c <= arrowCount; c++) {
      result.push(L.marker(myMidPoint(arrLatlngs[i], arrLatlngs[i - 1], (c / (arrowCount + 1)), mapObj), { icon: icon }));
    }
  }
  return result;
}

const getAngle = (latLng1, latlng2, coef) => {
  var dy = latlng2[0] - latLng1[0];
  var dx = Math.cos(Math.PI / 180 * latLng1[0]) * (latlng2[1] - latLng1[1]);
  var ang = ((Math.atan2(dy, dx) / Math.PI) * 180 * coef);
  return (ang).toFixed(2);
}

const myMidPoint = (latlng1, latlng2, per, mapObj) => {
  if (!mapObj)
    throw new Error('map is not defined');

  var halfDist, segDist, dist, p1, p2, ratio,
    points = [];

  p1 = mapObj.project(new L.latLng(latlng1));
  p2 = mapObj.project(new L.latLng(latlng2));

  halfDist = distanceTo(p1, p2) * per;

  if (halfDist === 0)
    return mapObj.unproject(p1);

  dist = distanceTo(p1, p2);

  if (dist > halfDist) {
    ratio = (dist - halfDist) / dist;
    let p = Point(p2.x - ratio * (p2.x - p1.x), p2.y - ratio * (p2.y - p1.y))
    p2.x = p.x
    p2.y = p.y
    var res = mapObj.unproject(p);
    return [res.lat, res.lng];
  }

}

const distanceTo=(p1, p2)=> {
  var x = p2.x - p1.x,
    y = p2.y - p1.y;

  return Math.sqrt(x * x + y * y);
}

const Point=(x, y, round) =>{
   x = (round ? Math.round(x) : x);
   y = (round ? Math.round(y) : y);
  return {x,y}
}
 
export default getArrows
