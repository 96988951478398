import React, { useEffect, useState } from 'react'
import * as API from "../api"
import SideBar from '../SideBar'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Loader from '../icons/loader.svg';
import DiscountCouponTable from './DiscountCouponTable';
const DiscountCoupons = (props) => {
    const [componentData, setComponentData] = useState({ user: "", type: "", sidebarMode: "", showBackIcon: true })
    const [loading, setLoading] = useState(false)
    let { user, type } = componentData
    const successRedirectComponent = () => {
        if (componentData.redirect) {
            return <Redirect to={{
                pathname: "/admin/new_subscription",
                state: {
                    user: componentData.user,
                    type: componentData.type,
                    sidebarMode: componentData.sidebarMode,
                    config: componentData.config,
                    plan: componentData.currentPlan
                }
            }} />
        }
    }
    useEffect(() => {
        setLoading(true)
        if (props.location.state) {
            let { user, type, sidebarMode } = props.location.state;

            setComponentData({
                ...componentData,
                user,
                type, sidebarMode
            })
            setLoading(false)
        } else {
            API.getUserType().then(([blah, type, user]) => {

                setComponentData({
                    ...componentData,
                    user,
                    type
                })
                setLoading(false)
            }, (e) => {
                window.location.replace("/login");
            })
        }

    }, [])
    return (
        componentData.user.username ? <div className='wrapper'>
            <div className="right-content-new"
                onClick={
                    () => {
                        setComponentData((prev) => ({
                            ...prev, sidebarMode: 'shrunk'
                        }))
                    }
                }
            >
                {successRedirectComponent()}
                <div className="top-bar-new"><div className="top-bar-text-new">Discount Coupons</div></div>
                {loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                </div> :
                    <DiscountCouponTable />
                }
            </div>
        </div> : <div />
    )
}

export default DiscountCoupons