import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import axios from 'axios';
import * as API from './api.js';
import qs from 'qs';
import { Line } from 'rc-progress';
import Loader from './icons/loader.svg';
import dateFormat from 'dateformat';

class AeroStreamDownloadProgress extends Component {
    state = {
        user: {},
        type: "",
        progress: 0,
        cancel: false,
        loading: true,
    }
    download = (url) => {
        const documentStyles = document.documentElement.style;
        let progress = 0;
        this.setState((state) => ({
            ...state,
            cancel: false,
            progress: 0
        }))
        const setProgress = (progress) => {
            this.setState((state) => ({
                ...state,
                progress: progress
            }))
        }
        axios(
            {
                url,
                responseType: "blob",
                onDownloadProgress(progressEvent) {
                    progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress)
                }
            }).then((response) => {
                if (this.state.cancel == false) {
                    this.setState((state) => ({
                        ...state,
                    }))
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.state.request.title} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}.mp4`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }

            });
    };

    componentWillMount() {
        document.title = "AeroStream - Download Progress";

        this.setState((state) => ({
            ...state,
            loading: false,
        }), () => {
            let flightID = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).fid
            API.getAeroStreamSingleVideo(flightID).then((data) => {
                this.setState((state) => ({
                    ...state,
                    request: data.request,
                    videoURL: data.videoURL
                }))
                this.download(data.videoURL)
            }, (e) => {
                console.log(e);
            })


        })
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                if (user.aeromegh_login_dirty) {
                    document.getElementById("downloadGCSPopupTriggerButton").click();
                    API.touchUserAeromeghLogin().then((result) => {
                        console.log("Set login dirty flag successfully")
                    }).catch(e => {
                        console.log("Error while setting login dirty flag", e)
                    })
                }
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    if (user.aeromegh_login_dirty) {
                        document.getElementById("downloadGCSPopupTriggerButton").click();
                        API.touchUserAeromeghLogin().then((result) => {
                            console.log("Set login dirty flag successfully")
                        }).catch(e => {
                            console.log("Error while setting login dirty flag", e)
                        })
                    }
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }
    render() {
        let { user, type, request } = this.state
        return (
            user.username ? <div className="wrapper">
                <div className="right-content">
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showDownloadPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                cancel: true

                            }));
                        }}
                    >
                        <Modal.Body >
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        cancel: true
                                    }));
                                }}
                            >
                                cancel
                            </button>
                        </Modal.Footer>
                    </Modal>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content" style={{ background: 'none' }}>
                        <p>{`Downloading ${request.title} ${dateFormat(new Date(), 'dd mmm yyyy')} ${new Date().toLocaleString().split(',')[1]} ${dateFormat(new Date(), 'dd mmm yyyy')} ${new Date().toLocaleString().split(',')[1]}.mp4 file ${this.state.progress}%`}</p>
                        <div >
                            <Line percent={this.state.progress} strokeWidth="1" strokeColor="#2989cf" />
                            {
                                this.state.progress == 100 ? <p style={{ color: 'green' }}>Done </p> : ''
                            }
                        </div>
                    </div>}
                </div>
            </div> : <div />
        );
    }
}



export default AeroStreamDownloadProgress;