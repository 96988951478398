import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import {  Redirect } from 'react-router-dom';
import dateFormat from 'dateformat';


export default class AdminGCSInstall extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        gcsdata: [],
        currentpage: 1,
        userperpage: 8,
        counts:0,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk")
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    onChangeListener = (e) => {
        let t = e.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.value
        }))
    }

    componentWillMount() {
        document.title = "Users - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    getUser = () => {
        const { user, type, userdisplay, currentpage, userperpage, key, counts } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        const currentuser = userdisplay.slice(firstpage, lastIndex);
        const totalpages = userdisplay.length / userperpage;
        API.getGCSCount(firstpage, userperpage).then((result) => {
            console.log(result.data);
            this.setState(state => ({
                ...state,
                gcsdata: result.data,
                counts: result.count,
                loading: false
            }))

        })

    }
    componentDidMount() {
        this.getUser()
    }
    firstpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: 1
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            })
                , () => {
                    this.getUser();

                });
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.counts / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.counts / this.state.userperpage)
            }), () => {
                this.getUser();
            });
        } else {
            console.log("Reached last page")
        }
    }

    changepage(num) {
        console.log(isNaN(num))
        if (isNaN(num)) {

            return null;
        }
        else {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getUser());
        }

    }


    search(key) {
        this.setState((state) => ({
            ...state,
            key,
        }),
            () => this.getUser());
        console.log(key)
    }


    render() {
        const { user, type, userdisplay, currentpage, userperpage, counts } = this.state;
        return (

            this.state.user && this.state.user.first_name ? <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Installation</div>
                       </div>
                    <div className="main-content">


                        <div style={{ display: "flex", justifyContent:'space-between' }}>

                            <div className="services-content-title">Installation Count : {counts}</div>
                            {/* <div className="add-user-form-text-wrapper" style={{ marginLeft: "auto" }}>
                                    <Link to={{ pathname: "/admin/allsubscription", state: { user, type, sidebarMode: this.state.sidebarMode } }}>
                                    <div className="addcustomer-button-empty-dashboard" style={{ paddingLeft: '30px', paddingRight: '30px' }}>Subscription</div>
                                    </Link>
                                </div> */}

                             {/* <div className="add-user-form-row-left" style={{ marginLeft: '20px' }}>
                                    <div className="add-user-form-text-wrapper">
                                        <img src={Search} style={{ height: "20px", width: "20px", position: 'relative', left: '25px' }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search User" style={{ height: '30px !important', width: "235px", paddingLeft: '28px' }} onChange={(event) => this.search(event.target.value)} />
                                    </div>
                                </div>  */}
                            {/* <div className="top-bar-text">
                                <div className="add-user-form-row-left">
                                    <div className="add-user-form-text-wrapper" style={{ marginLeft: "50rem" }}>
                                        <img src={Search} style={{ height: "20px", width: "20px" }} />
                                        <input type="text" className="add-user-form-text" placeholder="Search User" style={{ width: "180px", height: "30px", borderColor: "white", position: "absolute" }} onChange={(event) => this.search(event.target.value)} />
                                    </div>
                                </div>
                            </div> */}
                        {/* </form> */}
                        </div>



                        <table className="customers-table">
                            <tr className="customers-table-header-row">
                                <th style={{textAlign:'center'}}>Id</th>
                                <th style={{textAlign:'center'}}>Tracking Id</th>
                                <th style={{textAlign:'center'}}>Date</th>
                                <th>Country</th>
                            </tr>
                            {
                            // userdisplay.length === 0 ?
                            //     <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                            //         <td colspan="3">No Users Available</td>
                            //     </tr> :

                                this.state.gcsdata.map((gcs) =>

                                    <tr className="customers-table-data-row" style={{ height: "45px" }}>
                                        <td style={{textAlign:'center'}}>{gcs.id}</td>
                                        <td style={{textAlign:'center'}}>{gcs.tracking_id}</td>
                                        <td style={{textAlign:'center'}}>{dateFormat(gcs.install_time,"dd-mm-yyyy")}</td>
                                        <td>{gcs.country == null ? "NA" : gcs.country}</td>
                                    </tr>
                                )
                                }

                        </table>
                        <footer className="footer" style={{ visibility: (this.state.counts > 8 ? "visible" : "hidden") }}>
                            <div style={{ float: "left", marginTop: "2rem", display: "flex" }}>
                                <div style={{ color: "#109cf1", marginTop: "11px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }} >
                                    Page : {currentpage}
                                </div>


                            </div>
                            <div style={{ float: "right", marginTop: "2rem", display: "flex" }}>
                                {currentpage == 1 ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", opacity:'0.5'}} >
                                    <div className="add-user-form-submit-button" >
                                        First
                                      </div>
                                </button>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px", opacity:'0.5' }} >
                                    <div className="addcustomer-button-empty-dashboard" >
                                        Prev
                                      </div>
                                </button></>:
                                <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", }} onClick={this.firstpage}>
                                <div className="add-user-form-submit-button" >
                                    First
                                  </div>
                            </button>
                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginRight: "10px", marginLeft: "10px" }} onClick={this.prevpage}>
                                <div className="addcustomer-button-empty-dashboard" >
                                    Prev
                                  </div>
                            </button></>}
                                <form>
                                    <div style={{ display: "flex" }}>
                                        <div className="add-user-form-label" style={{ color: "#109cf1", marginTop: "7px", fontSize: "13px", fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600" }}>
                                            Go to page :
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <input type="text" className="add-user-form-text" style={{ height: "40px", width: "40px", textAlign: "center", color: "#109cf1", fontSize: "13px", marginLeft: "5px", backgroundColor: "#F5F6F8" }} 
                                             onKeyPress={(event) => { if (event.key === "Enter") { event.preventDefault(); } }}
                                            onChange={(event) => this.changepage(parseInt(event.target.value))} name="currentpage" value={currentpage} />
                                        </div>
                                    </div>
                                </form>

                                {this.state.currentpage == Math.ceil(this.state.counts / this.state.userperpage) ? <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px", opacity:'0.5' }}>
                                    <div className="addcustomer-button-empty-dashboard" >
                                        Next
                                       </div>
                                </button>
                                <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px", opacity:'0.5' }}>
                                    <div className="add-user-form-submit-button" >
                                        Last
                                      </div>
                                </button> </> :
                                <><button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", marginLeft: "15px", color: "#109cf1", marginLeft: "10px" }} onClick={this.nextpage}>
                                <div className="addcustomer-button-empty-dashboard" >
                                    Next
                                   </div>
                            </button>
                            <button style={{ padding: "0", outline: "none", border: "none", background: "#F5F6F8", color: "#109cf1", marginLeft: "10px" }} onClick={this.lastpage}>
                                <div className="add-user-form-submit-button" >
                                    Last
                                  </div>
                            </button> </>}

                            </div>


                        </footer>
                      
                    </div>
                </div>
            </div> : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto"  }} />
      </div>
        );
    }
}
