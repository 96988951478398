import React, { Component } from 'react';
import SideBar from '../SideBar';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon';
import { deleteIncidentReport } from '../api';
import { ActivityPopup } from '../ReusableComponents/ActivityPopup';
import teams from "../sidebaricon/TeamsInactive.png";
import planner from "../assets/sideBarIcons/PlanInactive.png";
import setting from "../sidebaricon/Settings.png";
import help from "../sidebaricon/Help.png";
import incidentActive from '../icons/incidentActive.png'

class IncidentReportDetail extends Component {
  state = {
    user: {},
    selectedDate: new Date(),

    item: undefined,
    itemName: undefined,
    processPopupIcon: undefined,
    processMessage: undefined,
    showProcessPopup: false,
  }

  componentWillMount() {
    document.title = "Incident Report";
    this.setState({
      user: this.props.location.state.user,
      report: this.props.location.state.report
    });
  }
  deleteReport = (id) => {
    console.log("id :", id);
    deleteIncidentReport(id).then(data => {
      this.setState({
        item: "COMPLETE",
        itemName: "COMPLETE",
        processPopupIcon: "COMPLETE",
        processMessage: " Deleted successfully ",
        showProcessPopup: true,
      })
    })
      .catch(e => {
        console.log(e)
        this.setState({
          item: "ERROR",
          itemName: "ERROR",
          processPopupIcon: "ERROR",
          processMessage: " Somthing went wrong while deleting ",
          showProcessPopup: true,
        })
      })
  }

  closeActivityPopup = () => {

    if (this.state.processPopupIcon == "COMPLETE") {
      this.setState({
        showProcessPopup: false,
      }, () => {
        window.history.back()
      });
    }
    else {
      this.setState({
        showProcessPopup: false,
      });
    }
  }

  render() {

    let { user, type, inviteModal, validationMessage, validationColor, teamUsers } = this.state
    console.log("user :", user);
    let sidebarConfigurations = {
      options: [
        // {
        //   icon: service,
        //   text: "Services",
        //   path: "/"
        // },
        {
          icon: teams,
          text: "Teams",
          path: "/teams",
        },
        {
          icon: planner,
          text: "Planner",
          path: "/planner",
        },
        {
          icon: incidentActive,
          text: "Incident Report",
          path: "/incidentReport",
          selected: true,
        },
        {
          icon: setting,
          text: "Settings",
          path: "/profile-settings",
        },
        {
          icon: help,
          text: "Help",
          path: "/feedback",
        },
      ],
      user,
      type,
      mode: this.state.sidebarMode,
    }

    return (
      <>

        <ActivityPopup
          item={this.state.item}
          open={this.state.showProcessPopup}
          icon={this.state.processPopupIcon}
          action={this.state.processAction}
          msg={this.state.processMessage}
          close={() => {
            console.log("Close Called !");
            this.closeActivityPopup()
          }}
          onClickOk={() => {
            this.setState({ showProcessPopup: false }, () => {
              this.deleteReport(this.state.report.id)
            })
          }}
        />

        {user.username ?
          <div className="wrapper">
            <SideBar
              config={sidebarConfigurations}
              showMainDashboardIcons={this.state.showBackIcon}
              parentSidebarSwitch={this.parentSidebarSwitch}
            />
            <div className="right-content"
              onClick={
                () => {
                  this.setState((state) => ({
                    ...state, sidebarMode: 'shrunk'
                  }))
                }
              }
            >
              <div className="top-bar"><div className="top-bar-text">{"Report"}</div></div>

              <div className="main-content" style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: "859px", height: 'fit-content', marginTop: '10px', borderRadius: "5px", outline: "1px solid #c6c6c6" }}>
                  <div style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", backgroundColor: "f3f3f3", height: "40px", padding: "10px", fontSize: "16px", color: "black", borderBottom: "1px solid #dbdbdb", textAlign: "left" }}>{"Incident Report Detail"}
                    {/* <div style={{ position: "absolute", right: "17px", top: "17px", width: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                  // onClick={closeTaskAssigner}
                  ><img style={{ width: "100%", height: "100%" }} src={Close} /></div> */}
                  </div>
                  {/* <hr style={{ margin: '0px' }} /> */}
                  <div style={{ padding: "20px 44px", display: "flex", flexDirection: "column", height: "100%", backgroundColor: "#ffffff" }}>

                    {/* description */}
                    <div style={{ display: "flex", }}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "", width: "240px", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Name</h5>
                        <p style={{ width: "100%", fontSize: "15px", color: "#3c3c3c", wordWrap: "break-word", borderRadius: "5px", textAlign: "left" }} >{this.state.report.name}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "100px", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Date & Time</h5>
                        <p style={{ width: "115px", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{`${this.state.report.incident_time_date.substring(0, 10)}`}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "100px", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Department</h5>
                        <p style={{ width: "222px", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{this.state.report.department}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", }}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Contact No</h5>
                        <p style={{ width: "200px", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{this.state.report.contact_number}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "140px", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Location</h5>
                        <p style={{ width: "100%", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{this.state.report.location}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", }}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Incident Details</h5>
                        <p style={{ width: "100%", fontSize: "15px", color: "#3c3c3c", wordWrap: "break-word", fontWeight: "", width: "770px", borderRadius: "5px", textAlign: "left" }} >
                          {this.state.report.details}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", }}>
                      <div style={{ width: '250px', display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Uploaded File</h5>
                        <p style={{ width: "250px", wordWrap: "break-word", fontSize: "15px", borderRadius: "5px", textAlign: "left" }} >
                          <a target="_blank" href={this.state.report.file_url ? `${this.state.report.file_url}?${this.props.location.state.st}` : null} >{this.state.report.file_url ? `View file` : `No File Available`}</a>
                        </p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "86px", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Drone ID</h5>
                        <p style={{ width: "200px", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{this.state.report.drone_id}</p>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "17px", backgroundColor: "", borderRadius: "5px", }}>
                        <h5 style={{ fontSize: "11px", color: "rgb(60 60 60 / 61%)", margin: "0" }}>Pilot Id</h5>
                        <p style={{ width: "222px", wordWrap: "break-word", fontSize: "15px", color: "#3c3c3c", borderRadius: "5px", textAlign: "left" }} >{this.state.report.pilot_id}</p>
                      </div>
                    </div>

                    <div style={{ width: "100%", margin: "20px 0 0 0" }}>
                      <div style={{ fontSize: "16px", textAlign: "right" }}>
                        <ButtonWithIcon isBtnActive
                          text="Delete"
                          onClick={() => {
                            this.setState({
                              item: "Warning",
                              itemName: "Warning",
                              processPopupIcon: "WARNING",
                              processMessage: "Are you sure you want to delete ?",
                              showProcessPopup: true,
                            });
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div > : <div />
        }
      </>
    );
  }
}
export default (IncidentReportDetail);

