import React, { Component } from 'react';
import * as API from './api.js';
import SnackBar from './snackBar.js';
import Loader from './icons/loader.svg';
import service from "./sidebaricon/Services.png";
import './style.css';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import { Link, } from 'react-router-dom';
import { connect } from "react-redux";
import { updateProfileDetails, activesubscription } from './redux'
import AeroMeghLogo from "./aeromeghLog.png";
import storageIcon from "../src/assets/storage.png"
import projectsIcon from "../src/assets/projects.png"
import imagesIcon from "../src/assets/images.png"
import pixelsIcon from "../src/assets/pixels.png"
import flightsIcon from "../src/assets/flights.png"
import flightHoursIcon from "../src/assets/flightHours.png"
import mapsIcon from "../src/assets/maps.png"
import collectionsIcon from "../src/assets/collections.png"
import objectsIcon from "../src/assets/detections.png"
import Help from "./sidebaricon/Help.png";
import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import nextIcon from "../src/assets/Icons/next.png"
import prevIcon from "../src/assets/Icons/prev.png"

var today = new Date();
var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
let temp = lastWeek.toISOString()
let endDate = new Date()
let eDate = endDate.toISOString()


class UsageStatistics extends Component {

  state = {
    key: " ",
    fromDate: temp,
    endDate: eDate,
    todaysDate: today,
    user: {},
    flightCount: 0,
    statData: [],
    monthlyFlightCount: 0,
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    statistics: {},
    droneData: {},
    options: {},
    droneHours: [],
    droneNames: [],
    totalAreaCovered: 0,
    totalDistanceCovered: 0,
    pilots: [],
    center: { lat: 19.993982, lng: 73.790416 },
    redirect: false,
    selectedFlight: {},
    subscriptionPopup: false,
    thisMonth: false,
    thisWeek: true,
    thisYear: false,
    storage: 0,
    storageUnit: "",
    dateString: "",
    outId: "",
    showDatePicker: false,
    loading: true
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  dateConverter = () => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'long', year: 'numeric'
    }).replace(/ /g, ' ');

    this.setState({
      dateString: formattedDate
    })

  }

  getStatistics = () => {
    this.setState({ loading: true }, () => {
      API.getUserStatistics(this.state.fromDate, this.state.endDate).then((result) => {
        let storage = result.storage.split(" ")
        this.setState((state) => ({
          ...state,
          statistics: result,
          totalAreaCovered: result.areaCovered,
          images: result.images,
          totalDistanceCovered: result.distanceCovered,
          pilots: result.pilots ? result.pilots : [],
          storage: storage[0],
          storageUnit: storage[1],
          loading: false
        }))
      }).catch(error => {
        console.log(error);
      })
    })
  }

  getFlightsCompletedHours = () => {
    API.getAllFlightsCompletedHours(' ', undefined, undefined, 0, 100000).then((data) => {
      let ApiResponseCount = this.state.ApiResponseCount + 1
      let drones = data.drones.drones
      let droneNames = []
      let hours = []
      drones = drones.length > 5 ? drones.slice(0, 5) : drones
      if (drones.length < 5 && drones.length != 0) {
        for (let i = drones.length; i < 5; i++)
          drones.push({ "drone_id": "" })
      }
      for (let i = 0; i < drones.length; i++) {
        let element = drones[i]
        droneNames.push(element.drone_name ? element.drone_name : element.drone_id)
        let minutes = element.total_flight_time == undefined ? 0
          : (element.total_flight_time.hours == undefined ? 0 : element.total_flight_time.hours * 60) + (element.total_flight_time.minutes == undefined ? 0 : element.total_flight_time.minutes)
        hours.push(minutes == undefined ? 0.00 : `${(minutes / 60).toFixed(2)}`)
      }
      let options = {
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
        },
      };
      const dronePerformance = (canvas) => {
        const ctx = canvas.getContext("2d")
        const gradient = ctx.createLinearGradient(0, 0, 300, 0);
        gradient.addColorStop(0, '#66c0ed');
        gradient.addColorStop(0.5, '#9dcbed');
        gradient.addColorStop(1, '#dceef7');
        return {
          labels: droneNames,
          datasets: [
            {
              data: hours,
              fill: true,
              backgroundColor: gradient,
              borderColor: "#ebf8ff",
              borderWidth: 1
            },
          ],
        }
      }

      this.setState((state) => ({
        ...state,
        droneData: dronePerformance,
        options: options,
        droneHours: hours,
        droneNames: droneNames,
        dronesCompletedHours: data.drones.drones,
        ApiResponseCount: ApiResponseCount
      }))
    }, (err) => {
      console.log(err);
    })
  }

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentWillMount() {
    addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))

    document.title = "Statistics";
    if (this.props.location.state) {
      let { user, type, project } = this.props.location.state;
      console.log(this.props);
      this.setState((state) => ({
        ...state,
        user,
        type,
      }), () => {
        this.getStatistics()
        this.dateConverter()
      })
    } else {
      API.getUserType().then(([blah, type, user]) => {
        this.setState((state) => ({
          ...state,
          user,
          type
        }))
      }, (e) => {
        window.location.replace("/login");
      })
    }
  }

  render() {
    let settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 300,
      arrow: false,
    }
    const { user, type, sidebarMode, statistics } = this.state;
    return (
      <div className="wrapper">

        <div style={{ display: 'flex' }}>
          <div className="sidebar-expanded" style={{ paddingTop: "0px" }}>
            <div style={{ display: "flex", height: "40px" }}>
              <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                <img
                  src={AeroMeghLogo}
                  style={{ width: "65px", height: "20px", marginTop: "10px" }}
                />
              </div>
            </div>
            <ul className="sidebar-nav" id="sidebar-nav">
              <div className={"nonSelectBorder"} >
                <li>
                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        type,
                        user,
                      },
                    }}
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={service}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                        onClick={this.changeColor}
                      >
                        {"Services"}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>

              <div className={"nonSelectBorder"} >
                <li>
                  <div
                    className="onhoverext"
                    onClick={
                      () => {
                        window.open("https://dronenaksha-docs.aeromegh.com/", "_blank")
                      }
                    }
                  >
                    <div
                      className="sidecontent"
                      id="sidecontent"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="sidebar-icon">
                        <img
                          className="sidebar-icon"
                          src={Help}
                          alt="serviceIcon"
                        />
                      </div>
                      <div
                        name="service"
                        className={"sidebaropt"}
                      >
                        {"Help"}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div className="right-content-new">
          <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}><div className="top-bar-text-new">Usage statistics</div> </div>

          <div className="main-content-new">
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', paddingTop: '20px', alignItems: 'center' }}>
              <div style={{ display: "flex", alignItems: 'center' }}>
                <div className="add-user-form-text-wrapper" style={{ marginRight: '20px' }} >
                  <div className="imageViewButton" style={{ display: 'flex', height: '24px', width: "275px" }}  >
                    <div className={this.state.thisWeek ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf', width: "33%" }}
                      onClick={
                        () => {
                          var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
                          let temp = lastWeek.toISOString()
                          let endDate = new Date()
                          let eDate = endDate.toISOString()
                          this.setState((state) => ({
                            ...state,
                            thisWeek: true,
                            thisMonth: false,
                            thisYear: false,
                            fromDate: temp,
                            endDate: eDate
                          }), () => {
                            this.getStatistics()
                          })
                        }}>
                      <div> This week </div>
                    </div>

                    <div className={this.state.thisMonth ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf', width: "34%" }}
                      onClick={
                        () => {
                          var lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
                          let temp = lastMonth.toISOString()
                          let endDate = new Date()
                          let eDate = endDate.toISOString()
                          this.setState((state) => ({
                            ...state,
                            thisMonth: true,
                            thisWeek: false,
                            thisYear: false,
                            fromDate: temp,
                            endDate: eDate
                          }), () => {
                            this.getStatistics()
                          })
                        }}
                    >
                      <div> This month</div>
                    </div>

                    <div className={this.state.thisYear ? 'imageViewButtonListActive' : 'imageViewButtonList'} style={{ borderRadius: '0px 2px 2px 0px', borderLeft: 'solid 1px #2989cf', width: "33%" }}
                      onClick={
                        () => {
                          var lastYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                          let temp = lastYear.toISOString()
                          let endDate = new Date()
                          let eDate = endDate.toISOString()

                          this.setState((state) => ({
                            ...state,
                            thisYear: true,
                            thisMonth: false,
                            thisWeek: false,
                            fromDate: temp,
                            endDate: eDate
                          }), () => {
                            this.getStatistics()
                          })
                        }}>
                      <div>This year</div>
                    </div>
                  </div>
                </div>


                <div style={{ display: "flex", alignItems: "center", border: "1px solid #e5e5e5", borderRadius: "2px", marginLeft: '380px' }}>
                  <div style={{ margin: "5px", cursor: "pointer" }}> < img src={prevIcon} style={{ height: "14px", width: "14px" }} onClick={
                    () => {
                      let date = new Date(this.state.endDate)
                      let newStartDate = new Date(this.state.endDate)
                      newStartDate.setDate(date.getDate() - 1)
                      let newEndDate = new Date(newStartDate)
                      newEndDate.setDate(newStartDate.getDate() + 1)

                      let fDate = newStartDate.toISOString()
                      let eDate = newEndDate.toISOString()
                      this.setState((state) => ({
                        fromDate: fDate,
                        endDate: eDate
                      }), () => {
                        this.getStatistics()
                        this.setState((state) => ({
                          endDate: fDate,
                          todaysDate: fDate
                        }))
                      })
                    }
                  } />
                  </div>
                  <DatePicker
                    format='dd MMM yyyy'
                    oneTap
                    cleanable='false'
                    size='xs'
                    value={new Date(this.state.todaysDate)}
                    onChange={(date) => {
                      let value = date
                      let temp = new Date(value)
                      let temp1 = new Date(value)
                      temp1.setDate(temp.getDate() + 1)
                      let sDate = temp.toISOString()
                      let eDate = temp1.toISOString()
                      this.setState((state) => ({
                        ...state,
                        fromDate: sDate,
                        endDate: eDate,
                        todaysDate: sDate
                      }), () => {
                        this.getStatistics()
                      })
                    }}
                    style={{ width: "135px", border: "none", color: "#3c3c3c" }}
                  />
                  <div style={{ margin: "5px", cursor: "pointer" }}>< img src={nextIcon} style={{ height: "14px", width: "14px" }} onClick={
                    () => {
                      let date = new Date(this.state.endDate)
                      let newStartDate = new Date(this.state.endDate)
                      newStartDate.setDate(date.getDate() + 1)
                      let newEndDate = new Date(newStartDate)
                      newEndDate.setDate(newStartDate.getDate() + 1)

                      let fDate = newStartDate.toISOString()
                      let eDate = newEndDate.toISOString()
                      this.setState((state) => ({
                        fromDate: fDate,
                        endDate: eDate
                      }), () => {
                        this.getStatistics()
                        this.setState((state) => ({
                          endDate: fDate,
                          todaysDate: fDate
                        }))
                      })
                    }
                  } /></div>
                </div>

              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: '20px' }}>
                <div style={{ display: "flex" }}>
                  <div style={{}}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Storage </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img src={storageIcon} style={{ height: "110px", width: "110px" }} />
                        </div>
                        {this.state.storage && !this.state.loading ? <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", display: "flex" }}>{this.state.storage != null ? Math.round(this.state.storage) : 0}
                          <div className='box-header1' style={{ fontSize: "18px", marginLeft: "10px" }}>{this.state.storageUnit} </div>
                        </div> :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }
                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}>Total storage consumed on platform</div>
                  </div>

                  <div style={{ marginLeft: "35px" }}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Images </div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div>
                          <img src={imagesIcon} style={{ height: "110px", width: "110px", }} />
                        </div>
                        {statistics.usage && !this.state.loading ? <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", }}>{statistics && statistics.usage ? statistics.usage[0]?.item_usage : 0}
                          <div lassName='box-header1' style={{ fontSize: "22px", color: "#3c3c3c", }} >{this.state.images || "0"}</div>
                        </div> :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }
                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}>Image processed in current month / remaining quota</div>
                  </div>

                  <div style={{ marginLeft: "35px" }}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Projects </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img src={projectsIcon} style={{ height: "110px", width: "110px" }} />
                        </div>
                        {statistics.projects && !this.state.loading ?
                          <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", }}>{statistics.projects}</div>
                          :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }
                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}> Projects created in given time interval </div>
                  </div>
                </div>

                <div style={{ display: "flex", marginTop: "30px" }}>

                  <div style={{}}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Collections </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img src={collectionsIcon} style={{ height: "110px", width: "110px" }} />
                        </div>
                        {statistics.imageSets && !this.state.loading ?
                          <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", }}>{statistics.imageSets}</div>
                          :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }

                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}> Image Collections created in given time interval </div>
                  </div>

                  <div style={{ marginLeft: "35px" }}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Flights Taken </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img src={flightsIcon} style={{ height: "110px", width: "110px" }} />
                        </div>
                        {statistics.flights && !this.state.loading ?
                          <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", }}>{statistics.flights}</div>
                          :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }

                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}> Flights taken in given time interval </div>
                  </div>

                  <div style={{ marginLeft: "35px" }}>
                    <div className='dashboard-box1'>
                      <div style={{ fontWeight: "500", color: "#3c3c3c", margin: "5px" }}> Flight Hours </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img src={flightHoursIcon} style={{ height: "110px", width: "110px" }} />
                        </div>
                        {statistics.flightHours && !this.state.loading ?
                          <div className='box-header1' style={{ marginLeft: "20px", color: "#3c3c3c", display: "flex" }}>{statistics.flightHours > 0 ? statistics.flightHours : 0}
                            <div className='box-header1' style={{ fontSize: "18px", marginLeft: "10px" }}>hr</div>
                          </div>
                          :
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20%" }}>
                            <img src={Loader} style={{ height: "80px" }} />
                          </div>
                        }

                      </div>
                    </div>
                    <div style={{ fontSize: "9px", }}> Total Flight hours of flights taken </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload)),
    activesubscription: (payload) => dispatch(activesubscription(payload))
  };
};

const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
    firstTimeLoginGCSE: state.profile.firstTimeLoginGCSE,
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(UsageStatistics));