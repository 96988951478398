import React from "react";
import WEEKLEYREPORTICON from "../../icons/weekly-report-icon.svg";


const WeekTile = ({ week, handleWeekClick, weekIndex }) => {

    const startDate = week.start.utc().format('DD/MM/YYYY');
    const endDate = week.end.utc().format('DD/MM/YYYY');

    const handleClick = () => {
        if (!week.isDisabled) {
            handleWeekClick(week);
        }
    };

    return (
        <div
            className={`week-tile ${week.isDisabled ? 'disabled' : ''}`}
            onClick={() => handleClick(week)}
            style={{
                pointerEvents: week.isDisabled ? 'none' : 'auto',
                opacity: week.isDisabled ? 0.5 : 1, // Adjust style for disabled weeks
            }}
        >
            <div >
                <img src={WEEKLEYREPORTICON} alt="Weekly Report Icon" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div>
                    <div style={{ fontWeight: 600 }}>Week {weekIndex + 1}</div>
                    <div style={{ fontSize: '13px', color: '#68717E' }}>
                        {`${startDate} - ${endDate}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeekTile