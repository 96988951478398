import React, { useEffect, useState } from 'react'
import { getAllSoftwareVersions, getUserType } from './api';
import { Popup } from './ReusableComponents/reusableFunctions';
import Loader from './icons/loader.svg';
import Edit from './icons/pen.png'
import btnAdd from './icons/btnAdd.svg';
import SoftwareVersionPopup from './SoftwareVersionPopup';
const SoftwareVersions = (props) => {

    const [user, setUser] = useState({});
    const [openAddVersionPopup, setOpenAddVersionPopup] = useState(false)
    const [softwareVersions, setSoftwareVersions] = useState([])
    const [loading, setLoading] = useState(true)
    const [openUpdateVersionPopup, setOpenUpdateVersionPopup] = useState(false)
    const [enableAddSoftwareVersionBtn, setEnableAddSoftwareVersionBtn] = useState(false)

    const initData = async () => {
        try {

            const [blah, type, user] = await getUserType();
            setUser(user);
            const software_versions = await getAllSoftwareVersions();
            setSoftwareVersions(software_versions)
            setLoading(false)
        } catch (e) {
            console.log(e);
            Popup.alert("ERROR", e.message)
        }
    }
    useEffect(() => {
        const enableAddButton = localStorage.getItem("enable_software_version_button");
        if (enableAddButton == 'true') {
            setEnableAddSoftwareVersionBtn(true)
        }
        document.title = "Software Versions - Admin";
        initData()
    }, []);

    return (

        <div className="wrapper">
            {(openAddVersionPopup || openUpdateVersionPopup) && <SoftwareVersionPopup
                version={openUpdateVersionPopup}
                onRequestClose={() => {
                    setOpenAddVersionPopup(false)
                    setOpenUpdateVersionPopup(false)
                }}
                onVersionAddCallback={(version) => {
                    setSoftwareVersions(prev => ([...prev, version]))
                    setOpenAddVersionPopup(false)
                    setOpenUpdateVersionPopup(false)
                }}
                onVersionUpdateCallback={(version) => {
                    setSoftwareVersions(prev => prev.map(v => {
                        if (v.id === version.id) return version;
                        else return v
                    }))
                    setOpenUpdateVersionPopup(false)
                    setOpenUpdateVersionPopup(false)
                }}
            />}
            <div className="right-content-new"
                style={{ zIndex: 0 }}
                onClick={() => { }}
            >
                <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Software Versions</div></div>
                <div className="main-content">
                    <div style={{ marginTop: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div className="services-content-title"> Software Versions : {softwareVersions.length}</div>
                        {enableAddSoftwareVersionBtn && <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                            <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => setOpenAddVersionPopup(true)} />
                        </div>}
                    </div>
                    {loading ? (
                        <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                        </div>) : (
                        <table className="customers-table">
                            <col style={{ width: "22.5%" }}></col>
                            <col style={{ width: "22.5%" }}></col>
                            <col style={{ width: "22.5%" }}></col>
                            <col style={{ width: "22.5%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                <th style={{ textAlign: 'left' }}>Version</th>
                                <th style={{ textAlign: 'left' }}>Software type</th>
                                <th style={{ textAlign: 'left' }}>Updated on</th>
                                <th style={{ textAlign: 'left' }}>Added by</th>
                                <th style={{ textAlign: 'left' }}></th>
                            </tr>
                            {softwareVersions.length === 0 ?
                                <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                    <td colspan="6">No versions available</td>
                                </tr> :
                                softwareVersions.map((ver, i) =>
                                    <tr className="customers-table-data-row" style={{ height: "45px" }} >
                                        <td style={{ cursor: "pointer", textAlign: "left" }}>{ver.version_number || "-"} </td>
                                        <td style={{ cursor: "pointer", textAlign: "left" }}>{ver.software_type || "-"} </td>
                                        <td style={{ cursor: "pointer", textAlign: "left" }}>{(new Date(ver.created_date)).toLocaleDateString({ numeric: true })} </td>
                                        <td style={{ cursor: "pointer", textAlign: "left" }}>{ver.admin_email || "-"} </td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: "center" }}>
                                                <img src={Edit} onClick={() => {
                                                    setOpenUpdateVersionPopup(ver)
                                                }} style={{ marginLeft: "5px", height: '14px', width: "14px", cursor: "pointer" }} />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                        </table>
                    )
                    }
                </div>

            </div>
        </div>

    );
};
export default SoftwareVersions