import { Link } from 'react-router-dom';
import React from 'react';
import "./style.css";
import ReactPlayer from 'react-player';



export default class VideoPlayer extends React.PureComponent {
  state = {
    url: '',
    videoLink: '',
    streamKey: ''
  }

  render() {
    let { url, playerHeight, playing, playIcon } = this.props;

    return <div>
      <div>
        <ReactPlayer
          config={{
            file: {
              attributes: {
                onContextMenu: e => e.preventDefault(),
              }
            }
          }}
          url={url}
          controls={true}
          playing={playing || false}
          width="100%"
          height={playerHeight ? playerHeight : "100%"}
        />
      </div>
    </div>
  }
}
