import { Link } from 'react-router-dom';
import React from 'react';
import "./style.css";
import { Component } from 'react';
import infoIcon from './icons/info.svg'


export default class SnackBar extends Component {
    state = {

    }
    calculateDaysRemaining = (expiryDate) => {
        let remainingDaysToExpire = (new Date(expiryDate) - new Date()) / 86400000;
        console.log("Remaingin Days For Subscription Expire", remainingDaysToExpire);
        this.setState((state) => ({
            ...state,
            remainingDaysToExpire: remainingDaysToExpire
        }))
    }
    componentDidMount() {
        let { user, type, sidebarMode } = this.props;
        this.setState((state) => ({
            ...state,
            user,
            type,
            sidebarMode

        }))
        if (user.subs.length == 0) {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'free',
                message: 'You do not have any active subscription. Your account is restricted to certain limited features'
            }))
        }
        else if (user.subs[0].plan == 'aeromegh_plan_pro') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro',
                message: 'Your AeroMegh Pro subscription expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })

        }
        else if (user.subs[0].plan == 'aeromegh_plan_pro_trial') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro-trial',
                message: 'Your AeroMegh Pro trial expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })
        }
        else if (user.subs[0].plan == 'aerogcs_plan_pro') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro',
                message: 'Your AeroMegh Pro subscription expires on ',
                expires: user.subs[0].expires

            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })

        }
        else if (user.subs[0].plan == 'aerogcs_plan_pro_trial') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro-trial',
                message: 'Your AeroMegh Pro trial expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })
        }
        else if (user.subs[0].plan == 'aerostream_plan_starter') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro',
                message: 'Your AeroStream Starter subscription expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })

        }
        else if (user.subs[0].plan == 'aerostream_plan_pro') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro',
                message: 'Your AeroStream Pro subscription expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })

        }
        else if (user.subs[0].plan == 'aerostream_plan_starter_trial') {
            this.setState((state) => ({
                ...state,
                subscriptionType: 'pro-trial',
                message: 'Your AeroStream Starter Trial expires on ',
                expires: user.subs[0].expires
            }), () => {
                this.calculateDaysRemaining(user.subs[0].expires)
            })

        }

    }
    componentWillUnmount() {

    }
    render() {
        let { user, type, sidebarMode, pageTitle, userName, firstName, lastName } = this.state;
        let { style } = this.props
        return <div style={style}>
            {this.state.user && (this.state.remainingDaysToExpire < 6 || user.subs.length == 0 || this.state.subscriptionType == 'pro-trial') && <div>
                {this.state.subscriptionType == 'free' &&
                    <div className="snack-bar-error-message" style={{ display: 'flex' }}>
                        <img className="snack-bar-image" src={infoIcon} />
                        <div className="snack-bar-text">{`${this.state.message}`}</div>
                        <Link
                            to={{ pathname: "/my_profile", state: { user, type, sidebarMode } }}
                            className="snack-bar-button">Upgrade Now </Link>
                    </div>
                }

                {
                    this.state.subscriptionType == 'pro' &&
                    <div className="snack-bar-warning-message" style={{ display: 'flex' }}>
                        <img className="snack-bar-image" src={infoIcon} />
                        <div className="snack-bar-text">{`${this.state.message}${new Date(this.state.expires).toGMTString().split(' ').slice(1, 4).join(' ')}`}</div>
                        <Link
                            to={{ pathname: "/my_profile", state: { user, type, sidebarMode } }}
                            className="snack-bar-button">Upgrade Now </Link>
                    </div>}
                {
                    this.state.subscriptionType == 'pro-trial' &&
                    <div className="snack-bar-info-message" style={{ display: 'flex' }}>
                        <img className="snack-bar-image" src={infoIcon} />
                        <div className="snack-bar-text">{`${this.state.message}${new Date(this.state.expires).toGMTString().split(' ').slice(1, 4).join(' ')}`}</div>
                        <Link
                            to={{ pathname: "/my_profile", state: { user, type, sidebarMode } }}
                            className="snack-bar-button">Upgrade Now </Link>
                    </div>}
            </div>
            }
        </div>
    }
}