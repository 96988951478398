import React, { useState, useEffect } from 'react';
import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import { Line, Bar } from "react-chartjs-2";
import dateFormat from 'dateformat';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const SystemMonitoring = (props) => {
    const [user, setUser] = useState({});
    const [type, setType] = useState("");
    const [date, setDate] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [sidebarMode, setSidebarMode] = useState((props.location.state ? props.location.state.sidebarMode : "shrunk"));

    const parentSidebarSwitch = () => {
        setSidebarMode(prevMode => (prevMode === "shrunk" ? "expanded" : "shrunk"));
    }

    const [streamData, setStreamData] = useState({
        labels: [],
        datasets: [
            {
                label: 'My Dataset',
                data: [],
                fill: false,
                borderColor: '#2989CF',
                backgroundColor: '#2989CF',

            },
        ],
    });

    const [flightsData, setFlightsData] = useState({
        labels: [],
        datasets: [
            {
                label: 'My Dataset',
                data: [],
                fill: false,
                borderColor: '#2989CF',
                backgroundColor: '#2989CF',

            },
        ],
    });


    const [xAxisLabel, setXAxisLabel] = useState('Time');
    const [yTicks, setYTicks] = useState({
        padding: 7,
        fill: 'black',
        display: true,
        fontSize: 10,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontColor: '#666666',
        lineHeight: 'auto',
    });

    useEffect(() => {
        document.title = "System Monitoring - Admin";
        setUser(props.location.state.user);
        // getSystemStats()
        setCurrentDate()
    }, []);

    const setCurrentDate = () => {
        const today = new Date().toISOString().split('T')[0];
        setDate(today);
    };

    const handleDate = (event) => {
        console.log("to_date", event.target.value);
        setDate(event.target.value);
    };

    useEffect(() => {
        if (date) getSystemStats()
    }, [date])



    const getSystemStats = async () => {
        try {
            setLoading(true);
            let interval = 2;
            console.log("date : ", new Date(date).toISOString(),);
            let from_date = date ? new Date(date).toISOString() : new Date().toISOString()
            let to_date = new Date(from_date);
            to_date.setHours(to_date.getHours() + 23);
            to_date.setMinutes(to_date.getMinutes() + 59);

            const response = await API.getSystemStats(interval, from_date, to_date.toISOString());

            let labels = [], liveStreamsData = [], liveFlights = [];

            response.system_stats.forEach(stat => {
                labels.push(dateFormat(stat.time, "h:MM TT"))
                liveStreamsData.push(stat.live_streams)
                liveFlights.push(stat.live_flights)
            });

            const streamsChart = {
                labels: labels,
                datasets: [
                    {
                        label: 'Live Streams',
                        data: liveStreamsData,
                        fill: false,
                        borderColor: '#2989CF',
                        backgroundColor: '#2989CF',
                    },
                ],
            };

            const flightsChart = {
                labels: labels,
                datasets: [
                    {
                        label: 'Live Flights',
                        data: liveFlights,
                        fill: false,
                        borderColor: '#2989CF',
                        backgroundColor: '#2989CF',
                    },
                ],
            };

            setStreamData(streamsChart);
            setFlightsData(flightsChart);
            setLoading(false)
            //  setSystemState(response.system_stats);

        } catch (error) {
            console.error('Error fetching system stats:', error);
        }
    };


    return (

        <div className="wrapper">
            <div className="right-content-new"
                style={{ zIndex: 0 }}
                onClick={() => setSidebarMode('shrunk')}
            >
                <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">System Mointoring</div></div>
                <div className="main-content" style={{ paddingBottom: "0px" }}>
                    <div style={{ marginTop: '20px', }} className="container">
                        <div className="add-user-form-text-wrapper" style={{ display: "flex", justifyContent: 'end', paddingBottom: '11px' }} >
                            <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px", marginLeft: '308px' }}>Date:</label>
                            <input
                                className="add-user-form-text"
                                id={date}
                                type='Date'
                                max='9000-01-01'
                                value={date}
                                onChange={handleDate}
                                style={{
                                    backgroundColor: "white",
                                    marginLeft: "19px",
                                    borderRadius: '10px',
                                    outline: "none",
                                    height: "30px",
                                    cursor: "pointer",
                                    width: "125px",
                                }} />
                        </div>
                        <div style={{
                            height: "890px",
                            background: "white",
                        }}>
                            <div style={{ height: "90%" }}>
                                <div className="table-header" style={{ height: '57px', paddingTop: '42px', paddingLeft: '74px' }}>Streams</div>
                                {/* liveStreams */}
                                <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>

                                    {loading ?
                                        <div style={{ height: "67vh", width: "65%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                            <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                        </div>
                                        :
                                        <div style={{ overflow: "hidden", width: '55vw', position: 'relative', paddingLeft: '45px' }}>

                                            <Line
                                                width={'100vw'}
                                                height={'70vh'}
                                                options={{
                                                    maintainAspectRatio: true,
                                                    responsive: true,
                                                    labels: {
                                                        font: {
                                                            family: 'Poppins',
                                                            size: '10',
                                                            style: 'normal',
                                                            color: '#666666',
                                                        },
                                                    },
                                                    legend: {
                                                        display: false,
                                                    },
                                                    elements: {
                                                        point: {
                                                            pointStyle: 'circle',
                                                            backgroundColor: '#2989CF',
                                                        },
                                                    },
                                                    scales: {
                                                        min: 1,
                                                        xAxes: [
                                                            {
                                                                barPercentage: 0.5,
                                                                gridLines: {
                                                                    color: '#2989cf',
                                                                    display: true,
                                                                    drawOnChartArea: false,
                                                                    drawTicks: true,
                                                                    lineWidth: 1,
                                                                },
                                                                ticks: {
                                                                    padding: 7,
                                                                    fill: 'black',
                                                                    display: true,
                                                                    fontSize: 10,
                                                                    fontFamily: 'Poppins',
                                                                    fontStyle: 'normal',
                                                                    fontColor: '#666666',
                                                                    lineHeight: 'auto',
                                                                },
                                                                scaleLabel: {
                                                                    display: true,
                                                                    fontSize: 12,
                                                                    fontFamily: 'Poppins',
                                                                    fontStyle: 'normal',
                                                                    fontColor: '#666666',
                                                                    labelString: xAxisLabel,
                                                                    borderColor: '#666666',
                                                                },
                                                            },
                                                        ],
                                                        yAxes: [
                                                            {
                                                                barPercentage: 1,
                                                                gridLines: {
                                                                    color: '#2989cf',
                                                                    value: 'none',
                                                                    display: true,
                                                                    drawOnChartArea: false,
                                                                    drawTicks: false,
                                                                    lineWidth: 1,
                                                                },
                                                                ticks: yTicks,
                                                                scaleLabel: {
                                                                    fontSize: 12,
                                                                    fontFamily: 'Poppins',
                                                                    fontStyle: 'normal',
                                                                    fontColor: '#666666',
                                                                    display: true,
                                                                    labelString: 'Count',
                                                                },
                                                            },
                                                        ],
                                                    },
                                                }}
                                                data={streamData}
                                            />

                                        </div>
                                    }

                                </div>

                                {/* liveFlights */}
                                <div style={{
                                    marginTop: '65px',
                                    background: "white",
                                    height: "890px",

                                }} className="container">
                                    <div style={{ height: "100%" }}>
                                        <div className="table-header" style={{ height: '57px', paddingTop: '42px', paddingLeft: '74px' }}>Flights</div>
                                        <div style={{ overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}>
                                            {loading ?
                                                <div style={{ height: "67vh", width: "65%", display: "flex", textAlign: "center", paddingLeft: "10%" }}>
                                                    <img src={Loader} style={{ height: "20vh", width: "20vw", margin: "auto" }} />
                                                </div>
                                                :
                                                <div style={{ overflow: "hidden", width: '55vw', position: 'relative', paddingLeft: '45px' }}>
                                                    <Line
                                                        width={'100vw'}
                                                        height={'70vh'}
                                                        options={{
                                                            maintainAspectRatio: true,
                                                            responsive: true,
                                                            labels: {
                                                                font: {
                                                                    family: 'Poppins',
                                                                    size: '10',
                                                                    style: 'normal',
                                                                    color: '#666666',
                                                                },
                                                            },
                                                            legend: {
                                                                display: false,
                                                            },
                                                            elements: {
                                                                point: {
                                                                    pointStyle: 'circle',
                                                                    backgroundColor: '#2989CF',
                                                                },
                                                            },
                                                            scales: {
                                                                min: 1,
                                                                xAxes: [
                                                                    {
                                                                        barPercentage: 1,
                                                                        gridLines: {
                                                                            color: '#2989cf',
                                                                            display: true,
                                                                            drawOnChartArea: false,
                                                                            drawTicks: true,
                                                                            lineWidth: 1,
                                                                        },
                                                                        ticks: {
                                                                            padding: 7,
                                                                            fill: 'black',
                                                                            display: true,
                                                                            fontSize: 10,
                                                                            fontFamily: 'Poppins',
                                                                            fontStyle: 'normal',
                                                                            fontColor: '#666666',
                                                                            lineHeight: 'auto',
                                                                        },
                                                                        scaleLabel: {
                                                                            display: true,
                                                                            fontSize: 12,
                                                                            fontFamily: 'Poppins',
                                                                            fontStyle: 'normal',
                                                                            fontColor: '#666666',
                                                                            labelString: xAxisLabel,
                                                                            borderColor: '#666666',
                                                                        },
                                                                    },
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        barPercentage: 1,
                                                                        gridLines: {
                                                                            color: '#2989cf',
                                                                            value: 'none',
                                                                            display: true,
                                                                            drawOnChartArea: false,
                                                                            drawTicks: false,
                                                                            lineWidth: 1,
                                                                        },
                                                                        ticks: yTicks,
                                                                        scaleLabel: {
                                                                            fontSize: 12,
                                                                            fontFamily: 'Poppins',
                                                                            fontStyle: 'normal',
                                                                            fontColor: '#666666',
                                                                            display: true,
                                                                            labelString: 'Count',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        }}
                                                        data={flightsData}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};



export default SystemMonitoring;