import React, { Component } from 'react';
import fail from './assets/error.png';
import { Modal } from "react-bootstrap";
import closeButton from './close-button.png';
import success from './assets/check_circle_24px.png';
import Loader from './icons/loader.svg';
import waiting from './res/waiting.png';
import * as API from './api.js';
import './style.css';
import Settings from './settings.png';
import minusIcon from './minus.svg';
import plusIcon from './plus.svg';
import loaderGIF from './res/loader.gif';
import alertIcon from './res/alert.png'
import Switch from "react-switch";
import Popup from "reactjs-popup";
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';

const AEROMEGH_PRO_MONTHLY_PRICE = 99

export default class ManageSubscription extends Component {
    state = {
        user: {},
        plan: {},
        popupPageNumber: 1,
        billingPeriods: ['Monthly', 'Yearly'],
        planTerminateUnits: ['Months', 'Years'],
        planTerminateUnit: 'Months',
        planTerminateCount: 1,
        billingPeriod: 'Monthly',
        showCancelSubsPopup: false,
        subscriptionStatus: '',
        loading: true,
        monthlyPlan: {},
        yearlyPlan: {},
        type: "",
        org: {},
        models: [],
        model: {},
        term: "monthly",
        purchaseCount: 1,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        dronesCount: 1,
        plans: []
    }

    togglePleaseWaitPopup = () => {
        document.getElementById("aeromegh-plan-purchase-wait-popup-trigger-button").click();
    }
    toggleCheckSubscriptionStatus = () => {
        document.getElementById("aeromegh-plan-purchase-check").click();
    }

    toggleFormPopup = () => {
        document.getElementById("aeromegh-plan-purchase-form-popup-trigger-button").click();
    }
    toggleSubsConfirm = () => {
        document.getElementById("subs-confirm-popup").click();
    }
    toggleUpdateSubsWaitPopup = () => {
        document.getElementById("update-subs-wait-popup").click();

    }
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }


    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans,
                }))
                let count = 0;
                res.plans.forEach(plan => {
                    count++;
                    if (plan.id === this.state.subscription.pg_subscription_plan_id) {
                        this.setState({ plan })
                    }
                    if (plan.billing_cycle == 'monthly') {
                        this.setState((state) => ({
                            ...state,
                            monthlyPlan: plan,
                            currencySymbol: plan.currency_symbol
                        }), () => {
                            this.getTotalCost()
                        })
                    } else if (plan.billing_cycle == 'yearly') {
                        this.setState((state) => ({
                            ...state,
                            yearlyPlan: plan,
                            totalCost: plan.amount,
                            currencySymbol: plan.currency_symbol
                        }), () => {
                            this.getTotalCost()
                        })
                    }
                    else {
                        return 0
                    }
                    if (count == res.plans.length) {
                        this.setState((state) => ({
                            ...state,
                            loading: false
                        }))
                    }
                });
            }
            // this.setState({
            //     aeromeghPlans: res.plans
            // })
        }).catch(e => {
            console.log(e)
            // alert("Failed to fetch required page data")
            // window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))
        })


    }


    getTotalCost = async () => {
        let totalCost = 0;
        let diffAmount = 0;
        if (this.state.plan) {
            let d = new Date();
            let expire = new Date(this.state.subscription.next_billing_date);
            let days = (expire - d) / 86400000
            let perDayCost = this.state.plan.amount / (this.state.plan.billing_cycle === "yearly" ? 365 : 30)
            diffAmount = days * perDayCost * (this.state.subscription.count - this.state.dronesCount)
            console.log("Yearly plan")
            console.log("today", d)
            console.log("expires on", expire)
            console.log("days left", days)
            console.log("perdaycost", perDayCost)
            console.log("diffamount", diffAmount)
        }
        totalCost = await (this.state.dronesCount * this.state.plan.amount).toFixed(2)
        this.setState((state) => ({
            ...state,
            totalCost: totalCost,
            diffAmount: diffAmount.toFixed(2)
        }))
    }
    getSubscription = () => {
        API.getSubscription(this.state.subscriptionID).then((data) => {
            if (data.status == 'active') {
                window.history.back()
            } else {
                this.getSubscription()
            }
        }, (e) => {
            console.log(e);
        })
    }
    UpdateSubcription = () => {
        API.updateSubscription(this.state.subscription.id, {
            pg_subscription_plan_id: this.state.subscription.pg_subscription_plan_id,
            count: this.state.dronesCount
        }).then((data) => {
            console.log(data);
            if (data.message == 'UPDATED') {
                // this.setState((state) => ({
                //     ...state,
                //     subscriptionID: data.id
                // }))
                // alert('subscription updated')
                this.setState((state) => ({
                    ...state,
                    createIcon: success,
                    updateSubsText: 'Subscription Updated Successfully...'
                }))
                window.history.back();

            } else {

                this.setState((state) => ({
                    ...state,
                    createIcon: fail,
                    updateSubsText: 'Something went Wrong...'
                }))
            }
        }, (e) => {
            console.log(e);
        })
    }
    cancelSubscription = () => {
        API.cancelSubscription(this.state.subscription.id).then((data) => {
            console.log(data);
            this.setState((state) => ({
                ...state,
                createIcon: success,
                cancelSubsText: 'Subscription Canceled Successfully'
            }), () => {
                window.history.back()
                // this.getAllSubscriptions();
            })
            // this.toggleSubCancelPopup()
        }, (e) => {
            console.log(e);
        })
    }

    componentDidMount() {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.popup-content { width: 35% !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);

    }
    componentWillMount() {
        document.title = "Plans - AeroMegh";
        if (this.props.location.state) {
            let { user, type, sidebarMode, subscription } = this.props.location.state;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            if (subscription.billing_cycle == 'monthly') {
                this.setState((state) => ({
                    ...state,
                    dronesCount: subscription.count,
                    totalCost: this.state.monthlyPlan.amount * subscription.count
                }))
            } else if (subscription.billing_cycle == 'yearly') {
                this.setState((state) => ({
                    ...state,
                    dronesCount: subscription.count,
                    totalCost: this.state.yearlyPlan.amount * subscription.count
                }))

            }
            
            this.setState((state) => ({
                ...state,
                user,
                type,
                sidebarMode,
                subscription,
                dronesCount: subscription.count

            }), () => {
                this.getAeromeghPlans();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getAeromeghPlans();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state
        let sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: Settings,
                    text: "Settings",
                    path: "/profile-settings",
                    selected:true,
                }
            ],
            user,
            type,
            mode: this.state.sidebarMode
        }
        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <Popup
                        trigger={<button id="update-subs-wait-popup" style={{
                            display: "none",
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("update-subs-wait-popup").click();
                                this.setState((state) => ({
                                    ...state,
                                    createIcon: waiting,
                                    updateSubsText: 'Updating Subscription Please Wait...',
                                }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.updateSubsText
                                }</div>

                            </>

                        </div>
                    </Popup>
                    <Popup
                        trigger={<button id="aeromegh-plan-purchase-wait-popup-trigger-button" style={{
                            display: "none"
                        }}></button>}
                        modal
                    >
                        <div id="aeromegh-plan-purchase-wait-popup">
                            <div id="aeromegh-plan-purchase-wait-popup-content">
                                <img id="aeromegh-plan-purchase-wait-popup-icon" src={this.state.aeromeghPlanPurchaseError ? alertIcon : loaderGIF} />
                                <div id="aeromegh-plan-purchase-wait-popup-text1">
                                    {this.state.aeromeghPlanPurchaseError ? "Error!" : "Updating your subscription..."}
                                </div>
                                <div id="aeromegh-plan-purchase-wait-popup-text2">
                                    {this.state.aeromeghPlanPurchaseError ? (typeof this.state.aeromeghPlanPurchaseError == "object" ? JSON.stringify(this.state.aeromeghPlanPurchaseError) : this.state.aeromeghPlanPurchaseError) : "Please wait."}
                                </div>
                            </div>
                        </div>
                    </Popup>
                    <Popup
                        trigger={<button id="send-plan-popup-button" style={{
                            display: "none",
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("send-plan-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    createIcon: waiting,
                                    cancelSubsText: 'Cancelling Subscription Please Wait...',
                                }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.cancelSubsText
                                }</div>

                            </>

                        </div>
                    </Popup>

                    <Popup
                        trigger={<button id="subs-confirm-popup" style={{
                            display: "none",
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("subs-confirm-popup").click();
                                // this.setState((state) => ({
                                //     ...state,
                                //     createIcon: waiting,
                                //     cancelSubsText: 'Cancelling Subscription Please Wait...',
                                // }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                {/* <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} /> */}
                                <div style={{ width: "100%", textAlign: "center", margin: '30px auto', height: '64px' }}>{
                                    // this.state.cancelSubsText
                                    `Cost to pay ${this.state.currencySymbol} ${this.state.diffAmount}`
                                }

                                </div>

                            </div>

                            <>


                                <button type="submit" style={{ margin: 'auto', marginBottom: '50px' }} className="add-user-form-submit-button"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.setState((state) => ({
                                            ...state,
                                            createIcon: waiting,
                                            updateSubsText: 'Updating Subscription Please Wait...',
                                        }))
                                        this.toggleSubsConfirm()
                                        this.toggleUpdateSubsWaitPopup()
                                        this.UpdateSubcription()

                                    }}
                                >
                                    Update
                                    </button>

                            </>

                        </div>
                    </Popup>
                    <Modal
                        style={{ marginTop: '150px' }}
                        show={this.state.showCancelSubsPopup}
                        onHide={() => {
                            this.setState((state) => ({
                                ...state,
                                showCancelSubsPopup: false,

                            }));
                        }}
                    >
                        <Modal.Body >
                            <p>Are you sure you want to cancel subscription?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        showCancelSubsPopup: false,
                                    }));
                                }}
                            >
                                cancel
            </button>
                            <button
                                className="add-user-form-submit-button popup-btn-right btn-ok"
                                onClick={() => {
                                    document.getElementById("send-plan-popup-button").click();
                                    this.cancelSubscription();
                                    this.setState((state) => ({
                                        ...state,
                                        showCancelSubsPopup: false,
                                        createIcon: waiting,
                                        cancelSubsText: 'Cancelling Subscription Please Wait...',
                                    }))


                                }}
                            >
                                Ok
            </button>

                        </Modal.Footer>
                    </Modal>
                    {this.state.redirectToInvoicePage && <Redirect to={{
                        pathname: `/invoice/${this.state.invoiceID}`,
                        state: this.state
                    }}></Redirect>}
                    <Popup
                        trigger={<button id="aeromegh-plan-purchase-wait-popup-trigger-button" style={{
                            display: "none"
                        }}></button>}
                        modal
                    >
                        <div id="aeromegh-plan-purchase-wait-popup">
                            <div id="aeromegh-plan-purchase-wait-popup-content">
                                <img id="aeromegh-plan-purchase-wait-popup-icon" src={this.state.aeromeghPlanPurchaseError ? alertIcon : loaderGIF} />
                                <div id="aeromegh-plan-purchase-wait-popup-text1">
                                    {this.state.aeromeghPlanPurchaseError ? "Error!" : "Placing your order..."}
                                </div>
                                <div id="aeromegh-plan-purchase-wait-popup-text2">
                                    {this.state.aeromeghPlanPurchaseError ? (typeof this.state.aeromeghPlanPurchaseError == "object" ? JSON.stringify(this.state.aeromeghPlanPurchaseError) : this.state.aeromeghPlanPurchaseError) : "Please wait."}
                                </div>
                            </div>
                        </div>
                    </Popup>
                    <Popup
                        trigger={<button id="aeromegh-plan-purchase-check" style={{
                            display: "none",
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("aeromegh-plan-purchase-check").click();

                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={waiting} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    `Complete your payment in new tab`
                                }</div>

                            </>

                        </div>
                    </Popup>
                    <Popup
                        trigger={<button id="aeromegh-plan-purchase-form-popup-trigger-button" style={{
                            display: "none"
                        }}></button>}
                        modal
                    >
                        <div style={{ display: 'flex' }}>
                            <form style={{ margin: 'auto', marginBottom: '30px', marginTop: '30px' }}>
                                <div className="services-content-title" style={{ marginLeft: '24%' }}>AeroMegh Pro</div>
                                <div style={{ margin: 'auto', marginLeft: '14%' }}>
                                    <div className="add-user-form-row">
                                        <div className="add-user-form-row-left" style={{ display: 'flex' }}>
                                            <div id="aeromegh-plan-validity-selector-wrapper">
                                                <div />
                                                <div id="aeromegh-plan-validity-selector">
                                                    <div id="aeromegh-plan-validity-selector-contents">

                                                        <div className="add-user-form-label" style={{ marginTop: '8px' }}>Anually</div>
                                                        <div style={{ marginTop: '9px' }}> <Switch
                                                            uncheckedIcon={false}
                                                            height={20}
                                                            width={40}
                                                            checkedIcon={false}
                                                            offColor={"#666666"}
                                                            onColor={"#2989CF"}
                                                            checked={this.state.term === "monthly"}
                                                            onChange={(checked) => {
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    term: (checked ? "monthly" : "yearly")
                                                                }), () => {
                                                                    this.getTotalCost()
                                                                })

                                                            }}
                                                        /></div>
                                                        <div className="add-user-form-label" style={{ marginTop: '8px' }}>Monthly</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="add-user-form-row">
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label" style={{ marginTop: '8px' }}>
                                            Number Of Drones
                                            </div>
                                        <div className="add-user-form-text-wrapper">


                                        </div>
                                    </div>
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                        </div>
                                        <div className="add-user-form-text-wrapper">
                                            <img style={{ width: '36px', cursor: 'pointer', marginLeft: '8px' }} src={minusIcon}
                                                onClick={() => {
                                                    if (this.state.dronesCount > 1) {
                                                        let count = this.state.dronesCount - 1
                                                        this.setState((state) => ({
                                                            ...state,
                                                            dronesCount: count
                                                        }), () => {
                                                            this.getTotalCost()
                                                        })
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                            {/* Number Of Drones */}
                                        </div>
                                        <div className="add-user-form-text-wrapper">

                                            <input name="droneCount" disabled="true" type="number" min="1" max="10000" required={true} className="add-user-form-text" value={this.state.dronesCount} style={{ width: '60px', height: '30px', marginLeft: '5px', textAlign: 'center' }} />

                                        </div>
                                    </div>

                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label">
                                        </div>
                                        <div className="add-user-form-text-wrapper" >
                                            <img style={{ width: '36px', cursor: 'pointer', marginLeft: '5px' }} src={plusIcon} onClick={() => {
                                                if (this.state.dronesCount > 0) {
                                                    let count = this.state.dronesCount + 1
                                                    this.setState((state) => ({
                                                        ...state,
                                                        dronesCount: count
                                                    }), () => {
                                                        this.getTotalCost()
                                                    })
                                                }
                                            }} />

                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-row" style={{ marginTop: "35px" }}>
                                    <div className="add-user-form-row-left">
                                        <div className="add-user-form-label" style={{ marginTop: '8px', margin: 'auto' }}>
                                            <div style={{ color: '#2989cf', display: 'flex' }}>
                                                <h2 style={{ color: "#3c3c3c", fontSize: '18px' }}>{`Total cost to pay  `}</h2>
                                                <h1 style={{ fontSize: '24px', fontWeight: '500', marginLeft: '5px' }}>{` ${this.state.currencySymbol}${this.state.totalCost}`}</h1>
                                            </div>
                                        </div>
                                        <div className="add-user-form-text-wrapper">


                                        </div>
                                    </div>
                                </div>

                                <div className="add-user-form-action-row" style={{ marginTop: '35px' }}>
                                    <button type="submit" style={{ margin: 'auto' }} className="add-user-form-submit-button"
                                        onClick={() => {
                                            this.toggleFormPopup();
                                            this.togglePleaseWaitPopup();
                                            this.BuySubcription()
                                        }}
                                    >
                                        Buy
                                    </button>
                                </div>
                            </form>
                        </div>


                    </Popup>
                    <div className="top-bar"><div className="top-bar-text">Manage Subscription</div></div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto"  }} />
      </div>  :
                        <div className="main-content">
                            <div className="page" style={{ padding: "0px", height: "auto", minHeight: "815px", paddingBottom: "60px" }}>
                                <div className="rpas-model-info-top-section" style={{ background: "white" }}>
                                    <div className="rpas-model-info-primary-details">
                                        <div className="rpas-model-info-primary-details-title-row">
                                            {this.state.plans.length > 0 ? <div className="services-content-title">

                                                {
                                                    this.state.subscription && this.state.plans && this.state.plans.length > 0 ?
                                                        this.state.plans.map((plan) => {
                                                            if (plan.id == this.state.subscription.pg_subscription_plan_id) {
                                                                return plan.name
                                                            }
                                                        }) : ''
                                                }
                                            </div> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="rpas-model-info-bottom-section">
                                    <div id="aeromegh-plan-validity-selector-wrapper">
                                        <div />

                                        <form style={{ margin: 'auto', marginBottom: '30px', marginTop: '30px' }}>
                                            {/* <div className="services-content-title" style={{ marginLeft: '24%' }}>AeroMegh Pro</div> */}
                                            <div style={{ margin: 'auto', marginLeft: '14%' }}>
                                                <div className="add-user-form-row">
                                                    <div className="add-user-form-row-left" style={{ display: 'flex' }}>
                                                        <div id="aeromegh-plan-validity-selector-wrapper">
                                                            <div />
                                                            {/* <div id="aeromegh-plan-validity-selector">
                                                                <div id="aeromegh-plan-validity-selector-contents">

                                                                    <div className="add-user-form-label" style={{ marginTop: '8px' }}>Anually</div>
                                                                    <div style={{ marginTop: '9px' }}> <Switch
                                                                        uncheckedIcon={false}
                                                                        height={20}
                                                                        width={40}
                                                                        checkedIcon={false}
                                                                        offColor={"#666666"}
                                                                        onColor={"#2989CF"}
                                                                        checked={this.state.term === "monthly"}
                                                                        onChange={(checked) => {
                                                                            this.setState((state) => ({
                                                                                ...state,
                                                                                term: (checked ? "monthly" : "yearly")
                                                                            }), () => {
                                                                                this.getTotalCost()
                                                                            })

                                                                        }}
                                                                    /></div>
                                                                    <div className="add-user-form-label" style={{ marginTop: '8px' }}>Monthly</div>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.subscription && this.state.subscription.next_billing_date && (new Date() < new Date(this.state.subscription.next_billing_date)) ? <div className="add-user-form-row" style={{ marginTop: "35px" }}>
                                                <div className="add-user-form-row-left" style={{ display: 'contents' }}>
                                                    <div className="add-user-form-label" style={{ marginTop: '8px', margin: 'auto' }}>
                                                        <div style={{ fontFamily: 'Poppins' }}>
                                                            <div
                                                                style={{ marginLeft: '8px', margin: 'auto' }}>{` Next Renewal Date `}<span style={{ fontWeight: 'bold' }}>{new Date(this.state.subscription.next_billing_date).toGMTString().split(' ').slice(1, 4).join(' ')}</span></div>
                                                            {/* <p style={{ fontWeight: 'bold' }}>{`${new Date(this.state.subscription.next_billing_date).toLocaleString()}`}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">


                                                    </div>
                                                </div>
                                            </div> : ''}
                                            <div className="add-user-form-row" style={{ display: 'flex' }}>
                                                <div className="add-user-form-row-left" style={{ margin: 'auto' }}>
                                                    <div className="add-user-form-label" style={{ marginTop: '8px', fontSize: '18px' }}>
                                                        Number Of Drones
                                            </div>
                                                    <div className="add-user-form-text-wrapper">


                                                    </div>
                                                </div>
                                                <div className="add-user-form-row-left" style={{ margin: 'auto' }}>
                                                    <div className="add-user-form-label">
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">
                                                        <img style={{ width: '36px', cursor: 'pointer', marginLeft: '8px' }} src={minusIcon}
                                                            onClick={() => {
                                                                if (this.state.dronesCount > 1) {
                                                                    let count = this.state.dronesCount - 1
                                                                    this.setState((state) => ({
                                                                        ...state,
                                                                        dronesCount: count
                                                                    }), () => {
                                                                        this.getTotalCost()
                                                                    })
                                                                }
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="add-user-form-row-left" style={{ margin: 'auto' }}>
                                                    <div className="add-user-form-label">
                                                        {/* Number Of Drones */}
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">

                                                        <input name="droneCount" disabled="true" type="number" min="1" max="10000" required={true} className="add-user-form-text" value={this.state.dronesCount} style={{ width: '60px', height: '30px', marginLeft: '5px', textAlign: 'center' }} />

                                                    </div>
                                                </div>

                                                <div className="add-user-form-row-left" style={{ margin: 'auto' }}>
                                                    <div className="add-user-form-label">
                                                    </div>
                                                    <div className="add-user-form-text-wrapper" >
                                                        <img style={{ width: '36px', cursor: 'pointer', marginLeft: '5px' }} src={plusIcon} onClick={() => {
                                                            if (this.state.dronesCount > 0) {
                                                                let count = this.state.dronesCount + 1
                                                                this.setState((state) => ({
                                                                    ...state,
                                                                    dronesCount: count
                                                                }), () => {
                                                                    this.getTotalCost()
                                                                })
                                                            }
                                                        }} />

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="add-user-form-row" style={{ marginTop: "35px" }}>
                                                <div className="add-user-form-row-left">
                                                    <div className="add-user-form-label" style={{ marginTop: '8px', margin: 'auto' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <h2 style={{ fontSize: '18px' }}>{`Updated total cost to pay  `}</h2>
                                                            <h1 style={{ fontSize: '24px', color: '#2989cf', fontWeight: '500', marginLeft: '10px' }}>{` ${this.state.currencySymbol}${this.state.totalCost}`}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="add-user-form-text-wrapper">


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="add-user-form-action-row" style={{ marginTop: '35px' }}>
                                                <button type="submit" style={{ margin: 'auto' }} className="add-user-form-submit-button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        // this.togglePleaseWaitPopup();

                                                        // this.toggleFormPopup();
                                                        // this.togglePleaseWaitPopup();
                                                        // this.BuySubcription()
                                                        // if (this.state.dronesCount > this.state.subscription.count) {
                                                        //     this.toggleSubsConfirm()
                                                        // } else {

                                                        this.setState((state) => ({
                                                            ...state,
                                                            createIcon: waiting,
                                                            updateSubsText: 'Updating Subscription Please Wait...',
                                                        }))
                                                        this.toggleUpdateSubsWaitPopup()
                                                        this.UpdateSubcription()
                                                        // }
                                                    }}
                                                >
                                                    Update
                                    </button>
                                            </div>
                                        </form>



                                    </div>
                                    <div style={{ fontFamily: 'Poppins', fontSize: '13px', display: 'flex' }}>
                                        {
                                            this.state.dronesCount > this.state.subscription.count &&

                                            <div className="add-user-form-row-left" style={{ margin: 'auto' }}>{`Note : You will be automatically charged for`}
                                                <span style={{ marginRight: '8px', fontWeight: 'bold', marginLeft: '8px' }}>{`${this.state.dronesCount - this.state.subscription.count}`}</span>
                                              new drones from
                                              <span style={{ marginRight: '8px', fontWeight: 'bold', marginLeft: '8px' }}>{`${new Date().toGMTString().split(' ').slice(1, 4).join(' ')}`}</span>
                                               to
                                               <span style={{ marginRight: '8px', fontWeight: 'bold', marginLeft: '8px' }}>{
                                                    new Date() < new Date(this.state.subscription.next_billing_date) ?
                                                        `${new Date(this.state.subscription.next_billing_date).toGMTString().split(' ').slice(1, 4).join(' ')}` : 'next billing cycle'}</span>
                                                on pro rata basis after you click update.</div>
                                        }
                                        {this.state.dronesCount < this.state.subscription.count &&
                                            <div className="add-user-form-row-left" style={{ margin: 'auto' }}>
                                                Note : These changes will take effect from
                                         <span style={{ marginRight: '8px', fontWeight: 'bold', marginLeft: '8px' }}>{
                                                    new Date() < new Date(this.state.subscription.next_billing_date) ?
                                                        `${new Date(this.state.subscription.next_billing_date).toGMTString().split(' ').slice(1, 4).join(' ')}` : 'next billing cycle'}</span> </div>
                                        }
                                    </div>

                                    <div className="add-user-form-action-row" style={{ marginTop: '12%', marginLeft: 'auto', width: 'fit-content' }}>
                                        <div type="submit"
                                            style={{ color: '#2989cf', cursor: 'pointer', fontSize: '14px' }}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    showCancelSubsPopup: true
                                                }))
                                            }}
                                        >
                                            Cancel Entire Subscription
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div> : <div />
        );
    }
}
