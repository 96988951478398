export const enterpriseSubscriptionPlans =
{
    yearly: {
        ProPlan: {
            Prise: 18819,
            DescriptionData: [
                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                'Flight logs with analytics',
                '8*5 Days Standard Support'
            ]
        },
        BusinessPlan: {
            Prise: 20910,
            DescriptionData: [
                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                'Flight logs with analytics',
                '8*5 Days Standard Support',
                'Live Video Streaming',
            ]
        },
        EnterprisePlan: {
            Prise: 22440,
            DescriptionData: [
                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Flight logs with analytics',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                '8*5 Days Standard Support',
                'Live Video Streaming',
                'AeroGCS Enterprise API',
            ]
        }
    },
    monthly: {
        ProPlan: {
            Prise: 1845,
            DescriptionData: [

                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Flight logs with analytics',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                '8*5 Days Standard Support',
            ]
        },
        BusinessPlan: {
            Prise: 2050,
            DescriptionData: [
                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Flight logs with analytics',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                '8*5 Days Standard Support',
                'Live Video Streaming'
            ]
        },
        EnterprisePlan: {
            Prise: 2200,
            DescriptionData: [
                'Unlimited Teams',
                'Unlimited Users',
                'Project Synchronization',
                'Live Tracking of drones on Cloud',
                'Flight logs with analytics',
                'Business Analytics',
                'Drone Performance Analytics',
                'Replay of Flight',
                '8*5 Days Standard Support',
                'Live Video Streaming',
                'AeroGCS Enterprise API'
            ]
        }
    }
}
