import React, { Component } from 'react';
import * as API from '../api.js';
import '../style.css';
import { connect } from "react-redux";
import AeroGCSInactive from '../sidebaricon/AeroGCSInactive.png';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import leaseActive from '../icons/leaseActive.png'
import Loader from '../icons/loader.svg';
import noDroneVector from '../icons/noDroneVector.png'

class leaseDroneMgmt extends Component {

    state = {
        user: {},
        type: "",
        organizations: [],
        drones: [],
        unregisteredAssignedDrones: [],
        sortType: 'Last Modified',
        sortTypeName: false,
        sortTypeDate: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        loading: true,
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    sortByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'A-Z'
        }));
        let sort = this.state.drones.sort((a, b) => a.drone_name.localeCompare(b.drone_name))
        this.setState((state) => ({
            ...state,
            drones: sort,
        }));

    }

    reverseByName = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Z-A'
        }));
        let sort = this.state.drones.sort((a, b) => b.drone_name.localeCompare(a.drone_name))
        this.setState((state) => ({
            ...state,
            drones: sort,
        }));
    }

    sortByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'First Modified'
        }));
        let sort = this.state.drones.sort((a, b) => a.registered_date.localeCompare(b.registered_date))
        this.setState((state) => ({
            ...state,
            drones: sort,
        }));
    }

    reverseByDate = () => {
        this.setState((state) => ({
            ...state,
            sortType: 'Last Modified'
        }));
        let sort = this.state.drones.sort((a, b) => b.registered_date.localeCompare(a.registered_date))
        this.setState((state) => ({
            ...state,
            drones: sort,
        }));
    }

    getDronesData = () => {
        API.gcsDrones().then((result) => {

            this.setState(state => ({
                ...state,
                drones: result.drones,
                unregisteredAssignedDrones: result.unregisteredAssignedDrones,
                loading: false
            }))
        }, (e) => {
            console.log(e)
        })
    }

    componentWillMount() {
        document.title = "Drones - Device Management";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => {
                this.getDronesData();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    this.getDronesData();
                })
            }, (e) => {
                window.location.replace("/login")
            })
        }
    }


    render() {
        let { user, type, customerDisplay, organizations, sidebarMode } = this.state
        let sidebarConfigurations = null
        sidebarConfigurations = {
            options: [
                // {
                //     icon: service,
                //     text: "Services",
                //     path: "/"
                // },
                {
                    icon: AeroGCSInactive,
                    text: "AeroGCS",
                    path: "/"
                },
                // {
                //     icon: DronesInactive,
                //     text: "Drones",
                //     path: "/drone_management/drones",
                // },
                // {
                //     icon: RPAS,
                //     text: "Drone Control Center",
                //     path: "/drone_control_center/all_drones",
                // },
                // {
                //     icon: ProjectsInActive,
                //     text: "Projects",
                //     path: "/projects",
                // }
                {
                    icon: leaseActive,
                    text: "Drone Lease",
                    path: "/drone_management/lease_dashboard",
                    selected: true
                },
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            aeroGCSHelp: true,
        }


        return (
            user.username ? <div className="wrapper">
                <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} />
                <div className="right-content"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >


                    <div className="top-bar"><div className="top-bar-text">LEASE DRONES</div></div>
                    {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                        <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                    </div> : <div className="main-content">

                        {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="services-content-title">{"Registered Drones"}</div>
                            {/* <Link style={{ color: "#3C3C3C" }} to={{
                                    pathname: `/drone_management/rent-drone_customers`,
                                    state: { user: this.state.user, type: this.state.type, sidebarMode: this.state.sidebarMode }
                                }}>
                                    Rent Drone
                                </Link> */}
                        </div>

                        <div>
                            {this.state.drones.length ? <table className="customers-table">
                                <tr className="customers-table-header-row">
                                    <th>Drone Id</th>
                                    <th>Drone Name
                                        {/* <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                
                                                sortAscending={this.sortByName}
                                                sortDecending={this.reverseByName}
                                                sortASC={false}
                                            /> */}
                                    </th>
                                    <th style={{ textAlign: 'center' }}>Registered Date
                                        {/* <SortComponent
                                                style={{ marginLeft: '5px' }}
                                                
                                                sortAscending={this.sortByDate}
                                                sortDecending={this.reverseByDate}
                                                sortASC={false}
                                            /> */}
                                    </th>
                                </tr>
                                {this.state.drones.map((drone, key) => (
                                    <tr className="customers-table-data-row">
                                        <td>
                                            <Link style={{ color: "#707683" }} to={{
                                                pathname: `/drone_management/lease_drone_details`,
                                                state: { user, type, drone, sidebarMode: this.state.sidebarMode }
                                            }}>
                                                {drone.drone_id}
                                            </Link>
                                        </td>
                                        <td>{drone.drone_name}</td>
                                        <td style={{ textAlign: 'center' }}>{(new Date(drone.registered_date)).toGMTString().split(' ').slice(1, 4).join(' ')}</td>
                                    </tr>
                                ))}
                            </table> :
                                <div className="empty-data-message" style={{ display: 'flex', flexDirection: 'column', height: '85vh', backgroundColor: "#FAFAFA", alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={noDroneVector} style={{ width: "18%", }} />
                                    <div>No Drones Found</div>
                                </div>
                            }
                        </div>
                        {/* {this.state.unregisteredAssignedDrones.length ? <div>
                                <div style={{ display: "flex", marginTop: "30px" }}>
                                    <div className="services-content-title">{"Available Drones"}</div>
                                </div>

                                <div>
                                    <table className="customers-table">
                                        <tr className="customers-table-header-row">
                                            <th>Drone ID</th>
                                            <th>Model</th>
                                            <th>Registered date</th>
                                        </tr>
                                        {this.state.unregisteredAssignedDrones.length ? (this.state.unregisteredAssignedDrones.map((drone, key) => (
                                            <tr className="customers-table-data-row">
                                                <td> {drone.drone_id} </td>
                                                <td> {drone.model} </td>
                                                <td>{(new Date(drone.registered_date)).toGMTString().split(' ').slice(1, 4).join(' ')}</td>
                                            </tr>
                                        ))) : <tr className="customers-table-data-row">
                                            <td />
                                            <td style={{ textAlign: "left", width: "60%" }}>{"No drones registered"}</td>
                                        </tr>}
                                    </table>
                                </div>
                            </div> : <div />} */}
                    </div>
                    }
                </div>
            </div> : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        activeTeamId: state.profile.activeTeamId,
        activeTeam: state.profile.activeTeam,
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps, null)(leaseDroneMgmt)
