// lib imports
import React, { Component } from "react"
import { BrowserRouter, Route, Switch, } from "react-router-dom"
import Cookies from "universal-cookie"
// lib imports ends

// default components 
import { Profile } from "./Profile"
import Services from "./Services"
import F0F from "./F0F"
import Logout from "./Logout"
import MyProfile from "./Profile/MyProfile"
import * as API from "./api"
import "./App.css"
import { OnIdleHandlerWrapper } from "./helperComponents/OnIdleHandlerWrapper"
import ProfileSettings from './Profile/ProfileSettings'
import userLogin from './userAuth/userLogin'
import ForgotPassword from './userAuth/ForgotPassword'
import ResetPasswordSuccessful from './userAuth/ResetPasswordSuccessful'
import accountActivetionmsg from './userAuth/accountActivetionmsg'
import userSignup from "./userAuth/UserSignUp"
import SideBarComponent from "./ReusableComponents/SideBarComponent.js"
import AeroGCSEnterpriseTeam from "./AeroGCSEnterprise/Components/AeroGCSEnterpriseTeam"
import Feedback from "./Feedback.js"
import ManageSubscription from "./ManageSubscription"
import editUser from "./Profile/editUser"
import AeroMeghPlans from './AeroMeghPlans'
import Invoice from './Invoice'
import Invoices from './Invoices'
import UsageStatistics from "./UsageStatistics"
import LoaderComponent from "./ReusableComponents/LoaderComponent"
// default components end

//Analytics Tool
import AnalyticUserDetails from "./AnalyticTool/Components/UserDetails"
import AnalyticUserLogs from "./AnalyticTool/Components/UserTrackingLogs"
import AnalyticScreenLogs from "./AnalyticTool/Components/UserScreenLogs"
import UserStatistics from "./AnalyticTool/Components/UserStatistics"
import CustomStatistics from "./AnalyticTool/Components/CustomStatistics"
//Analytics Tool end

// GCS-E
import DashboardAeroplane from './AeroGCSEnterprise/Dashboard/DashboardAeroplane.js'
import Flights from "./AeroGCSEnterprise/Flights/Flights.js"
import Acres from "./AeroGCSEnterprise/Acres/Acres.js"
import Pilots from "./AeroGCSEnterprise/Pilots/Pilots.js"
import Projects from "./Projects"
import Plans from "./Plans"
import RMPAllFlightPlans from "./RMPAllFlightPlans.js"
import userCustomer from './leaseDrones/userCustomer'
import userCustomerInfo from './leaseDrones/userCustomerInfo'
import userAllLease from './leaseDrones/userAllLease'
import leaseDashboard from './leaseDrones/leaseDashboard'
import leaseDroneMgmt from './leaseDrones/leaseDroneMgmt'
import leaseDroneDetails from './leaseDrones/leaseDroneDetails'
import leaseDroneInvoice from './leaseDrones/leaseDroneInvoice'
import leaseInvoices from './leaseDrones/leaseInvoices'
import AeroGCSGreenDashboard from "./AeroGCSGreenEnterprise/AeroGCSGreenDashboard"
import AeroGCSGreenCustomers from "./AeroGCSGreenEnterprise/AeroGCSGreenCustomers"
import AeroGCSGreenReports from "./AeroGCSGreenEnterprise/AeroGCSGreenReports"
import DrnMgmtDrones from "./DrnMgmtDrones"
import DrnMgmtDroneDetails from "./DrnMgmtDroneDetails"
import DCCAllDrones from "./DCCAllDrones"
import DCCAllFeeds from "./DCCAllFeeds"
import DCCFeed from "./DCCFeed"
import DCCDroneFlyView from './DCCDroneFlyView'
import RMSelectLocation from "./RMSelectLocation"
import RMFlightPlan from "./RMFlightPlan"
import RMAssignDrone from './RMAssignDrone'
import RMDetailedLog from './RMDetailedLog'
import RMFlightDetails from './RMFlightDetails'
import RMPMyVideos from './RMPMyVideos'
import RMPMyImages from './RMPMyImages'
import RMFlightRoute from './RMFlightMapView'
import RMPFlightStatusLogs from './RMPFlightStatusLogs'
import RMPFlightStatistics from './RMPFlightsStatistics'
import RMPFlightCompletedHours from './RMPFlightCompletedHours'
import RMPFlightLogsByDroneId from './RMPFlightLogsByDroneId.js'
import RMPAllFlightsLogs from './RMPAllFlightsLogs.js'
import RMPSurveyFlightPlan from './RMPSurveyFlightPlan.js'
import RMPSprayinhFlightPlan from './RMPSprayinhFlightPlan.js'
import RMPSprayingFlightPlot from './RMPSprayingFlightPlot.js'
import RMPLiveVideoStramingURL from "./RMPLiveVideoStramingURL"
import AeroStreamVideos from "./aeroStreamVideos"
import AeroStreamDownloadProgress from './AeroStreamDownloadProgress'
import IncidentReport from "./incident/IncidentReport"
import NewIncident from "./incident/NewIncident"
import IncidentReportDetail from "./incident/IncedentReportDetail"
import RMPImage_collection from "./RMPImage_collection"
// GCS E end

//admin tool 
import AdminUsers from "./AdminUsers"
import AdminEditUsers from "./AdminEditUsers"
import AdminUserDetails from "./AdminUserDetails"
import AdminUserTrackingLogs from './AdminUserTrackingLogs'
import AdminUserScreenLog from './AdminUserScreenLog'
import AdminUsersStatistics from './AdminUsersStatistics'
import AdminAddChangeSubscription from './AdminAddChangeSubscription'
import SystemMonitoring from "./SystemMonitoring.js"
import AdminAssignService from "./AdminAssignService"
import allSubscription from './subscription/allSubscription'
import subscriptiontems from './subscription/subscriptiontems'
import newSubscription from './subscription/newSubscription'
import DiscountCoupons from "./subscription/DiscountCoupons"
import AdminGCSInstall from './AdminGCSInstall'
import TaskAndVmList from './TaskAndVmList'
import FlightErrorLogs from "./FlightErrorLogs.js"
import WeeklyReport from "./AeroGCSEnterprise/WeeklyReport/WeeklyReport.js"
import SoftwareVersions from "./SoftwareVersions.js"
// admin tool end

class App extends Component {
  state = {
    done: false,
    teamsLoaded: false,
    serviceLoad: [],
    stateRoutes: [
      { path: "/teams", componentToMount: AeroGCSEnterpriseTeam },
      { path: "/feedback", componentToMount: Feedback, },
      { path: "/logout", componentToMount: Logout, },
      { path: "/my_profile", componentToMount: MyProfile, },
      { path: "/manage_subscription", componentToMount: ManageSubscription, },
      { path: "/edit_profile", componentToMount: editUser, },
      { path: "/aeromegh_plans", componentToMount: AeroMeghPlans, },
      { path: "/invoice", componentToMount: Invoice, },
      { path: "/invoices", componentToMount: Invoices, },
      { path: "/usage_statistics", componentToMount: UsageStatistics, },
      { path: "/profile-settings", componentToMount: ProfileSettings }
    ],
  }
  isUserLoggedIn = () => {
    let cookie = new Cookies()
    let sessionCookie = cookie.get("session_IAMAero")
    return !(sessionCookie == "" ||
      !sessionCookie ||
      window.location.href.includes("/login") ||
      window.location.href.includes("/signup") ||
      window.location.href.includes("/forgotPassword") ||
      window.location.href.includes("/accountActivated") ||
      window.location.href.includes("/resetPasswordSuccessful") ||
      window.location.href.includes("/accept_Inv") ||
      window.location.href.includes("/live_camera_feed")
    )
  }
  componentWillMount() {

    let cookie = new Cookies()
    let sessionCookie = cookie.get("session_IAMAero")

    if (window.location.href.includes("/login") || window.location.href.includes("/signup") || window.location.href.includes("/forgotPassword") || window.location.href.includes("/accountActivated") || window.location.href.includes("/resetPasswordSuccessful")) {
      if (sessionCookie == "" || !sessionCookie) {
        let routes = this.state.stateRoutes
        routes.push({ path: "/login", componentToMount: userLogin })
        routes.push({ path: "/signup", componentToMount: userSignup })
        routes.push({ path: "/forgotPassword", componentToMount: ForgotPassword })
        routes.push({ path: "/accountActivated", componentToMount: accountActivetionmsg })
        routes.push({ path: "/resetPasswordSuccessful", componentToMount: ResetPasswordSuccessful })

        this.setState({
          done: true,
          hideProfile: true,
          stateRoutes: routes,
        })
      } else {
        window.location.replace("/")
      }
    } else if (window.location.href.includes("/live_camera_feed")) {
      let routes = this.state.stateRoutes
      routes.push({ path: "/live_camera_feed", componentToMount: RMPLiveVideoStramingURL })

      this.setState((state) => ({
        ...state,
        done: true,
        hideProfile: true,
        stateRoutes: routes,
      }))
    } else if (sessionCookie == "") {
      window.location.replace("/login")
      return <div />
    } else {
      API.getUserSubbedServices().then(
        (response) => {
          let services = response[0]
          this.setState((prev) => ({
            ...prev,
            services,
            serviceLoad: services.filter((service) => service.title == "Admin Tools" || service.title == 'Analytic Tool')
          }), () => {
            let routes = this.state.stateRoutes
            if (services.length) {
              return services.forEach((service, key) => {
                if (service.title == "Admin Tools") {
                  routes.push({ path: "/admin/users", componentToMount: AdminUsers, })
                  routes.push({ path: "/admin/gcs-installation", componentToMount: AdminGCSInstall, })
                  routes.push({ path: "/admin/machines", componentToMount: TaskAndVmList, })
                  routes.push({ path: "/admin/allSubscription", componentToMount: allSubscription, })
                  routes.push({ path: "/admin/new_subscription", componentToMount: newSubscription, })
                  routes.push({ path: "/admin/discount_coupons", componentToMount: DiscountCoupons, })
                  routes.push({ path: "/admin/subscription_items", componentToMount: subscriptiontems, })
                  routes.push({ path: "/admin/userdetails", componentToMount: AdminUserDetails, })
                  routes.push({ path: "/admin/user_tracking_logs", componentToMount: AdminUserTrackingLogs, })
                  routes.push({ path: "/admin/user_screen_logs", componentToMount: AdminUserScreenLog, })
                  routes.push({ path: "/admin/edituser", componentToMount: AdminEditUsers, })
                  routes.push({ path: "/admin/assign_services", componentToMount: AdminAssignService, })
                  routes.push({ path: "/admin/user_manage_plan", componentToMount: AdminAddChangeSubscription, })
                  routes.push({ path: "/admin/users_stats", componentToMount: AdminUsersStatistics, })
                  routes.push({ path: "/admin/system_monitoring", componentToMount: SystemMonitoring, })
                  routes.push({ path: "/admin/software_versions", componentToMount: SoftwareVersions, })
                } else if (service.title == 'Analytic Tool') {
                  routes.push({ path: "/analytictool/user_statistics", componentToMount: UserStatistics })
                  routes.push({ path: "/analytictool/userdetails", componentToMount: AnalyticUserDetails })
                  routes.push({ path: "/analytictool/user_tracking_logs", componentToMount: AnalyticUserLogs })
                  routes.push({ path: "/analytictool/user_screen_logs", componentToMount: AnalyticScreenLogs })
                  routes.push({ path: "/analytictool/customStatistics", componentToMount: CustomStatistics })
                } else if (service.title == 'AeroGCS Enterprise') {
                  routes.push({ path: "/", componentToMount: DashboardAeroplane })
                  routes.push({ path: "/aerogcsenterprise/flights", componentToMount: Flights })
                  routes.push({ path: "/aerogcsenterprise/flights/acres", componentToMount: Acres })
                  routes.push({ path: "/aerogcsenterprise/flights/pilots", componentToMount: Pilots, })
                  routes.push({ path: "/projects", componentToMount: Projects })
                  routes.push({ path: "/plans", componentToMount: Plans })
                  routes.push({ path: "/allFlightPlans", componentToMount: RMPAllFlightPlans })
                  routes.push({ path: "/drone_management/rent-drone_customers", componentToMount: userCustomer })
                  routes.push({ path: "/drone_management/rent-drone_customer", componentToMount: userCustomerInfo })
                  routes.push({ path: "/drone_management/user_drone_lease", componentToMount: userAllLease })
                  routes.push({ path: "/drone_management/lease_dashboard", componentToMount: leaseDashboard })
                  routes.push({ path: "/drone_management/lease_dronesMgmt", componentToMount: leaseDroneMgmt })
                  routes.push({ path: "/drone_management/lease_drone_details", componentToMount: leaseDroneDetails })
                  routes.push({ path: "/drone_management/lease_drone_invoice", componentToMount: leaseDroneInvoice })
                  routes.push({ path: "/drone_management/lease_drone_invoices", componentToMount: leaseInvoices })
                  routes.push({ path: "/aerogcsenterprise/green_dashboard", componentToMount: AeroGCSGreenDashboard })
                  routes.push({ path: "/aerogcsenterprise/green_all_customers", componentToMount: AeroGCSGreenCustomers })
                  routes.push({ path: "/aerogcsenterprise/green_all_reports", componentToMount: AeroGCSGreenReports })
                  routes.push({ path: "/drone_management/drones", componentToMount: DrnMgmtDrones, })
                  routes.push({ path: "/drone_management/drone_details", componentToMount: DrnMgmtDroneDetails, })
                  routes.push({ path: "/drone_management/drone/errorLogs", componentToMount: FlightErrorLogs, })
                  routes.push({ path: "/drone_control_center/all_drones", componentToMount: DCCAllDrones })
                  routes.push({ path: "/drone_control_center/all_feeds", componentToMount: DCCAllFeeds })
                  routes.push({ path: "/drone_control_center/fly_view", componentToMount: DCCDroneFlyView })
                  routes.push({ path: "/drone_control_center/feed", componentToMount: DCCFeed })
                  routes.push({ path: "/remote_mission/selectLocation", componentToMount: RMSelectLocation, })
                  routes.push({ path: "/remote_mission/image_collection", componentToMount: RMPImage_collection, })
                  routes.push({ path: "/remote_mission/flightPlan", componentToMount: RMFlightPlan, })
                  routes.push({ path: "/remote_mission/assignDroneToPlan", componentToMount: RMAssignDrone, })
                  routes.push({ path: "/remote_mission/flightLogs", componentToMount: Flights, })
                  routes.push({ path: "/remote_mission/detailedlog", componentToMount: RMDetailedLog, })
                  routes.push({ path: "/remote_mission/flight_details", componentToMount: RMFlightDetails, })
                  routes.push({ path: "/remote_mission/my_videos", componentToMount: RMPMyVideos, })
                  routes.push({ path: "/remote_mission/my_images", componentToMount: RMPMyImages, })
                  routes.push({ path: "/remote_mission/flight_route", componentToMount: RMFlightRoute, })
                  routes.push({ path: "/remote_mission/flight_status_logs", componentToMount: RMPFlightStatusLogs, })
                  routes.push({ path: "/remote_mission/flights_statistics", componentToMount: RMPFlightStatistics, })
                  routes.push({ path: "/remote_mission/flights_completed_hours", componentToMount: RMPFlightCompletedHours, })
                  routes.push({ path: "/remote_mission/flight_logs_by_droneId", componentToMount: RMPFlightLogsByDroneId, })
                  routes.push({ path: "/remote_mission/all_flights_logs", componentToMount: RMPAllFlightsLogs, })
                  routes.push({ path: "/remote_mission/surveyFlightPlan", componentToMount: RMPSurveyFlightPlan, })
                  routes.push({ path: "/remote_mission/sprayingFlightPlan", componentToMount: RMPSprayinhFlightPlan, })
                  routes.push({ path: "/remote_mission/sprayingFlightPlot", componentToMount: RMPSprayingFlightPlot, })
                  routes.push({ path: "/request_a_drone/all_videos", componentToMount: AeroStreamVideos, })
                  routes.push({ path: "/request_a_drone/download/progress", componentToMount: AeroStreamDownloadProgress, })
                  routes.push({ path: "/incidentReport", componentToMount: IncidentReport, })
                  routes.push({ path: "/incidentReportDetail", componentToMount: IncidentReportDetail, })
                  routes.push({ path: "/newIncidentReport", componentToMount: NewIncident, })
                  routes.push({ path: "/weeklyReport", componentToMount: WeeklyReport, })
                }
                if (key == services.length - 1) {
                  this.setState({
                    done: true,
                    stateRoutes: routes,
                  })
                }
              })
            } else {
              this.setState({
                done: true,
                stateRoutes: routes,
              })
            }
          })

        },
        (e) => {
          console.log("app.js", e)
          window.location.replace("/login")
        }
      )
    }
  }
  render() {
    let routes = this.state.stateRoutes
    if (this.state.done) {
      return (
        <>
          <BrowserRouter>
            <OnIdleHandlerWrapper>
              {this.state.hideProfile ? <></> :
                <Profile teamsLoaded={() => this.setState({ teamsLoaded: true })} />}
              {<SideBarComponent />}
              {this.state.teamsLoaded || !this.isUserLoggedIn() ?
                <Switch>
                  <Route exact path="/" component={DashboardAeroplane} />
                  {routes.map((route, key) => (
                    <Route exact path={route.path} component={route.componentToMount} />
                  ))}
                  <Route component={F0F} />
                </Switch>
                : <LoaderComponent height={'90vh'} />}
            </OnIdleHandlerWrapper>
          </BrowserRouter>
        </>
      )
    }
    else {
      return <LoaderComponent height={'90vh'} />
    }
  }
}

export default App 