import React, { Component } from "react";
import "./style.css";
import * as API from './api.js';
import backbutton from "./Icon/backbutton.png";


import {
  Map,
  Marker,
  InfoWindow,
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
class RMFlightMapView extends Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      key: "AS_TELE_DATA",
      label: 1,
      latLng: [],
      center: {},
      circleStrokeColor: "orange",
      circleFillColor: "orange",
      flightId: "",
      user: {},
      type: "",
      zoom: 22,
      mapScrollWeel: false,
      mapDoubleClick: false,
      mapZoom: false,
      mapMode: "small"
    };
  }

  drawBoundaries = async (map, google, bounds) => {
    if (this.props.fenceBoundary) {
      let fenceBoundary = JSON.parse(this.props.fenceBoundary)
      const fencePoly = new google.maps.Polygon({
        paths: fenceBoundary.map(coord => {
          if (!this.props.planBoundary) bounds.extend(new google.maps.LatLng(coord[0], coord[1]))
          return ({
            lat: coord[0],
            lng: coord[1]
          })
        }),
        strokeColor: '#FF0000',
        fillOpacity: 0
      });

      fencePoly.setMap(map);
    }
    if (this.props.planBoundary) {
      let planBoundary = JSON.parse(this.props.planBoundary)
      const planBoundaryPoly = new google.maps.Polygon({
        paths: planBoundary.map(coord => {
          bounds.extend(new google.maps.LatLng(coord[0], coord[1]));
          return ({
            lat: coord[0],
            lng: coord[1]
          })
        }),
        strokeColor: 'yellow',
        fillOpacity: 0
      });

      planBoundaryPoly.setMap(map);
    }
    return this.props.planBoundary || this.props.fenceBoundary
  }

  initMap = async (mapProps, map) => {
    var self = this;
    const { google } = mapProps;
    var bounds = new google.maps.LatLngBounds();
    const isBoundsDrawn = await this.drawBoundaries(map, google, bounds)
    this.setFlyView = (latLng) => {
      let gmarkers = [];
      let poly = new google.maps.Polyline({
        strokeColor: "orange",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });
      poly.setMap(map);
      var circle = {
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: this.state.circleStrokeColor,
        fillColor: this.state.circleFillColor,
        fillOpacity: 1,
        scale: 6,
      };
      for (let i = 0; i < latLng.length; i++) {
        let temp = i + 1;
        let lat = latLng[i].lat
        let lng = latLng[i].lng
        let lat1 = parseFloat(lat)
        let lng1 = parseFloat(lng)
        let latlng = { lat: lat1, lng: lng1 }
        gmarkers.push(latlng)
        if (!isBoundsDrawn) bounds.extend(new google.maps.LatLng(lat, lng));
        if (i == latLng.length - 1) {
          poly.setPath(gmarkers)

        }
      }
      map.fitBounds(bounds);

    }
    this.setFlyView(this.state.latLng)
  }

  componentWillMount() {
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.flightId) {
      let state1 = this.props.location.state
      this.setState((state) => ({
        ...state,
        user: state1.user,
        type: state1.type,
        sidebarMode: state1.sidebarMode,
        flightId: state1.flightId,
        mapScrollWeel: true
      },
        API.log(this.props.location.state.flightId, this.state.key, 0, 0).then((data) => {
          let latLng = []
          let temp1 = data.logs;
          let count = 0;
          temp1.forEach((element, i) => {
            let temp = JSON.parse(element.data)
            let latlng = {}
            let lat = temp.lat
            let lng = temp.lon
            latlng.lat = lat;
            latlng.lng = lng;
            latLng.push(latlng)

            count++
            if (count == temp1.length) {
              let lat = latLng[0].lat;
              let lng = latLng[0].lng
              let latlng1 = { lat: lat, lng: lng };
              this.setState((state) => ({
                ...state,
                latLng: latLng,
                center: latlng1,
                mapMode: "large",
                mapZoom: true
              }))
              this.setFlyView(latLng)
            }
          });

        }, (err) => {
          console.log(err)
        })
      ))
    }
    else if (this.props && this.props.flightId) {
      this.setState((state) => ({
        ...state,
        flightId: this.props.flightId,
        mapScrollWeel: false,
      },
        API.log(this.props.flightId, this.state.key, 0, 0).then((data) => {
          let latLng = []
          let temp1 = data.logs;
          let count = 0;
          temp1.forEach((element, i) => {
            let temp = JSON.parse(element.data)
            let latlng = {}
            let lat = temp.lat
            let lng = temp.lon
            latlng.lat = lat;
            latlng.lng = lng;
            latLng.push(latlng)
            count++
            if (count == temp1.length) {
              let lat = latLng[0].lat;
              let lng = latLng[0].lng
              let latlng1 = { lat: lat, lng: lng };
              this.setState((state) => ({
                ...state,
                latLng: latLng,
                center: latlng1,
                mapScrollWeel: false,
                mapDoubleClick: true
              }))
              this.setFlyView(latLng)
            }
          });

        }, (err) => {
          console.log(err)
        })))
    }
  }
  
  render() {
    return (
      <>
        <Map
          id="mapComp"
          style={{
            width: this.props.width ? this.props.width : '446px',
            height: this.props.height ? this.props.height : '350px',
            position: 'absolute'
          }}
          google={this.props.google}
          onReady={this.initMap}
          showIcons={true}
          disableDefaultUI={true}
          initialCenter={this.state.center ? this.state.center : { lat: 20.008482, lng: 73.756834 }}
          center={this.state.center}
          zoomControl={false}
          mapType={"satellite"}
          zoom={this.state.zoom}
          yesIWantToUseGoogleMapApiInternals
        ></Map>
        {
          this.state.mapMode == 'large' ?
            <div className="dcc-all-drones-drone-count" style={{ top: '20px', cursor: 'pointer' }} onClick={() => {
              window.history.back()
            }}>
              <img className="icons" src={backbutton} alt="back" />
            </div> : <div />
        }
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})(RMFlightMapView)
